import { Options } from "highcharts";

export const donutChartOptions: Options = {
  chart: {
    type: 'pie',
    plotShadow: false,
    width: 309,
    height: 240,
    backgroundColor: '#ffffff'
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      animation: true,
      innerSize: '70%',
      borderRadius: 0,
      borderColor: '',
      allowPointSelect: false,
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
    },
  },
  title: {
    text: '',
    // verticalAlign: 'middle',
    // floating: true,
    // text: '$ 0',
    // style: {
    //   color: '#43adf7'
    // }
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    formatter: function (tooltip) {

      if (tooltip.chart && tooltip.chart.series && tooltip.chart.series[0] && tooltip.chart.series[0].data && tooltip.chart.series[0].data[0] && tooltip.chart.series[0].data[0].name !== 'empty') {
        return tooltip.defaultFormatter.call(this, tooltip);
      }
      return false
    }
  },
  series: [
    {
      type: 'pie',
      name: 'balance',
      data: [{
        y: 100,
        color: '#e0e0e0',
        name: 'empty'
        // selected: false
      }],
    },
  ],
};
