<h2 class="dashbox_title">Fees</h2>
<ul class="nav nav-pills primary_tab mb-0" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="pills-trading-fees-tab" data-bs-toggle="pill"
      data-bs-target="#pills-trading-fees" type="button" role="tab" aria-controls="pills-trading-fees"
      aria-selected="true" (click)="changeTab()">
      Trading Fees
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-deposit-fees-tab" data-bs-toggle="pill" data-bs-target="#pills-deposit-fees"
      type="button" role="tab" aria-controls="pills-deposit-fees" aria-selected="false" (click)="changeTab()">
      Deposit & Withdraw Fees
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-deposit-status-tab" data-bs-toggle="pill" data-bs-target="#pills-deposit-status"
      type="button" role="tab" aria-controls="pills-deposit-status" aria-selected="false" (click)="changeTab()">
      Deposit & Withdraw Status
    </button>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-trading-fees" role="tabpanel"
    aria-labelledby="pills-trading-fees-tab" tabindex="0">
    <div class="table-responsive">
      <div class="col-lg-4 col-md-6">
        <div class="search_fee_form">
          <input class="form-control" (input)="onChangeFee($event)" placeholder="Search by Market" type="text"
            name="tradeFeeFilter" value="{{ filter.tradeFeeFilter }}" alphaOnly />
          <button *ngIf="!isEmpty(filter.tradeFeeFilter)" type="button" (click)="clear()">Clear</button>
        </div>
      </div>
      <table class="table primary_table">
        <thead>
          <tr>
            <!-- <th>Last Update on</th> -->
            <th>Markets</th>
            <th>Min Trade Amount</th>
            <th>Maker Fee</th>
            <th>Taker Fee</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredPairList">
            <!-- <td>{{ item.updatedAt | date : "MMM d, y, h:mm:ss" }}</td> -->
            <td>
              <span class="blue_txt">{{ item.tikerRoot }}</span>
            </td>
            <td>{{ item.minQuantity }}</td>
            <td>{{ item.maker_rebate }}%</td>
            <td>{{ item.taker_fees }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="tab-pane fade" id="pills-deposit-fees" role="tabpanel" aria-labelledby="pills-deposit-fees-tab"
    tabindex="0">
    <div class="table-responsive">
      <div class="col-lg-4 col-md-6">
        <div class="search_fee_form">
          <input class="form-control" (input)="onChangeAsset($event)" placeholder="Search by Coin" type="text"
            name="assetFilter" value="{{ filter.assetFilter }}" alphaOnly />
          <button *ngIf="!isEmpty(filter.assetFilter)" type="button" (click)="clear()">Clear</button>
        </div>
      </div>
      <table class="table primary_table">
        <thead>
          <tr>
            <th>Asset</th>
            <th>Withdraw Fee</th>
            <th>Min Withdraw Amount</th>
            <th>Max Withdraw Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredCurrencyList">
            <td>
              <div class="d-flex align-items-center">
                <img src="{{ item.image }}" alt="Crypto" class="img-fluid history_table_icon" />
                <span>{{ item.coin }}</span>
              </div>
            </td>
            <td>{{ item.withdrawFee }}</td>
            <td>{{ item.minimumWithdraw }}</td>
            <td>{{ item.maximumWithdraw }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="tab-pane fade" id="pills-deposit-status" role="tabpanel" aria-labelledby="pills-deposit-status-tab"
    tabindex="0">
    <div class="table-responsive">
      <div class="col-lg-4 col-md-6">
        <div class="search_fee_form">
          <input class="form-control" (input)="onChangeStatus($event)" placeholder="Search by Coin" type="text"
            name="statusFilter" value="{{ filter.statusFilter }}" alphaOnly />
          <button *ngIf="!isEmpty(filter.statusFilter)" type="button" (click)="clear()">Clear</button>
        </div>
      </div>
      <table class="table primary_table">
        <thead>
          <tr>
            <th>Currency</th>
            <th>Deposit Status</th>
            <th>Withdraw Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredStatusList">
            <td>
              <div class="d-flex align-items-center">
                <img src="{{ item.image }}" alt="Crypto" class="img-fluid history_table_icon" />
                <span>{{ item.coin }}</span>
              </div>
            </td>
            <td>
              <span [class]="item.status == 'active' && item.depositStatus == 'active'? 'green_txt': 'red_txt'">
                {{
                item.status == 'active' && item.depositStatus == 'active'
                ? 'Enabled'
                : 'Disabled'
                }}
              </span>
            </td>
            <td>
              <span [class]="item.status == 'active' && item.withdrawStatus == 'active'? 'green_txt': 'red_txt'">
                {{
                item.status == 'active' && item.withdrawStatus == 'active'
                ? 'Enabled'
                : 'Disabled'
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>