<app-header-dashboard></app-header-dashboard>
<main class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="dashbox">
                    <h2 class="dashbox_title">Change phone number</h2>
                    <p>
                        You will need to log in to your account after changing your phone number
                    </p>
                    <div class="row">
                        <div class="col-lg-5">
                            <form action="#" class="auth_form" [formGroup]="form" (ngSubmit)="submitForm()">

                                <div class="mb-3">
                                    <div class="sign_group sign_group_input mb-1">
                                        <label>Phone Number</label>
                                        <div class="mb-0">
                                            <ngx-mat-intl-tel-input formControlName="newPhoneNo"
                                                [enablePlaceholder]="true" [enableSearch]="true" name="newPhoneNo"
                                                (countryChanged)="countryChangedEvent($event)"
                                                (ngModelChange)="onInputChange()">
                                            </ngx-mat-intl-tel-input>
                                        </div>
                                    </div>
                                    <div *ngIf="newPhoneNo?.invalid && (newPhoneNo?.touched || submitted)">
                                        <span class="required" *ngIf="newPhoneNo?.errors?.['required']">
                                            Phone Number field is required
                                        </span>
                                        <span class="required" *ngIf="newPhoneNo?.errors?.['validatePhoneNumber']">
                                            Phone Number is invalid
                                        </span>
                                    </div>
                                    <span class="required" *ngIf="serverErrors?.newPhoneNo">
                                        {{serverErrors.newPhoneNo }}
                                    </span>
                                </div>
                                <!-- [disabled]="newPhoneNo?.invalid"  -->
                                <button class="withdraw_btn mb-3" (click)="requestOtp()" type="button"
                                    *ngIf="!loaders; else timerInSec" [disabled]="otpLoader">
                                    <span *ngIf="otpLoader" class="spinner-grow spinner-grow-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{!isOtpInit ? 'SEND OTP': 'RESEND OTP'}}
                                </button>
                                <ng-template #timerInSec>
                                    <span class="text-end">Resend OTP in {{ seconds }}s</span>
                                </ng-template>
                                <div class="sign_group sign_group_input mt-3" *ngIf="isOtpInit">
                                    <label>OTP</label>
                                    <div class="primary_inp_grp">
                                        <input formControlName="otp" type="text" class="sign_input"
                                            placeholder="Enter 6 digit OTP" (input)="onInputChange()" />
                                    </div>
                                    <div *ngIf="otp?.invalid && (otp?.touched || submitted)">
                                        <span class="required" *ngIf="otp?.errors?.['required']">
                                            otp field is required
                                        </span>
                                    </div>
                                    <span class="required"
                                        *ngIf="serverErrors?.otp">{{serverErrors.otp}}</span>
                                </div>
                                <button *ngIf="isOtpInit" class="sign_btn" [ngClass]="{ disabled: submitted }"
                                    type="submit">
                                    <span *ngIf="submitted" class="spinner-grow spinner-grow-sm" role="status"
                                        aria-hidden="true"></span>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>