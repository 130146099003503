import * as $ from 'jquery';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpotService } from 'src/app/services/spot.service';
import { TradePairFacade } from 'src/app/store/tradepair';
import { spotSocket } from 'src/app/app.module';
import { SocketService } from 'src/app/services/socket.service';
import isEmpty from 'src/app/lib/isEmpty';

@Component({
  selector: 'app-spot',
  templateUrl: './spot.component.html',
  styleUrls: ['./spot.component.css']
})
export class SpotComponent {

  public isMobileLayout = false;
  public isDestopLayout = false;

  tikerRoot: any;

  constructor(
    private route: ActivatedRoute,
    private spotService: SpotService,
    private toastr: ToastrService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1199;
    window.onload = () => this.isMobileLayout = window.innerWidth <= 1199;
    // console.log(this.isMobileLayout, 'isMobileLayout')
    this.route.paramMap.subscribe(params => {
      this.tikerRoot = params.get('tikerRoot');

      if (!isEmpty(this.tikerRoot)) {
        this.socketService.emit("subscribe", this.tikerRoot.replace("_", ""));        
      }
      // this.tradePairFacade.tradePair$.subscribe((pair) => {
      //   console.log(pair, 'pair')
      // })
    });
  }

  ngOnDestroy() {
    if (!isEmpty(this.tikerRoot)) {
      this.socketService.emit("unSubscribe", this.tikerRoot.replace("_", ""));
    }
  }

  cancelOrder() {
    const orderId = localStorage.getItem('cancelOrderId')
    // console.log(orderId, 'getOrderId')
    if (orderId) {
      this.cancelorderApi(orderId)
    }
  }

  cancelorderApi(orderId: string) {
    this.spotService.cancelOrder(orderId).subscribe({
      next: (v: any) => {
        $("#cancelOrderModalBtn").click()
        this.toastr.success(v.message);
      },
      error: (err: any) => {
        console.log(err, 'err.error')
        this.toastr.error(err.error.message);

      }
    })

  }

  closeModel() {
    localStorage.removeItem('cancelOrderId')
  }

}
