import { createAction, props } from '@ngrx/store';

export const dashboardKey = '[Dashboard]';

export const setTotalBalance = createAction(
    `${dashboardKey} SetTotalBalance`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${dashboardKey} Reset`
);