import { createAction, props } from '@ngrx/store';

export const marketPriceKey = '[MarketPrice]';

export const setSpotMarketPrice = createAction(
    `${marketPriceKey} SetSpotMarketPrice`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${marketPriceKey} Reset`
);