<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Change password</h2>
          <p>
            Strong password required. Enter at least 6 characters long. Combine
            uppercase, lowercase letters, numbers and symbols.
          </p>
          <div class="row">
            <div class="col-lg-5">
              <form action="#" class="auth_form" [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="sign_group sign_group_input">
                  <label>Old Password</label>
                  <div class="primary_inp_grp">
                    <input formControlName="oldPassword" [type]="showOldPassword ? 'text' : 'password'"
                      class="sign_input" placeholder="Enter Old Password" (input)="onInputChange()" />
                    <span (click)="hanldeShowOldPassword()" class="input_info">
                      <i [ngClass]="showOldPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </span>
                  </div>
                  <div *ngIf="
                      oldPassword?.invalid &&
                      (oldPassword?.touched || submitted)
                    ">
                    <span class="required" *ngIf="oldPassword?.errors?.['required']">Old password field is
                      required</span>
                  </div>

                  <span class="required" *ngIf="serverErrors?.oldPassword">{{
                    serverErrors.oldPassword
                    }}</span>
                </div>

                <div class="sign_group sign_group_input">
                  <label>New Password</label>
                  <div class="primary_inp_grp">
                    <input formControlName="password" [type]="showNewPassword ? 'text' : 'password'" class="sign_input"
                      placeholder="Enter New Password" (input)="onInputChange()" />
                    <span (click)="hanldeShowNewPassword()" class="input_info">
                      <i [ngClass]="showNewPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </span>
                  </div>
                  <div *ngIf="
                      password?.invalid && (password?.touched || submitted)
                    ">
                    <span class="required" *ngIf="password?.errors?.['required']">Password field is required</span>
                    <span class="required" *ngIf="password?.errors?.['pattern']">Password should contain atleast one
                      uppercase, atleast one lowercase, atleast one number, atleast one special character and minimum 6
                      and maximum 18</span>
                  </div>
                  <span class="required" *ngIf="serverErrors?.password">{{serverErrors.password}}</span>
                </div>

                <div class="sign_group sign_group_input">
                  <label>Confirm New Password</label>
                  <div class="primary_inp_grp">
                    <input formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
                      class="sign_input" placeholder="Re-Enter New Password" (input)="onInputChange()" />
                    <span (click)="hanldeShowConfirmPassword()" class="input_info">
                      <i [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                    </span>
                  </div>

                  <div *ngIf="
                      confirmPassword?.invalid &&
                      (confirmPassword?.touched || submitted)
                    ">
                    <span class="required" *ngIf="confirmPassword?.errors?.['required']">Confirm Password field is
                      required</span>
                  </div>

                  <span class="required" *ngIf="serverErrors?.confirmPassword">{{ serverErrors.confirmPassword }}</span>
                </div>

                <button class="sign_btn" [ngClass]="{ disabled: submitted }" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>