<div class="baseblock_inner baseblock_inner_trade_market">
  <div class="baseblock_content">
    <div class="trade_market_search">
      <div class="primary_inp_grp">
        <input placeholder="Search" type="text" class="primary_inp" (input)="handleSearchPair($event)" />
        <img src="assets/images/search_icon.png" class="img-fluid" alt="Search" />
      </div>
    </div>
    <div class="trade_market_specialwrapper">
      <div class="trade_market_table_wrap">
        <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-fav-tab" data-bs-toggle="pill" data-bs-target="#pills-fav" type="button"
              role="tab" aria-controls="pills-fav" aria-selected="false">
              <i class="fas fa-star"></i>
            </button>
          </li>
          <li class="nav-item" role="presentation" *ngFor="let currency of currencies">
            <button class="nav-link" [ngClass]="{ 'active': currency === quoteCurrency }" id="pills-{{currency}}-tab"
              data-bs-toggle="pill" [attr.data-bs-target]="'#pills-' + currency + '-pair'" type="button" role="tab"
              [attr.aria-controls]="'#pills-' + currency + '-pair'" aria-selected="false">
              {{currency}}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade" id="pills-fav" role="tabpanel" [attr.aria-labelledby]="'pills-fav-tab'"
            tabindex="0">
            <div class="basevirtualizedtable_wrapper trade_market_table">
              <div>
                <div class="basevirtualizedtable_headrow">
                  <div class="basevirtualizedtable_headcell">
                    Market
                  </div>
                  <div class="basevirtualizedtable_headcell">Price</div>
                  <div class="basevirtualizedtable_headcell text-end">
                    Volume
                  </div>
                </div>
              </div>
              <ng-scrollbar track="all" pointerEventsMethod="scrollbar" [autoHeightDisabled]="false">
                <div class="basevirtualizedtable_body"
                  *ngFor="let fav of favPairData | textFilter: 'tikerRoot': searchPair">
                  <div class="basevirtualizedtable_bodyrow">
                    <div class="basevirtualizedtable_bodycell">
                      <span (click)="addToFav(fav)">
                        <i class="fas fa-star" *ngIf="!favPairList.includes(fav.tikerRoot); else selected"></i>
                        <ng-template #selected>
                          <i class="fas fa-star green_txt"></i>
                        </ng-template>
                      </span>
                      <span (click)="changePair(fav)">{{fav.firstCurrencySymbol}}/{{fav.secondCurrencySymbol}}</span>
                    </div>
                    <div class="basevirtualizedtable_bodycell">
                      {{truncateDecimals(fav.markPrice, fav.secondFloatDigit)}}
                    </div>
                    <div class="basevirtualizedtable_bodycell text-end">
                      {{toFixedDown(fav.secondVolume, fav.secondFloatDigit)}}
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </div>
          <div class="tab-pane fade" [ngClass]="{ 'active show': currency === quoteCurrency }"
            [id]="'pills-' + currency + '-pair'" role="tabpanel"
            [attr.aria-labelledby]="'pills-' + currency + '-pair-tab'" tabindex="0" *ngFor="let currency of currencies">
            <div class="basevirtualizedtable_wrapper trade_market_table">
              <div>
                <div class="basevirtualizedtable_headrow">
                  <div class="basevirtualizedtable_headcell">
                    Market
                  </div>
                  <div class="basevirtualizedtable_headcell">Price</div>
                  <div class="basevirtualizedtable_headcell text-end">
                    Volume
                  </div>
                </div>
              </div>
              <ng-scrollbar track="all" pointerEventsMethod="scrollbar" [autoHeightDisabled]="false">
                <div class="basevirtualizedtable_bodyrow"
                  *ngFor="let pair of pairs[currency]  | textFilter: 'tikerRoot': searchPair">
                  <div class="basevirtualizedtable_bodycell">
                    <span (click)="addToFav(pair)">
                      <i class="fas fa-star" *ngIf="!favPairList?.includes(pair.tikerRoot); else selected"></i>
                      <ng-template #selected>
                        <i class="fas fa-star green_txt"></i>
                      </ng-template>
                    </span>
                    <span (click)="changePair(pair)">{{pair.firstCurrencySymbol}}/{{pair.secondCurrencySymbol}}</span>
                  </div>
                  <div class="basevirtualizedtable_bodycell text-end">
                    <span [title]="truncateDecimals(pair.markPrice, pair.secondFloatDigit)">
                      {{nFormatter(truncateDecimals(pair.markPrice, pair.secondFloatDigit))}}
                    </span>
                  </div>
                  <div class="basevirtualizedtable_bodycell text-end">
                    <span [title]="truncateDecimals(pair.markPrice, pair.secondFloatDigit)">
                      {{nFormatter(truncateDecimals(pair.secondVolume, pair.secondFloatDigit))}}
                    </span>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>