<div class="login_wrapper">
  <div class="authbanner_wrapper">
    <a routerLink="/">
      <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="Login"
        class="img-fluid auth_logo" />
    </a>
    <div class="auth_content">
      <h2>The Ultimate Utility Crypto Exchange!</h2>
      <p>
        Experience the ultimate utility crypto exchange. Our mission is to empower traders, from novices to experts, with the tools they need to succeed in the dynamic world of cryptocurrencies.
      </p>
    </div>
    <img src="assets/images/signin_img.png" alt="Login" class="img-fluid auth_right_img" />
  </div>
  <div class="auth_form_wrapper">
    <div class="row">
      <div class="col-lg-12">
        <app-login-form></app-login-form>
      </div>
    </div>
  </div>
</div>