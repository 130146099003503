import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, WalletState } from './wallet.state';
import { setWallet, reset } from './wallet.actions';

export const walletFeatureKey = 'wallet';

export const walletReducers: ActionReducer<WalletState> = createReducer(
    initialState,
    on(setWallet, (state, action) => action.payload),
    on(reset, () => initialState)
);