import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { signIn, signOut } from './auth.actions';
import { selectAuth } from './auth.selectors';
import { Observable } from 'rxjs';
import { AuthState } from './auth.state';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
    private readonly store: Store = inject(Store);

    readonly auth$: Observable<AuthState> = this.store.select(selectAuth);

    onSignInSuccess(payload: any): void {
        this.store.dispatch(signIn({ payload }));
    }

    onSignOutSuccess(): void {
        this.store.dispatch(signOut());
    }
}