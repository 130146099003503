import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { WalletService } from 'src/app/services/wallet.service';

// Import facade
import { PairFacade } from '../../store/pair'
import { CurrencyFacade } from '../../store/currency'


// Import lib
import isEmpty from 'src/app/lib/isEmpty';

// Import config
import { spotSocket } from 'src/app/app.module';

// Import lib
import { convert, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { MarketPriceFacade } from 'src/app/store/marketprice';
import { Cookies } from 'src/app/lib/cookies';
import { TradeAssetFacade } from 'src/app/store/trade-asset';

@Component({
  selector: 'app-home-market-trend',
  templateUrl: './home-market-trend.component.html',
  styleUrls: ['./home-market-trend.component.css'],
})
export class HomeMarketTrendComponent {

  toFixedDown = toFixedDown
  convert = convert
  truncateDecimals = truncateDecimals

  pairs: any = {}
  currencylist: any = {}
  pairData: any = {}
  type = "hot"


  constructor(
    private service: SpotService,
    private pairFacade: PairFacade,
    private currencyFacade: CurrencyFacade,
    private spotSocket: spotSocket,
    private marketPriceFacade: MarketPriceFacade,
  ) {
  }

  ngOnInit() {
    this.currencyRedux()
    this.getPairList()
    this.trendType(this.type)
    this.spotSocket.on('marketPrice', this.marketPriceWs.bind(this))
  }
  
  // ngOnDestroy() {
  //   this.spotSocket.off('marketPrice');
  // }

  getPairList() {
    this.service.getPairList()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.pairFacade.setPair(v.result)

            this.pairs = v.result;


          }
        },
        error: (err: any) => {
        }
      })
  }
  marketPriceWs(result: any) {
    let currPair = -1;
    let pairsObj = JSON.parse(JSON.stringify(this.pairs))
    currPair = pairsObj.findIndex((el: any) => el._id == result.pairId);
    if (currPair >= 0) {
      // console.log(result.data.markPrice, '--result.data.markPrice')
      pairsObj[currPair].markPrice = result.data.markPrice;
      pairsObj[currPair].change = result.data.change;
      pairsObj[currPair].firstVolume = result.data.firstVolume;
      this.pairs = pairsObj
    }
  }

  currencyRedux() {
    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        this.currencylist = data;
      },
      error: (err: any) => {
        // console.log(err, '-----21');
      },
    });
  }

  marketTrend(type: any) {
    this.pairFacade.pair$.subscribe({
      next: (data: any) => {
        this.pairs = data;

        if (type == 'hot') {

          this.pairs = data;
        }

        else if (type == 'gain') {
          let value = JSON.parse(JSON.stringify(data))
          this.pairs = value.sort((a: any, b: any) => (b.change - a.change))
        }

        else if (type == 'loss') {
          let value = JSON.parse(JSON.stringify(data))
          this.pairs = value.sort((a: any, b: any) => (a.change - b.change))

        }


      },
      error: (err: any) => {
        // console.log(err, '-----21');
      },
    });
  }


  getCurrecyImage(currency: string) {
    let curr = this.currencylist.find((el: any) => el.coin == currency);
    return curr?.image ? curr?.image : "";

  }


  trendType(type: any) {
    this.type = type
    this.marketTrend(type)

  }


}


