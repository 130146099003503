import { createAction, props } from '@ngrx/store';

export const currencyKey = '[Currency]';

export const setCurrency = createAction(
    `${currencyKey} SetCurrency`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${currencyKey} Reset`
);