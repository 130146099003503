import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { userSocket } from 'src/app/app.module';
import { getDisplayedPages } from 'src/app/lib/pagination';

// Import service
import { AnnouncementService } from 'src/app/services/announcement.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {

  announcements: any = []
  getDisplayedPages: number[] = []
  announcePaginator: any = []
  getDisplayedAnnPages: number[] = []
  announcefilter: any = {
    page: 1,
    limit: 5,
    totalPages: 0
  }

  activities: any = []
  activityPaginator: any = []
  activityFilter: any = {
    page: 1,
    limit: 5,
    totalPages: 0
  }
  unread_count = 0;

  constructor(
    private announcementService: AnnouncementService,
    private userService: UserService,
    private userSocket: userSocket,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getNotification(this.activityFilter)
    this.getAnnouncement(this.announcefilter)
    this.userSocket.on('unreadnotification', this.unreadNotificationSocket.bind(this))
    this.userSocket.on('announcement', this.unreadAnnouncement.bind(this))
    
    this.unreadCount()
    this.userSocket.on('unread_count', this.unreadCountSocket.bind(this))
  }

  unreadCount() {
    this.userService.unreadCount()
      .subscribe({
        next: (v: any) => {
          this.unread_count = v.count
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  unreadCountSocket(result: any) {
    // console.log(result, 'result')
    this.unread_count = result
  }
  
  // ngOnDestroy() {
  //   this.userSocket.off('unreadnotification');
  //   this.userSocket.off('announcement');
  // }

  getNotification(params: any) {
    this.userService.getNotification(params)
      .subscribe({
        next: (v: any) => {
          this.activities = v.result
          this.activityFilter.totalPages = Math.ceil(v.count / this.activityFilter.limit)
          // this.activityPaginator = Array(this.activityFilter.totalPages).fill(1).map((x, y) => x + y)
          this.getDisplayedPages = getDisplayedPages(this.activityFilter.page, this.activityFilter.totalPages, 5)
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  getAnnouncement(params: any) {
    this.announcementService.getAll(params)
      .subscribe({
        next: (v: any) => {
          this.announcements = v.result
          this.announcefilter.totalPages = Math.ceil(v.count / this.announcefilter.limit)
          // this.announcePaginator = Array(this.announcefilter.totalPages).fill(1).map((x, y) => x + y)
          this.getDisplayedAnnPages = getDisplayedPages(this.announcefilter.page, this.announcefilter.totalPages, 5)
        },
        error: (err) => console.log(err)
      })
  }

  unreadAnnouncement(data: any) {
    this.getAnnouncement(this.announcefilter)
    this.toastr.info(data.title.substring(0, 20) + '...', 'Announcement!')
    
    // return this.userSocket.off('announcement')
  }

  unreadNotificationSocket(result: any) {
    // console.log(result, 'result unread socket')
    this.getNotification(this.activityFilter)
    
    // return this.userSocket.off('unreadnotification')
  }

  nextPageAnn() {
    this.announcefilter.page += 1
    this.getAnnouncement(this.announcefilter)
  }

  goToPageAnn(pageNo: number) {
    this.announcefilter.page = pageNo
    this.getAnnouncement(this.announcefilter)
  }

  previousPageAnn() {
    if (this.announcefilter.page > 1)
      this.announcefilter.page -= 1
    this.getAnnouncement(this.announcefilter)
  }

  nextPageAct() {
    this.activityFilter.page += 1
    this.getNotification(this.activityFilter)
  }

  goToPageAct(pageNo: number) {
    this.activityFilter.page = pageNo
    this.getNotification(this.activityFilter)
  }

  previousPageAct() {
    if (this.activityFilter.page > 1)
      this.activityFilter.page -= 1
    this.getNotification(this.activityFilter)
  }

}
