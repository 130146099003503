import { Component, Renderer2 } from '@angular/core';

// Import facade
import { UserSettingFacade } from 'src/app/store/usersetting';

// Import service
import { UserService } from 'src/app/services/user.service';
import { Cookies } from 'src/app/lib/cookies';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.css']
})
export class ThemeComponent {

  userSettings: any = {}
  activeTheme = ''
  theme = ''

  constructor(
    private renderer: Renderer2,
    private userSettingFacade: UserSettingFacade,
    private cookies: Cookies,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userSettingFacade.usersetting$.subscribe((data: any) => {
      // this.userSettings = data
      // console.log(data.userSetting.theme, 'data.usersetting')
      this.theme = data.userSetting.theme
    })
    this.activeTheme = localStorage.getItem('theme') || 'dark'
    if (this.activeTheme === 'light') {
      this.renderer.addClass(document.body, 'light_theme');
    }
  }

  onClickToggleClass() {
    const activeTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark'
    let reqData = {
      theme: activeTheme
    }
    localStorage.setItem('theme', activeTheme)
    this.updateDOM(activeTheme)
    
    this.userSettingFacade.updateTheme(reqData)
    if (this.cookies.isLogin()) {
      this.userService.updateThemeApi(reqData).subscribe({
        next: () => {
          this.activeTheme = activeTheme
        },
        error: (err) => console.log(err)
      })
    }
  }

  updateDOM(theme: string) {
    if (theme !== '' || typeof theme !== undefined) {
      if (theme === 'dark') {
        this.renderer.removeClass(document.body, 'light_theme');
      } else if (theme === 'light') {
        this.renderer.addClass(document.body, 'light_theme');
      }
    }
  }

}
