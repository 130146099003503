import { createAction, props } from '@ngrx/store';

export const authKey = '[Auth]';

export const signIn = createAction(
    `${authKey} SignIn`,
    props<{ payload: any }>()
);

export const signOut = createAction(
    `${authKey} SignOut`
);