import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

// Import services
import { AuthService } from 'src/app/services/auth.service'
import { WalletService } from 'src/app/services/wallet.service';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent {
  sub: any;
  person: any;

  constructor(
    private route: ActivatedRoute,
    private service: AuthService,
    private Walletservice: WalletService,

    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    const type = this.route.snapshot.params['type']
    // console.log(type, 'type')
    if (type === 'forgotPassword') {
      this.forgotPassword()
    } else if (type === 'register') {
      this.emailActivation()
    } else if (type === 'coinwithdraw') {
      // console.log("Coin with")
      this.coinWithdrawActivation()
    } else if (type === 'withdraw-cancel') {
      this.withdrawCancel()
    } else if (type === 'fiatWithdraw') {
      this.fiatWithdrawActivation()
    }

  }

  forgotPassword() {
    const auth = this.route.snapshot.queryParamMap.get('auth');
    this.service.resetPasswordVerification({
      authToken: auth
    }).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.router.navigateByUrl("/reset-password/" + auth)
          this.toastr.success(v.message)
        }
      },
      error: err => {
        // console.log(err)
        if ([400, 500].includes(err.status)) {
          this.router.navigateByUrl("/signin")
          this.toastr.error(err.error.message)
        }
      }
    })
  }

  emailActivation() {
    const auth = this.route.snapshot.queryParamMap.get('auth');
    this.service.userEmailActivation({
      userId: auth
    }).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.router.navigateByUrl("/signin")
          this.toastr.success(v.message)
        }
      },
      error: err => {
        if ([400].includes(err.status)) {
          this.router.navigateByUrl("/signup")
          this.toastr.error(err.error.message)
        }
      }
    })
  }

  coinWithdrawActivation() {
    const auth = this.route.snapshot.queryParamMap.get('auth');
    this.Walletservice.coinRequestVerify({
      token: auth
    }).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.router.navigateByUrl("/signin")
          this.toastr.success(v.message)
        }
      },
      error: err => {
        // console.log(err)
        if ([400].includes(err.status)) {
          this.router.navigateByUrl("/signup")
          this.toastr.error(err.error.message)
        }
      }
    })
  }

  withdrawCancel() {
    const auth = this.route.snapshot.queryParamMap.get('auth');
    this.Walletservice.withdrawCancel({
      token: auth
    }).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.router.navigateByUrl("/signin")
          this.toastr.success(v.message)
        }
      },
      error: err => {
        // console.log(err)
        if ([400].includes(err.status)) {
          this.router.navigateByUrl("/signup")
          this.toastr.error(err.error.message)
        }
      }
    })
  }

  fiatWithdrawActivation() {
    const auth = this.route.snapshot.queryParamMap.get('auth');
    this.Walletservice.apifiatRequestVerify({
      token: auth
    }).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.router.navigateByUrl("/signin")
          this.toastr.success(v.message)
        }
      },
      error: err => {
        if ([400].includes(err.status)) {
          this.router.navigateByUrl("/signup")
          this.toastr.error(err.error.message)
        }
      }
    })
  }


}
