<div
  class="dashbox verify_box cursor-pointer"
  (click)="redirect('identification')"
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.72 357.12">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M410.09,38.79a6.75,6.75,0,0,0-6.15-6,136.13,136.13,0,0,1-37.39-10.41,132.77,132.77,0,0,1-34-20.59,6.77,6.77,0,0,0-9.13,0,132.43,132.43,0,0,1-34,20.59A136.68,136.68,0,0,1,252.3,32.77a6.77,6.77,0,0,0-6.19,6c-.3,2.87-7.06,70.38,9.64,103.71,20,39.82,67.6,62.16,69.6,63.11a6.79,6.79,0,0,0,5.68,0c2-1,49.62-23.29,69.6-63.11C417.16,109.17,410.4,41.66,410.09,38.79ZM372.23,85.91l-47.32,47.32a6.72,6.72,0,0,1-9.57,0L283.8,101.7a6.77,6.77,0,0,1,9.57-9.57l26.77,26.77,42.53-42.52a6.76,6.76,0,0,1,9.56,9.53Z"
        />
        <circle cx="128" cy="177.12" r="36" />
        <path
          d="M144,229.12H112q-1.05,0-2.1,0a47,47,0,0,0-45.9,48v4a12,12,0,0,0,12,12H180a12,12,0,0,0,12-12v-4c0-.71,0-1.41,0-2.11A47,47,0,0,0,144,229.12Z"
        />
        <path
          d="M363,210.44A219.75,219.75,0,0,1,333.33,228l-.09.05a12,12,0,0,1-10.08,0l-.09-.05c-6.15-2.91-60.76-29.82-84.16-76.47-8.5-16.95-12-40.8-13.24-63.13L204,69.12H28a28,28,0,0,0-28,28v232a28,28,0,0,0,28,28H356a28,28,0,0,0,28-28v-100ZM208,277.12v4a28,28,0,0,1-28,28H76a28,28,0,0,1-28-28v-4a62.31,62.31,0,0,1,45.28-61.4,52,52,0,1,1,69.44,0A62.31,62.31,0,0,1,208,277.12Zm40-32h48a8,8,0,0,1,0,16H248a8,8,0,0,1,0-16Zm80,64H248a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Z"
        />
      </g>
    </g>
  </svg>
  <h2>KYC Verification</h2>
  <h5
    [ngClass]="{
      green_txt: user.kycStatus == 'approved',
      red_txt: user.kycStatus !== 'approved'
    }"
  >
    {{
      user.kycStatus == "approved"
        ? "Verified"
        : "Unverified"
    }}
  </h5>
</div>
