export interface UserState {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    blockNo: string,
    address: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    emailStatus: string,
    phoneStatus: string,
    phoneCode: string,
    phoneNo: string,
    type: string,
    idProof: string,
    addressProof: string,
    twoFAStatus: string,
    createAt: string,
    loginHistory: any,
    bankDetail: any,
    profileImage: string,
    referralCode: string,
    changepassword: string,
    _id: string
}

export const initialState: any = {}