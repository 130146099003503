<div class="dashbox">
  <div class="dashbox_title_flex">
    <h2 class="dashbox_title">Balance Details</h2>
    <!-- <select class="form-select" aria-label="Default select example" disabled>
      <option selected>{{usersettings.userSetting.currencySymbol}}</option>
      <option value="1">USD</option>
      <option value="2">INR</option>
    </select> -->
  </div>
  <div class="dash_chart_panel">
    <div class="dash_chart_div">
      <!-- <p *ngIf="chartdata.length"> -->
      <!-- <canvas id="myChart">{{ chart }}</canvas> -->
      <!-- <div class="pie" [chart]="doughnutchart"></div> -->
      <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag"></highcharts-chart>

      <!-- </p> -->
    </div>
    <div class="dash_chart_detail">
      <div class="dash_chart_detail_top">
        <div>
          <h5>Available Balance</h5>
          <h2 [countUp]="totalBalanceBtc" [reanimateOnClick]="false" [options]="btcOptions"></h2>
          <h5 class="mb-0" [countUp]="totalBalanceUsdt" [reanimateOnClick]="false" [options]="usdtOptions"></h5>
        </div>
        <div>
          <span class="deposit_btn me-2 cursor-pointer" (click)="navigate('deposit-fiat')">Deposit</span>
          <span class="withdraw_btn cursor-pointer" (click)="navigate('withdraw-fiat')">Withdraw</span>
        </div>
      </div>
      <div class="dash_chart_detail_bottom">
        <ul class="chart_list">
          <li *ngFor="let currency of balanceList">
            <label>{{ currency.currency }}</label>
            <label>$ {{ toFixedDown(currency.convertPrice, 6) }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>