import { Component, inject, ElementRef } from '@angular/core';

// Import service
import { AuthService } from 'src/app/services/auth.service';
import { UserFacade } from '../../store/user';
import { UserService } from 'src/app/services/user.service';
import { userSocket } from 'src/app/app.module';
import { UserSettingFacade } from 'src/app/store/usersetting';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  isProfileUpdated: string
  fullname: string
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  // providers: [userSocket]
})
export class SidebarComponent {
  user: any;
  userId: any;
  currentRoute = ''
  usersetting = {
    theme: ''
  }
  unread_count = 0;
  profileDetails: Profile = {
    firstName: '',
    lastName: '',
    email: '',
    isProfileUpdated: '',
    fullname: ''
  };
  date = new Date()
  private readonly userFacade: UserFacade = inject(UserFacade);

  constructor(
    private service: AuthService,
    private userService: UserService,
    private userSocket: userSocket,
    private elementRef: ElementRef,
    private userSettingFacade: UserSettingFacade,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {
    this.getprofile()
  }

  ngOnInit() {
    // router.changes.subscribe((val: any) => /*whatever*/)
    // this.router.events.subscribe((event: any) => {
    //   console.log(event, 'event')
    //   if (event instanceof NavigationEnd) {
    //   }
    // });

    this.unreadCount()
    this.userSocket.on('unread_count', this.unreadCountSocket.bind(this))
    this.getUserSetting()
  }

  // ngOnDestroy() {
  //   this.userSocket.off('unread_count');
  // }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.usersetting.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }
  signOut(): void {
    this.service.logoutApi()
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.profileDetails.email = data.email
          this.profileDetails.firstName = data.firstName
          this.profileDetails.lastName = data.lastName
          this.profileDetails.fullname = data.firstName + ' ' + data.lastName
          this.profileDetails.isProfileUpdated = data.isProfileUpdated
        },

        complete: () => { }
      })
  }

  unreadCount() {
    this.userService.unreadCount()
      .subscribe({
        next: (v: any) => {
          this.unread_count = v.count
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  unreadCountSocket(result: any) {
    this.unread_count = result
  }

  // ngAfterViewInit() {
  //   // Use jQuery to handle the click event
  //   $(this.elementRef.nativeElement).find('.sidebar__nav-link').click(() => {
  //     $(".sidebar").removeClass("sidebar--active");
  //   });
  // }
  switchNearMalls()
  {
    this.userFacade.user$.subscribe({
      next: (userData) => {
        this.userId = userData._id;
        this.user = userData.userId;
      },
      error: (error) => { }
      })
    const token = this.cookieService.get('user_token');
    const getemail = this.cookieService.get('email') || '';
    const apiBaseUrl = `https://utility.nearmalls.com/`;
    // const apiBaseUrl = `http://credbullapp.nearmalls.com/login?token=${token}&email${getemail}&userId=${this.userId}&user=${this.user}`;
   //const apiBaseUrl = `http://localhost:58204/login?token=${token}&email${getemail}&userId=${this.userId}&user=${this.user}`;
   const decodedUrl = decodeURIComponent(apiBaseUrl);
     window.open(decodedUrl, '_blank');
  }
}
