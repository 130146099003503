import { Component, ElementRef, Input, ViewChild } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

// Import lib
// import { encryptObject } from 'src/app/lib/cryptoJs';
import { toFixedDown } from 'src/app/lib/roundOf';

// Import service
// import { SpotService } from 'src/app/services/spot.service';
import { ShareService } from 'src/app/services/share.service';

// Import facade
import { TradePairFacade } from 'src/app/store/tradepair';
// import { TradeAssetFacade } from 'src/app/store/trade-asset';

@Component({
  selector: 'app-limitorder',
  templateUrl: './limitorder.component.html',
  styleUrls: ['./limitorder.component.css']
})
export class LimitorderComponent {
  // @Input() isSellVisible: any;
  // @Input() isBuyVisible: any;

  // @Input() inputValue: any = {};

  // firstCurrency: any = {}
  // secondCurrency: any = {}
  tradePair: any = {}
  updateBuyForm = {}
  updateSellForm = {}

  // buyForm: FormGroup;
  // sellForm: FormGroup;
  // buyPercentage: number = 0
  // sellPercentage: number = 0
  // submitted: boolean = false;
  // serverErrors: any = {}

  closed$ = new Subject<any>();

  updatedValues: any = {}

  constructor(
    // private _fb: FormBuilder,
    // private tradeAssetFacade: TradeAssetFacade,
    // private service: SpotService,
    // private toastr: ToastrService,
    private shareService: ShareService,
    private tradePairFacade: TradePairFacade,
  ) {
    // this.buyForm = this._fb.group({
    //   price: ['', Validators.required],
    //   quantity: ['', Validators.required],
    //   total: ['']
    // });

    // this.sellForm = this._fb.group({
    //   price: ['', Validators.required],
    //   quantity: ['', Validators.required],
    //   total: ['']
    // });
  }

  ngOnInit() {
    // this.tradeAssetFacade.tradeAsset$.subscribe({
    //   next: ({ firstCurrency, secondCurrency }: any) => {
    //     this.firstCurrency = firstCurrency
    //     this.secondCurrency = secondCurrency
    //   },
    //   error: (err: any) => { }
    // })
    this.getTradePair()
    this.updateOrderValues()
  }

  // get trade pair from redux
  getTradePair() {
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
      },
      error: (err: any) => { }
    })
  }

  // get value from order book and patch to order place
  updateOrderValues() {
    this.shareService.headerClick$.pipe(
      takeUntil(this.closed$)
    ).subscribe({
      next: value => {

        const total = parseFloat(value.price) * parseFloat(value.quantity)
        const updatedValues = {
          price: toFixedDown(value.price, this.tradePair.secondFloatDigit),
          quantity: toFixedDown(value.quantity, this.tradePair.firstFloatDigit),
          total: toFixedDown(total, this.tradePair.secondFloatDigit),
        };

        if (value.type === 'buy') {
          this.updateSellForm = updatedValues
        } else if (value.type === 'sell') {
          this.updateBuyForm = updatedValues
        }
      }
    });
  }

  // get price() { return this.buyForm.get('price') }
  // get quantity() { return this.buyForm.get('quantity') }

  // handleBuyTotal() {
  //   const { price, quantity } = this.buyForm.value
  //   const total = price * quantity
  //   this.buyForm.patchValue({
  //     total: total
  //   })
  // }

  // handleSellTotal() {
  //   const { price, quantity } = this.sellForm.value
  //   const total = price * quantity
  //   this.sellForm.patchValue({
  //     total: total
  //   })
  // }

  // handleSetPercentage(percent: number, buyorsell: string) {
  //   const spotBal = this.secondCurrency.spotBal
  //   const newPrice = (spotBal / 100) * percent
  //   if (buyorsell === 'buy') {
  //     this.buyPercentage = percent
  //     this.buyForm.patchValue({
  //       price: newPrice,
  //       total: newPrice * this.buyForm.value.quantity
  //     })
  //   } else if (buyorsell === 'sell') {
  //     this.sellPercentage = percent
  //     this.sellForm.patchValue({
  //       price: newPrice,
  //       total: newPrice * this.sellForm.value.quantity
  //     })
  //   }
  // }

  // IncDec(field: string, type: string, buyorsell: 'buy' | 'sell') {
  //   if (buyorsell == 'buy') {
  //     let value = this.buyForm.value[field];
  //     if (type === 'dec' && value > 0)
  //       value--;
  //     else if (type === 'inc')
  //       value++;

  //     this.buyForm.patchValue({
  //       [field]: value
  //     });
  //     this.handleBuyTotal()
  //   } else {
  //     let value = this.sellForm.value[field];
  //     if (type === 'dec' && value > 0)
  //       value--;
  //     else if (type === 'inc')
  //       value++;

  //     this.sellForm.patchValue({
  //       [field]: value
  //     });
  //     this.handleSellTotal()
  //   }
  // }

  // submitBuyForm() {
  //   const { price, quantity } = this.buyForm.value
  //   this.submitted = true

  //   if (!this.buyForm.valid)
  //     return

  //   let reqData = {
  //     price,
  //     quantity,
  //     buyorsell: 'buy',
  //     orderType: 'limit',
  //     spotPairId: this.tradePair._id,
  //     newdate: new Date()
  //   }
  //   const encryptData = {
  //     token: encryptObject(reqData)
  //   }

  //   this.service.orderPlace(encryptData)
  //     .subscribe({
  //       next: (v: any) => {
  //         this.submitted = false
  //         if (v.status) {
  //           this.toastr.success(v.message)
  //           this.buyForm.reset()
  //         } else {
  //           this.toastr.success(v.message)
  //         }
  //       },
  //       error: (err: any) => {
  //         this.submitted = false
  //         if (err.error.message) {
  //           this.toastr.error(err.error.message)
  //         } else if (err.error.errors) {
  //           const validationErrors = err.error.errors;
  //           this.serverErrors = validationErrors || {};
  //         }
  //       }
  //     })
  // }

  // submitSellForm() {
  //   const { price, quantity } = this.sellForm.value
  //   // this.submitted = true

  //   if (!this.sellForm.valid)
  //     return

  //   let reqData = {
  //     price,
  //     quantity,
  //     buyorsell: 'sell',
  //     orderType: 'limit',
  //     spotPairId: this.tradePair._id,
  //     newdate: new Date()
  //   }
  //   const encryptData = {
  //     token: encryptObject(reqData)
  //   }

  //   this.service.orderPlace(encryptData)
  //     .subscribe({
  //       next: (v: any) => {
  //         this.submitted = false
  //         if (v.status) {
  //           this.toastr.success(v.message)
  //           this.buyForm.reset()
  //         } else {
  //           this.toastr.success(v.message)
  //         }
  //       },
  //       error: (err: any) => {
  //         this.submitted = false
  //         if (err.error.message) {
  //           this.toastr.error(err.error.message)
  //         } else if (err.error.errors) {
  //           const validationErrors = err.error.errors;
  //           this.serverErrors = validationErrors || {};
  //         }
  //       }
  //     })
  // }

}
