import { Component } from '@angular/core';

// Import service
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent {

  content: string = ''
  title: string = ''

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getCmsContent()
  }

  getCmsContent() {
    this.commonService.getCmsPages('privacy-policy')
      .subscribe({
        next: (v: any) => {
          this.content = v.result?.content
          this.title = v.result?.title
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }
}
