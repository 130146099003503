import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LaunchService {
  constructor(private http: HttpClient) {}

  getLaunchData(listType: string) {
    return this.http.get(
      environment.launchpadApiUrl + 'api/launchpad/list/' + listType
    );
  }
  getLaunchById(id: string) {
    return this.http.get(
      environment.launchpadApiUrl + 'api/launchpad/listbyid/' + id
    );
  }
  getCommit(id: string, params: any) {
    return this.http.get(
      environment.launchpadApiUrl + 'api/launchpad/commitList/' + id,
      { params }
    );
  }
  commitAdd(data: any) {
    return this.http.post(
      environment.launchpadApiUrl + 'api/launchpad/commit-add',
      data
    );
  }
}
