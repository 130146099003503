import { Injectable } from "@angular/core";

import { ReplaySubject } from "rxjs";

@Injectable()
export class ShareService {
  public headerClickSrc = new ReplaySubject<any>(1);
  public headerClick$ = this.headerClickSrc.asObservable();

  constructor() {}
}
