import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent {
  
  isProfileUpdated = false

  constructor (private userFacade: UserFacade, private router: Router) {

  }

  ngOnInit() {
    this.userFacade.user$.subscribe({
      next: (data: any) => {
        this.isProfileUpdated = data.isProfileUpdated
        if (data.isProfileUpdated) {
          return this.router.navigateByUrl('/dashboard')
        }
        return
      }
    })
  }

}
