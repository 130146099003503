<app-header></app-header>
<main class="inner_cms_layout launchpad_layout">
  <section class="launchpad_page_header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="launchpad_page_header_content">
            <!-- <h2>CRED BULL</h2> -->
            <!-- <h1>LAUNCHPAD</h1> -->
            <!-- <h5>
              A token launch platform for groundbreaking blockchain projects
            </h5> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section launchpad_list_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-upcoming-tab" data-bs-toggle="pill"
                data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming"
                aria-selected="true" name="upcoming" (click)="onInputChange($event)">
                Upcoming
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-live-tab" data-bs-toggle="pill" data-bs-target="#pills-live"
                type="button" role="tab" aria-controls="pills-live" aria-selected="false" name="live"
                (click)="onInputChange($event)">
                Live
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed"
                type="button" role="tab" aria-controls="pills-completed" aria-selected="false" name="completed"
                (click)="onInputChange($event)">
                Completed
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-upcoming" role="tabpanel"
              aria-labelledby="pills-upcoming-tab" tabindex="0">
              <app-launchpad-list [data]="data" [launchData]="launchData" />
            </div>
            <div class="tab-pane fade" id="pills-live" role="tabpanel" aria-labelledby="pills-live-tab" tabindex="0">
              <app-launchpad-list [data]="data" [launchData]="launchData" />
            </div>
            <div class="tab-pane fade" id="pills-completed" role="tabpanel" aria-labelledby="pills-completed-tab"
              tabindex="0">
              <!-- <div class="text-center">
                <img
                  src="assets/images/coming_soon.png"
                  class="img-fluid"
                  alt="Token"
                />
                <h5 class="mt-3">Coming Soon!</h5>
              </div> -->
              <app-launchpad-list [data]="data" [launchData]="launchData" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>