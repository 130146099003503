import { Component, Input, SimpleChanges } from '@angular/core';
import { OnrampWebSDK } from '@onramp.money/onramp-web-sdk';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onramp-overlay-mode',
  templateUrl: './onramp-overlay-mode.component.html',
  styleUrls: ['./onramp-overlay-mode.component.css']
})
export class OnrampOverlayModeComponent {
  @Input() SdkOptions: any
  @Input() loading: any

  appId: number = environment.onrampAppID

  onrampInstance: any

  ngOnChanges() {
    if (this.loading) {
      this.onrampInstance = new OnrampWebSDK({
        appId: this.appId,
        coinCode: this.SdkOptions.coinCode,
        network: this.SdkOptions.network,
        fiatAmount: this.SdkOptions.fiatAmount,
        walletAddress: this.SdkOptions.walletAddress
        // fiatType: 1,
        // flowType: 1 // 1 -> onramp || 2 -> offramp || 3 -> Merchant checkout
      });
      // console.log(this.SdkOptions, 'this.SdkOptions')
      this.onrampInstance.show();
    }
  }

}