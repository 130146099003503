<div class="row">
    <div class="text-center" *ngIf="launchData[0]?.launchData.length == 0">
        <img src="assets/images/coming_soon.png" class="img-fluid" alt="Token" />
        <h5 class="mt-3">Coming Soon!</h5>
    </div>
    <div class="col-lg-6" *ngFor="let item of launchData[0]?.launchData">
        <div class="dashbox">
            <div class="launchpad_box">
                <img [src]="url + item?.coinImage" class="img-fluid" alt="Token" />
                <div class="launchpad_box_content">
                    <h2>{{ item?.coinName }}</h2>
                    <!-- <p>
                        Suspendisse eu feugiat justo, et rutrum nibh.
                        Phasellus non elit ultrices orci sodales rhoncus non
                        commodo felis...
                    </p> -->
                    <section [innerHtml]="item?.content.substring(0, 40)"></section>
                    <div>
                        <span>Total Supply</span>
                        <span class="green_txt">{{tokenSupply(item)}}</span>
                    </div>
                    <div>
                        <span>Price</span>
                        <span>{{convert(item?.launchPrice)}} INR</span>
                    </div>
                    <div>
                        <span>Event Start </span>
                        <span>{{item?.eventStart | date:"medium"}}</span>
                    </div>
                </div>
            </div>
            <div class="text-center launpad_link" *ngIf="isLogin">
                <a routerLink="/launchpad-details/{{enc(item._id)}}">View Event Details</a>

                <!-- <button type="button" (click)="redirectToEdit(item._id)">
                    View Event Details
                </button> -->
            </div>
        </div>
    </div>
</div>