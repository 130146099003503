import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import isEmpty from 'src/app/lib/isEmpty';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-deactivation',
  templateUrl: './deactivation.component.html',
  styleUrls: ['./deactivation.component.css']
})
export class DeactivationComponent {
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: any;
  form: FormGroup

  submitted: boolean = false
  serverErrors: any = {}
  isReqBtnLoading: boolean = false
  formType: number = 1

  userEmail: string = ''
  isOtpSubmitted: boolean = false
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

  constructor(
    private _fb: FormBuilder,
    private service: UserService,
    private toastr: ToastrService,
  ) {
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
    })
  }

  get email() { return this.form.get('email') }

  submitForm() {
    this.submitted = true
    if (!this.form.valid || this.isReqBtnLoading == true)
      return

    this.isReqBtnLoading = true;

    // Request OTP Api
    this.service.deactivateSendOTP({
      email: this.form?.value?.email,
      roleType: 1,
      requestType: 'deleteAccount'
    })
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.isReqBtnLoading = false
            this.toastr.success(v.message)
            this.formType = 2
          }
        },
        error: (err) => {
          this.submitted = false
          this.isReqBtnLoading = false
          const { status, message, error } = err;
          if (error.message) {
            this.toastr.error(error.message);
          }
        }
      })
  }

  onOtpChange(otp: string) {
    if (this.isReqBtnLoading == true)
      return

    if (otp.length == 6) {
      this.ngOtpInput.otpForm.disable();
      this.deleteAccount({ otp })
    }
  }

  deleteAccount(data: any) {
    this.isOtpSubmitted = true

    // Delete Account Request Api
    this.service.deleteAccount({
      email: this.form?.value?.email,
      otp: data.otp
    })
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.isOtpSubmitted = false
            this.toastr.success(v.message)
            this.formType = 1
            this.form.reset()
          }
        },
        error: (err) => {
          this.isOtpSubmitted = false
          this.ngOtpInput.otpForm.enable();
          const { status, message, error } = err;
          if (error.message) {
            this.toastr.error(error.message);
          }
        }
      })
  }

  redirectToBack() {
    this.formType = 1 // set formType 1 to show request Otp page
    this.submitted = false
  }

}
