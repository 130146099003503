export interface MarketPriceState {
    _id: string,
    markPrice: number,
    markPriceUSDT: number,
    secondCurrencySymbol: string,
    low: number,
    high: number,
    firstVolume: number,
    secondVolume: number,
    changePrice: number,
    change: number,
    botstatus: string,
}

export const initialState: any = {}