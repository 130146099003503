import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { encryptString } from 'src/app/lib/cryptoJs'
import { ToastrService } from 'ngx-toastr';

interface BankDetails {
  _id: string
  accountNo: string;
  bankAddress: string;
  bankId: string;
  bankName: string;
  bankcode: string;
  city: string;
  country: string;
  currencyId: string;
  holderName: string;
  status: string;
}
@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.css'],
})
export class BankInfoComponent {
  bankDetails: BankDetails[] = [];

  constructor(private service: UserService,
    private router: Router,
    private toastr: ToastrService,

  ) { }
  ngOnInit() {
    this.getbankdetails();
  }

  getbankdetails() {
    this.service.getbankdetails().subscribe({
      next: (v: any) => {
        this.bankDetails = v.result.bankDetails;

      },
      error: (err) => { },
    });
  }

  redirectToEdit(id: string) {
    this.router.navigateByUrl('/edit-bank/' + encryptString(id, true))
  }

  redirectToDelete(id: string) {

    const reqData = {
      bankId: id
    }
    if (confirm('Are you sure you want to delete this record?')) {
      this.service.deletebank(reqData).subscribe({
        next: (v: any) => {
          this.toastr.success(v.message)
          this.getbankdetails();
        },
        error: (err) => { },
      });
    }

  }


}
