<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Fiat Deposit</h2>
          <div class="row"></div>
          <p>Please make the payment to the payee details mentioned below</p>
          <div class="row">
            <div class="col-xl-6 col-lg-6 mb-5">
              <form class="auth_form" [formGroup]="form">
                <div class="sign_group sign_group_input">
                  <label>Choose Cryptocurrency</label>
                  <select class="sign_input" (change)="changeCurrency($event)">
                    <option
                      *ngFor="let option of currencyList"
                      [selected]="selectedCurrency == option.coin"
                    >
                      {{ option.coin }}
                    </option>
                  </select>
                </div>
                <!-- <div class="sign_group sign_group_input">
                  <label>Choose the Bank Account*</label>
                  <select class="sign_input">
                    <option>IDBI Bank - 0890102000017356</option>
                  </select>
                </div>
                <p class="form_note">
                  Once you make the payment to the payee details mentioned above
                  fill the below from with the contact details to complete the
                  deposit process.
                </p>
                <div class="sign_group sign_group_input">
                  <label>Choose Your Bank Account (From Account)*</label>
                  <select class="sign_input">
                    <option>ICICI - 38578952541</option>
                    <option>AXIS - 38578952541</option>
                    <option>HDFC - 38578952541</option>
                  </select>
                </div> -->
                <div class="sign_group sign_group_input">
                  <label>Enter Amount<span>*</span></label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="0.00"
                      formControlName="amount"
                      [appDecimalLimit]="2"
                    />
                    <span class="input_info">{{ selectedCoin?.symbol }}</span>
                  </div>
                  <div
                    *ngIf="amount?.invalid && (amount?.touched || submitted)"
                  >
                    <span class="required" *ngIf="amount?.errors?.['required']"
                      >Amount field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.amount">
                    {{ serverErrors.amount }}
                  </span>
                </div>
                <div class="form_note">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <label
                      >Min:
                      <span
                        >{{ selectedCoin?.minimumDeposit }}
                        {{ selectedCoin?.symbol }}</span
                      ></label
                    >
                    <!-- <label>Max: <span>100000 {{selectedCoin.symbol}}</span></label> -->
                  </div>
                  <!-- <div class="d-flex align-items-center justify-content-between">
                    <label>Receive Amount: <span>₹100</span></label>
                    <label>Fee: <span>₹10</span></label>
                  </div> -->
                </div>
                <div class="sign_group sign_group_input">
                  <label>Bank Payment Type*</label>
                  <select class="sign_input" formControlName="bankPaymentType">
                    <option value="" selected>Select Payment Type</option>
                    <option value="IMPS">IMPS</option>
                    <option value="NEFT">NEFT</option>
                  </select>
                  <div
                    *ngIf="
                      bankPaymentType?.invalid &&
                      (bankPaymentType?.touched || submitted)
                    "
                  >
                    <span
                      class="required"
                      *ngIf="bankPaymentType?.errors?.['required']"
                      >Payment Type field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.bankPaymentType">
                    {{ serverErrors.bankPaymentType }}
                  </span>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Transaction ID*</label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="Enter Transaction ID"
                      formControlName="transactionId"
                    />
                  </div>
                  <div
                    *ngIf="
                      transactionId?.invalid &&
                      (transactionId?.touched || submitted)
                    "
                  >
                    <span
                      class="required"
                      *ngIf="transactionId?.errors?.['required']"
                      >Transaction Id field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.transactionId">
                    {{ serverErrors.transactionId }}
                  </span>
                </div>
                <div class="form_note">
                  <label>Only UTR Number format is acceptable</label>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Upload Your Receipt*</label>
                  <div class="primary_inp_grp custom_file_upload">
                    <input
                      type="file"
                      class="sign_input"
                      placeholder="(.png/ .jpg/ .jpeg formats only)"
                      (change)="upload($event)"
                      #fileInput
                    />
                    <label>(.png/ .jpg/ .jpeg/ .pdf formats only)</label>
                    <img
                      src="assets/images/folder_icon.png"
                      class="img-fluid copy_icon"
                      alt="Copy"
                    />
                  </div>
                  <span class="text-primary" *ngIf="preview !== ''">
                    {{ preview }}
                    <i class=""></i>
                  </span>
                  <div *ngIf="image?.invalid && (image?.touched || submitted)">
                    <span class="required" *ngIf="image?.errors?.['required']"
                      >Image is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.image">
                    {{ serverErrors.image }}
                  </span>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Enter 2FA Code*</label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="Enter 2FA"
                      formControlName="twoFA"
                    />
                  </div>
                  <div *ngIf="twoFA?.invalid && (twoFA?.touched || submitted)">
                    <span class="required" *ngIf="twoFA?.errors?.['required']"
                      >2FA field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.twoFA">
                    {{ serverErrors.twoFA }}
                  </span>
                </div>
                <div class="sign_group sign_group_checkbox">
                  <input
                    id="remember"
                    name="remember"
                    type="checkbox"
                    formControlName="verified"
                  />
                  <label for="remember"
                    >I have transferred funds from my own account and verified
                    on Cred Bull. These funds have been acquired by me through
                    legitimate sources.</label
                  >
                  <div
                    *ngIf="
                      verified?.invalid && (verified?.touched || submitted)
                    "
                  >
                    <span
                      class="required"
                      *ngIf="verified?.errors?.['required']"
                      >Please check the box to proceed</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.verified">
                    {{ serverErrors.verified }}
                  </span>
                </div>
                <button
                  class="trade_sell_btn"
                  type="button"
                  *ngIf="
                    user && user.twoFAStatus && user.twoFAStatus === 'disabled';
                    else depositSubmit
                  "
                  (click)="navigate('security')"
                >
                  Enable 2FA
                </button>
                <ng-template #depositSubmit>
                  <button
                    class="sign_btn"
                    [disabled]="loading"
                    type="button"
                    (click)="submitForm()"
                  >
                    <div *ngIf="loading; else submitButton">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                      Submitting...
                    </div>
                    <ng-template #submitButton> Submit </ng-template>
                  </button>
                </ng-template>
              </form>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="dashbox">
                <div class="fiat_account_details">
                  <h3>Send Deposit Amount to</h3>
                  <div>
                    <span>Account No</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.accountNo }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="copyText(selectedCoin?.bankDetails?.accountNo)"
                      />
                    </div>
                  </div>
                  <div>
                    <span>Bank Name</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.bankName }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="copyText(selectedCoin?.bankDetails?.bankName)"
                      />
                    </div>
                  </div>
                  <div>
                    <span>Account Holder Name</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.holderName }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="
                          copyText(selectedCoin?.bankDetails?.holderName)
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <span>Account Type</span>
                    <div>
                      <span>{{
                        selectedCoin?.bankDetails?.accountType | titlecase
                      }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="
                          copyText(selectedCoin?.bankDetails?.accountType)
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <span>Country</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.country }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="copyText(selectedCoin?.bankDetails?.country)"
                      />
                    </div>
                  </div>
                  <div>
                    <span>Branch</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.branchName }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="
                          copyText(selectedCoin?.bankDetails?.branchName)
                        "
                      />
                    </div>
                  </div>
                  <div>
                    <span>IFSC Code</span>
                    <div>
                      <span>{{ selectedCoin?.bankDetails?.bankcode }}</span>
                      <img
                        src="assets/images/copy_icon.png"
                        alt="Copy"
                        (click)="copyText(selectedCoin?.bankDetails?.bankcode)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="dashbox">
                <div class="fiat_wallet_notes">
                  <h3>Notes:</h3>
                  <ul>
                    <li>Transfer funds from your account.</li>
                    <li>
                      Transferring funds from any other accounts is prohibited.
                      Such transfers will be refunded to the source account
                      after 15 days.
                    </li>
                    <li>
                      Add your Cred Bull registered phone no in transaction
                      remarks for faster verification.
                    </li>
                    <li>
                      Do not deposit the INR with the mobile banking
                      application. We do not accept digital payments like Gpay,
                      PhonePe, Paytm etc.
                    </li>
                    <li>
                      INR Deposit Fees - {{ selectedCoin?.depositFee }}
                      {{ selectedCoin?.symbol }} will be deduct from your
                      deposit amount and the remaining amount will credit to
                      your spot wallet.
                    </li>
                    <li>
                      Kindly do not use terms such as bitcoin, cryptocurrency,
                      buy coins, sell coins, Cred Bull or any cryptocurrency's
                      names in transaction remarks.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
