import { Component } from '@angular/core';

@Component({
  selector: 'app-onramp-hosted-mode',
  templateUrl: './onramp-hosted-mode.component.html',
  styleUrls: ['./onramp-hosted-mode.component.css']
})
export class OnrampHostedModeComponent {

}
