import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserSettingFacade } from 'src/app/store/usersetting';

// Import env
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onramper-widget',
  templateUrl: './onramper-widget.component.html',
  styleUrls: ['./onramper-widget.component.css']
})
export class OnramperWidgetComponent {
  @Input() showModal: any
  @Input() onramperParams: any

  @Output("closeModal") closeModal: EventEmitter<any> = new EventEmitter();

  apiKey = environment.onramperApiKey
  onramperURL = ''
  widgetData: any
  loader: boolean = false
  url: any = ''
  urlSafe: SafeResourceUrl = "";
  themeData: string = ''
  // "https://buy.onramper.com/?apiKey=" + this.apiKey

  constructor(
    public sanitizer: DomSanitizer,
    private usersettingFacade: UserSettingFacade
  ) {

  }

  ngOnInit() {
    this.usersettingFacade.usersetting$.subscribe((data: any) => {
      if (data) {
        this.themeData = data.defaultTheme
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes['onramperParams'] && !changes['onramperParams'].firstChange) {

      const currentValue = changes['onramperParams'].currentValue
      this.widgetData = currentValue

      // this.loader = true
      const onramperURL = `https://buy.onramper.com/?themeName=${this.themeData == 'dark'? 'dark': 'bluey'}&&apiKey=pk_prod_5vRsHm3YMs0lTrL7JIzZYvjjjqtuyg&&onlyCryptos=${this.widgetData.coinSymbol}&&wallets=${this.widgetData.coinSymbol}:${this.widgetData.address}&&onlyFiats=${this.widgetData.fiatSymbol}&&defaultAmount=${this.widgetData.fiatAmount}&&defaultFiat=${this.widgetData.fiatSymbol}`
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(onramperURL)
    
    }
  }

  // close model from parent component 
  close() {
    this.closeModal.emit();
  }

}
