<div class="support_ticket_details">
  <h5>Support Details</h5>
  <div class="accordion support_ticket_accordian" id="support_accordian">
    <small class="mt-3" *ngIf="supportlist?.ticketList.length == 0">No support tickets were found</small>
    <div class="accordion-item" *ngFor="let item of supportlist?.ticketList; let i = index">
      <div class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
          <h2><span>Sub:</span> {{ item?.categoryName }}</h2>
          <div>
            <span class="ticket_id">[ Ticket ID: # {{ item?.tickerId }} ]</span>
            <span class="ticket_status">[ Status: <span class="red_txt">{{ item?.status }}</span> ]</span>
          </div>
        </button>
      </div>
      <div [id]="'collapse' + i" class="accordion-collapse collapse" data-bs-parent="#support_accordian">
        <div class="support_ticket_body">
          <div *ngFor="let replyitem of item?.reply">
            <div class="support_ticket_msg" [ngClass]="{
                support_ticket_usermsg:
                  replyitem?.senderId === supportlist?.sender?.id,
                support_ticket_adminmsg:
                  replyitem?.senderId !== supportlist?.sender?.id
              }">
              <img src="assets/images/support_ticket_user.png" alt="User" class="img-fluid" />
              <div>
                <p>
                  {{
                  replyitem?.senderId === supportlist?.sender?.id
                  ? supportlist?.sender?.firstName
                  : supportlist?.receiver?.name
                  }}
                </p>
                <p class="blue_txt">{{ replyitem?.createdAt | date: 'medium' }}</p>
                <div>
                  <p>{{ replyitem?.message }}</p>
                  <a *ngIf="replyitem?.image !== '';" [href]="supportUrl + replyitem?.image" target="__BLANK"
                    style="text-decoration: none;">Attachment</a>
                </div>
              </div>
            </div>
          </div>
          <form action="#" class="auth_form row" [formGroup]="form" *ngIf="item.status == 'open'">
            <div class="sign_group sign_group_input mb-3">
              <textarea class="sign_input" placeholder="Write a Message" rows="3" formControlName="message"></textarea>
            </div>
            <div class="sign_group sign_group_input p-0 mb-1">
              <div class="input-group custom-file-button">
                <label class="input-group-text me-3" for="inputGroupFile1">Upload File</label>
                <input type="file" class="sign_input" id="inputGroupFile1" (change)="uploadreply($event)" />
              </div>
            </div>
            <span class="required" *ngIf="serverErrors?.image">
              {{serverErrors.image}}
            </span>
            <small class="text-light">Max 1MB Allowed Only types jpg, jpeg, png, pdf.</small>
            <div class="support_btn_grp">
              <button *ngIf="item.status == 'open'" class="withdraw_btn" type="button"
                (click)="submitForm(item?._id, supportlist?.receiver?._id)">
                Reply
              </button>

              <button *ngIf="item.status == 'open'" class="deposit_btn" (click)="closeTicket(item?._id)">
                Satisfied, Close this ticket
              </button>

              <button *ngIf="item.status == 'closed'" class="deposit_btn">
                Closed
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>