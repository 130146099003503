<section class="market_section section">
  <div class="container">
    <div class="row">
      <div
        class="col-12"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div class="market_section_panel">
          <div class="market_table_head">
            <h2>Market Trend</h2>
            <div class="market_table_btn_grps">
              <button
                type="button"
                [ngClass]="type == 'hot' ? 'active' : ''"
                (click)="trendType('hot')"
              >
                Hot List
              </button>
              <button
                type="button"
                [ngClass]="type == 'gain' ? 'active' : ''"
                (click)="trendType('gain')"
              >
                Top Gainers
              </button>
              <button
                type="button"
                [ngClass]="type == 'loss' ? 'active' : ''"
                (click)="trendType('loss')"
              >
                Top Loser
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table market_table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Last Price</th>
                  <th>24h change</th>
                  <th>Trade</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pair of pairs.slice(0, 10); let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <img
                        src="{{ getCurrecyImage(pair.firstCurrencySymbol) }}"
                        alt="Crypto"
                        class="img-fluid market_table_icon"
                      />
                      <h5>
                        {{
                          pair.firstCurrencySymbol +
                            "/" +
                            pair.secondCurrencySymbol
                        }}
                        <span>{{ pair.secondCurrencySymbol }}</span>
                      </h5>
                    </div>
                  </td>
                  <td>{{ convert(pair.markPrice) }}</td>
                  <td>
                    <span *ngIf="pair.change >= 0" class="green_txt"
                      >{{ truncateDecimals(pair.change, 3) }}%</span
                    >

                    <span *ngIf="pair.change < 0" class="red_txt"
                      >{{ truncateDecimals(pair.change, 3) }}%</span
                    >
                  </td>
                  <td>
                    <button class="trade_btn">
                      <a
                        href="{{
                          '/spot/' +
                            pair.firstCurrencySymbol +
                            '_' +
                            pair.secondCurrencySymbol
                        }}"
                        >Trade</a
                      >
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-4">
            <button class="primary_btn">
              <a routerLink="/market-home">View More</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
