import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Import environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(params: any) {
    return this.http.get(environment.userApiUrl + 'api/user/announcement', { params })
  }

}
