import { createAction, props } from '@ngrx/store';

export const userKey = '[User]';

export const setUser = createAction(
    `${userKey} SetUser`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${userKey} Reset`
);