<app-header></app-header>
<main class="inner_cms_layout">
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-4 col-lg-3">
        <div class="dashbox cms_list_box">
          <nav>
            <ul class="cms_content_list" scroll-spy>
              <li><a href="#section_01">Introduction</a></li>
              <li><a href="#section_02">Maecenas et elit sit</a></li>
              <li><a href="#section_03">Vestibulum pretium risus</a></li>
              <li><a href="#section_04">Donec fermentum</a></li>
              <li><a href="#section_05">Nam a orci in metus</a></li>
            </ul>
          </nav>
        </div>
      </div> -->
      <div class="col-md-12 col-lg-12 cms_content_main">
        <h1>{{title}}</h1>
        <section [innerHtml]="content"></section>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>