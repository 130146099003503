import { createAction, props } from '@ngrx/store';

export const walletKey = '[Wallet]';

export const setWallet = createAction(
    `${walletKey} SetWallet`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${walletKey} Reset`
);