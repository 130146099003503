import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, TradeAssetState } from './trade-asset.state';
import { setFirstCurrency, reset, setSecondCurrency } from './trade-asset.actions';

export const tradeAssetFeatureKey = 'tradeasset';

export const tradeAssetReducers: ActionReducer<TradeAssetState> = createReducer(
    initialState,
    on(setFirstCurrency, (state, action) => ({ ...state, firstCurrency: action.payload })),
    on(setSecondCurrency, (state, action) => ({ ...state, secondCurrency: action.payload })),
    on(reset, () => initialState)
);