import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import isEmpty from './isEmpty';

@Injectable({
    providedIn: 'root'
})
export class Cookies {

    constructor(private cookieService: CookieService) { }

    isLogin() {
        // console.log(this.cookieService.get('user_token'), 'this.cookieService.get("user_token")')
        // console.log(this.cookieService, 'this.cookieService')
        if (!isEmpty(this.cookieService.get('user_token'))) {
            return true;
        }
        else {
            return false;
        }
    }

    getAuthToken() {
        if (!isEmpty(this.cookieService.get('user_token')))
            return this.cookieService.get('user_token');
        else return "";
    }

}
