import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, MarketPriceState } from './marketprice.state';
import { setSpotMarketPrice, reset } from './marketprice.actions';

export const marketPriceFeatureKey = 'marketprice';

export const marketPriceReducers: ActionReducer<MarketPriceState> = createReducer(
    initialState,
    on(setSpotMarketPrice, (_, action) => action.payload),
    on(reset, () => initialState)
);