import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subject } from "rxjs";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-supporttiket-details',
  templateUrl: './supporttiket-details.component.html',
  styleUrls: ['./supporttiket-details.component.css'],

})
export class SupporttiketDetailsComponent implements OnInit {

  @Input() supportprops: Subject<boolean> = new Subject<boolean>();
  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  supportlist: any = [];
  preview: string = "";
  supportUrl: string = environment.userApiUrl + 'support/'

  constructor(
    private service: UserService,
    private AuthService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder
  ) {

    this.form = this._fb.group({
      message: ['', [Validators.required]],
      image: ['']
    });
  }

  ngOnInit() {
    this.getsupportlist();
    this.supportprops.subscribe(response => {
      if (response) {
        this.getsupportlist();
      }
    })
  }

  getsupportlist() {
    this.service.getsupporticket().subscribe({
      next: (v: any) => {
        this.supportlist = v.result;

      },
      error: (err: any) => {
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  submitForm(ticketId: string, receiverId: string) {
    this.submitted = true;
    if (!this.form.valid)
      return
    const formValue = this.form.value
    var formData = new FormData();
    // console.log(formValue, 'formValue.endTimeStamp')
    formData.append('ticketId', ticketId);
    formData.append('receiverId', receiverId);
    formData.append('message', formValue.message);
    formData.append('image', formValue.image);

    this.service.replyticket(formData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.form.reset()
            this.serverErrors = {}
            this.getsupportlist();
          }
        },
        error: (err: any) => {
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  uploadreply(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.form.patchValue({
      image: files[0]
    })
    const reader = new FileReader();
    reader.onload = () => {
      reader.result as string;
      // this.preview = reader.result as string;
      this.preview = files[0].name;

    }
    reader.readAsDataURL(files[0])
  }

  closeTicket(ticketId: string) {
    this.submitted = true;

    let reqData = { ticketId: ticketId }
    this.service.closeTicket(reqData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.form.reset()
            this.serverErrors = {}
            this.getsupportlist();
          }
        },
        error: (err: any) => {
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }


}


