import { Component } from '@angular/core';
import { FormBuilder, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import isEmpty from 'src/app/lib/isEmpty';
import { OnrampWebSDK } from '@onramp.money/onramp-web-sdk';
import { ToastrService } from 'ngx-toastr';

// Import facade
import { CurrencyFacade } from 'src/app/store/currency';
import { WalletFacade } from 'src/app/store/wallet';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onramp-money-home',
  templateUrl: './onramp-money-home.component.html',
  styleUrls: ['./onramp-money-home.component.css']
})
export class OnrampMoneyHomeComponent {

  SdkOptions: any = {}

  form!: FormGroup
  isLoading: boolean = false
  serverErrors: any = {}
  submitted: boolean = false
  fbLoading: boolean = false

  // redux state
  currency: any = []
  wallet: any = []

  coinOptions: any = []
  selectedCoin: any = {}

  // onramper
  showModal: boolean = false
  onramperParams: any = {
    themeData: '',
    coinSymbol: '',
    address: '',
    fiatSymbol: '',
    fiatAmount: ''
  }

  constructor(
    private _fb: NonNullableFormBuilder,
    private currencyFacade: CurrencyFacade,
    private walletFacade: WalletFacade,
    private toastr: ToastrService
  ) {
    this.initForm()
  }

  ngOnInit() {
    // this.getCurrencies()
    this.initialCall()
  }

  // init form builder
  initForm() {
    this.form = this._fb.group({
      coinSymbol: ['', Validators.required],
      fiatAmount: ['', [Validators.required]],
      address: ['']
    })
  }

  async initialCall() {
    this.getWallet()
  }

  get coinSymbol() {
    return this.form.get('coinSymbol');
  }

  get fiatAmount() {
    return this.form.get('fiatAmount');
  }

  get address() {
    return this.form.get('address');
  }

  // get user wallet
  private getWallet() {
    this.walletFacade.wallet$
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.wallet = data
            this.getCurrencies()
          }

        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  // get all currencies
  private getCurrencies() {
    this.currencyFacade.currency$
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.currency = data;

            const coinOptions: any[] = []

            data && data.length > 0 && data.map((item: any) => {
              if (item && item.type === 'crypto') {

                const selectedCoin = this.wallet && this.wallet.find((el: any) => {
                  if (el.coin == item.coin) {
                    return el
                  }
                })
                if (selectedCoin) {
                  coinOptions.push({
                    'label': item.coin,
                    'value': item.coin,
                    'type': 'crypto',
                    'coin': item.coin.toLowerCase(),
                    'network': '',
                    'address': !isEmpty(selectedCoin.address) ? selectedCoin.address : ""
                  })
                }
              } else if (item && item.type === 'token') {

                const selectedCoin = this.wallet && this.wallet.find((el: any) => {
                  if (el.coin == item.coin) {
                    return el
                  }
                })
                if (selectedCoin) {
                  const tokenArray = selectedCoin.tokenAddressArray

                  tokenArray.map((token: any) => {
                    const tokenName = `${item.coin} - ${token.tokenType.toUpperCase()}`

                    coinOptions.push({
                      'label': tokenName,
                      'value': tokenName,
                      'type': 'token',
                      'coin': item.coin.toLowerCase(),
                      'network': token.tokenType,
                      'address': !isEmpty(token.address) ? token.address : ""
                    })
                  })
                }
              }
            })

            this.coinOptions = coinOptions
            this.isLoading = true
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  changeCurrency(event: Event) {

  }

  validation(fiatAmount: string, address: string) {

    let message = ''

    if (parseFloat(fiatAmount) <= 0) {
      message = 'Please enter a valid amount'
    } else if (isEmpty(address)) {
      message = 'Address is Empty'
    }

    return message
  }

  submitForm() {
    this.showModal = false
    this.submitted = true

    if (!this.form.valid)
      return

    const { coinSymbol, fiatAmount } = this.form.value
    const selectCoin = this.coinOptions.find((el: any) => el.value == coinSymbol)

    const isValid = this.validation(fiatAmount, selectCoin.address);
    if (!isEmpty(isValid)) {
      this.toastr.error(isValid)
      return
    }

    this.SdkOptions = {
      coinCode: selectCoin.coin,
      network: selectCoin.network,
      fiatAmount: parseFloat(fiatAmount),
      walletAddress: selectCoin.address
    }
    this.showModal = true
  }

  resetForm() {
    this.submitted = false
    this.form.reset()
    this.selectedCoin = {}
  }

  toggleModal() {
    this.showModal = false
    this.fbLoading = false
  }

}
