import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Import facade
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.css']
})
export class TwoFAComponent {

  user: any = {}

  constructor(private userFacade: UserFacade, private router: Router) { }

  ngOnInit() {
    this.userFacade.user$.subscribe({
      next: (user) => {
        this.user = user
      },
      error: () => { }
    })
  }

  redirect(url: string) {
    this.router.navigateByUrl(url)
  }

}
