import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { CommonService } from 'src/app/services/common.service';

// Import service
import { DashboardService } from 'src/app/services/dashboard.service';

// Import facade
import { DashboardFacade } from 'src/app/store/dashboard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  dashboardBanner: any = {}
  cmsurl: string = environment.userApiUrl + 'cms-images/'

  constructor(
    private dashboardService: DashboardService,
    private dashboardFacade: DashboardFacade,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    var element = document.getElementById("zsiq_float");
    if (element)
      element.classList.add("disabled");

    // element.classList.remove("otherclass");
    this.dashboardService.getTotalBalance().subscribe({
      next: (v: any) => {
        this.dashboardFacade.setTotalBalance(v.result.data)
      },
      error: () => { }
    })
    this.getCmsContent()
  }

  getCmsContent() {
    this.commonService.getHomeCmsPages()
      .subscribe({
        next: (v: any) => {
          const result = v.result
          if (result && result.length > 0) {
            result.map((item: any) => {
              if (item.identifier == "dashboard-banner") {
                this.dashboardBanner = item
              }
            })
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }
}
