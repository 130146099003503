<app-header></app-header>
<main class="inner_cms_layout launchpad_layout">
  <section class="launchpad_page_header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="launchpad_page_header_content">
            <h2>CRED BULL</h2>
            <h1>LAUNCHPAD</h1>
            <h5>
              A token launch platform for groundbreaking blockchain projects
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section launchpad_list_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashbox">
            <div class="launchpad_box launchpad_detailbox">
              <img [src]="url + launchSingle?.coinImage" class="img-fluid" alt="Token" />
              <div class="launchpad_detailbox_content">
                <div class="launchpad_box_content">
                  <h2>{{launchSingle?.coinName}}</h2>
                  <section [innerHtml]="launchSingle?.content"></section>
                  <div class="coin_web_info">
                    <a [href]="launchSingle?.website" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.94 55.36">
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Glyph">
                            <path d="M27,15.67a61.89,61.89,0,0,1-9.49-.87,50.4,50.4,0,0,0-1.08,5.88H27Z" />
                            <path d="M27,.79c-3.69.71-7,5.39-9,12.07a60.42,60.42,0,0,0,9,.81Z" />
                            <path d="M38.46,14.8a61.89,61.89,0,0,1-9.49.87v5H39.54A50.4,50.4,0,0,0,38.46,14.8Z" />
                            <path d="M29,39.69a61.89,61.89,0,0,1,9.49.87,50.4,50.4,0,0,0,1.08-5.88H29Z" />
                            <path d="M17.48,40.56A61.89,61.89,0,0,1,27,39.69v-5H16.4A50.4,50.4,0,0,0,17.48,40.56Z" />
                            <path
                              d="M41.56,20.68H46a2,2,0,0,1,2,2v4h8a27.74,27.74,0,0,0-5.29-15.41.67.67,0,0,1-.21.13,54.19,54.19,0,0,1-10,3A52.2,52.2,0,0,1,41.56,20.68Z" />
                            <path d="M27,54.58V41.69a60.42,60.42,0,0,0-9,.81C20,49.18,23.28,53.86,27,54.58Z" />
                            <path d="M37.94,42.5a60.42,60.42,0,0,0-9-.81V54.58C32.66,53.86,35.94,49.18,37.94,42.5Z" />
                            <path
                              d="M16,42.87a53.09,53.09,0,0,0-9.45,2.82,28,28,0,0,0,17.18,9.67C20.43,53.26,17.71,48.79,16,42.87Z" />
                            <path
                              d="M32.23,55.36a28,28,0,0,0,17.18-9.67A53.09,53.09,0,0,0,40,42.87C38.23,48.79,35.51,53.26,32.23,55.36Z" />
                            <path
                              d="M48,32.68a2,2,0,0,1-2,2H41.56a52.2,52.2,0,0,1-1.09,6.26,54.19,54.19,0,0,1,10,3,.67.67,0,0,1,.21.13,27.74,27.74,0,0,0,5.29-15.41H48Z" />
                            <path
                              d="M14.38,34.68H10a2,2,0,0,1-2-2v-4H0A27.74,27.74,0,0,0,5.29,44.09.67.67,0,0,1,5.5,44a54.19,54.19,0,0,1,10-3A52.2,52.2,0,0,1,14.38,34.68Z" />
                            <path d="M29,.79V13.67a60.42,60.42,0,0,0,9-.81C35.94,6.18,32.66,1.5,29,.79Z" />
                            <path
                              d="M8,22.68a2,2,0,0,1,2-2h4.41a52.2,52.2,0,0,1,1.09-6.26,54.19,54.19,0,0,1-10-3,.67.67,0,0,1-.21-.13A27.74,27.74,0,0,0,0,26.68H8Z" />
                            <path
                              d="M40,12.49a53.09,53.09,0,0,0,9.45-2.82A28,28,0,0,0,32.23,0C35.51,2.1,38.23,6.57,40,12.49Z" />
                            <path
                              d="M23.71,0A28,28,0,0,0,6.53,9.67,53.09,53.09,0,0,0,16,12.49C17.71,6.57,20.43,2.1,23.71,0Z" />
                            <path
                              d="M20,24.54l-.41,2.88L18.86,26a1,1,0,0,0-1.78,0l-.71,1.39L16,24.54a1,1,0,1,0-2,.28l.86,6a1,1,0,0,0,.83.84,1,1,0,0,0,1-.53L18,28.68l1.25,2.45a1,1,0,0,0,.89.55h.16a1,1,0,0,0,.83-.84l.86-6a1,1,0,0,0-2-.28Z" />
                            <path
                              d="M30.11,31.68h.16a1,1,0,0,0,.83-.84l.86-6a1,1,0,0,0-2-.28l-.41,2.88L28.86,26a1,1,0,0,0-1.78,0l-.71,1.39L26,24.55a1,1,0,0,0-2,.28l.86,6a1,1,0,0,0,.83.84,1,1,0,0,0,1-.53L28,28.69l1.25,2.45a1,1,0,0,0,.89.55Z" />
                            <path
                              d="M40.28,31.67a1,1,0,0,0,.83-.84l.86-6a1,1,0,0,0-2-.28l-.41,2.88L38.87,26a1,1,0,0,0-1.78,0l-.71,1.39L36,24.55a1,1,0,1,0-2,.28l.86,6a1,1,0,0,0,.83.84,1,1,0,0,0,1-.53L38,28.69l1.25,2.45a1,1,0,0,0,.89.55h.16Z" />
                          </g>
                        </g>
                      </svg>
                      <span>Website</span>
                    </a>
                    <a [href]="launchUrl + launchSingle?.whitePaper" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.64 426.67">
                        <defs>
                          <style>
                            .cls-1 {
                              fill-rule: evenodd;
                            }
                          </style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="aw">
                            <path class="cls-1"
                              d="M64,0a11,11,0,0,0-7.57,3.09S0,58.24,0,213.33,56.43,423.57,56.43,423.57a10.53,10.53,0,0,0,7.57,3.1H288a10.52,10.52,0,0,0,9.81-6.62,10.72,10.72,0,0,0-2.34-11.62s-50.24-51.52-50.24-195.1S295.57,18.24,295.57,18.24A10.67,10.67,0,0,0,288.11,0ZM295.47,53.33c-13.66,30.08-28.8,81.18-28.8,160,0,102.19,25.49,157.76,40.1,181.34h2.56a11,11,0,0,0,8.75-4.48,10.82,10.82,0,0,0,1.39-9.71s-20.7-59.41-20.7-167.15S357.65,73.07,357.65,73.07a10.65,10.65,0,0,0-5.54-19.74H295.47ZM213.33,330.67A10.7,10.7,0,0,0,202.67,320H160a10.7,10.7,0,0,0-10.67,10.67v42.66A10.7,10.7,0,0,0,160,384h42.67a10.7,10.7,0,0,0,10.66-10.67ZM192,341.33v21.34H170.67V341.33ZM355.63,96C339.2,117.23,320,154.56,320,213.33c0,64.11,7.36,111.15,13.33,138.67H352a10.65,10.65,0,0,0,10.45-12.91S352,290.56,352,213.33c0-71.25,67.73-96.64,67.73-96.64A10.63,10.63,0,0,0,416,96.11H355.63ZM64,288H192a10.67,10.67,0,1,0,0-21.33H64A10.67,10.67,0,1,0,64,288ZM53.33,224h128a10.67,10.67,0,1,0,0-21.33h-128a10.67,10.67,0,0,0,0,21.33ZM64,160H192a10.67,10.67,0,1,0,0-21.33H64A10.67,10.67,0,1,0,64,160ZM202.67,53.33A10.7,10.7,0,0,0,192,42.67H85.33A10.7,10.7,0,0,0,74.67,53.33V96a10.7,10.7,0,0,0,10.66,10.67H192A10.7,10.7,0,0,0,202.67,96ZM181.33,64V85.33H96V64Z" />
                          </g>
                        </g>
                      </svg>
                      <span>Whitepaper</span>
                    </a>
                    <span class="cursor-pointer" style="color: #81cafc;" (click)="scrollToDiv(termsAndCondition)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 365.71 512">
                        <defs>
                          <style>
                            .cls-1 {
                              fill-rule: evenodd;
                            }
                          </style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Capa_1" data-name="Capa 1">
                            <g id="Page-1">
                              <g id="_100---Terms-And-Conditions" data-name="100---Terms-And-Conditions">
                                <path id="Path" class="cls-1"
                                  d="M107.71,362.29a9.17,9.17,0,0,1-6.63,3.41h-.51A9.06,9.06,0,0,1,94.11,363L85,353.89A9.14,9.14,0,0,1,97.89,341l1.92,1.92L118.1,320h-45A9.14,9.14,0,0,0,64,329.17v45.71A9.15,9.15,0,0,0,73.14,384H128a9.15,9.15,0,0,0,9.14-9.15V329.14a9,9,0,0,0-.58-2.9Z" />
                                <path id="Path-2" data-name="Path" class="cls-1"
                                  d="M283.43,438.86H82.29a18.29,18.29,0,1,0,0,36.57H283.43a18.29,18.29,0,1,0,0-36.57Z" />
                                <path id="Shape"
                                  d="M292.57,100.57a27.43,27.43,0,0,1-27.43-27.43V0H9.14A9.14,9.14,0,0,0,0,9.14V502.86A9.14,9.14,0,0,0,9.14,512H356.57a9.14,9.14,0,0,0,9.14-9.14V100.57ZM45.72,45.71h73.14a9.15,9.15,0,0,1,0,18.29H45.72a9.15,9.15,0,1,1,0-18.29Zm237.71,448H82.29a36.57,36.57,0,1,1,0-73.14H283.43a36.57,36.57,0,1,1,0,73.14Zm-128-164.57v45.72A27.43,27.43,0,0,1,128,402.29H73.14a27.44,27.44,0,0,1-27.43-27.43V329.14a27.43,27.43,0,0,1,27.43-27.43H128a27,27,0,0,1,4.39.45l4.93-6.16a9.15,9.15,0,1,1,14.28,11.43l-3,3.78a27.18,27.18,0,0,1,6.85,17.93ZM320,374.86H182.86a9.15,9.15,0,0,1,0-18.29H320a9.15,9.15,0,0,1,0,18.29ZM173.72,329.14a9.14,9.14,0,0,1,9.14-9.14h36.57a9.15,9.15,0,0,1,0,18.29H182.86a9.15,9.15,0,0,1-9.14-9.15ZM320,274.29H45.72a9.15,9.15,0,1,1,0-18.29H320a9.15,9.15,0,0,1,0,18.29Zm0-45.72H45.72a9.14,9.14,0,1,1,0-18.28H320a9.14,9.14,0,0,1,0,18.28Zm0-45.71H45.72a9.15,9.15,0,1,1,0-18.29H320a9.15,9.15,0,0,1,0,18.29Zm0-45.72H45.72a9.14,9.14,0,1,1,0-18.28H320a9.14,9.14,0,0,1,0,18.28Z" />
                                <path id="Path-3" data-name="Path" class="cls-1"
                                  d="M292.57,82.29h60.22L283.43,12.93V73.14A9.15,9.15,0,0,0,292.57,82.29Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span>Terms & Conditions</span>
                    </span>
                  </div>
                </div>
                <div class="live_btn">{{status}}</div>
              </div>
            </div>

            <div class="token_supply_details">
              <div class="token_supply_details_left">
                <div>
                  <span>Total Supply</span>
                  <span class="green_txt">{{launchSingle?.availableSupply - totalCommit?.launchAmount}}</span>
                </div>
                <div>
                  <span>Price</span>
                  <span>{{convert(launchSingle?.launchPrice)}} {{launchSingle?.launchCoin}}</span>
                </div>
                <div>
                  <span>Total Participants</span>
                  <span>{{moreZero(totalCommit?.count)}}</span>
                </div>
                <div>
                  <span>Total Amount Committed</span>
                  <span>{{moreZero(totalCommit?.convertPrice)}} {{launchSingle?.launchCoin}}</span>
                </div>
                <div>
                  <span>Launch Date</span>
                  <span>{{launchSingle?.startTimeStamp | date:"medium"}}</span>
                </div>
              </div>
              <div class="token_supply_details_right" [style.display]="launchShow == 'show' ? 'block' : 'none'">
                <h3>Launchpad</h3>
                <div class="countdown_wrapper">
                  <div class="countdown_times">
                    <div>
                      <p #days></p>
                      <p>Days</p>
                    </div>
                    <span>:</span>
                    <div>
                      <p #hours></p>
                      <p>Hours</p>
                    </div>
                    <span>:</span>
                    <div>
                      <p #minutes></p>
                      <p>Minutes</p>
                    </div>
                    <span>:</span>
                    <div>
                      <p #seconds></p>
                      <p>Seconds</p>
                    </div>
                  </div>
                  <button data-bs-toggle="modal" data-bs-target="#ChosseBuy" class="sign_btn">Buy Now</button>
                </div>
              </div>
              <div class="token_supply_launchpad" *ngIf="status == 'Completed'">This event has ended!</div>
              <div class="token_supply_launchpad" *ngIf="status == 'Upcoming'">This event still not Start!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="dashbox token_eventbox">
            <img src="assets/images/green_tick.png" class="img-fluid" alt="Tick Icon" />
            <h2>Schedule Starts</h2>
            <h3>{{launchSingle?.startTimeStamp | date:"medium"}}</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="dashbox token_eventbox">
            <img src="assets/images/green_tick.png" class="img-fluid" alt="Tick Icon" />
            <h2>Event Starts</h2>
            <h3>{{launchSingle?.eventStart | date:"medium"}}</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="dashbox token_eventbox">
            <img src="assets/images/blue_tick.png" class="img-fluid" alt="Tick Icon" />
            <h2>Event End</h2>
            <h3>{{launchSingle?.eventEnd | date:"medium"}}</h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="dashbox token_eventbox">
            <img src="assets/images/blue_tick.png" class="img-fluid" alt="Tick Icon" />
            <h2>Token Launch Date</h2>
            <h3>{{launchSingle?.startTimeStamp | date:"medium"}}</h3>
          </div>
        </div>
      </div>

      <div class="row" #termsAndCondition>
        <div class="col-lg-6 col-md-12">
          <div class="dashbox">
            <h2 class="dashbox_title">Subscription Cap</h2>
            <div class="d-flex justify-content-between">
              <p class="mb-0">Your Subscription Cap</p>
              <p class="blue_txt mb-0">{{launchSingle?.subCap}} INR</p>
            </div>
          </div>
          <div class="dashbox">
            <h2 class="dashbox_title">Terms & Conditions</h2>
            <section [innerHtml]="launchSingle?.termsCond"></section>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="dashbox">
            <h2 class="dashbox_title">Activity Rules</h2>
            <section [innerHtml]="launchSingle?.activRules"></section>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="dashbox">
            <h2 class="dashbox_title">Last Transaction</h2>
            <div class="table-responsive">
              <table class="table primary_table">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Logo</th>
                    <th>Price</th>
                    <th>Launch Price</th>
                    <th>Commit</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of commitData; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.coin}}</td>
                    <td>{{item.price}} - {{item.coin}}</td>
                    <td>{{item.launchAmount}} - {{item.launchCoin}}</td>
                    <td>{{item.commit}}</td>
                    <td>{{item.createdAt | date:'medium'}}</td>
                  </tr>

                  <tr *ngIf="commitData?.length == 0">
                    <td colspan="5">
                      <div class="text-center mt-3">
                        <img src="assets/images/no_records_found.png" alt="No Records" img="img-fluid" />
                        <p class="mt-3">No Transaction Found !</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav class="primary_pagination" *ngIf="activityFilter.totalPages > 1">
                <ul class="pagination">
                  <li class="page-item" [ngClass]="{disabled: activityFilter.page == 1}">
                    <span class="page-link cursor-pointer" aria-label="Previous" (click)="previousPageAct()">
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>
                  <li class="page-item" *ngFor="let item of getDisplayedPages">
                    <a class="page-link" [ngClass]="{ active: item == activityFilter.page}" href="javascript:void(0)"
                      (click)="goToPageAct(item)">{{item}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{disabled: activityFilter.totalPages == activityFilter.page}">
                    <span class="page-link cursor-pointer" (click)="nextPageAct()" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Launchpad Buy -->
  <div class="modal fade primary_modal" id="ChosseBuy" tabindex="-1" aria-labelledby="ChooseLaunchpad"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="ChooseLaunchpad">
            Buy Token
          </h1>
          <button type="button" class="btn-close" id="curmodalclose" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div class="mb-3">
            <label cLabel class="form-label">Currency</label>
            <select class="form-select sign_input" (input)="onSelectChange($event)">
              <option *ngFor="let option of currencyOption" [value]="option._id">{{ option.coin }}</option>
            </select>
          </div>
          <div class="mb-3">
            <label cLabel class="form-label">Price - {{toFixedDown(convert(convertprice), 8)}} {{ coin }}</label>
          </div>
          <div class="mb-3">
            <label cLabel class="form-label">Balance - {{ toFixedDown(coinBalance, 8) }} {{ coin }}</label>
          </div>
          <div class="mb-3">
            <label cLabel class="form-label">Amount - {{ launchSingle?.coinName }}</label>
            <input type="text" class="form-control sign_input" placeholder="Enter Amount"
              (input)="onInputChange($event)" [value]="amount" appDecimalLimit="8" />
            <span class="required" *ngIf="serverErrors?.amount">{{
              serverErrors.amount
              }}</span>
          </div>
          <div class="mb-3">
            <label cLabel class="form-label">Total - {{ coin }}</label>
            <input id="total" [value]="total" type="text" class="sign_input" readOnly="true" />
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" (click)="submitData()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>