import { Component } from '@angular/core';

// Import service
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-last-transaction',
  templateUrl: './last-transaction.component.html',
  styleUrls: ['./last-transaction.component.css']
})
export class LastTransactionComponent {
  
  transactions: any = []

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    let pagination = {
      skip: 0,
      limit: 5,
      page: 1
    }
    this.commonService.getRecentTxn(pagination)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.transactions = v.result
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }
}
