import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cancelorder',
  templateUrl: './cancelorder.component.html',
  styleUrls: ['./cancelorder.component.css']
})
export class CancelorderComponent {
  @Input() data: any;
  showModal: boolean = true;

  ngOnChanges() {
    // console.log('triggered')
  }

  openModal(ele: any) {
    // console.log(ele, 'ele')
    this.showModal = true
  }

}
