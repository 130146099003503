import { Component } from '@angular/core';

// Service
import { LaunchService } from 'src/app/services/launchpad.service';
@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.css'],
})
export class LaunchpadComponent {
  data: any = 'upcoming';
  launchData: any = [];
  constructor(private LaunchService: LaunchService) {}
  onInputChange(event: Event) {
    const eventData = (event.target as HTMLInputElement).name;
    this.data = eventData;
    this.getLaunchPad();
  }

  ngOnInit() {
    this.getLaunchPad();
  }
  getLaunchPad() {
    this.LaunchService.getLaunchData(this.data).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.launchData = v.result.data;
        }
      },
      error: (err) => {},
    });
  }
}
