import { Component } from '@angular/core';
import { Subject } from "rxjs";

@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.css']
})
export class SupportTicketComponent {
  supportprops: Subject<boolean> = new Subject<boolean>();
  ngOnInit() {
  }


}
