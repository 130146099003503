import { createAction, props } from '@ngrx/store';

export const pairKey = '[Pair]';

export const setPair = createAction(
    `${pairKey} SetPair`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${pairKey} Reset`
);