import { Component } from '@angular/core';
import { UserSettingFacade } from 'src/app/store/usersetting';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent {
  
  theme = ''

  constructor (private userSettingFacade: UserSettingFacade) {

  }

  ngOnInit() {
    this.getUserSetting()
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }
}
