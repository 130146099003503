<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <div class="dashbox_title_flex">
            <h2 class="dashbox_title">Bank Info</h2>
            <a routerLink="/add-bank" *ngIf="bankDetails && bankDetails.length < 3">
              <button class="withdraw_btn">Add Bank Account</button></a
            >
          </div>
          <div class="row">
            <div
              class="col-xl-6 col-xxl-4 col-md-6"
              *ngFor="let bankDetail of bankDetails"
            >
              <div class="bank_card">
                <div class="bank_card_top">
                  <h2>{{ bankDetail.bankName }}</h2>
                  <div>{{ bankDetail?.status | titlecase }}</div>
                  <!-- <div>
                    <button class="icon_btn" (click)="redirectToEdit(bankDetail._id)">
                      <i class="far fa-edit"></i>
                    </button>
                    <button class="icon_btn" (click)="redirectToDelete(bankDetail._id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div> -->
                </div>
                <div class="bank_card_middle">
                  <h3>Account Number</h3>
                  <h2>{{ bankDetail.accountNo }}</h2>
                </div>
                <div class="bank_card_bottom">
                  <div>
                    <h5>Name</h5>
                    <h4>{{ bankDetail.holderName }}</h4>
                  </div>
                  <div>
                    <h5>IFSC Code</h5>
                    <h4>{{ bankDetail.bankcode }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="bankDetails && bankDetails.length == 0">
              <div class="text-center mt-3">
                <img
                  src="assets/images/no_records_found.png"
                  alt="No Records"
                  img="img-fluid"
                />
                <p>No Records Found !</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
