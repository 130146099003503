

<form class="auth_form signup_form" [formGroup]="form" (ngSubmit)="submitForm()">

  <div class="mb-3">
    <div class="sign_group sign_group_input">
      <label>First Name</label>
      <input autoComplete="off" type="text" class="sign_input" placeholder="Enter First Name" (input)="onInputChange()"
        formControlName="firstName" id="firstName" alphaOnly />
    </div>
    <!-- <div *ngIf="firstName?.invalid && (firstName?.touched || submitted)">
      <span class="required" *ngIf="firstName?.errors?.['required']">First Name field is required</span>
    </div>
    <span class="required" *ngIf="serverErrors?.firstName">{{ serverErrors.firstName }}</span> -->
  </div>

  <div class="mb-3">
    <div class="sign_group sign_group_input">
      <label>Last Name</label>
      <input autoComplete="off" type="text" class="sign_input" placeholder="Enter Last Name" (input)="onInputChange()"
        formControlName="lastName" id="lastName" alphaOnly />
    </div>
    <!-- <div *ngIf="lastName?.invalid && (lastName?.touched || submitted)">
      <span class="required" *ngIf="lastName?.errors?.['required']">Last Name field is required</span>
    </div>
    <span class="required" *ngIf="serverErrors?.lastName">{{ serverErrors.lastName }}</span> -->
  </div>



  <div class="mb-3">
    <div class="sign_group sign_group_input">
      <label>Phone Number</label>
      <div>
        <ngx-mat-intl-tel-input formControlName="newPhoneNo" [enablePlaceholder]="true" [enableSearch]="true" [preferredCountries]="['in']"
          name="newPhoneNo" (countryChanged)="countryChangedEvent($event)" >
        </ngx-mat-intl-tel-input>
      </div>
    </div>
    <div *ngIf="newPhoneNo?.invalid && (newPhoneNo?.touched || submitted)">
      <span class="required" *ngIf="newPhoneNo?.errors?.['required']">Phone Number field is required</span>
      <span class="required" *ngIf="newPhoneNo?.errors?.['validatePhoneNumber']">Phone Number is invalid</span>
    </div>
    <span class="required" *ngIf="serverErrors?.newPhoneNo">{{
      serverErrors.newPhoneNo
      }}</span>
  </div>
  <div class="mb-3" *ngIf="potp">
    <div class="sign_group sign_group_input">
      <label>OTP</label>
      <div class="primary_inp_grp">
        <input
          type="text"
          class="sign_input"
          placeholder="Enter OTP"
          autoComplete="off"
          (input)="onInputChange()"
          formControlName="phoneOTP"
        />
        <span class="input_info">
          <svg cIcon name="cilCheck" *ngIf="otpVerified; else resend"></svg>
          <ng-template #resend>
            <span class="cursor-pointer" (click)="sendMobileOTP()" *ngIf="!loaders; else timerInSec">
              <small>Resend Code</small>
            </span>
          </ng-template>
          <ng-template #timerInSec>
            {{ seconds }}
          </ng-template>
        </span>
      </div>
      <div *ngIf="phoneOTP?.invalid && (phoneOTP?.touched || submitted)">
        <span class="required" *ngIf="phoneOTP?.errors?.['required']">OTP field is required</span>
      </div>
      <span class="required" *ngIf="serverErrors?.phoneOTP">{{
        serverErrors.phoneOTP
      }}</span>
    </div>
  </div>
  <button class="sign_btn"  *ngIf="!potpbtn"  (click)="sendMobileOTP()">
    <!-- <span  class="spinner-grow spinner-grow-sm"></span> -->
    Sign Up
  </button>
  <button class="vrf_btn"  *ngIf="potp"  (click)="verifyPhoneOTP()">
    <!-- <span  class="spinner-grow spinner-grow-sm"></span> -->
    Verify OTP
  </button>
  <!-- <button class="sign_btn"  *ngIf="potp" (click)="verifyPhoneOTP()">
  
    Verify OTP
  </button> -->
  <div class="mb-3" *ngIf="allowEmail">
    <div class="sign_group sign_group_input">
      <label>Email address</label>
      <input autoComplete="off" type="text" class="sign_input" placeholder="Enter Email" (input)="onInputChange()"
        formControlName="email" id="email" />
    </div>

    <div *ngIf="email?.invalid && (email?.touched || submitted)">
      <span class="required" *ngIf="email?.errors?.['required']">Email field is required</span>
      <span class="required" *ngIf="email?.errors?.['pattern']">Invalid email</span>
    </div>
    <span class="required" *ngIf="serverErrors?.email">{{
      serverErrors.email
      }}</span>
  </div>
  <button class="sign_btn"  *ngIf="!eotpbtn" (click)="sendMailOTP()">
    <!-- <span  class="spinner-grow spinner-grow-sm" ></span> -->
    Sign Up
  </button>
  <div class="mb-3"  *ngIf="eotp">
    <div class="sign_group sign_group_input">
      <label>OTP</label>
      <div class="primary_inp_grp">
        <input
          type="text"
          class="sign_input"
          placeholder="Enter OTP"
          autoComplete="off"
          (input)="onInputChange()"
          formControlName="emailOTP"
        />
        <span class="input_info">
          <svg cIcon name="cilCheck" *ngIf="otpVerified; else resend"></svg>
          <ng-template #resend>
            <span class="cursor-pointer" (click)="sendMailOTP()" *ngIf="!loaders; else timerInSec">
              <small>Resend Code</small>
            </span>
          </ng-template>
          <ng-template #timerInSec>
            {{ seconds }}
          </ng-template>
        </span>
      </div>
      <div *ngIf="emailOTP?.invalid && (emailOTP?.touched || submitted)">
        <span class="required" *ngIf="emailOTP?.errors?.['required']"
          >OTP field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.emailOTP">{{
        serverErrors.emailOTP
      }}</span>
    </div>
  </div>
  <button class="vrf_btn" *ngIf="eotp" (click)="verifyEmailOTP()">
    <!-- <span  class="spinner-grow spinner-grow-sm"></span> -->
    Verify OTP
  </button>
  <div class="mb-3" *ngIf="mobileVerify">
    <div class="sign_group sign_group_input">
      <label>Password</label>
      <div class="primary_inp_grp">
        <input [type]="showPassword ? 'text' : 'password'" class="sign_input" placeholder="Enter Password"
          autoComplete="off" (input)="onInputChange()" formControlName="password" />
        <span class="input_info" (click)="hanldeShowPassword()">
          <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
        </span>
      </div>
    </div>
    <div *ngIf="password?.invalid && (password?.touched || submitted)">
      <span class="required" *ngIf="password?.errors?.['required']">Password field is required</span>
      <span class="required" *ngIf="password?.errors?.['pattern']">Password should contain atleast one uppercase,
        atleast one lowercase, atleast one number, atleast one special character and minimum 6 and maximum 18</span>
    </div>
    <span class="required" *ngIf="serverErrors?.password">{{
      serverErrors.password
      }}</span>
  </div>

  <div class="mb-3 form-group" *ngIf="mobileVerify">
    <div class="sign_group sign_group_input">
      <label htmlFor="confirmPassword">Confirm Password</label>
      <div class="primary_inp_grp">
        <input id="confirmPassword" [type]="confirmShowPassword ? 'text' : 'password'" class="sign_input"
          placeholder="Enter Confirm Password" autoComplete="off" (input)="onInputChange()"
          formControlName="confirmPassword" />
        <span class="input_info" (click)="hanldeConfirmShowPassword()">
          <i [ngClass]="confirmShowPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
        </span>
      </div>
    </div>
    <span class="required" *ngIf="serverErrors?.confirmPassword">{{
      serverErrors.confirmPassword
      }}</span>
  </div>

  <div class="mb-3" *ngIf="mobileVerify">
    <div class="sign_group sign_group_input">
      <label htmlFor="referralcode">Referral Code (Optional)</label>
      <input id="referralcode" type="text" formControlName="referralcode" class="sign_input"
        placeholder="Enter Referral Code" autoComplete="off" (input)="checkReferral($event)" />
      <label *ngIf="validReferral" style="color: white;">Referal User Name:{{ReferralUser}}</label>
      <span class="required" *ngIf="serverErrors?.referralcode">{{
        serverErrors.referralcode
        }}</span>
    </div>
  </div>

  <div class="mb-3" *ngIf="mobileVerify">
    <div class="sign_group sign_group_checkbox">
      <input id="aggree" name="aggree" type="checkbox" autoComplete="off" (input)="onInputChange()"
        formControlName="aggree" />
      <label for="aggree">I agree to <a routerLink="/terms">Terms of Service</a> and
        <a routerLink="/privacy-policy">Privacy Policy</a></label>
    </div>
    <span class="required" *ngIf="serverErrors?.aggree">{{
      serverErrors.aggree
      }}</span>
  </div>

  <button class="sign_btn" [type]="form.get('aggree')?.value ? 'submit' : 'button'"
    [ngClass]="{ disabled: !form.get('aggree')?.value }" [disabled]="isLoading" *ngIf="mobileVerify">
    <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    Sign Up
  </button>
  <span class="sign_text">Already have an account? <a routerLink="/signin">Sign In</a></span>
</form>

