<app-header-dashboard></app-header-dashboard>
<main class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="dashbox">
                    <app-market></app-market>
                </div>
            </div>
        </div>
    </div>
</main>