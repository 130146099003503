<div class="tab-pane">
  <div class="row history_search">
    <form action="#" class="auth_form row" [formGroup]="form">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          (input)="onOptionChange($event)"
          id="pairName"
          formControlName="pairName"
        >
          <option value="all" selected="selected">All</option>
          <option *ngFor="let item of pairList" value="{{ item.tikerRoot }}">
            {{ item.tikerRoot }}
          </option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="orderType"
          (input)="onOptionChange($event)"
          formControlName="orderType"
        >
          <option value="all" selected="selected">All</option>
          <option value="limit">Limit</option>
          <option value="market">Market</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="buyorsell"
          (input)="onOptionChange($event)"
          formControlName="buyorsell"
        >
          <option value="all" selected="selected">All</option>
          <option value="buy">Buy</option>
          <option value="sell">Sell</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="status"
          (input)="onOptionChange($event)"
          formControlName="status"
        >
          <option value="all" selected="selected">All</option>
          <option value="open">Open</option>
          <option value="completed">Completed</option>
          <option value="cancel">Canceled</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="withdraw_btn withdraw_btn_mw" (click)="searchfilter()">Search</button>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="primary_btn w-100" (click)="clearfilter()">Clear</button>
        </div>
      </div>
    </form>
  </div>

  <div class="table-responsive">
    <table class="table primary_table">
      <thead>
        <tr>
          <th>Pair</th>
          <th>Order Date</th>
          <th>Trade Type</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Filled / Remaining</th>
          <th>Side</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of orderHistory?.data">
          <td>{{ item.pairName }}</td>
          <td>{{ item.orderDate | date : "MMM d, y, h:mm:ss" }}</td>
          <td>{{ item.orderType == "market" ? "Market" : "Limit" }}</td>

          <td>
            {{ item.orderType == "market" ? "Market" : toFixed(item.price, 4) }}
          </td>
          <td>
            {{
              toFixed(
                item.orderType == "market"
                  ? item.filledQuantity
                  : item.openQuantity,
                4
              )
            }}
          </td>

          <td>
            {{ toFixed(item.filledQuantity, 4) }}/{{
              toFixed(
                item.orderType == "market"
                  ? item.buyorsell == "buy"
                    ? 0
                    : item.openQuantity - item.filledQuantity
                  : item.openQuantity - item.filledQuantity,
                4
              )
            }}
          </td>
          <td>
            <span class="green_txt" *ngIf="item.buyorsell == 'buy'">Buy</span>
            <span class="red_txt" *ngIf="item.buyorsell == 'sell'">Sell</span>
          </td>

          <td>{{ item.status }}</td>
        </tr>
        <tr *ngIf="orderHistory.count == 0">
          <td colspan="10">
            <div class="text-center mt-3">
              <img
                src="assets/images/no_records_found.png"
                alt="No Records"
                img="img-fluid"
              />
              <p>No Records Found !</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav class="primary_pagination" *ngIf="filter.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{ disabled: filter.page == 1 }">
        <span
          class="page-link cursor-pointer"
          aria-label="Previous"
          (click)="previousPage()"
        >
          <span aria-hidden="true">&laquo;</span>
        </span>
      </li>
      <li class="page-item" *ngFor="let item of getDisplayedPages">
        <a
          class="page-link"
          [ngClass]="{ active: item == filter.page }"
          href="javascript:void(0)"
          (click)="goToPage(item)"
          >{{ item }}</a
        >
      </li>
      <li
        class="page-item"
        [ngClass]="{ disabled: filter.totalPages == filter.page }"
      >
        <span
          class="page-link cursor-pointer"
          (click)="nextPage()"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </span>
      </li>
    </ul>
  </nav>
</div>
