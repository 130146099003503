import { Component } from '@angular/core';

@Component({
  selector: 'app-tradeorderhistory',
  templateUrl: './tradeorderhistory.component.html',
  styleUrls: ['./tradeorderhistory.component.css']
})
export class TradeorderhistoryComponent {

}
