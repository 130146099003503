<div class="login_wrapper">
  <div class="authbanner_wrapper">
    <a routerLink="/">
      <img [src]="
          theme == 'dark'
            ? 'assets/images/new-logo.svg'
            : 'assets/images/new-logo.svg'
        " alt="Login" class="img-fluid auth_logo" />
    </a>
    <div class="auth_content">
      <h2>Buy and Sell Crypto Instantly!</h2>
      <p>
        Get Rs25 worth CRED crypto token by completing your KYC And also reffer
        others to register and complete their KYC And get Rs25 Worth of
        CRED crypto Token.
      </p>
    </div>
    <img src="assets/images/signup_img.png" alt="Login" class="img-fluid auth_right_img" />
  </div>
  <div class="auth_form_wrapper">
    <div class="row">
      <div class="col-lg-12">
        <div class="sign_content">
          
          <!-- authorization form -->
          <div class="mb-0">
            <h2>Sign Up</h2>
          </div>
          <div>
            <span (click)="know_SignUp()" class="sign_text"> How to Sign Up : <a class="cursor-pointer">Click
                Here</a></span>
          </div>
          <app-register-form></app-register-form>
          <!-- <app-login-form></app-login-form> -->
          <!-- end authorization form -->
        </div>
      </div>
    </div>
  </div>
  <!-- youtube video viewer  -->
  <div class="modal primary_modal" [style]="{ display: showVideoModal ? 'block' : 'none' }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="googleauth_enable_modalLabel">
            Know About Sign Up
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="know_SignUp()"></button>
        </div>
        <div class="modal-body">
          <iframe width="470" height="270" src="https://www.youtube.com/embed/BQoMfroq1RI?si=K3qI-O8yHE1HcMsl"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
</div>