import { Injectable } from '@angular/core';
import { isEmpty } from '../lib/validate';

// import * as jwtDecode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SocketService } from './socket.service';
import { AuthFacade } from '../store/auth';

@Injectable({
  providedIn: 'root'
})
export class JwtWebTokenService {

  constructor(
    private jwtHelperService: JwtHelperService,
    private socketService: SocketService,
    private authFacade: AuthFacade
  ) { }

  decodeJwt(token: string) {
    if (!isEmpty(token)) {
      token = token.replace('Bearer ', '')
      const decodedToken = this.jwtHelperService.decodeToken(token);
      this.authFacade.onSignInSuccess({
        signedIn: true,
        token: token,
        uniqueId: decodedToken.uniqueId
      });
      this.socketService.createSocketUser(decodedToken._id)
    }
  }
}
