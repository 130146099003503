<div class="trade_layout_wrap">
  <app-header-trade></app-header-trade>
  <main class="trade_layout_main">
    <div class="trade_wrap">
      <!-- Trade Pair -->
      <app-tickerprice [tikerRoot]="tikerRoot" class="trade_pair"></app-tickerprice>

      <!-- Trade Chart -->
      <app-tradechart class="trade_chart baseblock_wrapper"></app-tradechart>

      <!-- Order Book -->
      <app-orderbook class="orderbook_wraper baseblock_wrapper"></app-orderbook>

      <!-- Buy/Sell -->
      <app-orderplace class="trade_buysell" [isMobileLayout]="isMobileLayout"></app-orderplace>

      <!-- Market Pair -->
      <app-marketpair [tikerRoot]="tikerRoot" class="trade_market baseblock_wrapper"></app-marketpair>

      <!-- Trade & Order History -->
      <app-tradeorderhistory class="trade_history baseblock_wrapper"></app-tradeorderhistory>

      <!-- Open Order Table -->
      <app-userorderlist class="open_order"></app-userorderlist>
    </div>

    <div class="modal fade primary_modal" id="cancelorder_enable_modal" tabindex="-1"
      aria-labelledby="cancelorder_enable_modalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="cancelorder_enable_modalLabel">
              Cancel Order
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="closeModel()"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Are you sure to cancel this order</label>
            </div>
            <div>
              <button class="trade_buy_btn my-2" type="button" (click)="cancelOrder()">
                <!-- <i class="fas fa-spinner fa-spin"></i> -->
                Confirm
              </button>
              <button class="trade_sell_btn" type="button" id="cancelOrderModalBtn" data-bs-dismiss="modal"
                aria-label="Close" (click)="closeModel()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>