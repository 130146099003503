<div class="tab-pane">
  <div class="row history_search">
    <form action="#" class="auth_form row" [formGroup]="form">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          (input)="onOptionpairChange($event)"
          formControlName="pairName"
        >
          <option value="all" selected="selected">All</option>
          <option *ngFor="let item of pairList" value="{{ item.tikerRoot }}">
            {{ item.tikerRoot }}
          </option>
        </select>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          (input)="onOptionsideChange($event)"
          formControlName="buyorsell"
        >
          <option value="all" selected="selected">All</option>
          <option value="buy">Buy</option>
          <option value="sell">Sell</option>
        </select>
      </div>
      <!-- <div class="col-lg-2 col-md-4 col-sm-6">
      <div class="primary_inp_grp">
        <input placeholder="Start Date" type="text" class="primary_inp" />
        <img src="assets/images/date_icon.png" class="img-fluid" alt="Search" />
      </div>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6">
      <div class="primary_inp_grp">
        <input placeholder="End Date" type="text" class="primary_inp" />
        <img src="assets/images/date_icon.png" class="img-fluid" alt="Search" />
      </div>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6">
      <select class="primary_select" aria-label="all">
        <option value="1">All</option>
        <option value="2">Open</option>
        <option value="3">Completed</option>
        <option value="4">Cancelled</option>
      </select>
    </div> -->
      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="withdraw_btn withdraw_btn_mw" (click)="searchfilter()">Search</button>
          <!-- <input placeholder="Search" type="text" class="blue_txt" />
        <img
          src="assets/images/search_icon.png"
          class="img-fluid"
          alt="Search"
        /> -->
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="primary_btn w-100" (click)="clearfilter()">Clear</button>
          <!-- <input placeholder="Search" type="text" class="blue_txt" />
          <img
            src="assets/images/search_icon.png"
            class="img-fluid"
            alt="Search"
          /> -->
        </div>
      </div>
    </form>
  </div>

  <div class="table-responsive">
    <table class="table primary_table">
      <thead>
        <tr>
          <th>Pair</th>
          <th>Order Date</th>
          <th>Transaction ID</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Total</th>
          <th>Side</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of tradeHistory?.data">
          <td>{{ item.pairName }}</td>
          <td>{{ item.createdAt | date : "MMM d, y, h:mm:ss" }}</td>
          <td>{{ item._id }}</td>

          <td>
            {{ toFixed(item.tradePrice, 4) }}
          </td>
          <td>
            {{ toFixed(item.tradeQty, 4) }}
          </td>

          <td>{{ toFixed(item.tradePrice * item.tradeQty, 4) }}</td>
          <td>
            <span class="green_txt" *ngIf="item.buyUserId == userId">Buy</span>
            <span class="red_txt" *ngIf="item.buyUserId != userId">Sell</span>
          </td>

          <td>
            <span class="green_txt" *ngIf="item.status == 'completed'">{{
              item.status
            }}</span>
            <span class="red_txt" *ngIf="item.buyUserId == 'cancel'">{{
              item.status
            }}</span>
          </td>
        </tr>
        <tr *ngIf="tradeHistory?.count == 0">
          <td colspan="10">
            <div class="text-center mt-3">
              <img
                src="assets/images/no_records_found.png"
                alt="No Records"
                img="img-fluid"
              />
              <p>No Records Found !</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav class="primary_pagination" *ngIf="filter.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{ disabled: filter.page == 1 }">
        <span
          class="page-link cursor-pointer"
          aria-label="Previous"
          (click)="previousPage()"
        >
          <span aria-hidden="true">&laquo;</span>
        </span>
      </li>
      <li class="page-item" *ngFor="let item of getDisplayedPages">
        <a
          class="page-link"
          [ngClass]="{ active: item == filter.page }"
          href="javascript:void(0)"
          (click)="goToPage(item)"
          >{{ item }}</a
        >
      </li>
      <li
        class="page-item"
        [ngClass]="{ disabled: filter.totalPages == filter.page }"
      >
        <span
          class="page-link cursor-pointer"
          (click)="nextPage()"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </span>
      </li>
    </ul>
  </nav>
</div>
