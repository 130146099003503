import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;

  showOldPassword: boolean = false
  showNewPassword: boolean = false
  showConfirmPassword: boolean = false

  passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{6,18}(?!\S)/

  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private AuthService: AuthService,
    private router: Router,
    private _fb: FormBuilder,
  ) {
    this.form = this._fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.pattern(this.passwordRegex)]],
      confirmPassword: ['', [Validators.required]]

    });
  }

  get oldPassword() { return this.form.get('oldPassword') }
  get password() { return this.form.get('password') }
  get confirmPassword() { return this.form.get('confirmPassword') }


  onInputChange() {
    this.serverErrors = {}
  }
  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.service.changepassword(this.form.value)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.form.reset()

            this.AuthService.logoutApi()

            this.router.navigateByUrl("/signin")
          }
        },
        error: (err: any) => {
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  hanldeShowOldPassword() {
    this.showOldPassword = !this.showOldPassword
  }

  hanldeShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  hanldeShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword
  }

}
