import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { WalletService } from 'src/app/services/wallet.service';

// Import facade
import { PairFacade } from '../../store/pair'
import { CurrencyFacade } from '../../store/currency'

// Import lib
import isEmpty from 'src/app/lib/isEmpty';

// Import config
import { spotSocket } from 'src/app/app.module';

// Import lib
import { convert, longDecimals, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { MarketPriceFacade } from 'src/app/store/marketprice';
import { Cookies } from 'src/app/lib/cookies';
import { TradeAssetFacade } from 'src/app/store/trade-asset';
@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent {

  @Input() tikerRoot: any

  toFixedDown = toFixedDown
  truncateDecimals = truncateDecimals
  convert = convert
  longDecimals = longDecimals

  pairs: any = {}
  currencies: any = []
  activeCurrency: string = '';
  pairData: any = {}
  currencylist: any = []
  searchPair: string = ''
  searchTerm: string = ''

  constructor(
    private service: SpotService,
    private walletService: WalletService,
    private pairFacade: PairFacade,
    private currencyFacade: CurrencyFacade,
    private tradeAssetFacade: TradeAssetFacade,
    private router: Router,
    private cookies: Cookies,
    private spotSocket: spotSocket,
    private marketPriceFacade: MarketPriceFacade,
    private route: ActivatedRoute
  ) {
    // console.log(this.isMobileLayout, 'isMobileLayout')
  }

  ngOnInit() {
    this.currencyRedux()
    this.getPairList()

    this.spotSocket.on('marketPrice', this.marketPriceWs.bind(this))
  }
  
  // ngOnDestroy() {
  //   this.spotSocket.off('marketPrice');
  // }




  currencyRedux() {
    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        this.currencylist = data;
      },
      error: (err: any) => {
        // console.log(err, '-----21');
      },
    });
  }

  getPairList() {
    this.service.getPairList()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.pairFacade.setPair(v.result)
            this.pairs = v.result.reduce((acc: any[], curr: any) => {
              if (!acc[curr.secondCurrencySymbol]) {
                acc[curr.secondCurrencySymbol] = [];
                this.currencies.push(curr.secondCurrencySymbol)
              }
              acc[curr.secondCurrencySymbol].push(curr);
              return acc;
            }, {});

            this.activeCurrency = this.currencies[0]
          }
        },
        error: (err: any) => {
        }
      })
  }

  getCurrecyImage(currency: string) {
    let curr = this.currencylist.find((el: any) => el.coin == currency);
    return curr?.image ? curr?.image : "";

  }
  fetchAssetByCurrency(spotPairId: string, type: 'firstCurrency' | 'secondCurrency') {
    if (!this.cookies.isLogin())
      return

    this.walletService.getAssetByCurrency(spotPairId)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            if (type === 'firstCurrency')
              this.tradeAssetFacade.setFirstCurrency(v.result)
            else if (type === 'secondCurrency')
              this.tradeAssetFacade.setSecondCurrency(v.result)
          }
        },
        error: (err: any) => {
        }
      })
  }

  marketPriceWs(result: any) {
    let currPair = -1;
    let pairsObj = JSON.parse(JSON.stringify(this.pairs))
    for (let secondCur of this.currencies) {
      currPair = pairsObj[secondCur].findIndex((el: any) => el._id == result.pairId);
      if (currPair >= 0) {
        // console.log(result.data.markPrice, '--result.data.markPrice')
        pairsObj[secondCur][currPair].markPrice = result.data.markPrice;
        pairsObj[secondCur][currPair].change = result.data.change;
        pairsObj[secondCur][currPair].high = result.data.high;
        pairsObj[secondCur][currPair].firstVolume = result.data.firstVolume;

        this.pairs = pairsObj
      }
    }
    // return () => {
    //   this.spotSocket.off('marketPrice');
    // }
  }

  handleSearchPair(event: any) {
    this.searchPair = event.target.value.toUpperCase().replace('/', '')
  }

}


