import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, PriceCnvState } from './pricecnv.state';
import { setPriceCnv, reset } from './pricecnv.actions';

export const priceCnvFeatureKey = 'priceconversion';

export const priceCnvReducers: ActionReducer<PriceCnvState> = createReducer(
    initialState,
    on(setPriceCnv, (_, action) => action.payload),
    on(reset, () => initialState)
);