import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component } from '@angular/core';
import { CurrencyFacade } from 'src/app/store/currency';
import { WalletFacade } from 'src/app/store/wallet';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/app/services/wallet.service';
import { encryptObject } from 'src/app/lib/cryptoJs';
import * as $ from 'jquery';
import isEmpty from 'src/app/lib/isEmpty';
import { UserService } from 'src/app/services/user.service';
import { UserFacade } from 'src/app/store/user';

// Import lib
import { toFixed, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { precentConvetPrice } from 'src/app/lib/calculation';
@Component({
  selector: 'app-withdraw-crypto',
  templateUrl: './withdraw-crypto.component.html',
  styleUrls: ['./withdraw-crypto.component.css'],
})
export class WithdrawCryptoComponent {
  toFixed = toFixed;
  truncateDecimals = truncateDecimals

  form: FormGroup;

  orgCurr: any = [];

  user: any = {};
  wallet: any = {};
  currency: any = {};
  choosenCurrency: string = 'BTC';
  selectedAsset: any = {};
  selectedCurrency: any = {};
  currencymodal: boolean = true;
  submitted: boolean = false;
  isLoading: boolean = false;
  serverErrors: any;

  constructor(
    private currencyFacade: CurrencyFacade,
    private walletFacade: WalletFacade,
    private service: WalletService,
    private toastr: ToastrService,
    private userFacade: UserFacade,
    private router: Router,
    private route: ActivatedRoute,
    private _fb: FormBuilder
  ) {
    const curr = this.route.snapshot.params['coin'];
    if (!isEmpty(curr)) {
      this.choosenCurrency = curr;
    }

    this.form = this._fb.group({
      receiverAddress: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      twoFACode: ['', [Validators.required]],
      currencyId: ['', [Validators.required]],
      finalAmount: ['']
    });
  }

  get receiverAddress() {
    return this.form.get('receiverAddress');
  }
  get amount() {
    return this.form.get('amount');
  }
  get twoFACode() {
    return this.form.get('twoFACode');
  }
  get currencyId() {
    return this.form.get('currencyId');
  }

  ngOnInit() {
    this.getUserData();
    this.walletRedux();
    this.currencyRedux();
  }

  getUserData() {
    this.userFacade.user$.subscribe({
      next: (user: any) => {
        this.user.twoFAStatus = user.twoFAStatus;
        this.user.aadharNumber =
          user.kycStatus == 'approved'
            ? 'verified'
            : 'Not verified';
      },
      error: (err: any) => {
        // console.log(err, err)
      },
    });
  }

  currencyRedux() {
    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        this.currency = data;
        this.assetList();
      },
      error: (err: any) => { },
    });
  }

  walletRedux() {
    this.walletFacade.wallet$.subscribe({
      next: (data: any) => {
        this.wallet = data;
        this.assetList();
      },
      error: (err: any) => { },
    });
  }
  assetList() {
    if (this.wallet && this.currency) {
      let asset = this.wallet.find(
        (el: any) => el.coin == this.choosenCurrency
      );
      let curr = this.currency.find(
        (el: any) => el.coin == this.choosenCurrency
      );
      if (asset && curr) {
        const uniqueArrayOfObjects = Object.values(
          this.currency.reduce((acc: any, obj: any) => {
            if (obj.status == 'active' && obj.withdrawStatus === 'active' && obj.type != 'fiat') {
              return { ...acc, [obj.coin]: obj };
            }
            return acc;
          }, {})
        );
        this.selectedAsset = asset;
        this.selectedCurrency = curr;
        this.form.patchValue({
          currencyId: curr._id,
        });

        this.orgCurr = uniqueArrayOfObjects;
      }
    }
  }

  selectDropdown(item: any) {
    this.choosenCurrency = item.coin;
    this.form.patchValue({
      currencyId: item._id,
    });
    $('#ChooseCrypto #curmodalclose').click();
    this.assetList();

    // console.log(this.form.value, 'this.form.value');
  }

  CopyText(value: any) {
    navigator.clipboard.writeText(value);
    this.toastr.success('Copied...');
  }

  submitForm() {
    if (this.selectedCurrency?.status !== 'active' || this.selectedCurrency?.withdrawStatus !== 'active') {
      this.toastr.error('Currency Not Active');
      return
    }

    this.selectedCurrency?.coin
    this.submitted = true;
    if (!this.form.valid) return;
    this.isLoading = true;
    const { currencyId, amount, receiverAddress, twoFACode, tokenType, coin } =
      this.form.value;
    let reqData = {
      currencyId,
      amount,
      receiverAddress,
      twoFACode,
      coin: this.selectedCurrency?.coin,
      tokenType: this.selectedCurrency?.tokenType,
      newdate: new Date(),
    };
    const encryptData = {
      token: encryptObject(reqData),
    };

    this.service.coinWithdraw(encryptData).subscribe({
      next: (v: any) => {
        this.isLoading = false;

        if (v.success) {
          this.submitted = false;
          this.toastr.success(v.message);
          this.form.reset();
          this.serverErrors = {}
          //this.router.navigateByUrl("/edit-profile")
        }
      },
      error: (err: any) => {
        this.submitted = false;
        this.isLoading = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  handleAmountChange(event: any) {
    this.serverErrors = {}
    const amount = event.target.value;
    let finalAmountBal = parseFloat(amount) - precentConvetPrice(amount, this.selectedCurrency.withdrawFee);
    this.form.patchValue({
      finalAmount: finalAmountBal && finalAmountBal > 0 ? toFixedDown(finalAmountBal, 8) : '0',
    });
  }
}
