import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalLimit]'
})
export class DecimalLimitDirective {
  @Input('appDecimalLimit') decimalLimit: any;

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    
    this.decimalLimit = parseFloat(this.decimalLimit)

    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    // Check if value has more decimal places than the limit
    if (value.includes('.') && value.split('.')[1].length > this.decimalLimit) {
      input.value = value.slice(0, value.indexOf('.') + 1 + this.decimalLimit); // Truncate to the specified decimal limit
    }
  }
}
