<app-header></app-header>
<main class="inner_cms_layout">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <app-market></app-market>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
