export interface AuthState {
    signedIn: false;
    token: string,
    uniqueId: string
}

export const initialState: AuthState = {
    signedIn: false,
    token: '',
    uniqueId: ''
}