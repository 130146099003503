import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private cookieService: CookieService, private router: Router, private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const req = request.clone({
      setHeaders: {
        'Authorization': this.cookieService.get('user_token'),
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
    return next.handle(req).pipe(tap(() => { },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // this.cookieService.delete('user_token');
            // this.router.navigate(['signin']);
            this.authService.logoutApi()
          }
        }
      }));
  }
}
