import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

// Import lib
import { encryptObject } from 'src/app/lib/cryptoJs';
import { toFixed, truncateDecimals } from 'src/app/lib/roundOf';

// Import service
import { SpotService } from 'src/app/services/spot.service';

// Import facade
import { TradeAssetFacade } from 'src/app/store/trade-asset';
import { TradePairFacade } from 'src/app/store/tradepair';
@Component({
  selector: 'app-marketorder',
  templateUrl: './marketorder.component.html',
  styleUrls: ['./marketorder.component.css']
})
export class MarketorderComponent {
  // @Input() isSellVisible: any;
  // @Input() isBuyVisible: any;
  @Input() buyorsell: any;

  truncateDecimals = truncateDecimals;

  firstCurrency: any = {}
  secondCurrency: any = {}
  tradePair: any = {}

  form: FormGroup;
  buyPercentage: number = 0
  submitted: boolean = false;
  serverErrors: any = {}

  constructor(
    private _fb: FormBuilder,
    private tradeAssetFacade: TradeAssetFacade,
    private tradePairFacade: TradePairFacade,
    private service: SpotService,
    private toastr: ToastrService
  ) {
    this.form = this._fb.group({
      price: ['', Validators.required],
      quantity: ['', Validators.required],
      total: ['']
    });
  }

  ngOnInit() {
    this.tradeAssetFacade.tradeAsset$.subscribe({
      next: ({ firstCurrency, secondCurrency }: any) => {
        this.firstCurrency = firstCurrency
        this.secondCurrency = secondCurrency
      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
      },
      error: (err: any) => { }
    })
  }

  get price() { return this.form.get('price') }
  get quantity() { return this.form.get('quantity') }

  handleChange(formControlName?: string) {
    const { price, quantity } = this.form.value
    const total = price * quantity
    this.form.patchValue({
      total: toFixed(total, this.tradePair.secondFloatDigit)
    })
  }

  handleSetPercentage(percent: number) {
    const spotBal = this.secondCurrency.spotBal
    const newPrice = (spotBal / 100) * percent
    this.buyPercentage = percent
    this.form.patchValue({
      price: newPrice,
      total: newPrice * this.form.value.quantity
    })
  }

  IncDec(field: string, type: string) {
    let value = this.form.value[field];
    if (type === 'dec' && value > 0)
      value--;
    else if (type === 'inc')
      value++;

    this.form.patchValue({
      [field]: value
    });
    this.handleChange()
  }

  submitForm() {
    const { price, quantity } = this.form.value
    this.submitted = true

    if (!this.form.valid)
      return

    let reqData = {
      price,
      quantity,
      buyorsell: this.buyorsell,
      orderType: 'limit',
      spotPairId: this.tradePair._id,
      newdate: new Date()
    }
    const encryptData = {
      token: encryptObject(reqData)
    }

    this.service.orderPlace(encryptData)
      .subscribe({
        next: (v: any) => {
          this.submitted = false
          if (v.status) {
            this.toastr.success(v.message)
            this.form.reset()
          } else {
            this.toastr.success(v.message)
          }
        },
        error: (err: any) => {
          this.submitted = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          } else if (err.error.errors) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          }
        }
      })
  }

}