import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

import { environment } from 'src/environments/environment';

// Import facade
import { UserSettingFacade } from 'src/app/store/usersetting';
import { convert } from 'src/app/lib/roundOf';

declare let AOS: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  convert = convert

  activeTheme = localStorage.getItem('theme')
  slides: string[] = [];
  sliderurl: string = ''
  cmsurl: string = environment.userApiUrl + 'cms-images/'
  whydarkimage: string = ''

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "arrows": false,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "loop": true,
    "responsive": [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  homeCustomerServed: any = {}
  homeServingBc: any = {}
  homeOrderProcessed: any = {}
  homeCoutries: any = {}
  homeimageBanner: any = {}
  homeimageWhyChoose: any = {}
  homeimageMobileApp: any = {}

  constructor(private commonService: CommonService, private userSettingFacade: UserSettingFacade) { }
  slickInit(e: any) {
    // console.log('slick initialized');
  }

  ngOnInit() {
    AOS.init();
    AOS.refresh();
    this.getCmsContent()
    this.getSliders()

    this.userSettingFacade.usersetting$
      .subscribe({
        next: (data: any) => {
          let image_name = 'cms-why_choose_img_dark.png';
          if (data.defaultTheme == 'light')
            image_name = 'cms-why_choose_img_light.png'

          this.whydarkimage = this.cmsurl + image_name
        },
        error: (err) => console.log(err)
      })
  }

  getSliders() {
    this.commonService.getSliders()
      .subscribe({
        next: (v: any) => {

          const result = v.result

          if (result && result.length > 0) {
            this.sliderurl = environment.userApiUrl + 'slider/'
            const list: string[] = []
            result.forEach((element: any) => {
              list.push(element.image)
            });
            this.slides = list
            // console.log(list, 'list')
          }
        },
        error: () => { }
      })
  }

  getCmsContent() {
    this.commonService.getHomeCmsPages()
      .subscribe({
        next: (v: any) => {
          const result = v.result
          if (result && result.length > 0) {
            result.map((item: any) => {
              if (item.identifier == "home-customers-served") {
                this.homeCustomerServed = item
              } if (item.identifier == "home-serving-bc") {
                this.homeServingBc = item
              } if (item.identifier == "home-order-processed") {
                this.homeOrderProcessed = item
              } if (item.identifier == "home-coutries") {
                this.homeCoutries = item
              } if (item.identifier == "homeimage-banner") {
                this.homeimageBanner = item
              } if (item.identifier == "homeimage-why_choose_image") {
                this.homeimageWhyChoose = item
              } if (item.identifier == "homeimage-mobile-app") {
                this.homeimageMobileApp = item
              }
            })
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

}
