import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setCurrency, reset } from './currency.actions';
import { selectCurrency } from './currency.selectors';
import { map, Observable } from 'rxjs';
import { CurrencyState } from './currency.state';

@Injectable({ providedIn: 'root' })
export class CurrencyFacade {
    private readonly store: Store = inject(Store);

    readonly currency$: Observable<CurrencyState> = this.store.select(selectCurrency);

    setCurrency(payload: any): void {
        this.store.dispatch(setCurrency({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}