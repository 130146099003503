import { Component } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';

import { widget } from 'src/assets/charting_library/charting_library.min';
import { environment } from 'src/environments/environment';
const chartUrl = environment.spotApiUrl;

@Component({
  selector: 'app-app-trade-chart',
  templateUrl: './app-trade-chart.component.html',
  styleUrls: ['./app-trade-chart.component.css']
})
export class AppTradeChartComponent {

  theme: 'Dark' | 'Light' = 'Dark'
  pairData: any = {}

  public isMobileLayout = false;
  public isDestopLayout = false;

  backUrl = chartUrl + 'api/spot/chart';
  pair = "BTCUSDT";
  first_time = true

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const symbol: any = this.route.snapshot.paramMap.get('pair');
    this.theme = this.route.snapshot.paramMap.get('theme') === 'dark' ? 'Dark' : 'Light'
    this.buildChart(symbol)
  }

  buildChart(pair: string) {
    const widgetOptions: any = {
      symbol: pair,
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.backUrl),
      interval: '5',
      container_id: 'tv_chart_container2',
      library_path: '/assets/app/charting_library/',
      locale: 'en',
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: true,
      autosize: false,
      studies_overrides: {},
      loading_screen: { backgroundColor: "#00339F" },
      theme: this.theme,
      toolbar_bg: "#00339F",
      overrides: {
        "paneProperties.background": "#00339F",
        "paneProperties.vertGridProperties.color": "#00339F",
        "paneProperties.horzGridProperties.color": "#00339F",
      },
    };

    if (this.theme == 'Light') {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => { },
          })
        );
      });
    });

  }

}
