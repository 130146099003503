import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  getCurrency() {
    return this.http.get(environment.walletApiUrl + 'api/currency/getCurrency')
  }

  getPriceCnv() {
    return this.http.get(environment.walletApiUrl + 'api/common/priceConversion')
  }

  getCmsPages(id: string) {
    return this.http.get(environment.userApiUrl + 'api/user/cms/' + id)
  }

  getHomeCmsPages() {
    return this.http.get(environment.userApiUrl + 'api/user/home-cms')
  }

  getSliders() {
    return this.http.get(environment.userApiUrl + 'api/user/sliders')
  }

  getRecentTxn(params: any) {
    return this.http.get(environment.walletApiUrl + 'api/wallet/recentTransaction', { params })
  }

  getSiteSetting() {
    return this.http.get(environment.userApiUrl + 'api/user/siteSetting')
  }
}
