import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setTradePair, reset } from './tradepair.actions';
import { selectTradePair } from './tradepair.selectors';
import { Observable } from 'rxjs';
import { TradePairState } from './tradepair.state';

@Injectable({ providedIn: 'root' })
export class TradePairFacade {
    private readonly store: Store = inject(Store);

    readonly tradePair$: Observable<TradePairState> = this.store.select(selectTradePair);

    setTradePair(payload: any): void {
        this.store.dispatch(setTradePair({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}