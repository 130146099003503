import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

// Import facade
import { AuthFacade } from 'src/app/store/auth';
import { UserFacade } from 'src/app/store/user';
import { UserSettingFacade } from 'src/app/store/usersetting';
import { WalletFacade } from '../store/wallet';
import { PriceCnvFacade } from '../store/pricecnv';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly authFacade: AuthFacade = inject(AuthFacade);
  private readonly userFacade: UserFacade = inject(UserFacade);
  private readonly userSettingFacade: UserSettingFacade = inject(UserSettingFacade);
  private readonly walletFacade: WalletFacade = inject(WalletFacade);
  private readonly priceCnvFacade: PriceCnvFacade = inject(PriceCnvFacade);

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  userEmailActivation(payload: any) {
    return this.http.post(environment.userApiUrl + 'api/auth/confirm-mail', payload)
  }

  registerApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/register', payload)
  }

  loginApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/login', payload)
  }

  verifyOtpApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/verifyOtp', payload)
  }

  forgotPasswordApi(data: any) {
    return this.http.post(environment.userApiUrl + 'api/auth/forgotPassword', data)
  }

  apiResetPassword(data: any) {
    return this.http.post(environment.userApiUrl + 'api/auth/resetPassword', data)
  }

  resetPasswordVerification = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/resetconfirmMail', payload)
  }

  resendOtpApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/resendOtp', payload)
  }

  logoutApi = () => {
    document.cookie = 'user_token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.authFacade.onSignOutSuccess()
    this.userSettingFacade.reset()
    this.userFacade.reset()
    this.walletFacade.reset()
    // this.priceCnvFacade.reset()
    this.router.navigateByUrl("/signin")
  }
  validateRefrralCode(params: any) {
    return this.http.get(environment.userApiUrl + `api/auth/validationReferalCode`,  {params})
  }
  sendPhoneOtpApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/sendPhoneOTPNew', payload);
  };
  sendEmailOtpApi = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/sendEmailOTP', payload);
  };
  validatePhone = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/validationPhone', payload)
  }
  validateEmail = (payload: any) => {
    return this.http.post(environment.userApiUrl + 'api/auth/validationEmail', payload)
  }
}
