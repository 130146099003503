import { Component, inject } from '@angular/core';
import { toFixed } from 'src/app/lib/roundOf';

// Import service
import { WalletService } from 'src/app/services/wallet.service';
import { CurrencyFacade } from '../../../store/currency';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getDisplayedPages } from 'src/app/lib/pagination';
@Component({
  selector: 'app-transactionhistory',
  templateUrl: './transactionhistory.component.html',
  styleUrls: ['./transactionhistory.component.css']
})
export class TransactionhistoryComponent {
  private readonly currencyFacade: CurrencyFacade = inject(CurrencyFacade);
  form: FormGroup;
  currencyList: any = []
  toFixed = toFixed
  txnHistory: any = []
  filter: any = {
    page: 1,
    limit: 10,
    totalPages: 0,
    coin: "all",
    paymentType: "all",
    payment: "all",
    status: "all"
  }
  // paginator: number[] = []
  getDisplayedPages: number[] = []

  constructor(private service: WalletService, private _fb: FormBuilder) {
    this.form = this._fb.group({
      coin: "all",
      paymentType: "all",
      payment: "all",

    });
  }

  ngOnInit() {
    this.fetchTxnHistory(this.filter);
    this.getCurrency()
  }


  getCurrency() {
    this.currencyFacade.currency$
      .subscribe({
        next: (data: any) => {
          this.currencyList = data && data.filter((el: any) => el.status == 'active')

        },
        error: (err: any) => {
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
          } else if (err.status === 500) {
          }
        },
        complete: () => { }
      })
  }



  fetchTxnHistory(filter: any) {
    this.service.gettxnhistory(filter).subscribe({
      next: (data: any) => {
        this.txnHistory = data.result
        // this.filter.page = data.count
        this.filter.totalPages = Math.ceil(data.result.count / this.filter.limit)
        // this.paginator = Array(this.filter.totalPages).fill(1).map((x, y) => x + y)
        this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5)
      },
      error: () => { }
    })
  }

  nextPage() {
    this.filter.page += 1

    this.fetchTxnHistory(this.filter);
  }

  goToPage(pageNo: number) {
    this.filter.page = pageNo
    this.fetchTxnHistory(this.filter);
  }

  previousPage() {
    if (this.filter.page > 1)
      this.filter.page -= 1

    this.fetchTxnHistory(this.filter);
  }

  onOptionChange(event: any) {
    const name = event.target.id
    const filterValue = event.target.value
    this.filter[name] = filterValue

  }

  searchfilter() {
    this.filter.page = 1;

    this.fetchTxnHistory(this.filter);

  }

  clearfilter() {
    const initialfilter = {
      page: 1,
      limit: 10,
      totalPages: 0,
      coin: "all",
      paymentType: "all",
      payment: "all",
    }
    // this.filter.page = 1
    this.form.reset()
    this.form.patchValue({
      coin: "all",
      paymentType: "all",
      payment: "all",

    })
    this.fetchTxnHistory(initialfilter);
    this.filter = initialfilter
  }



}



