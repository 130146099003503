<div class="tradepair_details">
    <div class="trading_pair_currency">
        <h1 class="tradepair_data">{{tikerRoot.replace('_', ' / ')}}</h1>
    </div>
    <div class="tradepair_lastprice">
        <!-- <div class="tradepair_label">Last Price</div> -->
        <span class="tradepair_data green_txt"
            [title]="truncateDecimals(tickerData.markPrice, pairData.secondFloatDigit)">
            {{longDecimals(truncateDecimals(tickerData.markPrice, pairData.secondFloatDigit))}}
        </span>
        <div class="tradepair_data text-secondary">
            $ {{truncateDecimals(tickerData.markPrice * convertPrice, 2) }}
        </div>
    </div>
    <div class="tradepair_change">
        <div class="tradepair_label">24H Change</div>
        <span class="{{'tradepair_data ' + (tickerData.change > 0 ? 'green_txt': 'red_txt')}}">
            {{truncateDecimals(tickerData.change, 2)}} %
        </span>
    </div>
    <div class="tradepair_high pr-0">
        <div class="tradepair_label">24H High</div>
        <span class="tradepair_data" [title]="truncateDecimals(tickerData.high, pairData.firstFloatDigit)">
            {{longDecimals(truncateDecimals(tickerData.high, pairData.firstFloatDigit))}}
        </span>
    </div>
    <div class="tradepair_low pr-0">
        <div class="tradepair_label">24H Low</div>
        <span class="tradepair_data" [title]="truncateDecimals(tickerData.low, pairData.firstFloatDigit)">
            {{longDecimals(truncateDecimals(tickerData.low, pairData.firstFloatDigit))}}
        </span>
    </div>
    <div class="tradepair_volume pr-0">
        <div class="tradepair_label">24H Volume ({{pairData.firstCurrencySymbol}})</div>
        <span class="tradepair_data" [title]="truncateDecimals(tickerData.firstVolume, pairData.firstFloatDigit)">
            {{longDecimals(truncateDecimals(tickerData.firstVolume, pairData.firstFloatDigit))}}
        </span>
    </div>
</div>

<!-- <span [title]="truncateDecimals(marketData.markPrice, tradePair.secondFloatDigit)">
    {{ longDecimals(truncateDecimals(marketData.markPrice, tradePair.secondFloatDigit)) }}
  </span> -->