import { Component } from '@angular/core';
import { FormBuilder, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import isEmpty from 'src/app/lib/isEmpty';

// Import facade
import { CurrencyFacade } from 'src/app/store/currency';
import { WalletFacade } from 'src/app/store/wallet';

@Component({
  selector: 'app-onramper-home',
  templateUrl: './onramper-home.component.html',
  styleUrls: ['./onramper-home.component.css']
})
export class OnramperHomeComponent {

  form!: FormGroup
  isLoading: boolean = false
  serverErrors: any = {}
  submitted: boolean = false
  fbLoading: boolean = false

  // redux state
  currency: any = []
  wallet: any = []

  // option variables
  coinOptions: any = []
  fiatOptions: any = []
  selectedCoin: string = ''
  selectedFiat: string = ''

  // onramper
  showModal: boolean = false
  onramperParams: any = {
    themeData: '',
    coinSymbol: '',
    address: '',
    fiatSymbol: '',
    fiatAmount: ''
  }

  constructor(
    private _fb: NonNullableFormBuilder,
    private currencyFacade: CurrencyFacade,
    private walletFacade: WalletFacade,
  ) {
    this.initForm()
  }

  ngOnInit() {
    // this.getCurrencies()
    this.initialCall()
  }

  // init form builder
  initForm() {
    this.form = this._fb.group({
      coinSymbol: ['', Validators.required],
      fiatSymbol: ['', Validators.required],
      fiatAmount: ['', [Validators.required]],
      address: ['']
    })
  }

  async initialCall() {
    this.getWallet()
  }

  get coinSymbol() {
    return this.form.get('coinSymbol');
  }

  get fiatSymbol() {
    return this.form.get('fiatSymbol');
  }

  get fiatAmount() {
    return this.form.get('fiatAmount');
  }

  get address() {
    return this.form.get('address');
  }

  // get user wallet
  private getWallet() {
    this.walletFacade.wallet$
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.wallet = data
            this.getCurrencies()
          }

        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  // get all currencies
  private getCurrencies() {
    this.currencyFacade.currency$
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.currency = data;

            const coinOptions: any[] = []
            const fiatOptions: any[] = []

            data && data.length > 0 && data.map((item: any) => {
              if (item && item.type == 'crypto') {
                let address
                address = this.wallet && this.wallet.find((el: any) => {
                  if (el.coin == item.coin) {
                    return el.address
                  }
                })
                coinOptions.push({
                  'label': item.coin,
                  'value': item.coin,
                  'address': !isEmpty(address) ? address : ""
                })
              } else if (item && item.type == 'fiat') {
                fiatOptions.push({
                  'label': item.coin,
                  'value': item.coin
                })
              }

              this.coinOptions = coinOptions
              this.fiatOptions = fiatOptions
              this.isLoading = true
            })
          }
        },
        error: (err) => {
          // console.log(err)
        }
      })
  }

  changeCurrency(event: Event) {

  }

  submitForm() {
    this.submitted = true

    if (!this.form.valid || this.fbLoading)
      return

    this.fbLoading = true

    const { coinSymbol, fiatSymbol, fiatAmount, address } = this.form.value
    this.onramperParams = {
      theme: 'light',
      coinSymbol: coinSymbol,
      address: address,
      fiatSymbol: fiatSymbol,
      fiatAmount: fiatAmount
    }
    this.showModal = true
  }

  resetForm() {
    this.form.reset()
    this.submitted = false
  }

  toggleModal() {
    this.showModal = false
    this.fbLoading = false
  }

}
