import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { spotSocket } from 'src/app/app.module';
import { Cookies } from 'src/app/lib/cookies';
import { encryptObject } from 'src/app/lib/cryptoJs';
import { convert, longDecimals, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { AuthFacade } from 'src/app/store/auth';

// Import facade
import { TradePairFacade, TradePairState, selectTradePair } from 'src/app/store/tradepair';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-openorders',
  templateUrl: './openorders.component.html',
  styleUrls: ['./openorders.component.css'],
})
export class OpenordersComponent {
  truncateDecimals = truncateDecimals;
  toFixedDown = toFixedDown;
  convert = convert
  longDecimals = longDecimals

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  isAuth: boolean = false
  tradePair: any = {}
  auth: any = {}
  orderData: any = {
    currentPage: 1,
    nextPage: false,
    limit: 50,
    count: 0,
    data: []
  }
  page = 1;
  cats: any[] = []
  pairId = ''

  isLoading = false;

  constructor(
    private spotService: SpotService,
    private tradePairFacade: TradePairFacade,
    private authFacade: AuthFacade,
    private cookies: Cookies,
    private spotSocket: spotSocket,
    private http: HttpClient
  ) {
    this.isAuth = this.cookies.isLogin()
  }

  ngOnInit() {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth;

      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$
      .subscribe({
        next: (pair: any) => {
          this.tradePair = pair;
          const { currentPage, limit } = this.orderData
          let reqData = {
            page: currentPage,
            limit
          }
          console.log(this.isAuth, 'this.isAuth--openorders')
          if (pair && pair._id && this.isAuth) {
            // console.log(this.isAuth, 'this.isAuth23')
            // console.log('coming')
            this.fetchOpenOrders(reqData, pair._id);
            this.spotSocket.on('openOrder', this.openOrderWs.bind(this))
          }
        },
        error: (err: any) => { }
      })
  }

  // ngOnDestroy() {
  //   this.spotSocket.off('openOrder');
  // }

  toggleLoading = () => this.isLoading = !this.isLoading;

  fetchOpenOrders(reqData: any, pairId: string) {
    this.pairId = pairId
    this.spotService.getOpenOrder(reqData, pairId).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.orderData = {
            currentPage: v.result.currentPage,
            limit: v.result.limit,
            count: v.result.count,
            data: [...v.result.data]
          }
        } else {
          this.orderData = {
            ...this.orderData,
            ...{ 'nextPage': true }
          }
        }
      },
      error: (err: any) => { }
    })
  }

  appendOpenOrders() {
    const { currentPage, limit } = this.orderData
    const reqData = {
      page: currentPage,
      limit
    }
    this.spotService.getOpenOrder(reqData, this.pairId).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.orderData = {
            currentPage: v.result.currentPage,
            limit: v.result.limit,
            count: v.result.count,
            data: [...this.orderData.data, ...v.result.data]
          }
        } else {
          this.orderData = {
            ...this.orderData,
            ...{ 'nextPage': true }
          }
        }
        this.toggleLoading()
      },
      error: (err: any) => console.log(err)
    })
  }

  openOrderWs(result: any) {
    // console.log(this.tradePair, 'this.tradePair')
    if (result.pairId == this.tradePair._id) {
      this.orderData = {
        // currentPage: result.currentPage,
        // nextPage: result.nextPage,
        // limit: result.limit,
        ...this.orderData,
        count: result.count,
        data: result.data
      }
      // console.log(result.count, 'result.count')
    }
    // return () => {
    //   this.spotSocket.off('openOrder');
    // }
    // console.log(result, 'result')
  }

  // onScroll(): void {
  //   console.log('scroll')
  // }

  onScroll = () => {
    this.orderData.currentPage++;
    this.appendOpenOrders();
  }

  setModalData(orderInfo: any) {
    let data = {
      tableId: `${orderInfo.buyorsell}OpenOrders_` + orderInfo.pairId,
      orderId: orderInfo._id,
      firstFloatDigit: orderInfo.firstFloatDigit,
      secondFloatDigit: orderInfo.secondFloatDigit
    }
    let hash = encryptObject(data)

    localStorage.setItem('cancelOrderId', hash)
    // console.log(orderId, 'setModalData')
  }

}
