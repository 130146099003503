import { createAction, props } from '@ngrx/store';

export const userSettingKey = '[UserSetting]';

export const setUserSetting = createAction(
    `${userSettingKey} SetUserSetting`,
    props<{ payload: any }>()
);

export const setSiteSetting = createAction(
    `${userSettingKey} setSiteSetting`,
    props<{ payload: any }>()
);

export const updateTheme = createAction(
    `${userSettingKey} UpdateTheme`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${userSettingKey} Reset`
);