<div class="baseblock_wrapper">
    <div class="baseblock_inner baseblock_inner_trade_buysell">
        <div class="baseblock_content">
            <div class="trade_buysell_head">
                <ul class="nav nav-pills primary_tab mb-0 pb-0" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-limit-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-limit" type="button" role="tab" aria-controls="pills-limit"
                            aria-selected="true">
                            Limit
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-market-tab" data-bs-toggle="pill"
                            data-bs-target="#pills-market" type="button" role="tab" aria-controls="pills-market"
                            aria-selected="false">
                            Market
                        </button>
                    </li>
                </ul>
                <!-- <button *ngIf="auth.signedIn" class="deposit_btn"
                    (click)="navigateByUrl('/deposit-fiat')">Deposit</button> -->
            </div>
            <!-- <div class="buy_sell_tab" *ngIf="isMobileView">
                <button class="buy_tab" (click)="showBuy()" [ngClass]="{ active: isBuyVisible }">
                    Buy
                </button>
                <button class="sell_tab" (click)="showSell()" [ngClass]="{ active: isSellVisible }">
                    Sell
                </button>
            </div> -->
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-limit" role="tabpanel"
                    aria-labelledby="pills-limit-tab" tabindex="0">
                    <div class="d-flex">
                        <app-limitorder class="trade_buysell_body"></app-limitorder>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab"
                    tabindex="0">
                    <div class="d-flex">
                        <app-marketorder class="trade_buysell_body"></app-marketorder>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>