import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import isEmpty from 'src/app/lib/isEmpty';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.css']
})
export class ChangePhoneNumberComponent {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;

  showOldPassword: boolean = false
  showNewPassword: boolean = false
  showConfirmPassword: boolean = false

  seconds: number = 60;
  timerInSeconds: number = 60;
  loaders: any = false
  isOtpInit: boolean = false
  tempPhoneNo: string = ''

  otpLoader: boolean = false

  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
    private userFacade: UserFacade
  ) {
    this.form = this._fb.group({
      newPhoneNo: ['', Validators.required],
      newPhoneCode: [''],
      otp: ['']
    });
  }

  onInputChange() {
    this.serverErrors = {}
  }

  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return

    const { newPhoneCode, otp } = this.form.value

    const reqData = {
      newPhoneNo: this.tempPhoneNo,
      newPhoneCode: newPhoneCode,
      otp: otp
    }
    this.service.verifyNewPhone(reqData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.getUser()
            this.router.navigateByUrl('/security')
            // this.form.reset()
            // this.AuthService.logoutApi()
            // this.router.navigateByUrl("/signin")
          }
        },
        error: (err: any) => {
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }
  get newPhoneNo() { return this.form.get('newPhoneNo') }
  get otp() { return this.form.get('otp') }

  hanldeShowOldPassword() {
    this.showOldPassword = !this.showOldPassword
  }

  hanldeShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  hanldeShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword
  }

  countryChangedEvent(event: any) {
    this.form.patchValue({
      newPhoneCode: '+' + event.dialCode
    })
  }

  requestOtp() {
    this.submitted = true
    if (!isEmpty(this.newPhoneNo?.errors))
      return

    this.otpLoader = true
    const { newPhoneNo, newPhoneCode } = this.form.value
    const reqData = {
      ...this.form.value,
      newPhoneNo: newPhoneNo.replace(newPhoneCode, '')
    }

    this.service.changeNewPhone(reqData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.otpLoader = false
            this.submitted = false
            this.toastr.success(v.message)

            this.loaders = true
            this.timer(this.timerInSeconds)

            if (!this.isOtpInit)
              this.isOtpInit = true

            this.tempPhoneNo = newPhoneNo.replace(newPhoneCode, '')
            this.form.controls['newPhoneNo']?.disable();
            this.serverErrors = {}
          }
        },
        error: (err: any) => {
          this.otpLoader = false
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })

  }

  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      // console.log(this.seconds)
      if (this.seconds == 0) {
        this.loaders = false
        clearInterval(timer);
      }
    }, 1000);
  }

  getUser() {
    this.service.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: () => { },
        complete: () => {
        }
      })
  }


}
