import { createAction, props } from '@ngrx/store';

export const priceCnvKey = '[PriceCnv]';

export const setPriceCnv = createAction(
    `${priceCnvKey} setPriceCnv`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${priceCnvKey} Reset`
);