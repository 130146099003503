// import lib
import isEmpty from './isEmpty';

export const toFixed = (item: any, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item)
      return item.toFixed(type)
    }
    return ''
  } catch (err) {
    return ''
  }
}
export const toFixedDown = (item: any, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      // item = parseFloat(item)
      item = convert(item)
      let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
        m = item.toString().match(decReg);
      return m ? parseFloat(m[1]) : item.valueOf();
    }
    return ''
  } catch (err) {
    return ''
  }
}

export const currencyFormat = (item: any) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = item.toString();
      let splitValue = item.split('.')
      return splitValue[1] ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${splitValue[1]}` : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return ''
  } catch (err) {
    return ''
  }
}

// Number.prototype.toFixedNoRounding = function (n) {
//   const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
//   const a = this.toString().match(reg)[0];
//   const dot = a.indexOf(".");
//   if (dot === -1) {
//     // integer, insert decimal dot and pad up zeros
//     return a + "." + "0".repeat(n);
//   }
//   const b = n - (a.length - dot) + 1;
//   return b > 0 ? a + "0".repeat(b) : a;
// };


// export const longNumbers = (x, n) => {
//   try {
//     if (!isEmpty(x) && !isNaN(x)) {
//       if (x < 0) {
//         x = x.toFixedNoRounding(n);
//         return x
//       }
//       if (x < 0.000001) {
//         return 0.0
//       } else if (x > 100) {
//         if (n < 2) {
//           x = x.toFixedNoRounding(n);
//           return x
//         }
//         x = x.toFixedNoRounding(2);
//         return x;
//       }
//       return x.toFixedNoRounding(n);
//     }
//     return ""
//   } catch (err) {
//     return "";
//   }
// };


export const truncateDecimals = (num: any, decimals: any) => {
  // if (typeof num === 'string') {
  //   num = parseFloat(num);
  // }

  // if (typeof num !== 'number' || isNaN(num)) {
  //   return '';
  // }

  if (decimals == 0) {
    return toFixedDown(num, 0)
  }

  num = convert(num)
  let s = num.toString(), p = s.indexOf('.');
  s += (p < 0 ? (p = 1 + s.length, '.') : '') + '0'.repeat(decimals)
  return s.slice(0, p + 1 + parseInt(decimals))
}


export const convert = (n: any) => {
  try {
    var sign = +n < 0 ? '-' : '',
      toStr = n.toString()
    if (!/e/i.test(toStr)) {
      return n
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, '')
      .replace(/^([0-9]+)(e.*)/, '$1.$2')
      .split(/e|\./)
    return +pow < 0
      ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
      : sign +
      lead +
      (+pow >= decimal.length
        ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
        : decimal.slice(0, +pow) + '.' + decimal.slice(+pow))
  } catch (err) {
    return 0
  }
}


export const longDecimals = (num: any, maxDecimals = 8) => {
  num = convert(num)

  // Split the string into integer and fractional parts
  const [integerPart, fractionalPart] = num.toString().split('.');

  // If there are more than 6 decimal places, show an ellipsis
  if (fractionalPart && fractionalPart.length > maxDecimals) {
    const truncatedFraction = fractionalPart.substring(0, maxDecimals);
    return `${integerPart}.${truncatedFraction.substring(0, 2)}...${fractionalPart.slice(-5)}`;
  }
  return num; // No need for ellipsis
}

export const fraction = (num: any, dec: any, maxDecimals = 8) => {
  if (dec == 0) return toFixedDown(num, 0)
  
  num = convert(num)

  // Split the string into integer and fractional parts
  const [integerPart, fractionalPart] = num.toString().split('.');

  // If there are more than 6 decimal places, show an ellipsis
  if (fractionalPart.length > maxDecimals) {
    return `...${fractionalPart.slice(-maxDecimals)}`;
  }
  return num; // No need for ellipsis
}

export const nFormatter = (num: any, digits: any = 2) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  if (num < 1000) {
    return longDecimals(num)
  }
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}
