<h2 class="dashbox_title">Buy Crypto</h2>
<div class="row">
    <div *ngIf="!isLoading; else data">
        <app-loader></app-loader>
    </div>
    <ng-template #data>

        <!-- buy crypto form -->
        <form [formGroup]="form" (ngSubmit)="submitForm()">

            <div class="sign_group sign_group_input">
                <label>Choose Crypto Currency</label>
                <select class="sign_input" (change)="changeCurrency($event)" formControlName="coinSymbol">
                    <option value="">Select Crypto Currency</option>
                    <option *ngFor="let option of coinOptions" [value]="option.value">
                        {{ option.label }}
                    </option>
                </select>
                <div *ngIf="coinSymbol?.invalid && (coinSymbol?.touched || submitted)">
                    <span class="required" *ngIf="coinSymbol?.errors?.['required']">Fiat Amount field is required</span>
                </div>
            </div>

            <div class="sign_group sign_group_input">
                <label>Choose Fiat Currency</label>
                <select class="sign_input" (change)="changeCurrency($event)" formControlName="fiatSymbol">
                    <option value="">Select Fiat Currency</option>
                    <option *ngFor="let option of fiatOptions" [value]="option.value">
                        {{ option.label }}
                    </option>
                </select>
                <div *ngIf="fiatSymbol?.invalid && (fiatSymbol?.touched || submitted)">
                    <span class="required" *ngIf="fiatSymbol?.errors?.['required']">Fiat Amount field is required</span>
                </div>
            </div>

            <div class="sign_group sign_group_input">
                <label>Fiat Amount</label>
                <div class="primary_inp_grp">
                    <input type="text" class="sign_input" placeholder="Enter Fiat Amount" formControlName="fiatAmount" decimals />
                </div>
                <div *ngIf="fiatAmount?.invalid && (fiatAmount?.touched || submitted)">
                    <span class="required" *ngIf="fiatAmount?.errors?.['required']">Fiat Amount field is required</span>
                    <!-- <span class="required" *ngIf="fiatAmount?.errors?.['min']">Fiat Amount should be Greater than 1</span> -->
                </div>
                <span class="required" *ngIf="serverErrors?.fiatAmount">{{ serverErrors.fiatAmount }}</span>
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="withdraw_btn me-2 cursor-pointer" (click)="resetForm()">Cancel</button>
                <button type="submit" class="withdraw_btn cursor-pointer">Confirm</button>
            </div>

        </form>
    </ng-template>
</div>
<app-onramper-widget [onramperParams]="onramperParams" (closeModal)="toggleModal()" [showModal]="showModal"></app-onramper-widget>