import { Component, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Import socket
import { userSocket } from 'src/app/app.module';

// Import service
import { UserService } from 'src/app/services/user.service';

// Import facade
import { UserSettingFacade } from 'src/app/store/usersetting';

// Import jQuery
import { CookieService } from 'ngx-cookie-service';
import { UserFacade } from 'src/app/store/user';
declare var $: any;

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.css']
})
export class HeaderDashboardComponent {
  theme = ''
  unread_count = 0;

  user: any;
  userId: any;
  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private userSettingFacade: UserSettingFacade,
    private userSocket: userSocket,
    private userService: UserService,
    private cookieService: CookieService,
    private userFacade: UserFacade,
  ) { }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url, 'app dashboard')
      }
    })
    this.getUserSetting()
    this.unreadCount()
    this.userSocket.on('unread_count', this.unreadCountSocket.bind(this))
  }

  ngAfterViewInit() {
    // Use jQuery to handle the click event
    $(this.elementRef.nativeElement).find('.header__btn').click(() => {
      // console.log('Button clicked using jQuery!');
      $(this.elementRef.nativeElement).toggleClass("header__btn--active");
      $(".header").toggleClass("header--active");
      $(".sidebar").toggleClass("sidebar--active");
      $(".home_menu_bar").toggleClass("sidebar--active");
    });
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }

  unreadCount() {
    this.userService.unreadCount()
      .subscribe({
        next: (v: any) => {
          this.unread_count = v.count
        },
        error: (err) => console.log(err)
      })
  }

  unreadCountSocket(result: any) {
    // console.log(result, 'unreadCountSocket')
    this.unread_count = result
  }

  switchNearMalls()
  {
    this.userFacade.user$.subscribe({
      next: (userData) => {
        this.userId = userData._id;
        this.user = userData.userId;
      },
      error: (error) => { }
      })
    const token = this.cookieService.get('user_token');
    const getemail = this.cookieService.get('email') || '';
    const apiBaseUrl = `https://utility.nearmalls.com/`;
   // const apiBaseUrl = `http://credbullapp.nearmalls.com/login?token=${token}&email${getemail}&userId=${this.userId}&user=${this.user}`;
  // const apiBaseUrl = `http://localhost:58204/login?token=${token}&email${getemail}&userId=${this.userId}&user=${this.user}`;
   const decodedUrl = decodeURIComponent(apiBaseUrl);
     window.open(decodedUrl, '_blank');
  }
}
