<app-header></app-header>
<main class="inner_cms_layout about_us_layout">
  <section class="about_us_header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about_us_page_header_content">
            <h1>
              <span>CRED BULL</span> India's Most Valued Crypto Company
            </h1>
            <h5>
              Enjoy the ride to pioneering the Indian Crypto and Web3 Industry
              with Cred Bull
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="text-center">
            <img
              src="assets/images/about_img_01.png"
              class="img-fluid"
              alt="Token"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <h5 class="about_subtitle">CREDBULL EXCHANGE</h5>
          <h2 class="about_maintitle">
            Who we are?
          </h2>
          <p>
            Credbull Exchange - Global Bitcoin & Cryptocurrency Exchange that assists traders to buy, Sell & Trade Bitcoin (BTC), Ethereum (ETH), Tether (USDT), Ripple (XRP), CRED Crypto Token and more cryptocurrencies. Our mission has always been to improve the trading experience for all crypto fans worldwide using a methodology that is more simple and more secure. We are dedicated to creating a highly safe, stable, and effective cryptocurrency exchange for users everywhere, and we do this with the help of our unique trade matching algorithm and first-rate user experience.
          </p>
          <!-- <ul class="about_sec_list">
            <li>Nunc pretium orci non lobortis semper.</li>
            <li>Phasellus in arcu vel quam volutpat.</li>
            <li>Curabitur fringilla tellus et odio mattis.</li>
          </ul> -->
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <h5 class="about_subtitle">Features</h5>
          <h2 class="about_maintitle">
            Why Choose <span>CREDBULL</span> Exchange
          </h2>          
          <div class="feature_list">
            <div class="feature_list_row">
              <img
                src="assets/images/feature_icon_01.png"
                class="img-fluid"
                alt="Token"
              />
              <div>
                <h4>Utility</h4>
                <p>
                  Listed tokens In Our CREDBULL Exchange will be automatically Eligible for our Utility Needs Globally and Nationally Such as Flight Tickets booking, Hotel Booking, sightseeing Booking, Mobile recharge, Electricity Bill Pay, DTH Recharge and Fastag. 
                </p>
              </div>
            </div>
            <div class="feature_list_row">
              <img
                src="assets/images/feature_icon_02.png"
                class="img-fluid"
                alt="Token"
              />
              <div>
                <h4>User-Friendly Interface</h4>
                <p>
                  No more confusing interfaces! Our intuitive platform ensures seamless navigation, making it a breeze for both beginners and seasoned traders. 
                </p>
              </div>
            </div>
            <div class="feature_list_row">
              <img
                src="assets/images/feature_icon_03.png"
                class="img-fluid"
                alt="Token"
              />
              <div>
                <h4>24/7 Customer Support</h4>
                <p>
                  Have questions? Our dedicated customer support team is available around the clock to assist you through Chats and Mail. Your satisfaction is our success. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="text-center">
            <img
              src="assets/images/about_img_02.png"
              class="img-fluid"
              alt="Token"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section laptop_section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img
            src="assets/images/about_img_03.png"
            class="img-fluid"
            alt="Token"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="section pt-0">
    <div class="container">
      <div class="row">
        <div class="col-md-8 m-auto text-center">
          <h2 class="about_maintitle">
            <span>CREDBULL is making crypto accessible</span>
          </h2>
          <p>
            Credbull CEX is not an ordinary crypto exchange, it has very unique features for not only traders both founders of token listed and the traders will enjoy Utility shopping facility and enjoy uninterpted and hassel free crypto trading, where in you enjoy once you explore.. our exchange.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="section top_features_section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="about_maintitle">Sed pulvinar</h2>
        </div>
        <div class="col-md-4">
          <div class="feature_list_row">
            <img
              src="assets/images/feature_icon_01.png"
              class="img-fluid"
              alt="Token"
            />
            <div>
              <h4>Morbi</h4>
              <p>
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="feature_list_row">
            <img
              src="assets/images/feature_icon_02.png"
              class="img-fluid"
              alt="Token"
            />
            <div>
              <h4>Vestibulum</h4>
              <p>
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="feature_list_row">
            <img
              src="assets/images/feature_icon_03.png"
              class="img-fluid"
              alt="Token"
            />
            <div>
              <h4>Quisque</h4>
              <p>
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</main>
<app-footer></app-footer>
