<div class="trade_historyorder_wrapper">
    <div class="basetable_wrap">
        <div class="basetable_wrapper">
            <div class="basetable_thead_row">
                <div class="basetable_thead_th">Time</div>
                <div class="basetable_thead_th text-end">
                    Price ({{tradePair?.secondCurrencySymbol}})
                </div>
                <div class="basetable_thead_th text-end">
                    Amount ({{tradePair?.firstCurrencySymbol}})
                </div>
            </div>

            <div class="basetable_body">
                <div class="basetable_row" *ngFor="let data of tradeData;">
                    <div class="basetable_cell">{{data?.createdAt | date:"hh:mm:ss"}}</div>
                    <div class="basetable_cell trade_history_tablecell_txtend">
                        <span [class]="data.Type == 'sell' ? 'red_txt': 'green_txt'" [title]="toFixed(data?.tradePrice, tradePair?.secondFloatDigit)">
                            {{fraction(toFixed(data?.tradePrice, tradePair?.secondFloatDigit), tradePair?.secondFloatDigit)}}
                        </span>
                    </div>
                    <div class="basetable_cell trade_history_tablecell_txtend">
                        <span class="" style="color: #43adf7;"
                            [title]="toFixed(data?.tradeQty, tradePair?.firstFloatDigit)">
                            {{fraction(toFixed(data?.tradeQty, tradePair?.firstFloatDigit), tradePair?.firstFloatDigit)}}
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>