export const environment = {
  production: true,
  userApiUrl: 'https://creduserapi.wearedev.team/',
  walletApiUrl: 'https://credwalletapi.wearedev.team/',
  spotApiUrl: 'https://credspotapi.wearedev.team/',
  launchpadApiUrl: 'https://credlaunchpadapi.wearedev.team/',
  recaptcha: {
    siteKey: '6LdMuPImAAAAAHQkXQi3UKLe2vBSeA5LzEwIJsWc',
  },
  onramperApiKey: '5vRsHm3YMs0lTrL7JIzZYvjjjqtuyg',
  onrampAppID: 192431
};
