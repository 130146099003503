<form
  action="#"
  class="auth_form row"
  [formGroup]="form"
  (ngSubmit)="submitForm()"
>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>First Name</label>
      <input
        formControlName="firstName"
        alphaOnly
        type="text"
        class="sign_input"
        placeholder="Enter First Name"
        (input)="onInputChange()"
      />
      <div *ngIf="firstName?.invalid && (firstName?.touched || submitted)">
        <span class="required" *ngIf="firstName?.errors?.['required']"
          >First Name field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.firstName">{{
        serverErrors.firstName
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Last Name</label>
      <input
        formControlName="lastName"
        alphaOnly
        type="text"
        class="sign_input"
        placeholder="Enter Last Name"
        (input)="onInputChange()"
      />
      <div *ngIf="lastName?.invalid && (lastName?.touched || submitted)">
        <span class="required" *ngIf="lastName?.errors?.['required']"
          >Last Name field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.lastName">{{
        serverErrors.lastName
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Email Address</label>
      <input
        formControlName="email"
        type="email"
        class="sign_input"
        placeholder="Enter Email"
        readonly="true"
      />
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Mobile Number</label>
      <input
        formControlName="phoneNo"
        type="text"
        class="sign_input"
        placeholder="Enter Mobile Number"
        readonly="true"
      />
    </div>
  </div>

  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Name At Bank(<span class="bankPassbookText">Name should as per Bank passbook.Kindly Refer your Passbook,Please</span>) *</label>
      <input
        formControlName="name_at_bank"
        alphaOnly
        type="text"
        class="sign_input"
        placeholder="Enter Name at Bank"
        (input)="onInputChange()"
        [readonly]="profileDetails && profileDetails.bankVerifyName == 'verified' ? true : false"
      />
      <div
        *ngIf="name_at_bank?.invalid && (name_at_bank?.touched || submitted)"
      >
        <span class="required" *ngIf="name_at_bank?.errors?.['required']"
          >Name at Bank field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.name_at_bank">{{
        serverErrors.name_at_bank
      }}</span>
    </div>
  </div>

  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Country</label>

      <select
        class="sign_input"
        formControlName="country"
        (input)="onInputChange()"
      >
        <option value="">Choose Country</option>
        <option *ngFor="let country of countries" [value]="country.shortName">
          {{ country.name }}
        </option>
      </select>

      <div *ngIf="countryIn?.invalid && (countryIn?.touched || submitted)">
        <span class="required" *ngIf="countryIn?.errors?.['required']"
          >Country field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.countryIn">{{
        serverErrors.country
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>State / Province</label>
      <select
        class="sign_input"
        formControlName="state"
        (input)="onInputChange()"
      >
        <option value="">Choose State / Province</option>
        <option *ngFor="let state of states" [value]="state">
          {{ state }}
        </option>
      </select>
      <div *ngIf="stateIn?.invalid && (stateIn?.touched || submitted)">
        <span class="required" *ngIf="stateIn?.errors?.['required']"
          >State field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.state">{{
        serverErrors.state
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>City</label>
      <select
        class="sign_input"
        formControlName="city"
        (input)="onInputChange()"
      >
        <option value="">Choose City</option>
        <option *ngFor="let city of cities" [value]="city">
          {{ city }}
        </option>
      </select>
      <div *ngIf="cityIn?.invalid && (cityIn?.touched || submitted)">
        <span class="required" *ngIf="cityIn?.errors?.['required']"
          >City field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.city">{{
        serverErrors.city
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>Postal Code</label>
      <input
        type="text"
        numbersOnly
        numbers
        class="sign_input"
        placeholder="Enter Postal Code"
        formControlName="postalCode"
        (input)="onInputChange()"
      />
      <div *ngIf="postalCode?.invalid && (postalCode?.touched || submitted)">
        <span class="required" *ngIf="postalCode?.errors?.['required']"
          >Postal Code field is required</span
        >
      </div>
      <span class="required" *ngIf="serverErrors?.postalCode">{{
        serverErrors.postalCode
      }}</span>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sign_group sign_group_input">
      <label>My Referral Code</label>
      <input formControlName="ownReferralCode" type="text" class="sign_input" placeholder="Enter My Referral Code"
        *ngIf='pan != "" && aadhar != ""'  />
      <input type="text" class="sign_input" placeholder="Enter My Referral Code" *ngIf='pan == "" && aadhar == ""'
        value="******" readonly="true" />
      <span class="required" *ngIf='pan == "" && aadhar == ""'>Please Complete your KYC to view your Referal code
      </span>
    </div>
  
  </div>
  <div class="col-md-6" *ngIf="profileDetails.isProfileUpdated == false">
    <div class="sign_group sign_group_input">
      <label>Parent Referral Code</label>
      <input type="text" class="sign_input" placeholder="Enter Parent Referral Code" formControlName="parentReferralCode"
        (blur)="checkReferral($event)" />
      <label *ngIf="validReferral">Referal User Name:{{ReferralUser}}</label>
    </div>
  </div>
  <span class="required" *ngIf="serverErrors?.parentReferralCode">{{
    serverErrors.parentReferralCode
    }}</span>
  <div class="row">
    <div class="col-md-3">
      <button type="submit" class="sign_btn" [disabled]="isLoading">
        Submit
      </button>
    </div>
  </div>
</form>
