import { Injectable } from '@angular/core';
import { spotSocket, userSocket } from '../app.module';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(
    private userSocket: userSocket,
    private spotSocket: spotSocket,
    private cookieService: CookieService,
    private jwtHelperService: JwtHelperService
  ) { }

  ngOnInit() {
  }

  createSocketUser(userId: string) {
    this.userSocket.emit('CREATEROOM', userId)
    this.spotSocket.emit('CREATEROOM', userId)
  }

  emit(event: string, message: string) {
    this.spotSocket.emit(event, message);
  }

}
