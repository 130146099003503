import { Component, OnInit, inject } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserFacade } from '../../store/user';
import { FormBuilder, FormGroup } from '@angular/forms';
declare var $: any;

interface Profile {
  twoFAStatus: string;
  secret: string,
  imageUrl: string,
  uri: string,
  phoneNo: string,
  phoneStatus: string,
  phoneCode: string
}

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css'],
})
export class SecurityComponent {
  otpform: FormGroup;
  tfaform: FormGroup;
  disableform: FormGroup;
  serverErrors: any = {};
  loader: boolean = false;
  showModal = false
  showotpModal = true
  showVideoModal: boolean = false
  otpsent: boolean = false
  profileDetails: Profile = {
    twoFAStatus: '',
    secret: '',
    imageUrl: '',
    uri: '',
    phoneNo: '',
    phoneStatus: '',
    phoneCode: ''
  };

  private readonly userFacade: UserFacade = inject(UserFacade);
  constructor(private service: UserService, private router: Router, private toastr: ToastrService, private _fb: FormBuilder) {

    this.otpform = this._fb.group({
      emailOTP: [],
      type: 2
    });

    this.tfaform = this._fb.group({
      secret: [''],
      code: [''],
      uri: [''],
      aggree: [false]
    });

    this.disableform = this._fb.group({
      secret: [''],
      code: [''],
      CheckValue: true,
      uri: ['']
    });


  }
  ngOnInit() {
    this.getprofile();
  }
  getprofile() {
    this.userFacade.user$.subscribe({
      next: (data: any) => {
        this.profileDetails.twoFAStatus = data.twoFAStatus;
        this.profileDetails.phoneNo = data.phoneNo;
        this.profileDetails.phoneCode = data.phoneCode;
        this.profileDetails.phoneStatus = data.phoneStatus;
      },
      error: (err: any) => {
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
        }
      },
      complete: () => { },
    });
  }

  hanldleChange() {
    this.serverErrors = {}
  }

  sendOTP() {
    this.loader = true;

    let reqData = {
      requestType: '2FA',
      roleType: 1,
    };

    this.service.sendOTP(reqData).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.loader = false;
          this.otpsent = true;
          this.toastr.success(v.message);
        }
      },
      error: (err: any) => {
        this.loader = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
          this.toastr.error(err.error.message);

        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  showmodal() {
    this.showModal = !this.showModal;

  }

  verifyOTP() {
    this.loader = true;
    this.service.verifyOTP(this.otpform.value).subscribe({
      next: (v: any) => {
        if (v.status) {
          this.loader = false;
          this.toastr.success(v.message);
          this.get2faCode()

        }
      },
      error: (err: any) => {
        this.showModal = false;

        this.loader = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  get2faCode() {
    this.loader = true;
    this.service.get2facode().subscribe({
      next: (v: any) => {
        if (v.success) {
          let data = v.result
          this.profileDetails.secret = data.secret;
          this.profileDetails.imageUrl = data.imageUrl;
          this.profileDetails.uri = data.uri;
          this.showModal = true;
          this.showotpModal = false

        }
      },
      error: (err: any) => {
        this.showModal = false;

        this.loader = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  videoModal() {
    this.showVideoModal = !this.showVideoModal
  }
  disable2fasubmit() {
    this.service.get2facode().subscribe({
      next: (v: any) => {
        if (v.success) {
          let data = v.result

          let reqData = {
            ...this.disableform.value,
            secret: data.secret, uri: data.uri
          }
          this.disable2fa(reqData)

        }
      },
      error: (err: any) => {
        this.showModal = false;

        this.loader = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  tfaSubmit() {
    this.loader = true;
    let reqData = {
      ...this.tfaform.value,
      secret: this.profileDetails.secret,
      uri: this.profileDetails.uri
    }
    this.service.update2facode(reqData).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.loader = false;
          this.toastr.success(v.message);
          this.showModal = false;
          //  this.router.navigateByUrl("/security")
          this.getUser();
          location.reload();


        } else {
          this.toastr.error(v.message);

        }
      },
      error: (err: any) => {
        this.loader = false;
        if ([400, 404].includes(err.status)) {

          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  getUser() {
    this.service.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: err => { }
      })
  }



  disable2fa(reqData: any) {

    this.service.disable2facode(reqData).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.loader = false;
          this.toastr.success(v.message);
          //  this.router.navigateByUrl("/security")
          this.getUser();
          location.reload();


        } else {
          this.toastr.error(v.message);

        }
      },
      error: (err: any) => {
        this.loader = false;
        if ([400, 404].includes(err.status)) {

          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }
  CopyText(value: any) {
    navigator.clipboard.writeText(value);
    this.toastr.success("Copied...")

  }
  onInputChange() {
    this.serverErrors = {}
  }

}


