<div class="order_table_wrapper">
    <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-open-orders-tab" data-bs-toggle="pill"
                data-bs-target="#pills-open-orders" type="button" role="tab" aria-controls="pills-open-orders"
                aria-selected="true">
                Open Orders ({{openOrderCount}})
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-order-history-table-tab" data-bs-toggle="pill"
                data-bs-target="#pills-order-history-table" type="button" role="tab"
                aria-controls="pills-order-history-table" aria-selected="false">
                Order History
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-order-trade-history-tab" data-bs-toggle="pill"
                data-bs-target="#pills-order-trade-history" type="button" role="tab"
                aria-controls="pills-order-trade-history" aria-selected="false">
                Trade History
            </button>
        </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-open-orders" role="tabpanel"
            aria-labelledby="pills-open-orders-tab" tabindex="0">
            <app-openorders></app-openorders>
        </div>
        <div class="tab-pane fade" id="pills-order-history-table" role="tabpanel"
            aria-labelledby="pills-order-history-table-tab" tabindex="0">
            <app-orderhistory></app-orderhistory>
        </div>
        <div class="tab-pane fade" id="pills-order-trade-history" role="tabpanel"
            aria-labelledby="pills-order-trade-history-tab" tabindex="0">
            <app-tradehistory></app-tradehistory>
        </div>
    </div>
</div>