<div class="baseblock_inner baseblock_inner_nopadding">
  <div class="orderbook_title">
    <h2>Order Book</h2>
    <div class="orderbook_controller">
      <div class="orderbook_control_icon orderbook_control_icon_sell" (click)="showDiv1()"></div>
      <div class="orderbook_control_icon orderbook_control_icon_buy" (click)="showDiv2()"></div>
      <div class="orderbook_control_icon orderbook_control_icon_neutral" (click)="showDivs()"></div>
    </div>
  </div>
  <div class="baseblock_content">
    <div class="orderbook_head">
      <div class="orderbook_tablehead_row">
        <div class="orderbook_tablehead_cell">
          <small>Price</small>
        </div>
        <div class="orderbook_tablehead_cell orderbook_tablehead_cell_right">
          <small>Amount</small>
        </div>
        <div class="orderbook_tablehead_cell orderbook_tablehead_cell_right">
          <small>Total</small>
        </div>
      </div>
    </div>
    <div class="orderbook_body">
      <div class="orderbook_body_wrap orderbook_body_wrap_sell" *ngIf="isDiv1Visible">
        <div class="basetable_wrap">
          <div class="basetable_wrapper basetable_sell basetable_orderbook">
            <div class="basetable_body basetable_body_hover">
              <div *ngFor="let order of sellOrder">
                <div class="basetable_row" (click)="handleClickOrder(order._id, order.quantity, 'sell')">
                  <span class="order_bg" [ngStyle]="{ width: calculateBarWidth(order._id * order.quantity) }"></span>
                  <div class="basetable_cell">
                    <span class="red_txt">
                      {{ longDecimals(truncateDecimals(order._id, tradePair.secondFloatDigit)) }}
                    </span>
                  </div>
                  <div class="basetable_cell orderbook_tablecell_txtend">
                    {{ longDecimals(truncateDecimals(order.quantity, tradePair.firstFloatDigit)) }}
                  </div>
                  <div class="basetable_cell orderbook_tablecell_txtend">
                    {{ longDecimals(truncateDecimals(order._id * order.quantity, tradePair.secondFloatDigit)) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="orderbook_body_pricewrap">
        <div [class]="'orderbook_body_price orderbook_body_price_' +(marketData.change > 0 ? 'up' : 'down')">
          <span *ngIf="tradePair.secondCurrencySymbol == 'INR'">&#x20b9;</span>
          <span [title]="truncateDecimals(marketData.markPrice, tradePair.secondFloatDigit)">
            {{ longDecimals(truncateDecimals(marketData.markPrice, tradePair.secondFloatDigit)) }}
          </span>
          <i [class]="'bi bi-arrow-' +(marketData.change > 0 ? 'up' : 'down') + '-short'"></i>
        </div>
      </div>
      <div class="orderbook_head">
        <div class="orderbook_tablehead_row">
          <div class="orderbook_tablehead_cell">
            <small>Price</small>
          </div>
          <div class="orderbook_tablehead_cell orderbook_tablehead_cell_right">
            <small>Amount</small>
          </div>
          <div class="orderbook_tablehead_cell orderbook_tablehead_cell_right">
            <small>Total</small>
          </div>
        </div>
      </div>
      <div class="orderbook_body_wrap orderbook_body_wrap_buy" *ngIf="isDiv2Visible">
        <div class="basetable_wrap">
          <div class="basetable_wrapper basetable_orderbook">
            <div class="basetable_body basetable_body_hover">
              <div *ngFor="let order of buyOrder">
                <div class="basetable_row" (click)="handleClickOrder(order._id, order.quantity, 'buy')">
                  <span class="order_bg" [ngStyle]="{ width: calculateBarWidth(order._id * order.quantity) }"></span>
                  <div class="basetable_cell">
                    <span class="green_txt">
                      {{ longDecimals(truncateDecimals(order._id, tradePair.secondFloatDigit)) }}
                    </span>
                  </div>
                  <div class="basetable_cell orderbook_tablecell_txtend">
                    {{ longDecimals(truncateDecimals(order.quantity, tradePair.firstFloatDigit)) }}
                  </div>
                  <div class="basetable_cell orderbook_tablecell_txtend">
                    {{ longDecimals(truncateDecimals(order._id * order.quantity, tradePair.secondFloatDigit)) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>