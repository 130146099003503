<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Support Ticket</h2>
          <app-supportticket-form
            [supportprops]="supportprops"
          ></app-supportticket-form>

          <app-supporttiket-details [supportprops]="supportprops">
          </app-supporttiket-details>
        </div>
      </div>
    </div>
  </div>
</main>
