<header class="header header--fixed">
  <div class="container">
    <div class="header__content">
      <nav class="navbar bg-body-dark navbar-expand-xl">
        <div class="d-flex align-items-center">
          <a class="navbar-brand header__logo" href="/">
            <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="Logo" class="img-fluid" />
          </a>
          <ul class="navbar-nav mobile_none home_navbar_middle">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/market-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Market</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/fee-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Fee</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/spot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Trade</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/launchpad"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Launchpad</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [href]="siteSetting.externalSiteUrl"
                target="_blank"
                >{{ siteSetting.externalSiteName }}</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/dashboard"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Dashboard</a
              >
            </li>
          </ul>
        </div>
        <button class="header__btn navbar-toggler" type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <app-theme class="dashboard_theme"></app-theme>
        <div class="home_menu_bar" tabindex="-1">
          <ul
            class="navbar-nav justify-content-end flex-grow-1 desktop_none"
          >
            <li class="nav-item user_info_dropdown" *ngIf="loggedIn">
              <a
                class="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="sidebar__user-img">
                  <span>
                    {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                    }}{{
                      profileDetails.lastName | slice : 0 : 1 | uppercase
                    }}</span
                  >
                </div>

                <div class="sidebar__user-title">
                  <p>
                    {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                  </p>
                </div>
              </a>              
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/market-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Market</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/fee-home"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Fee</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/spot"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Trade</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/launchpad"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Launchpad</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [href]="siteSetting.externalSiteUrl"
                target="_blank"
                >{{ siteSetting.externalSiteName }}</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/dashboard"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Dashboard</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/edit-profile"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Edit Profile</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/wallet"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Wallet</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                routerLink="/security"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Security</a
              >
            </li>
            <li class="nav-item" *ngIf="loggedIn">
              <a
                class="nav-link"
                href="javascript:void(0)"
                routerLinkActive="active"
                (click)="signOut()"
                [routerLinkActiveOptions]="{ exact: true }"
                >Logout</a
              >
            </li>           
          </ul>
          <ul
            class="navbar-nav justify-content-end flex-grow-1 align-item-center"
          >
            <li class="nav-item" *ngIf="!loggedIn">
              <a
                class="nav-link"
                routerLink="/signin"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Sign In</a
              >
            </li>
            <li class="nav-item" *ngIf="!loggedIn">
              <a
                class="nav-link primary_btn"
                routerLink="/signup"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Sign Up</a
              >
            </li>
            <li class="nav-item dropdown user_info_dropdown mobile_none" *ngIf="loggedIn">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="sidebar__user-img">
                  <span>
                    {{ profileDetails.firstName | slice : 0 : 1 | uppercase
                    }}{{
                      profileDetails.lastName | slice : 0 : 1 | uppercase
                    }}</span
                  >
                </div>

                <div class="sidebar__user-title">
                  <p>
                    {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                  </p>
                </div>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" routerLink="/edit-profile"
                    ><i class="bi bi-pencil-square me-1"></i> Edit Profile</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/wallet"
                    ><i class="bi bi-wallet2 me-1"></i>Wallet</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="/security"
                    ><i class="bi bi-shield-check me-1"></i>Security</a
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="signOut()"
                    ><i class="bi bi-door-closed me-1"></i>Logout</a
                  >
                </li>
              </ul>
            </li>
            <li class="mobile_none">
              <app-theme></app-theme>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <!-- <div class="header__content">
    
    <a href="/" class="header__logo">
      <img src="assets/images/logo.svg" alt="" />
    </a>
    

    
    <button class="header__btn" type="button">
      <span></span>
      <span></span>
      <span></span>
    </button>

    
  </div> -->
</header>
<!-- end header -->
