import { Component } from '@angular/core';

@Component({
  selector: 'app-fee-inner',
  templateUrl: './fee-inner.component.html',
  styleUrls: ['./fee-inner.component.css']
})
export class FeeInnerComponent {

}
