import { Component } from '@angular/core';
import { spotSocket } from 'src/app/app.module';
import { Title } from '@angular/platform-browser';

// Import lib
import isEmpty from 'src/app/lib/isEmpty';
import { convert, fraction, longDecimals, nFormatter, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { ShareService } from 'src/app/services/share.service';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { TitleService } from 'src/app/services/title.service';

// Import facade
import { MarketPriceFacade } from 'src/app/store/marketprice';
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-orderbook',
  templateUrl: './orderbook.component.html',
  styleUrls: ['./orderbook.component.css']
})
export class OrderbookComponent {

  convert = convert
  longDecimals = longDecimals
  fraction = fraction
  nFormatter = nFormatter

  isDiv1Visible = true;
  isDiv2Visible = true;
  isAuth: boolean = false

  dynamicWidth = '50%'

  truncateDecimals = truncateDecimals
  toFixedDown = toFixedDown

  tradePair: any = {}
  marketData: any = {}

  buyOrder: any[] = []
  sellOrder: any[] = []

  maxSize: number = 0

  showDiv1() {
    this.isDiv1Visible = true;
    this.isDiv2Visible = false;
  }

  showDiv2() {
    this.isDiv2Visible = true;
    this.isDiv1Visible = false;
  }

  showDivs() {
    this.isDiv1Visible = true;
    this.isDiv2Visible = true;
  }

  constructor(
    private spotService: SpotService,
    private tradePairFacade: TradePairFacade,
    private marketPriceFacade: MarketPriceFacade,
    private spotSocket: spotSocket,
    private share: ShareService,
    private titleService: TitleService
  ) {
  }

  ngOnInit() {
    this.marketPriceFacade.marketPrice$.subscribe({
      next: (price: any) => {
        this.marketData = price

        // if (price.markPrice && this.tradePair.firstCurrencySymbol && this.tradePair.secondCurrencySymbol) {
        //   const title = `${convert(price.markPrice)} |  ${this.tradePair.firstCurrencySymbol + this.tradePair.secondCurrencySymbol}  |  Credbull Spot`
        //   this.titleService.setTitle(title);
        // }
      },
      error: (err: any) => { }
    })

    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
        if (!isEmpty(pair) && pair._id) {
          this.fetchOrderBook(pair._id)
          this.spotSocket.on('orderBook', this.orderBookWs.bind(this))
        }
      },
      error: (err: any) => { }
    })
  }

  // ngOnDestroy() {
  //   this.titleService.resetTitle();
  // }

  // ngOnDestroy() {
  //   this.spotSocket.off('orderBook');
  // }

  getFillWidthStyle() {
    const fillWidth: number = 40;
    return { '--fillWidth': `40%` };
  }
  // fillWidth () {
  //   // console.log(Ordertotal, 'Ordertotal')
  //   // console.log(currentTotal, 'currentTotal')
  //   // const fillWidth = (Ordertotal / currentTotal) * 100;
  //   // console.log(`${fillWidth.toFixed(0)}%`, 'fillWidth')
  //   // return `${fillWidth.toFixed(0)}%`;
  //   return '40%'
  // }

  fetchOrderBook(pairId: string) {
    this.sellOrder = []
    this.buyOrder = []

    this.spotService.getOrderBook(pairId).subscribe({
      next: (v: any) => {
        if (v.success) {
          const maxAsking = Math.max(...v.result.buyOrder.map((o: any) => Number(o._id) * Number(o.quantity)));
          const maxBidding = Math.max(...v.result.sellOrder.map((o: any) => Number(o._id) * Number(o.quantity)));
          const max = Math.max(maxAsking, maxBidding);
          this.maxSize = Number(max);

          this.sellOrder = v.result && v.result.sellOrder
          this.buyOrder = v.result && v.result.buyOrder
        } else {
          this.sellOrder = []
          this.buyOrder = []
        }
      },
      error: (err: any) => { }
    })
  }

  orderBookWs(result: any) {
    if (result.pairId == this.tradePair._id) {

      this.sellOrder = result && result.sellOrder
      this.buyOrder = result.buyOrder

      const maxAsking = Math.max(...result.buyOrder.map((o: any) => Number(o._id) * Number(o.quantity)));
      const maxBidding = Math.max(...result.sellOrder.map((o: any) => Number(o._id) * Number(o.quantity)));
      const max = Math.max(maxAsking, maxBidding);
      this.maxSize = Number(max);
    }
    // return () => {
    //   this.spotSocket.off('orderBook');
    // }
  }

  calculateBarWidth = (size: number) => {
    const percentage = (Number(size) / Number(this.maxSize)) * 100
    return `${percentage.toFixed(2)}%`;
  };

  handleClickOrder(price: string, quantity: string, type: string) {
    const payload = {
      price,
      quantity,
      type
    }
    this.share.headerClickSrc.next(payload);
  }

  // set() {
  //   this.share.headerClickSrc.next('fetched value1');
  // }

  // getpercentage(item: any, type: string) {
  //   let buyordeindex = 0

  //   if (type == 'buy') {
  //     const le = this.buyOrder.length;
  //     buyordeindex = this.buyOrder[le - 1].total;
  //   } else if (type == 'sell') {
  //     const le = this.sellOrder.length;
  //     buyordeindex = this.sellOrder[le - 1].total;
  //   }
  //   var precentage = (item.total / buyordeindex) * 100;
  //   return `${precentage}%`;
  // }

}
