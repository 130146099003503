import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, TradePairState } from './tradepair.state';
import { setTradePair, reset } from './tradepair.actions';

export const tradePairFeatureKey = 'tradepair';

export const tradePairReducers: ActionReducer<TradePairState> = createReducer(
    initialState,
    on(setTradePair, (_, action) => action.payload),
    on(reset, () => initialState)
);