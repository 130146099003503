import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, CurrencyState } from './currency.state';
import { setCurrency, reset } from './currency.actions';

export const currencyFeatureKey = 'currency';

export const currencyReducers: ActionReducer<CurrencyState> = createReducer(
    initialState,
    on(setCurrency, (state, action) => action.payload),
    on(reset, () => initialState)
);