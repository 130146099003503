import { Component } from '@angular/core';
import { UserSettingFacade } from 'src/app/store/usersetting';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  theme = ''
  showVideoModal: boolean = false;

  constructor (private userSettingFacade: UserSettingFacade) {

  }

  ngOnInit() {
    this.getUserSetting()
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }
  know_SignUp(){
    this.showVideoModal = !this.showVideoModal

  }
}
