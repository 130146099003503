import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-kyc-verification',
  templateUrl: './kyc-verification.component.html',
  styleUrls: ['./kyc-verification.component.css']
})
export class KycVerificationComponent {

  user: any = {}

  constructor(private userFacade: UserFacade, private router: Router) { }

  ngOnInit() {
    this.userFacade.user$.subscribe({
      next: (user) => {
        this.user = user
      },
      error: () => { }
    })
  }

  redirect(url: string) {
    this.router.navigateByUrl(url)
  }

}
