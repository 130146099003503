import { Component, inject } from '@angular/core';

import { CurrencyFacade } from '../../store/currency';
import { PairFacade } from '../../store/pair';
import { SpotService } from 'src/app/services/spot.service';
import { toFixedDown } from 'src/app/lib/roundOf';

// import lib
import isEmpty from 'src/app/lib/isEmpty';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css']
})
export class FeeComponent {
  private readonly currencyFacade: CurrencyFacade = inject(CurrencyFacade);
  private readonly pairFacade: PairFacade = inject(PairFacade);
  toFixedDown = toFixedDown
  isEmpty = isEmpty

  currencyList: any = []
  pairList: any = []
  filteredPairList: any = []
  filteredCurrencyList: any = []
  filteredStatusList: any = []

  filter: any = {
    tradeFeeFilter: '',
    assetFilter: '',
    statusFilter: ''
  }

  constructor(private service: SpotService) { }

  ngOnInit() {
    this.getCurrency()
    this.getPairList()
  }


  getCurrency() {
    this.currencyFacade.currency$
      .subscribe({
        next: (data: any) => {
          this.currencyList = data?.length > 0 && data.filter((c: any) => c.status == 'active')
          this.filteredCurrencyList = data?.length > 0 && data.filter((c: any) => c.status == 'active')

        },
        error: (err: any) => {
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
          } else if (err.status === 500) {
          }
        },
        complete: () => { }
      })
  }

  getPairList() {
    this.service.getPairList().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.pairFacade.setPair(v.result);
          this.filteredPairList = v.result;
          this.pairList = v.result;
        }
      },
      error: (err: any) => { },
    });
  }

  onChangeFee(event: any) {
    this.filter[event.target.name] = event.target.value
    this.searchFee(event.target.value)
  }

  searchFee(text: string) {
    const filteredArray = this.pairList.filter((el: any) => el.tikerRoot.toUpperCase().includes(text.toUpperCase()))
    this.filteredPairList = filteredArray
  }

  onChangeAsset(event: any) {
    this.filter[event.target.name] = event.target.value
    this.searchAsset(event.target.value)
  }

  searchAsset(text: string) {
    const filteredArray = this.currencyList.filter((el: any) => el.coin.toUpperCase().includes(text.toUpperCase()))
    this.filteredCurrencyList = filteredArray
  }

  onChangeStatus(event: any) {
    this.filter[event.target.name] = event.target.value
    this.searchStatus(event.target.value)
  }

  searchStatus(text: string) {
    const filteredArray = this.currencyList.filter((el: any) => el.coin.toUpperCase().includes(text.toUpperCase()))
    this.filteredStatusList = filteredArray
  }

  clear() {
    this.filter.tradeFeeFilter = '';
    this.filter.assetFilter = ''
    this.filter.statusFilter = ''
    this.filteredPairList = this.pairList;
    this.filteredCurrencyList = this.currencyList;
    this.filteredStatusList = this.currencyList;
  }

  changeTab () {
    this.clear()
  }

}
