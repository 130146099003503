import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserSettingFacade } from 'src/app/store/usersetting';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {

  theme = ''

  form: FormGroup
  submitted: boolean = false
  serverErrors: any = {}
  authToken: any;
  showNewPassword: boolean = false
  showConfirmPassword: boolean = false
  
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private userSettingFacade: UserSettingFacade
  ) {
    this.form = this._fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.authToken = params.get('authToken');
      // this.tradePairFacade.tradePair$.subscribe((pair) => {
      //   console.log(pair, 'pair')
      // })
    });
    this.getUserSetting()
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }
  
  get password() { return this.form.get('password') }
  get confirmPassword() { return this.form.get('confirmPassword') }


  submitForm() {
    this.submitted = true
    if (!this.form.valid) {
      return;
    }


    const reqData = {
      ...this.form.value,
      authToken: this.authToken
    }
    // console.log(reqData, '-----------------reqData')
    this.authService.apiResetPassword(reqData)
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.router.navigateByUrl('signin')
          }
        },
        error: (err) => {
          this.submitted = false
          const { error } = err;
          if (error.message) {
            this.toastr.error(error.message);
          } else if (error.errors) {
            const validationErrors = error.errors || {};
            this.serverErrors = validationErrors;
          }
        }
      })
  }

  handleInputChange() {
    this.serverErrors = {}
  }


  hanldeShowNewPassword() {
    this.showNewPassword = !this.showNewPassword
  }

  hanldeShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword
  }
}
