import { Component } from '@angular/core';
import { Cookies } from 'src/app/lib/cookies';
import isEmpty from 'src/app/lib/isEmpty';
import { TradePairFacade } from 'src/app/store/tradepair';
import { UserSettingFacade } from 'src/app/store/usersetting';

import {
  widget,
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  DialogParams, IChartWidgetApi,
  LanguageCode,
} from 'src/assets/charting_library/charting_library.min';
import { environment } from 'src/environments/environment';
const chartUrl = environment.spotApiUrl;

@Component({
  selector: 'app-tradechart',
  templateUrl: './tradechart.component.html',
  styleUrls: ['./tradechart.component.css']
})
export class TradechartComponent {

  theme: 'Dark' | 'Light' = 'Dark'
  pairData: any = {}

  public isMobileLayout = false;
  public isDestopLayout = false;

  backUrl = chartUrl + 'api/spot/chart';
  pair = "BNBUSDT";
  first_time = true
  private _tvWidget: IChartingLibraryWidget | null = null;

  constructor(
    private tradePairFacade: TradePairFacade,
    private userSettingFacade: UserSettingFacade,
    private cookies: Cookies
  ) { }

  ngOnInit() {
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1199;
    window.onload = () => this.isMobileLayout = window.innerWidth <= 1199;
    this.getThemeData()
    // this.getTradeData()
    // this.theme = localStorage.getItem('theme') === 'dark' ? 'Dark' : 'Light'
    // console.log(this.theme, 'this.theme')
    // this.getTradeData()
  }

  getThemeData() {
    // if (this.cookies.isLogin()) {
    //   this.userSettingFacade.usersetting$.subscribe({
    //     next: (data: any) => {
    //       if (data.defaultTheme) {
    //         this.theme = data.defaultTheme === 'dark' ? 'Dark' : 'Light'
    //         this.getTradeData()
    //       }
    //     },
    //     error: (err: any) => { }
    //   })
    // } else {
    //   this.theme = localStorage.getItem('theme') === 'dark' ? 'Dark' : 'Light'
    //   console.log(this.theme, 'this.theme')
    //   this.getTradeData()
    // }
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        if (data.defaultTheme) {
          this.theme = data.defaultTheme === 'dark' ? 'Dark' : 'Light'
          this.getTradeData()
        }
      },
      error: (err: any) => { }
    })
  }

  getTradeData() {
    this.tradePairFacade.tradePair$.subscribe({
      next: (tradePair: any) => {
        if (!isEmpty(tradePair)) {
          let symbol = tradePair.firstCurrencySymbol + tradePair.secondCurrencySymbol;
          // this.pair = symbol
          // console.log(this.theme, 'this.theme')
          this.buildChart(symbol)
        }
      },
      error: (err: any) => { }
    })
  }

  buildChart(pair: string) {
    const widgetOptions: any = {
      symbol: pair,
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.backUrl),
      interval: '5',
      container_id: 'tv_chart_container',
      library_path: '/assets/charting_library/',
      locale: 'en',
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      autosize: false,
      studies_overrides: {},
      loading_screen: { backgroundColor: "#00094a" },
      theme: this.theme,
      toolbar_bg: "#00094a",
      overrides: {
        // "symbolWatermarkProperties.color": "##05051f",
        "paneProperties.background": "#00094a",
        "paneProperties.vertGridProperties.color": "transparent",
        "paneProperties.horzGridProperties.color": "transparent",
      },
    };

    if (this.theme == 'Light') {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);
    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => { },
          })
        );
      });
    });

  }

}
