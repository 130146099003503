import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service'

// Import service
import { ToastrService } from 'ngx-toastr';

// Import facade
import { UserSettingFacade } from 'src/app/store/usersetting';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  theme = ''
  submitted: boolean = false
  isLoading: boolean = false
  serverErrors: any;
  email: string = ""
  siteSetting: any = {};
  date = new Date()

  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private userSettingFacade: UserSettingFacade
  ) {
  }

  ngOnInit() {
    this.getUserSetting()
  }

  getUserSetting() {
    this.userSettingFacade.usersetting$
      .subscribe({
        next: (v: any) => {
          this.theme = v.defaultTheme
          if (v.siteSetting) {
            this.siteSetting = v.siteSetting
          }
        },
        error: () => { }
      })
  }

  submitForm() {
    let reqData = { email: this.email }
    this.service.addsubscribe(reqData)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false

          if (v.status) {
            this.submitted = false
            this.toastr.success(v.message)
            this.email = ""
            //this.router.navigateByUrl("/edit-profile")
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false

          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            // console.log('kkkkk', err)
            this.toastr.error(err.error.errors.email)
          }
        },
        complete: () => { }
      })
  }

  onInputChange(event: any) {
    this.email = event.target.value
    this.serverErrors = {}
  }



}
