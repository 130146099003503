<div class="table-responsive trade_history_table">
    <table class="table primary_table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Pair</th>
                <th>Type</th>
                <th>Side</th>
                <th>Executed Price</th>
                <th>Price</th>
                <th>Executed</th>
                <th>Amount</th>
                <th>Total</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody *ngIf="orderData.data.length > 0; else nodata">
            <tr *ngFor="let order of orderData.data">
                <td>{{order.orderDate | date:"YYYY-MM-dd HH:mm"}}</td>
                <td>{{order.firstCurrency}}/{{order.secondCurrency}}</td>
                <td>{{order.orderType | titlecase}}</td>
                <td>
                    <span
                        [ngClass]="{ 'red_txt': order.buyorsell == 'sell', 'green_txt': order.buyorsell == 'buy' }">{{order.buyorsell
                        | titlecase}}
                    </span>
                </td>
                <td>
                    {{
                    order.averagePrice > 0 ?
                    truncateDecimals(order.averagePrice / order.filledQuantity,
                    tradePair.secondFloatDigit)
                    : "-"
                    }}
                </td>
                <td>{{order.orderType == 'market' ? 'Market' : order.price > 0 ?
                    truncateDecimals(order.price,
                    tradePair.secondFloatDigit) : "-"}}</td>
                <td>
                    {{truncateDecimals(order.filledQuantity, tradePair.firstFloatDigit)}}
                </td>
                <td>
                    {{order.orderType == 'market' && order.buyorsell == 'buy' ?
                    truncateDecimals(order.filledQuantity,
                    tradePair.firstFloatDigit) : truncateDecimals(order.openQuantity,
                    tradePair.firstFloatDigit)}}
                </td>
                <td>
                    {{
                    order.averagePrice == 0 && order.filledQuantity > 0 ? truncateDecimals(order.price *
                    order.openQuantity, tradePair.secondFloatDigit)
                    : order.filledQuantity > 0 ? truncateDecimals(order.averagePrice ? order.averagePrice :
                    (order.price * order.filledQuantity), tradePair.secondFloatDigit) : '-'

                    }}
                </td>
                <td>{{order.status == "cancel" ? 'Cancelled' : order.status | titlecase}}</td>
            </tr>
        </tbody>
        <ng-template #nodata>
            <tbody>
                <tr>
                    <td colspan="9" style="border-color: transparent;">
                        <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                            <img src="assets/images/no_records_found.png" alt="Notification" class="img-fluid mb-2" />
                            <p>No Records Found!</p>
                        </div>
                        <ng-template #login>
                            <div class="text-center mt-4 mb-4">
                                <img src="assets/images/no_records_found.png" alt="Notification"
                                    class="img-fluid mb-2" />
                                <p>
                                    <a class="blue_txt no-underline" href="signin">Login</a>
                                    or
                                    <a class="blue_txt" href="signup" class="blue_txt no-underline">Register</a>
                                    Now to trade
                                </p>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>