import { Component, inject } from '@angular/core';
import { toFixed } from 'src/app/lib/roundOf';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { UserFacade } from '../../../store/user';
import { PairFacade } from '../../../store/pair';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getDisplayedPages } from 'src/app/lib/pagination';


@Component({
  selector: 'app-trade-history',
  templateUrl: './trade-history.component.html',
  styleUrls: ['./trade-history.component.css']
})
export class TradeHistoryComponent {
  private readonly userFacade: UserFacade = inject(UserFacade);
  private readonly pairFacade: PairFacade = inject(PairFacade);

  form: FormGroup;
  toFixed = toFixed
  userId = ""
  tradeHistory: any = []
  pairList: any = []
  getDisplayedPages: number[] = []

  filter = {
    page: 1,
    limit: 10,
    totalPages: 0,
    pairName: "all",
    orderType: "all",
    buyorsell: "all",
    status: "all"
  }

  // filter: any = this.initialfilter
  // paginator: number[] = []

  constructor(private service: SpotService, private _fb: FormBuilder,
  ) {
    this.form = this._fb.group({
      pairName: "all",
      buyorsell: "all",
    });
  }



  ngOnInit() {
    this.fetchtradeHistory(this.filter);
    this.getUser()
    this.getPairList()
  }

  getUser() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.userId = data?._id

        },
        error: (err: any) => {
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
          } else if (err.status === 500) {
          }
        },
        complete: () => { }
      })
  }


  getPairList() {
    this.service.getPairList().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.pairFacade.setPair(v.result);
          this.pairList = v.result;
        }
      },
      error: (err: any) => { },
    });
  }


  fetchtradeHistory(filter: any) {
    this.service.gethistorytradeHistory(filter).subscribe({
      next: (data: any) => {
        this.tradeHistory = data.result
        // this.filter.page = data.result.count
        this.filter.totalPages = Math.ceil(data.result.count / this.filter.limit)
        // this.paginator = Array(this.filter.totalPages).fill(1).map((x, y) => x + y)
        this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5)

      },
      error: () => { }
    })
  }

  nextPage() {
    this.filter.page += 1

    this.fetchtradeHistory(this.filter);
  }

  goToPage(pageNo: number) {
    this.filter.page = pageNo
    this.fetchtradeHistory(this.filter);
  }

  previousPage() {
    if (this.filter.page > 1)
      this.filter.page -= 1

    this.fetchtradeHistory(this.filter);
  }

  onOptionsideChange(event: any) {
    const filterValue = event.target.value
    this.filter.buyorsell = filterValue

  }


  onOptionpairChange(event: any) {
    const filterValue = event.target.value
    this.filter.pairName = filterValue

  }

  searchfilter() {
    this.filter.page = 1;
    this.fetchtradeHistory(this.filter);

  }

  clearfilter() {
    const initialfilter = {
      page: 1,
      limit: 10,
      totalPages: 0,
      pairName: "all",
      orderType: "all",
      buyorsell: "all",
      status: "all"
    }
    // this.filter.page = 1
    this.form.reset()
    this.form.patchValue({
      pairName: "all",
      buyorsell: "all"
    })
    this.fetchtradeHistory(initialfilter);
    this.filter = initialfilter
  }
}



