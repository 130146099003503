import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setPriceCnv, reset } from './pricecnv.actions';
import { selectPriceCnv } from './pricecnv.selectors';
import { Observable } from 'rxjs';
import { PriceCnvState } from './pricecnv.state';

@Injectable({ providedIn: 'root' })
export class PriceCnvFacade {
    private readonly store: Store = inject(Store);

    readonly pricecnv$: Observable<PriceCnvState> = this.store.select(selectPriceCnv);

    setPriceCnv(payload: any): void {
        this.store.dispatch(setPriceCnv({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}