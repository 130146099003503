import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setSpotMarketPrice, reset } from './marketprice.actions';
import { selectMarketPrice } from './marketprice.selectors';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { MarketPriceState } from './marketprice.state';
import { PriceCnvFacade } from '../pricecnv';

@Injectable({ providedIn: 'root' })
export class MarketPriceFacade {
    private readonly store: Store = inject(Store);

    readonly marketPrice$: Observable<MarketPriceState> = this.store.select(selectMarketPrice);

    async setSpotMarketPrice({
        _id,
        secondCurrencySymbol,
        markPrice,
        markPriceUSDT,
        low,
        high,
        firstVolume,
        secondVolume,
        changePrice,
        change,
        botstatus
    }: MarketPriceState) {
        // await this.getPrice()
        // console.log(this.priceCnv, 'this.priceCnv')
        // if (this.priceCnv && this.priceCnv.length > 0) {
        //     const conversion = this.priceCnv.find((item: any) => item.baseSymbol == secondCurrencySymbol && item.convertSymbol == 'USDT')
        //     const convertPrice = markPrice * parseFloat(conversion.convertPrice)
            const payload = {
                _id,
                markPrice,
                // markPriceUSDT: convertPrice,
                low,
                high,
                firstVolume,
                secondVolume,
                changePrice,
                change,
                botstatus
            }
            this.store.dispatch(setSpotMarketPrice({ payload }));

        // }
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}