<h2 class="dashbox_title">Buy Crypto with INR</h2>
<div class="row">
    <div *ngIf="!isLoading; else data">
        <app-loader></app-loader>
    </div>
    <ng-template #data>

        <!-- buy crypto form -->
        <form [formGroup]="form" (ngSubmit)="submitForm()">

            <div class="sign_group sign_group_input">
                <label>Choose Currency</label>
                <select class="sign_input" (change)="changeCurrency($event)" formControlName="coinSymbol">
                    <option value="">Select Currency</option>
                    <option *ngFor="let option of coinOptions" [value]="option.value">
                        {{ option.label }}
                    </option>
                </select>
                <div *ngIf="coinSymbol?.invalid && (coinSymbol?.touched || submitted)">
                    <span class="required" *ngIf="coinSymbol?.errors?.['required']">Fiat Amount field is required</span>
                </div>
            </div>

            <div class="sign_group sign_group_input">
                <label>Enter INR Amount</label>
                <div class="primary_inp_grp">
                    <input type="text" class="sign_input" placeholder="Enter INR Amount" formControlName="fiatAmount"
                        decimals />
                </div>
                <div *ngIf="fiatAmount?.invalid && (fiatAmount?.touched || submitted)">
                    <span class="required" *ngIf="fiatAmount?.errors?.['required']">Fiat Amount field is required</span>
                    <span class="required" *ngIf="fiatAmount?.errors?.['pattern']">Fiat Amount should be Greater than 0</span>
                </div>
                <span class="required" *ngIf="serverErrors?.fiatAmount">{{ serverErrors.fiatAmount }}</span>
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="withdraw_btn me-2 cursor-pointer" (click)="resetForm()">Cancel</button>
                <button type="submit" class="withdraw_btn cursor-pointer">Buy</button>
            </div>

        </form>
    </ng-template>
</div>
<app-onramp-overlay-mode *ngIf="showModal" [loading]="showModal" [SdkOptions]="SdkOptions"></app-onramp-overlay-mode>