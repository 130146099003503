import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setUserSetting, reset, updateTheme, setSiteSetting } from './usersetting.actions';
import { selectUserSetting } from './usersetting.selectors';
import { Observable } from 'rxjs';
import { UserSettingState } from './usersetting.state';

@Injectable({ providedIn: 'root' })
export class UserSettingFacade {
    private readonly store: Store = inject(Store);

    readonly usersetting$: Observable<UserSettingState> = this.store.select(selectUserSetting);

    setUserSetting(payload: any): void {
        this.store.dispatch(setUserSetting({ payload }));
    }
    
    setSiteSetting(payload: any): void {
        this.store.dispatch(setSiteSetting({ payload }));
    }

    updateTheme(payload: any): void {
        this.store.dispatch(updateTheme({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }

}