import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Cookies } from "src/app/lib/cookies";
import { encryptString } from "src/app/lib/cryptoJs";
import { convert, toFixedDown } from "src/app/lib/roundOf";

import { environment } from "src/environments/environment";

@Component({
  selector: "app-launchpad-list",
  templateUrl: "./launchpad-list.component.html",
  styleUrls: ["./launchpad-list.component.css"],
})
export class LaunchpadListComponent {
  @Input("data") data: any;
  @Input("launchData") launchData: any;

  toFixedDown = toFixedDown
  convert = convert

  url: any = environment.walletApiUrl + "currency/";
  isLogin: boolean = false;
  constructor(private router: Router, private cookies: Cookies) { }

  ngOnInit() {
    // console.log(this.cookies.isLogin(), 'cookies')
    this.isLogin = this.cookies.isLogin();
    // console.log(this.launchData, 'launchData')
  }
  // isLogin = () => return
  enc = (id: string) => encryptString(id, true);

  tokenSupply(item: any) {
    let commitData = this.launchData[0]?.commitData.find(
      (el: any) => el._id.toString() === item._id.toString()
    );
    if (commitData) {
      return item.availableSupply - commitData.launchAmount;
    }
    return item.availableSupply;
  }
}
