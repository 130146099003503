<div class="login_wrapper">
  <div class="authbanner_wrapper">
    <a routerLink="/">
      <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="Login"
        class="img-fluid auth_logo" />
    </a>
    <div class="auth_content">
      <h2>The Ultimate Utility Crypto Exchange!</h2>
      <p>
        Experience the ultimate utility crypto exchange. Our mission is to empower traders, from novices to experts, with the tools they need to succeed in the dynamic world of cryptocurrencies.
      </p>
    </div>
    <img src="assets/images/signin_img.png" alt="Login" class="img-fluid auth_right_img" />
  </div>
  <div class="auth_form_wrapper">
    <div class="row">
      <div class="col-lg-12">
        <div class="sign_content">
          <!-- authorization form -->
          <div>
            <h2>Reset Password</h2>
          </div>
          <p>
            Reset your password with a device you've recently used to access
            crypto to avoid a temporary security restriction
          </p>
          <form class="auth_form" [formGroup]="form" (submit)="submitForm()">
            <div class="sign_group sign_group_input">
              <label>New Password</label>
              <div class="primary_inp_grp">
              <input [type]="showNewPassword ? 'text' : 'password'" class="sign_input" formControlName="password"
                (input)="handleInputChange()" placeholder="Enter New Password" />
              <span (click)="hanldeShowNewPassword()" class="input_info">
                <i [ngClass]="showNewPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </span>
            </div>
              <div *ngIf="password?.invalid && (password?.touched || submitted)">
                <span class="required" *ngIf="password?.errors?.['required']">New Password field is required</span>
              </div>
              <span class="required" *ngIf="serverErrors?.password">{{
                serverErrors.password
                }}</span>
            </div>
            <div class="sign_group sign_group_input">
              <label>Confirm New Password</label>
              <div class="primary_inp_grp">
              <input [type]="showConfirmPassword ? 'text' : 'password'" class="sign_input"
                formControlName="confirmPassword" (input)="handleInputChange()" placeholder="Re-Enter New Password" />
              <span (click)="hanldeShowConfirmPassword()" class="input_info">
                <i [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </span>
            </div>
              <div *ngIf="confirmPassword?.invalid && (confirmPassword?.touched || submitted)">
                <span class="required" *ngIf="confirmPassword?.errors?.['required']">Confirm New Password field is
                  required</span>
              </div>
              <span class="required" *ngIf="serverErrors?.confirmPassword">{{
                serverErrors.confirmPassword
                }}</span>
            </div>
            <button class="sign_btn" type="submit">Reset Password</button>
          </form>
          <!-- end authorization form -->
        </div>
      </div>
    </div>
  </div>
</div>