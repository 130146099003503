import { Component } from '@angular/core';
import { toFixed, toFixedDown } from 'src/app/lib/roundOf';
import { isEmpty } from 'src/app/lib/validate';
import { CurrencyFacade } from 'src/app/store/currency';
import { DashboardFacade } from 'src/app/store/dashboard';
import { PriceCnvFacade } from 'src/app/store/pricecnv';
import { WalletFacade } from 'src/app/store/wallet';
import { Router } from '@angular/router';

import * as Highcharts from 'highcharts';
import { donutChartOptions } from '../../charts/doughnut-charts';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent {

  toFixedDown = toFixedDown
  toFixed = toFixed

  // pie chart
  Highcharts: typeof Highcharts = Highcharts
  updateFlag = false
  chartsdata: any = []
  colors: any = ['#f3ba2f', '#3ebeff', '#2d75ca', '#26a17b']
  chartOptions: any = donutChartOptions

  // public chart: any;

  totalBalanceUsdt: number = 0
  totalBalanceBtc: number = 0
  currencyList: any = []

  chartedLoaded: boolean = false

  wallet: any = [];
  priceCnv: any = [];
  balanceList: any[] = []


  btcOptions = {
    decimalPlaces: 8,
    suffix: ' BTC',
    scrollSpyOnce: false
  }

  usdtOptions = {
    decimalPlaces: 6,
    prefix: '≈ $ ',
    scrollSpyOnce: true
  }

  constructor(
    private dashboardFacade: DashboardFacade,
    private currencyFacade: CurrencyFacade,
    private priceCnvFacade: PriceCnvFacade,
    private walletFacade: WalletFacade,
    private router: Router
  ) {
    this.getDashboard()
  }

  ngOnInit() {
    this.makeAPICalls()
  }

  makeAPICalls() {
    this.currencyFacade.currency$.subscribe({
      next: (currency) => {
        this.currencyList = currency
        this.walletFacade.wallet$.subscribe({
          next: (wallet) => {
            this.wallet = wallet;
            this.priceCnvFacade.pricecnv$.subscribe({
              next: (priceconversion) => {
                this.priceCnv = priceconversion
                this.chartData()
              }, error: (err) => {
                // console.log(err)
              }
            })
          }, error: (err) => {
            // console.log(err)
          }
        })
      }, error: (err) => {
        // console.log(err)
      }
    })
  }

  getDashboard() {
    this.dashboardFacade.dashboard$.subscribe({
      next: ({ totalBalance }) => {
        if (totalBalance) {
          this.totalBalanceUsdt = totalBalance.usdt;
          this.totalBalanceBtc = totalBalance.btc;
        }
      },
      error: (error) => { }
    })
  }

  chartData() {
    // Check if necessary data is available and if the chart is not already loaded
    if (this.wallet && this.wallet.length > 0 && this.priceCnv && this.priceCnv.length > 0 && !this.chartedLoaded) {
      
      // Set chartedLoaded to true to prevent duplicate chart creation
      this.chartedLoaded = true

      let priceCnvData;
      let conversionPrice;
      let baseSymbol;

      // Iterate through wallet data (up to the first 4 elements)
      for (let i = 0; i < Math.min(this.wallet.length, 4); i++) {
        let spotBal = this.wallet[i].spotBal

        if (this.priceCnv && this.priceCnv.length > 0) {

          if (this.wallet[i].coin === 'USDT') {
            conversionPrice = 1;
            baseSymbol = 'USDT';
          } else {
            priceCnvData = this.priceCnv.find((el: any) => el.baseSymbol == this.wallet[i].coin && el.convertSymbol == 'USDT');
            if (priceCnvData) {
              conversionPrice = priceCnvData.convertPrice;
              baseSymbol = priceCnvData.baseSymbol;
            }
          }

          if (this.wallet[i].coin === 'USDT' || !isEmpty(priceCnvData)) {
            const convertPrice = spotBal * conversionPrice
            if (spotBal > 0) {
              this.chartsdata.push({
                name: baseSymbol,
                y: convertPrice,
                color: this.colors[i]
              })
            }
            this.balanceList.push({ currency: baseSymbol, convertPrice })
          }
        }
      }
      // Create the chart with the data
      this.createChart(this.chartsdata)
    }
  }

  createChart(data: any) {
    if (data.length > 0) {
      this.chartOptions.series[0] = {
        type: 'pie',
        name: 'Balance',
        data: data,
        events: {
          click: false
        }
      }
      this.updateFlag = true
    }
  }

  // createChart(label: any, data: any) {
  //   this.chart = new Chart('myChart', {
  //     type: 'doughnut',
  //     data: {
  //       labels: label,
  //       datasets: [{
  //         label: 'Balance',
  //         data: data,
  //         backgroundColor: [
  //           // 'rgb(122, 156, 158)',
  //           'rgb(255, 99, 132)',
  //           'rgb(54, 162, 235)',
  //           'rgb(255, 205, 86)'
  //         ],
  //         borderWidth: 0,
  //         hoverOffset: 4
  //       }],
  //     },
  //     options: {
  //       cutout: 78,
  //       aspectRatio: 1.3,
  //       plugins: {
  //         legend: {
  //           display: false
  //         },
  //       },
  //       layout: {
  //         padding: 5
  //       }
  //     },
  //   });
  //   this.chartdata = data
  //   // console.log(this.chartdata, 'this.chartdata')
  // }

  navigate(url: string) {
    this.router.navigateByUrl(url)
  }

}
