<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <div class="dashbox_title_flex">
            <h2 class="dashbox_title">Wallet Balance</h2>
          </div>
          <div class="wallet_balance_overview">
            <div>
              <label>Total Crypto Balance</label>
              <h3 [countUp]="cryptoBalance" [reanimateOnClick]="false" [options]="usdtOptions"></h3>
            </div>
            <div>
              <label>Total Balance</label>
              <h3 [countUp]="totalBalance" [reanimateOnClick]="false" [options]="usdtOptions"></h3>
            </div>
            <div>
              <label>Total INR Balance</label>
              <h3 [countUp]="inrBalance" [reanimateOnClick]="false" [options]="inrOptions"></h3>
              <div class="fiat_wallet_btns">
                <a [ngClass]="{ disabled: inrCurrency?.status !== 'active' || inrCurrency?.depositStatus !== 'active' }"
                  routerLink="/deposit-fiat" class="deposit_btn">Deposit</a>
                <a [ngClass]="{ disabled: inrCurrency?.status !== 'active' || inrCurrency?.withdrawStatus !== 'active' }"
                  routerLink="/withdraw-fiat" class="withdraw_btn">Withdraw</a>
              </div>
            </div>
          </div>
        </div>

        <div class="dashbox">
          <h2 class="dashbox_title">Assets Overview</h2>
          <div class="table-responsive">
            <div class="d-flex justify-content-center mb-5" *ngIf="!loader; else tabledata">
              <span class="text-primary spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              <span class="text-primary spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
              <span class="text-primary spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
            </div>
            <ng-template #tabledata>
              <table class="table primary_table">
                <thead>
                  <tr>
                    <th>Assets</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of Assets">
                    <td>
                      <div class="d-flex align-items-center">
                        <img src="{{ item.currency?.image }}" alt="{{ item.wallet?.coin }}" alt="Crypto"
                          class="img-fluid history_table_icon" />

                        <span>{{ item.wallet?.coin }} /
                          <span class="blue_txt">{{
                            item.currency?.name
                            }}</span></span>
                      </div>
                    </td>
                    <td>{{ toFixedDown(item.wallet.spotBal, item?.currency?.decimals) }}</td>
                    <td>
                      <div class="wallet_asset_links">
                        <a [ngClass]="{ disabled: item?.currency?.depositStatus !== 'active' }"
                          routerLink="/deposit-{{item?.currency?.type == 'fiat' ? 'fiat': 'crypto'}}/{{ item.wallet?.coin }}"
                          class="green_txt">Deposit</a>

                        <a [ngClass]="{ disabled: item?.currency?.withdrawStatus !== 'active' }"
                          routerLink="/withdraw-{{item?.currency?.type == 'fiat' ? 'fiat': 'crypto'}}/{{ item.wallet?.coin }}"
                          class="red_txt">Withdraw</a>

                        <a *ngIf="item.hasTradePair" href="{{
                          'spot/' +
                            item.hasTradePair.firstCurrencySymbol +
                            '_' +
                            item.hasTradePair.secondCurrencySymbol
                        }}
                        " class="blue_txt">Trade</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>