<div class="table-responsive trade_history_table">
    <table class="table primary_table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Pair</th>
                <th>Type</th>
                <th>Side</th>
                <th>Price</th>
                <th>Amount</th>
                <th>Filled</th>
                <th>Total</th>
                <th>Action</th>
            </tr>
        </thead>
        <!-- <tbody *ngIf="orderData.data.length > 0; else nodata" class="scroll-table" infiniteScroll
            (scrolled)="onScroll()" [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="500"
            [scrollWindow]="false" [infiniteScrollDisabled]="orderData.nextPage">
            <tr *ngFor="let order of orderData.data">
                <td>{{order.orderDate | date:"YYYY-MM-dd HH:mm"}}</td>
                <td>{{order.firstCurrency}}/{{order.secondCurrency}}</td>
                <td>{{order.orderType | titlecase}}</td>
                <td>
                    <span
                        [ngClass]="{ 'red_txt': order.buyorsell == 'sell', 'green_txt': order.buyorsell == 'buy' }">{{order.buyorsell
                        | titlecase}}
                    </span>
                </td>
                <td>{{order.price === 'market' ? 'Market': truncateDecimals(order.price, tradePair.secondFloatDigit)}}
                </td>
                <td>{{truncateDecimals(order.openQuantity, tradePair.firstFloatDigit)}}</td>
                <td>
                    {{truncateDecimals(order.filledQuantity, tradePair.firstFloatDigit)}}
                </td>
                <td>
                    {{truncateDecimals(order.orderValue ? order.orderValue: 0, tradePair.secondFloatDigit)}}
                </td>
                <td>
                    <span class="text-white cursor-pointer red_txt" data-bs-toggle="modal"
                        data-bs-target="#cancelorder_enable_modal" (click)="setModalData(order)">
                        Cancel
                    </span>
                </td>
            </tr>

            <div *ngIf="isLoading">{{isLoading}}Loading...</div>
        </tbody> -->
        <tbody *ngIf="orderData.data.length > 0; else nodata">
            <tr *ngFor="let order of orderData.data">
                <td>{{order.orderDate | date:"YYYY-MM-dd HH:mm"}}</td>
                <td>{{order.firstCurrency}}/{{order.secondCurrency}}</td>
                <td>{{order.orderType | titlecase}}</td>
                <td>
                    <span
                        [ngClass]="{ 'red_txt': order.buyorsell == 'sell', 'green_txt': order.buyorsell == 'buy' }">{{order.buyorsell
                        | titlecase}}
                    </span>
                </td>
                <td>{{order.price === 'market' ? 'Market': truncateDecimals(order.price,
                    tradePair.secondFloatDigit)}}
                </td>
                <td>{{truncateDecimals(order.openQuantity, tradePair.firstFloatDigit)}}</td>
                <td>
                    {{truncateDecimals(order.filledQuantity, tradePair.firstFloatDigit)}}
                </td>
                <td>
                    {{truncateDecimals(order.orderValue ? order.orderValue: 0,
                    tradePair.secondFloatDigit)}}
                </td>
                <td>
                    <span class="text-white cursor-pointer red_txt" data-bs-toggle="modal"
                        data-bs-target="#cancelorder_enable_modal" (click)="setModalData(order)">
                        Cancel
                    </span>
                </td>
            </tr>

            <div *ngIf="isLoading">{{isLoading}}Loading...</div>
        </tbody>
        <ng-template #nodata>
            <tbody>
                <tr>
                    <td colspan="9" style="border-color: transparent;">
                        <div class="text-center mt-4 mb-4" *ngIf="auth.signedIn; else login">
                            <img src="assets/images/no_records_found.png" alt="Notification" class="img-fluid mb-2" />
                            <p>No Records Found!</p>
                        </div>
                        <ng-template #login>
                            <div class="text-center mt-4 mb-4">
                                <!-- <p>
                                    <a class="blue_txt no-underline" href="signin">Login</a> or <a class="blue_txt"
                                        href="signup" class="blue_txt no-underline">Register</a> Now to trade
                                </p> -->
                                <img src="assets/images/no_records_found.png" alt="Notification"
                                    class="img-fluid mb-2" />
                                <p>
                                    <a class="blue_txt no-underline" href="signin">Login</a>
                                    or
                                    <a class="blue_txt" href="signup" class="blue_txt no-underline">Register</a>
                                    Now to trade
                                </p>
                            </div>
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>