import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from 'src/app/store/user';


@Component({
  selector: 'app-bank-verification',
  templateUrl: './bank-verification.component.html',
  styleUrls: ['./bank-verification.component.css']
})
export class BankVerificationComponent {

  user: any = {}

  constructor(private userFacade: UserFacade, private router: Router) { }
  ngOnInit() {
    this.userFacade.user$.subscribe({
      next: (user) => {
        this.user = user
      },
      error: () => { }
    })
  }

  redirect(url: string) {
    this.router.navigateByUrl(url)
  }

  bankStatus() {
    const bankData = this.user.bankDetails

    const VERIFIED = 'verified',
      UNVERIFIED = 'unverified',
      REJECTED = 'rejected'

    if (!bankData || bankData.length == 0)
      return UNVERIFIED;

    const approvedbank = bankData.some((el: any) => el.status == 'approved')
    if (approvedbank) return VERIFIED;

    const rejectedbank = bankData.some((el: any) => el.status == 'rejected')
    if (rejectedbank) return REJECTED;

    return UNVERIFIED;
  }

}
