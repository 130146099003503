import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;
  supportMail: string = ''

  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;


  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.form = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      mobile: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  get name() { return this.form.get('name') }
  get email() { return this.form.get('email') }
  get mobile() { return this.form.get('mobile') }
  get subject() { return this.form.get('subject') }
  get message() { return this.form.get('message') }

  ngOnInit() {
    this.getSiteData()
  }

  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true;

    this.service.addcontactus(this.form.value)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false
          if (v.status) {
            this.submitted = false
            this.toastr.success(v.message)
            this.form.reset()
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  getSiteData () {
    this.commonService.getSiteSetting()
    .subscribe({
      next: (data: any) => this.supportMail = data?.result?.supportMail, 
      error: (err) => {
        // console.log(err)
      }
    })
  }

}
