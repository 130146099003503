<div class="tab-pane fade show active">
  <div class="row history_search">
    <form action="#" class="auth_form row" [formGroup]="form">
      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          (input)="onOptionChange($event)"
          id="coin"
          formControlName="coin"
        >
          <option value="all" selected="selected">All</option>
          <option *ngFor="let item of currencyList" value="{{ item.coin }}">
            {{ item.coin }}
          </option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="paymentType"
          (input)="onOptionChange($event)"
          formControlName="paymentType"
        >
          <option value="all" selected="selected">All</option>
          <option value="coin_deposit">Coin Deposit</option>
          <option value="coin_withdraw">Coin Withdraw</option>
          <option value="coin_transfer">Coin Transfer</option>
          <option value="fiat_deposit">Fiat Deposit</option>
          <option value="fiat_withdraw">Fiat Withdraw</option>
          <option value="fiat_withdraw">Fiat Transfer</option>
          <option value="utility_withdraw">Utility Withdraw</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="payment"
          (input)="onOptionChange($event)"
          formControlName="payment"
        >
          <option value="all" selected="selected">All</option>
          <option value="crypto">Crypto</option>
          <option value="fiat">Fiat</option>
        </select>
      </div>
      <!-- 
      <div class="col-lg-2 col-md-4 col-sm-6">
        <select
          class="primary_select"
          aria-label="all"
          id="status"
          (input)="onOptionChange($event)"
          formControlName="status"
        >
          <option value="all" selected="selected">All</option>
          <option value="open">Open</option>
          <option value="completed">Completed</option>
          <option value="cancel">Canceled</option>
        </select>
      </div> -->

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="withdraw_btn withdraw_btn_mw" (click)="searchfilter()">
            Search
          </button>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-6">
        <div class="primary_inp_grp">
          <button class="primary_btn w-100" (click)="clearfilter()">
            Clear
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="table-responsive">
    <table class="table primary_table">
      <thead>
        <tr>
          <th>Currency</th>
          <th>Date</th>
          <th>Payment Type</th>
          <th>Amount</th>
          <!-- <th>Fee</th> -->
          <th>Txn Ref</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of txnHistory?.data">
          <td>
            <div class="d-flex align-items-center">
              <!-- <img
                src="assets/images/cryptocoins/btc.png"
                alt="Crypto"
                class="img-fluid history_table_icon"
              /> -->
              <span>{{ item.coin }}</span>
            </div>
          </td>
          <td>{{ item.createdAt | date : "MMM d, y, h:mm:ss" }}</td>
          <td>{{ item.paymentType.replace("_", " ") | titlecase }}</td>
          <td>
            <span class="green_txt"> {{ toFixed(item.actualAmount, 4) }}</span>
          </td>
          <!-- <td>
            <span *ngIf="item.commissionFee > 0" class="green_txt">
              {{ toFixed(item.commissionFee, 4) }}</span
            >
          </td> -->

          <td>{{ item.txid }}</td>

          <td>
            <span class="green_txt" *ngIf="item.status == 'completed'">{{
              item.status | titlecase
            }}</span>
            <span class="blue_txt" *ngIf="item.status != 'completed'">{{
              item.status | titlecase
            }}</span>
          </td>
        </tr>

        <tr *ngIf="txnHistory?.count == 0">
          <td colspan="10">
            <div class="text-center mt-3">
              <img
                src="assets/images/no_records_found.png"
                alt="No Records"
                img="img-fluid"
              />
              <p>No Records Found !</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <nav class="primary_pagination" *ngIf="filter.totalPages > 1">
    <ul class="pagination">
      <li class="page-item" [ngClass]="{ disabled: filter.page == 1 }">
        <span
          class="page-link cursor-pointer"
          aria-label="Previous"
          (click)="previousPage()"
        >
          <span aria-hidden="true">&laquo;</span>
        </span>
      </li>
      <li class="page-item" *ngFor="let item of getDisplayedPages">
        <a
          class="page-link"
          [ngClass]="{ active: item == filter.page }"
          href="javascript:void(0)"
          (click)="goToPage(item)"
          >{{ item }}</a
        >
      </li>
      <li
        class="page-item"
        [ngClass]="{ disabled: filter.totalPages == filter.page }"
      >
        <span
          class="page-link cursor-pointer"
          (click)="nextPage()"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </span>
      </li>
    </ul>
  </nav>
</div>
