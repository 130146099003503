import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

// Import lib
import { encryptObject } from 'src/app/lib/cryptoJs';
import { convert, toFixed, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';

// Import service
import { SpotService } from 'src/app/services/spot.service';

// Import facade
import { TradeAssetFacade } from 'src/app/store/trade-asset';
import { TradePairFacade } from 'src/app/store/tradepair';
import { MarketPriceFacade } from 'src/app/store/marketprice';
import isEmpty from 'src/app/lib/isEmpty';
import { AuthFacade } from 'src/app/store/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-marketorder-form',
  templateUrl: './marketorder-form.component.html',
  styleUrls: ['./marketorder-form.component.css']
})
export class MarketorderFormComponent {
  @Input() buyorsell: any;

  truncateDecimals = truncateDecimals;
  toFixedDown = toFixedDown;
  convert = convert

  firstCurrency: any = {}
  secondCurrency: any = {}
  tradePair: any = {}
  marketPrice: any = {}
  auth: any = {}

  form!: FormGroup;
  buyPercentage: number = 0
  submitted: boolean = false;
  serverErrors: any = {}

  constructor(
    private _fb: FormBuilder,
    private tradeAssetFacade: TradeAssetFacade,
    private tradePairFacade: TradePairFacade,
    private service: SpotService,
    private toastr: ToastrService,
    private marketPriceFacade: MarketPriceFacade,
    private authFacade: AuthFacade,
    private router: Router
  ) { }

  ngOnInit() {

    let type: string = this.buyorsell == 'buy' ? 'orderValue' : 'amount'
    this.form = this._fb.group({
      [type]: ['', Validators.required],
    });
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
    this.tradeAssetFacade.tradeAsset$.subscribe({
      next: ({ firstCurrency, secondCurrency }: any) => {
        this.firstCurrency = firstCurrency
        this.secondCurrency = secondCurrency
      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
      },
      error: (err: any) => { }
    })
    this.marketPriceFacade.marketPrice$.subscribe({
      next: (price: any) => {
        this.marketPrice = price
      },
      error: (err: any) => { }
    })

  }

  get orderValue() { return this.form.get('orderValue') }
  get amount() { return this.form.get('amount') }

  handleSetPercentage(percent: number) {
    let priceValue = 0
    this.buyPercentage = percent

    if (!isEmpty(this.marketPrice) && !isNaN(this.marketPrice.markPrice)) {
      priceValue = this.marketPrice.markPrice;
    }

    priceValue = this.marketPrice.markPrice
    if (this.buyorsell == 'buy') {
      let userBalance = this.secondCurrency && this.secondCurrency.spotBal > 0 ? this.secondCurrency.spotBal : 0;
      this.form.patchValue({
        orderValue: truncateDecimals(userBalance * (percent / 100), this.tradePair.secondFloatDigit)
      })
    } else if (this.buyorsell == 'sell') {
      let userBalance = this.firstCurrency && this.firstCurrency.spotBal > 0 ? parseFloat(this.firstCurrency.spotBal) : 0;
      this.form.patchValue({
        amount: truncateDecimals(userBalance * (percent / 100), this.tradePair.firstFloatDigit)
      })
    }
  }

  IncDec(field: string, type: string) {
    let value = this.form.value[field];
    if (type === 'dec' && value > 0)
      value--;
    else if (type === 'inc')
      value++;

    this.form.patchValue({
      [field]: convert(value)
    });
    // this.handleChange()
  }

  submitForm() {
    let { orderValue, amount } = this.form.value

    orderValue = toFixedDown(orderValue, this.tradePair.secondFloatDigit)
    amount = toFixedDown(amount, this.tradePair.firstFloatDigit)

    this.submitted = true

    if (!this.form.valid)
      return

    let type: string = this.buyorsell == 'buy' ? 'orderValue' : 'amount'
    let reqData = {
      [type]: this.buyorsell == 'buy' ? orderValue : amount,
      buyorsell: this.buyorsell,
      orderType: 'market',
      spotPairId: this.tradePair._id,
      'newdate': new Date()
    };
    const encryptData = {
      token: encryptObject(reqData)
    }

    this.service.orderPlace(encryptData)
      .subscribe({
        next: (v: any) => {
          this.submitted = false
          if (v.status) {
            this.toastr.success(v.message)
            this.form.reset()
          } else {
            this.toastr.success(v.message)
          }
        },
        error: (err: any) => {
          this.submitted = false
          if (err.error.message) {
            this.toastr.error(err.error.message)
          } else if (err.error.errors) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          }
        }
      })
  }

  navigateToLogin() {
    this.router.navigateByUrl('signin')
  }

  checkOrderBook() {

  }

}
