<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Notification</h2>
          <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
            <!-- <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-all-notify-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-all-notify"
                type="button"
                role="tab"
                aria-controls="pills-all-notify"
                aria-selected="true"
              >
                All Notifications
              </button>
            </li> -->
            <!-- <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-system-notify-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-system-notify"
                type="button"
                role="tab"
                aria-controls="pills-system-notify"
                aria-selected="false"
              >
                System Notifications
              </button>
            </li> -->
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-annoucements-notify-tab" data-bs-toggle="pill"
                data-bs-target="#pills-annoucements-notify" type="button" role="tab"
                aria-controls="pills-annoucements-notify" aria-selected="false">
                Announcements
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link d-flex" id="pills-activities-notify-tab" data-bs-toggle="pill"
                data-bs-target="#pills-activities-notify" type="button" role="tab"
                aria-controls="pills-activities-notify" aria-selected="false">
                Activities Notification
                <span class="notify_count mx-1" *ngIf="unread_count > 0">{{unread_count}}</span>
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <!-- <div
              class="tab-pane fade show active"
              id="pills-all-notify"
              role="tabpanel"
              aria-labelledby="pills-all-notify-tab"
              tabindex="0"
            >
              <div class="notification_panel">
                <div class="notification_row">
                  <div>
                    <div class="notification_unread">
                      <img
                        src="assets/images/notification_msg.png"
                        alt="Notification"
                        class="img-fluid"
                      />
                      <span class="notification_unread_dot"
                        ><i class="fas fa-circle"></i
                      ></span>
                    </div>
                    <div class="notification_row_content">
                      <h2>GreenArt Coin IEO has started on P2B</h2>
                      <p>
                        GreenArt Coin is the first ethical Ecosystem, It works
                        around Cryptocurrency, Cryptopoint Franchsing, Digital
                        Bank, Game P2E and NFT Marketplace
                      </p>
                    </div>
                  </div>
                  <p class="notify_date">Jun 16, 2023 / 11:28</p>
                </div>

                <div class="notification_row">
                  <div>
                    <div class="notification_unread">
                      <img
                        src="assets/images/notification_msg.png"
                        alt="Notification"
                        class="img-fluid"
                      />
                      <span class="notification_unread_dot"
                        ><i class="fas fa-circle"></i
                      ></span>
                    </div>
                    <div class="notification_row_content">
                      <h2>GreenArt Coin IEO has started on P2B</h2>
                      <p>
                        GreenArt Coin is the first ethical Ecosystem, It works
                        around Cryptocurrency, Cryptopoint Franchsing, Digital
                        Bank, Game P2E and NFT Marketplace
                      </p>
                    </div>
                  </div>
                  <p class="notify_date">Jun 16, 2023 / 11:28</p>
                </div>

                <div class="notification_row">
                  <div>
                    <div class="notification_unread">
                      <img
                        src="assets/images/notification_msg.png"
                        alt="Notification"
                        class="img-fluid"
                      />
                    </div>
                    <div class="notification_row_content">
                      <h2>GreenArt Coin IEO has started on P2B</h2>
                      <p>
                        GreenArt Coin is the first ethical Ecosystem, It works
                        around Cryptocurrency, Cryptopoint Franchsing, Digital
                        Bank, Game P2E and NFT Marketplace
                      </p>
                    </div>
                  </div>
                  <p class="notify_date">Jun 16, 2023 / 11:28</p>
                </div>
              </div>
            </div> -->
            <!-- <div
              class="tab-pane fade"
              id="pills-system-notify"
              role="tabpanel"
              aria-labelledby="pills-system-notify-tab"
              tabindex="0"
            >
              <div class="notification_panel">
                <div class="notification_row">
                  <div>
                    <div class="notification_unread">
                      <img
                        src="assets/images/notification_msg.png"
                        alt="Notification"
                        class="img-fluid"
                      />
                    </div>
                    <div class="notification_row_content">
                      <h2>GreenArt Coin IEO has started on P2B</h2>
                      <p>
                        GreenArt Coin is the first ethical Ecosystem, It works
                        around Cryptocurrency, Cryptopoint Franchsing, Digital
                        Bank, Game P2E and NFT Marketplace
                      </p>
                    </div>
                  </div>
                  <p class="notify_date">Jun 16, 2023 / 11:28</p>
                </div>
              </div>
            </div> -->
            <div class="tab-pane fade" id="pills-activities-notify" role="tabpanel"
              aria-labelledby="pills-activities-notify-tab" tabindex="0">
              <div class="notification_panel">

                <div class="notification_row" *ngFor="let item of activities">
                  <div>
                    <div class="notification_unread">
                      <img src="assets/images/notification_msg.png" alt="Notification" class="img-fluid" />
                      <span class="notification_unread_dot" *ngIf="!item.noti_view_status"><i
                          class="fas fa-circle"></i></span>
                    </div>
                    <div class="notification_row_content">
                      <h2>{{item.title}}</h2>
                      <p>{{item.description}}</p>
                    </div>
                  </div>
                  <p class="notify_date">{{item.createdAt | date: 'medium'}}</p>
                </div>

                <div class="notification_row" *ngIf="activities.length == 0">
                  <div class="d-block text-center w-100">
                    <img src="assets/images/no_records_found.png" alt="Notification" class="img-fluid" />
                    <p class="mt-3">No New Notifications</p>
                  </div>
                </div>
              </div>
              <nav class="primary_pagination" *ngIf="activityFilter.totalPages > 1">
                <ul class="pagination">
                  <li class="page-item" [ngClass]="{disabled: activityFilter.page == 1}">
                    <span class="page-link cursor-pointer" aria-label="Previous" (click)="previousPageAct()">
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>
                  <li class="page-item" *ngFor="let item of getDisplayedPages">
                    <a class="page-link" [ngClass]="{ active: item == activityFilter.page}" href="javascript:void(0)"
                      (click)="goToPageAct(item)">{{item}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{disabled: activityFilter.totalPages == activityFilter.page}">
                    <span class="page-link cursor-pointer" (click)="nextPageAct()" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="tab-pane fade show active" id="pills-annoucements-notify" role="tabpanel"
              aria-labelledby="pills-annoucements-notify-tab" tabindex="0">
              <div class="notification_panel">

                <div class="notification_row" *ngFor="let item of announcements">
                  <div>
                    <div class="notification_unread announcements_unread">
                      <img src="assets/images/announce.png" alt="Notification" class="img-fluid" />
                      <!-- <span class="notification_unread_dot"><i class="fas fa-circle"></i></span> -->
                    </div>
                    <div class="notification_row_content">
                      <h2>{{item.title}}</h2>
                      <p [innerHTML]="item.content"></p>
                    </div>
                  </div>
                  <p class="notify_date">{{item.createdAt | date: 'medium'}}</p>
                </div>

                <div class="notification_row" *ngIf="announcements.length == 0">
                  <div class="d-block text-center w-100">
                    <img src="assets/images/no_records_found.png" alt="Notification" class="img-fluid" />
                    <p class="mt-3">No New Announcements</p>
                  </div>
                </div>

              </div>
              <nav class="primary_pagination" *ngIf="announcefilter.totalPages > 1">
                <ul class="pagination">
                  <li class="page-item" [ngClass]="{disabled: announcefilter.page == 1}">
                    <span class="page-link cursor-pointer" aria-label="Previous" (click)="previousPageAnn()">
                      <span aria-hidden="true">&laquo;</span>
                    </span>
                  </li>
                  <li class="page-item" *ngFor="let item of getDisplayedAnnPages">
                    <a class="page-link" [ngClass]="{ active: item == announcefilter.page}" href="javascript:void(0)"
                      (click)="goToPageAnn(item)">{{item}}</a>
                  </li>
                  <li class="page-item" [ngClass]="{disabled: announcefilter.totalPages == announcefilter.page}">
                    <span class="page-link cursor-pointer" (click)="nextPageAnn()" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>