import { Component, Input } from '@angular/core';
import { TradeAssetFacade } from 'src/app/store/trade-asset';
import { TradePairFacade } from 'src/app/store/tradepair';

// Import lib
import { longDecimals, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import isEmpty from 'src/app/lib/isEmpty';
import { AuthFacade } from 'src/app/store/auth';

@Component({
  selector: 'app-view-balance',
  templateUrl: './view-balance.component.html',
  styleUrls: ['./view-balance.component.css']
})
export class ViewBalanceComponent {
  @Input() buyorsell: any

  truncateDecimals = truncateDecimals;
  toFixedDown = toFixedDown;
  isEmpty = isEmpty;
  longDecimals = longDecimals;

  tradePair: any = {}
  auth: any = {}

  firstCurrency: any = {}
  secondCurrency: any = {}

  constructor(
    private tradePairFacade: TradePairFacade,
    private tradeAssetFacade: TradeAssetFacade,
    private authFacade: AuthFacade
  ) { }

  ngOnInit() {

    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
      },
      error: (err: any) => { }
    })

    this.tradeAssetFacade.tradeAsset$.subscribe({
      next: ({ firstCurrency, secondCurrency }: any) => {
        this.firstCurrency = firstCurrency
        this.secondCurrency = secondCurrency
      },
      error: (err: any) => { }
    })
  }

}
