<div class="sign_content">
  <!-- authorization form -->
  <div class="mb-0">
    <h2>Sign In</h2>
    <div class="signin_btns">
      <button (click)="showDiv1()" [ngClass]="{ active: isDiv1Visible }">
        <i class="fas fa-envelope"></i>
      </button>
      <button (click)="showDiv2()" [ngClass]="{ active: isDiv2Visible }">
        <i class="fas fa-phone-alt"></i>
      </button>
    </div>
  </div>
  <div>
    <span (click)="know_SignUp()" class="sign_text"> How to Sign Up : <a class="cursor-pointer">Click Here</a></span>
  </div>

  <form #f="ngForm" class="auth_form signup_form" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="mb-3">
      <div class="sign_group sign_group_input" *ngIf="isDiv1Visible">
        <label htmlFor="email">Email address</label>
        <input type="text" class="sign_input" placeholder="Enter Email" formControlName="email"
          (input)="onInputChange()" id="email" />
      </div>
      <div *ngIf="email?.invalid && (email?.touched || submitted) && isDiv1Visible">
        <span class="required" *ngIf="email?.errors?.['required']">Email field is required</span>
        <span class="required" *ngIf="email?.errors?.['pattern']">Invalid email</span>
      </div>
      <span class="required" *ngIf="serverErrors?.email && isDiv1Visible">{{
        serverErrors.email
        }}</span>
    </div>

    <div class="mb-3" *ngIf="isDiv2Visible">
      <div class="sign_group sign_group_input">
        <label>Phone Number</label>
        <div class="mb-0">
          <ngx-mat-intl-tel-input formControlName="newPhoneNo" [enablePlaceholder]="true" [enableSearch]="true"
            name="newPhoneNo" [preferredCountries]="['in']" (countryChanged)="countryChangedEvent($event)"
            (ngModelChange)="onInputChange()">
          </ngx-mat-intl-tel-input>
        </div>
      </div>
      <div *ngIf="
          newPhoneNo?.invalid &&
          (newPhoneNo?.touched || submitted) &&
          isDiv2Visible
        ">
        <span class="required" *ngIf="newPhoneNo?.errors?.['required']">Phone Number field is required</span>
        <span class="required" *ngIf="newPhoneNo?.errors?.['validatePhoneNumber']">Phone Number is invalid</span>
      </div>
      <span class="required" *ngIf="serverErrors?.newPhoneNo && isDiv2Visible">{{ serverErrors.newPhoneNo }}</span>
    </div>

    <div class="mb-3">
      <div class="sign_group sign_group_input">
        <label>Password</label>
        <div class="primary_inp_grp">
          <input [type]="showPassword ? 'text' : 'password'" class="sign_input" placeholder="Enter Password"
            formControlName="password" (input)="onInputChange()" id="password" [disabled]="otpTextBox"
            [readOnly]="otpTextBox" />
          <span (click)="hanldeShowPassword()" class="input_info">
            <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </span>
        </div>
      </div>
      <div *ngIf="password?.invalid && (password?.touched || submitted)">
        <span class="required" *ngIf="password?.errors?.['required']">Password field is required</span>
      </div>
      <span class="required" *ngIf="serverErrors?.password">{{
        serverErrors.password
        }}</span>
    </div>
    <div class="mb-3" *ngIf="otpTextBox && !otpVerified">
      <div class="sign_group sign_group_input">
        <label>OTP</label>
        <div class="primary_inp_grp">
          <input type="text" class="sign_input" placeholder="Enter OTP" autoComplete="off" (input)="onInputChange()"
            formControlName="otp" />
          <span class="input_info">
            <svg cIcon name="cilCheck" *ngIf="otpVerified; else resend"></svg>
            <!-- <span class="cursor-pointer" (click)="resendOtp()" *ngIf="!loaders; else timerInSec">
              Resend OTP
            </span> -->
            <ng-template #resend>
              <span class="cursor-pointer" (click)="resendOtp()" *ngIf="!loaders; else timerInSec">
                <small>Resend Code</small>
              </span>
            </ng-template>
            <ng-template #timerInSec>
              {{ seconds }}
            </ng-template>
          </span>
        </div>
        <div *ngIf="otp?.invalid && (otp?.touched || submitted)">
          <span class="required" *ngIf="otp?.errors?.['required']">OTP field is required</span>
        </div>
        <span class="required" *ngIf="serverErrors?.otp">{{
          serverErrors.otp
          }}</span>
      </div>
    </div>

    <!-- <div class="mb-3">
      <div class="sign_group sign_group_input">
        <label>OTP</label>
        <div class="primary_inp_grp">
          <input type="text" class="sign_input" placeholder="Enter OTP" formControlName="otp" (input)="onInputChange()"
            id="otp" />
          <span class="input-info cursor-pointer" (click)="resendOtp()">
            {{seconds === 0 ? 'Resend OTP': '1' }}
          </span> -->
    <!-- <ng-template class="input_info" #timerInSeconds>
            <span>{{ seconds }}</span>
          </ng-template> -->
    <!-- <span *ngIf="loaders == true" class="input_info cursor-pointer">
            {{ seconds }}</span> -->
    <!-- <span *ngIf="seconds != 0" class="input_info cursor-pointer" (click)="resendOtp()">Send
            OTP</span> -->
    <!-- </div>
      </div>
      <div *ngIf="otp?.invalid && (otp?.touched || submitted)">
        <span class="required" *ngIf="otp?.errors?.['required']">OTP field is required</span>
      </div>
      <span class="required" *ngIf="serverErrors?.otp">{{
        serverErrors.otp
        }}</span>
    </div> -->

    <div class="mb-3" *ngIf="is2FAEnabled">
      <div class="sign_group sign_group_input">
        <label>2FA</label>
        <div class="primary_inp_grp">
          <input type="text" class="sign_input" placeholder="Enter 2FA" formControlName="twoFACode"
            (input)="onInputChange()" id="twoFACode" />
        </div>
      </div>
      <div *ngIf="twoFACode?.invalid && (twoFACode?.touched || submitted)">
        <span class="required" *ngIf="twoFACode?.errors?.['required']">2FA field is required</span>
      </div>
      <span class="required" *ngIf="serverErrors?.twoFACode">{{
        serverErrors.twoFACode
        }}</span>
    </div>

    <div class="sign_group sign_group_checkbox" *ngIf="!otpTextBox && isDiv1Visible">
      <input id="rememberMeEmail" [checked]="rememberMeEmail" (change)="handleRememberMeEmail($event)"
        type="checkbox" />
      <label for="rememberMeEmail">Remember Me</label>
    </div>
    <div class="sign_group sign_group_checkbox" *ngIf="!otpTextBox && isDiv2Visible">
      <input id="rememberMeNewPhoneNo" [checked]="rememberMeNewPhoneNo" (change)="handleRememberMeNewPhoneNo($event)"
        type="checkbox" />
      <label for="rememberMeNewPhoneNo">Remember Me</label>
    </div>

    <button class="sign_btn" [type]="!isLoading ? 'submit' : 'button'" [disabled]="isLoading">
      <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      Sign In
    </button>
    <!-- <button class="sign_btn" type="button" disabled>
      <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      Signing In...
    </button> -->
    <!-- <ng-template #otp_confirm>
      <button class="sign_btn" type="button" (click)="verifyOtp()" [disabled]="isLoading">
        <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Confirm OTP
      </button>
    </ng-template> -->

    <span class="sign_text">Don't have an account? <a routerLink="/signup">Sign Up!</a></span>
    <span class="sign_text"><a routerLink="/forgot-password">Forgot Password?</a></span>
  </form>
  <!-- end authorization form -->

  <!-- youtube video viewer  -->
  <div class="modal primary_modal" [style]="{ display: showVideoModal ? 'block' : 'none' }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="googleauth_enable_modalLabel">
            Know About Sign Up
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="know_SignUp()"></button>
        </div>
        <div class="modal-body">
          <iframe width="470" height="270" src="https://www.youtube.com/embed/BQoMfroq1RI?si=K3qI-O8yHE1HcMsl"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>