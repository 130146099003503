<app-header></app-header>
<main class="inner_cms_layout">
  <section>
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-7 col-md-12">
          <h2 class="dashbox_title">Contact Us</h2>
          <p>A member of the team will reach out to you as soon as possible.</p>
          <form
            action="#"
            class="auth_form row"
            [formGroup]="form"
            (ngSubmit)="submitForm()"
          >
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Full Name</label>
                <input
                  type="text"
                  class="sign_input"
                  placeholder="Enter Name"
                  formControlName="name"
                />
                <div *ngIf="name?.invalid && (name?.touched || submitted)">
                  <span class="required" *ngIf="name?.errors?.['required']"
                    >Name field is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Email Address</label>
                <input
                  type="email"
                  class="sign_input"
                  placeholder="Enter Email"
                  formControlName="email"
                />
                <div *ngIf="email?.invalid && (email?.touched || submitted)">
                  <span class="required" *ngIf="email?.errors?.['required']"
                    >Email field is required</span
                  >
                  <span class="required" *ngIf="email?.errors?.['pattern']"
                    >Invalid email</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Mobile Number</label>
                <input
                  type="text"
                  class="sign_input"
                  placeholder="Enter Mobile Number"
                  formControlName="mobile"
                />
                <div *ngIf="mobile?.invalid && (mobile?.touched || submitted)">
                  <span class="required" *ngIf="mobile?.errors?.['required']"
                    >Mobile field is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Subject</label>
                <input
                  type="text"
                  class="sign_input"
                  placeholder="Enter Subject"
                  formControlName="subject"
                />
                <div
                  *ngIf="subject?.invalid && (subject?.touched || submitted)"
                >
                  <span class="required" *ngIf="subject?.errors?.['required']"
                    >Subject field is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="sign_group sign_group_input">
                <label>Message</label>
                <textarea
                  type="number"
                  class="sign_input"
                  placeholder="Enter Message"
                  rows="5"
                  formControlName="message"
                ></textarea>
                <div
                  *ngIf="message?.invalid && (message?.touched || submitted)"
                >
                  <span class="required" *ngIf="message?.errors?.['required']"
                    >Message field is required</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <button class="sign_btn" type="submit" [disabled]="isLoading">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-12">
          <div class="dashbox contact_left_box">
            <div class="contact_left_row">
              <div>
                <label>Email Us</label>
                <a href="mailto:info@credexchange.com">info@credbull.com</a>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 325 206.23"
                width="48"
                height="31"
              >
                <defs>
                  <style>
                    .cls-1 {
                      fill-rule: evenodd;
                    }
                  </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      class="cls-1"
                      d="M325,202.36V12.1l-95.14,95.13ZM20.32,206.23H304.68l-86.91-86.9-33.12,33.11a8.52,8.52,0,0,1-6.05,2.5H146.4a8.49,8.49,0,0,1-6.05-2.5l-33.12-33.11-86.91,86.9ZM0,12.09V202.36l95.14-95.13ZM312.91,0H12.1L149.94,137.84h25.12Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="contact_left_row">
              <div>
                <label>Support</label>
                <a [href]="'mailto:' + supportMail"
                  >{{supportMail}}</a
                >
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 460.19 512"
                width="50"
                height="55"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      d="M75,263v-56.9A156.22,156.22,0,0,1,75.79,190H55.32c7.84-89.54,83.23-160,174.77-160S397,100.47,404.86,190H384.39a153.46,153.46,0,0,1,.84,16.13V263a154.71,154.71,0,0,1-4.88,38.68h55.19V205.44q0-7.78-.59-15.43C427.05,83.91,338.18,0,230.09,0S33.14,83.91,25.23,190q-.59,7.63-.59,15.43v96.28h55.2A155.17,155.17,0,0,1,75,263Z"
                    />
                    <path
                      d="M230.09,81A125.28,125.28,0,0,0,105,206.14V224a232.21,232.21,0,0,0,106.13-28.35c28.86-15.66,44.83-32.25,45-32.41l12.3-12.91L279,164.61c.28.37,27.92,36.45,76.21,47.61v-6.08A125.28,125.28,0,0,0,230.09,81Z"
                    />
                    <path
                      d="M342.51,378.06h-7.94L230.09,509.16,125.62,378.06h-7.94A117.81,117.81,0,0,0,0,495.74V512H460.19V495.74A117.81,117.81,0,0,0,342.51,378.06Z"
                    />
                    <path
                      d="M268,413.49a155.36,155.36,0,0,1-75.77,0L230.09,461Z"
                    />
                    <path
                      d="M328.18,340.66H246.36v-30h99.45A124.4,124.4,0,0,0,355.23,263V242.87c-43.89-8.05-74.3-32.82-89.41-48.05a250.65,250.65,0,0,1-39.27,26.55A266.21,266.21,0,0,1,105,254V263a124.86,124.86,0,0,0,41.2,92.74l0,0,.15.19a124.94,124.94,0,0,0,181.83-15.28Z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>
