import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { spotSocket } from 'src/app/app.module';
import { Cookies } from 'src/app/lib/cookies';
import { convert, longDecimals, toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { AuthFacade } from 'src/app/store/auth';

// Import facade
import { TradePairFacade, TradePairState, selectTradePair } from 'src/app/store/tradepair';

@Component({
  selector: 'app-orderhistory',
  templateUrl: './orderhistory.component.html',
  styleUrls: ['./orderhistory.component.css']
})
export class OrderhistoryComponent {
  truncateDecimals = truncateDecimals;
  toFixedDown = toFixedDown;
  convert = convert;
  longDecimals = longDecimals

  isAuth: boolean = false
  tradePair: any = {}
  auth: any = {}

  orderData: any = {
    currentPage: 1,
    nextPage: true,
    limit: 10,
    count: 0,
    data: []
  }

  constructor(
    private spotService: SpotService,
    private tradePairFacade: TradePairFacade,
    private authFacade: AuthFacade,
    private cookies: Cookies,
    private spotSocket: spotSocket,
  ) {
    this.isAuth = this.cookies.isLogin()
  }

  ngOnInit() {
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth;
      },
      error: (err: any) => { }
    })
    this.tradePairFacade.tradePair$
      .subscribe({
        next: (pair: any) => {
          this.tradePair = pair;
          // console.log(pair, 'pair')
          const { currentPage, limit } = this.orderData
          let reqData = {
            page: currentPage,
            limit
          }
          if (pair && pair._id && this.isAuth) {
            this.fetchOrderHistory(reqData, pair._id);
            this.spotSocket.on('orderHistory', this.orderHistoryWs.bind(this))
          }
        },
        error: (err: any) => { }
      })
  }

  // ngOnDestroy() {
  //   this.spotSocket.off('orderHistory');
  // }

  fetchOrderHistory(reqData: any, pairId: string) {
    this.spotService.getOrderHistory(reqData, pairId).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.orderData = {
            currentPage: v.result.currentPage,
            nextPage: v.result.nextPage,
            limit: v.result.limit,
            count: v.result.count,
            data: [...v.result.data]
          }
        } else {
          this.orderData = {
            ...this.orderData,
            ...{ 'nextPage': false }
          }
        }
      },
      error: (err: any) => { }
    })
  }

  orderHistoryWs(result: any) {
    // console.log(result, 'result')
    // console.log(this.tradePair, 'this.tradePair')
    if (result.pairId == this.tradePair._id) {
      this.orderData = {
        // currentPage: result.currentPage,
        // nextPage: result.nextPage,
        // limit: result.limit,
        count: result.count,
        data: result.data
      }
    }
    // return () => {
    //   this.spotSocket.off('orderHistory');
    // }
    // console.log(result, 'result')
  }

}
