import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import isEmpty from 'src/app/lib/isEmpty';
import { WalletService } from 'src/app/services/wallet.service';

// Import facade
import { CurrencyFacade } from 'src/app/store/currency';
import { UserFacade } from 'src/app/store/user';

@Component({
  selector: 'app-deposit-fiat',
  templateUrl: './deposit-fiat.component.html',
  styleUrls: ['./deposit-fiat.component.css'],
})
export class DepositFiatComponent {
  form: FormGroup;
  serverErrors: any = {};
  submitted: boolean = false;
  loading: boolean = false;

  currencyList: any[] = [];
  user: any = {};
  selectedCurrency: any = "INR";
  selectedCoin: any = {};
  preview: string = '';

  constructor(
    private currencyFacade: CurrencyFacade,
    private userFacade: UserFacade,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: FormBuilder,
    private walletService: WalletService,
    private toastr: ToastrService
  ) {
    this.form = this._fb.group({
      amount: ['', Validators.required],
      image: ['', Validators.required],
      bankPaymentType: ['', Validators.required],
      transactionId: ['', Validators.required],
      twoFA: ['', Validators.required],
      verified: [false, Validators.requiredTrue],
      userAssetId: [''],
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      if (!isEmpty(params.get('coin')))
        this.selectedCurrency = params.get('coin');
    });
    this.fetchSelectedCurrency(this.selectedCurrency);

    this.userFacade.user$.subscribe({
      next: (user: any) => {
        this.user.twoFAStatus = user.twoFAStatus;
      },
      error: (err) => {
        // console.log(err, err);
      },
    });
  }

  fetchSelectedCurrency(currency?: string) {
    if (currency) {
      this.selectedCurrency = currency;
    } else {
      this.route.paramMap.subscribe((params) => {
        this.selectedCurrency = params.get('coin');
      });
    }
    // console.log(this.selectedCurrency, 'this.selectedCurrency')

    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        if (data) {
          if (data && data.length > 0 && isEmpty(this.selectedCurrency)) {
            const findFiat = data.find(
              (element: any) => element.type == 'fiat'
            );
            const uri = 'deposit-fiat/' + findFiat.coin;
            // this.router.navigateByUrl(uri);
          } else {
            this.currencyList = data.filter(
              (item: any) =>
                item.type === 'fiat' && item.depositStatus == 'active'
            );
            this.selectedCoin = this.currencyList.find(
              (el: any) => el.coin == this.selectedCurrency
            );
            // console.log(this.selectedCoin, 'this.selectedCoin')
            if (this.selectedCoin)
              this.form.patchValue({
                userAssetId: this.selectedCoin._id,
              });
          }
        }
      },
      error: (error) => {
        // console.log(error, 'error');
      },
    });
  }

  get amount() {
    return this.form.get('amount');
  }
  get image() {
    return this.form.get('image');
  }
  get bankPaymentType() {
    return this.form.get('bankPaymentType');
  }
  get transactionId() {
    return this.form.get('transactionId');
  }
  get twoFA() {
    return this.form.get('twoFA');
  }
  get verified() {
    return this.form.get('verified');
  }

  submitForm() {
    this.loading = true;
    this.submitted = true;
    if (!this.form.valid) {
      this.loading = false;
      return;
    }

    let formData = new FormData();
    formData.append('amount', this.form.value.amount);
    formData.append('image', this.form.value.image);
    formData.append('bankPaymentType', this.form.value.bankPaymentType);
    formData.append('transactionId', this.form.value.transactionId);
    formData.append('twoFACode', this.form.value.twoFA);
    formData.append('verified', this.form.value.verified);
    formData.append('userAssetId', this.form.value.userAssetId);

    this.walletService.apiFiatDepositRequest(formData).subscribe({
      next: (v: any) => {
        this.submitted = false;
        this.loading = false;
        if (v.success) {
          this.toastr.success(v.message);
          this.form.reset();
          this.serverErrors = {};
          this.form.patchValue({
            userAssetId: this.selectedCoin._id,
          });
          this.preview = ""
        }
      },
      error: (err) => {
        this.submitted = false;
        this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        } else if (err.error.errors) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        }
      },
    });
  }

  upload(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.form.patchValue({
      image: files[0],
    });

    const reader = new FileReader();
    reader.onload = () => {
      reader.result as string;
      this.preview = files[0].name;
      target.value = ""

    };
    reader.readAsDataURL(files[0]);

  }

  copyText(textToCopy: string) {
    navigator.clipboard.writeText(textToCopy);
    this.toastr.success('Copied...');
  }

  changeCurrency(event: any) {
    // console.log(event.target.value, 'event.target.value');
    // this.router.navigateByUrl('/deposit-fiat/' + event.target.value);
    this.fetchSelectedCurrency(event.target.value);
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
