import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, UserState } from './user.state';
import { setUser, reset } from './user.actions';

export const userFeatureKey = 'user';

export const userReducers: ActionReducer<UserState> = createReducer(
    initialState,
    on(setUser, (_, action) => {
        return { ...action.payload }
    }),
    on(reset, () => initialState),
);