<div class="baseblock_inner baseblock_inner_trade_history">
  <div class="baseblock_content">
    <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-recent-trades-tab" data-bs-toggle="pill"
          data-bs-target="#pills-recent-trades" type="button" role="tab" aria-controls="pills-recent-trades"
          aria-selected="true">
          Recent Trades
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <div class="dashbox_title_flex">
        </div>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-recent-trades" role="tabpanel"
        aria-labelledby="pills-recent-trades-tab" tabindex="0">
        <app-recent-trades></app-recent-trades>
      </div>
      <!-- <div class="tab-pane fade" id="pills-open-order" role="tabpanel" aria-labelledby="pills-open-order-tab"
        tabindex="0">
        <app-openorders></app-openorders>
      </div>
      <div class="tab-pane fade" id="pills-order-history" role="tabpanel" aria-labelledby="pills-order-history-tab"
        tabindex="0">
        <app-orderhistory></app-orderhistory>
      </div>
      <div class="tab-pane fade" id="pills-trade-history" role="tabpanel" aria-labelledby="pills-trade-history-tab"
        tabindex="0">
        <app-tradehistory></app-tradehistory>
      </div> -->
    </div>
  </div>
</div>