import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';

// import lib
import isEmpty from 'src/app/lib/isEmpty';

// Import service
import { UserService } from 'src/app/services/user.service';

// Import facade
import { UserFacade } from 'src/app/store/user';


interface ProfileDetails {
  kycStatus: string,
  kycCountry: string
}

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})

export class KycComponent {

  isEmpty = isEmpty

  profileDetails: ProfileDetails = {
    kycStatus: '',
    kycCountry: '',
  }

  country: any = ''
  addressOptions: any = []
  idOptions: any = []
  selectedIdType: any = {}
  selectedAddressType: any = {}

  form: FormGroup;

  selfieImg: any = ''
  addressProofFrontImg: any = ''
  addressProofBackImg: any = ''
  idProofFrontImg: any = ''
  idProofBackImg: any = ''

  submitted: boolean = false
  isLoading: boolean = false
  serverErrors: any = {}


  constructor(
    private route: ActivatedRoute,
    private service: UserService,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private userFacade: UserFacade,
    private router: Router
  ) {
    this.form = this._fb.group({
      selfieImg: ['', [Validators.required, RxwebValidators.fileSize({ maxSize: 102400 }),]],
      addressProofFrontImg: [''],
      addressProofBackImg: [''],
      idProofFrontImg: [''],
      idProofBackImg: [''],
      idType: ['', [Validators.required]],
      addressType: ['', [Validators.required]]
    });
  }

  get getSelfieImg() { return this.form.get('selfieImg') }
  get getIdType() { return this.form.get('idType') }
  get getAddressType() { return this.form.get('addressType') }
  get getAddressProofFrontImg() { return this.form.get('addressProofFrontImg') }
  get getAddressProofBackImg() { return this.form.get('addressProofBackImg') }
  get getIdProofFrontImg() { return this.form.get('idProofFrontImg') }
  get getIdProofBackImg() { return this.form.get('idProofBackImg') }

  ngOnInit() {
    this.getprofile()
    this.route.paramMap.subscribe(params => {
      this.country = params.get('country')
      this.getValidDocuments(this.country)
    });
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          if (data.kycStatus && !['new', 'rejected'].includes(data.kycStatus)) {
            this.router.navigateByUrl('/identification')
          }
          this.profileDetails.kycCountry = data.kycCountry
          this.profileDetails.kycStatus = data.kycStatus
        },
        error: (err: any) => { }
      })
  }

  getValidDocuments(id: any) {
    if (isEmpty(id)) {
      return
    }
    id = id.toUpperCase()
    this.service.getValidDocuments(id)
      .subscribe({
        next: ((data: any) => {
          const { success, result } = data
          if (success) {
            this.idOptions = result.idProof
            this.addressOptions = result.addressProof
          }
        }),
        error: (err => {
          // console.log(err, 'err')
        })
      })
  }

  handleSelectIdType(event: any) {
    const { name, value } = event;

    if (name == 'id') {
      this.form.patchValue({
        idProofFrontImg: '',
        idProofBackImg: ''
      });
      this.idProofFrontImg = ''
      this.idProofBackImg = ''

      const selectedId = this.idOptions.find((item: any) => item._id == value)
      this.selectedIdType = !isEmpty(selectedId) ? selectedId : {}

      if (!isEmpty(selectedId)) {
        this.getIdProofFrontImg?.setValidators([]);
        this.getIdProofBackImg?.setValidators([]);

        if (selectedId.front) this.getIdProofFrontImg?.setValidators(Validators.required)
        if (selectedId.back) this.getIdProofBackImg?.setValidators(Validators.required)

        this.getIdProofFrontImg?.updateValueAndValidity();
        this.getIdProofBackImg?.updateValueAndValidity();
      }
    } else if (name == 'address') {
      this.form.patchValue({
        addressProofFrontImg: '',
        addressProofBackImg: ''
      });
      this.addressProofFrontImg = ''
      this.addressProofBackImg = ''

      const selectedAddress = this.addressOptions.find((item: any) => item._id == value)
      this.selectedAddressType = !isEmpty(selectedAddress) ? selectedAddress : {}

      if (!isEmpty(selectedAddress)) {
        this.getAddressProofFrontImg?.setValidators([]);
        this.getAddressProofBackImg?.setValidators([]);

        if (selectedAddress.front) this.getAddressProofFrontImg?.setValidators(Validators.required)
        if (selectedAddress.back) this.getAddressProofBackImg?.setValidators(Validators.required)

        this.getAddressProofFrontImg?.updateValueAndValidity();
        this.getAddressProofBackImg?.updateValueAndValidity();
      }

    }
  }


  upload(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    let imgExtension = ['image/jpeg', 'image/jpg', 'image/png'];
    let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/pdf'];

    if ((target.name == 'selfieImg' && !imgExtension.includes(files[0].type)) || !allowedExtension.includes(files[0].type)) {
      this.toastr.error('Invalid Format')
      return
    }

    if (files[0].size > 1000000) {
      this.toastr.error('File size should not greater than 1MB')
      return
    }

    this.form.patchValue({
      [target.name]: files[0]
    })

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      reader.result as string;
      const preview = files[0].name;

      if (target.name == 'selfieImg')
        this.selfieImg = preview;
      else if (target.name == 'addressProofFrontImg')
        this.addressProofFrontImg = preview;
      else if (target.name == 'addressProofBackImg')
        this.addressProofBackImg = preview;
      else if (target.name == 'idProofFrontImg')
        this.idProofFrontImg = preview;
      else if (target.name == 'idProofBackImg')
        this.idProofBackImg = preview;
    }

    this.serverErrors = {}
  }

  submitForm() {
    // console.log(this.form.value, 'this.form.value')

    this.submitted = true;
    // console.log(this.form.controls['selfieImg'], 'this.form')

    if (!this.form.valid)
      return
    this.isLoading = true

    const formValue = this.form.value
    const formData = new FormData();

    formData.append('idProofFrontImg', formValue.idProofFrontImg);
    formData.append('idProofBackImg', formValue.idProofBackImg);
    formData.append('addressProofFrontImg', formValue.addressProofFrontImg);
    formData.append('addressProofBackImg', formValue.addressProofBackImg);
    formData.append('selfieImg', formValue.selfieImg);
    formData.append('countryCode', this.country.toUpperCase());
    formData.append('idType', formValue.idType);
    formData.append('addressType', formValue.addressType);

    this.service.submitKyc(formData)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false

          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)

            this.form.reset()
            this.serverErrors = {}

            this.selfieImg = ''
            this.addressProofFrontImg = ''
            this.addressProofBackImg = ''
            this.idProofFrontImg = ''
            this.idProofBackImg = ''

            this.getUser()

          }
        },
        error: (err: any) => {
          // console.log(err, 'err')
          this.submitted = false
          this.isLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })

  }

  getUser() {
    this.service.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: () => { }
      })
  }

}
