import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, UserSettingState } from './usersetting.state';
import { setUserSetting, reset, updateTheme, setSiteSetting } from './usersetting.actions';

export const userFeatureKey = 'usersetting';

export const userSettingReducers: ActionReducer<UserSettingState> = createReducer(
    initialState,
    on(setUserSetting, (state, action) => ({ ...state, userSetting: action.payload })),
    on(setSiteSetting, (state, action) => ({ ...state, siteSetting: action.payload })),
    on(updateTheme, (state, action) => ({
        ...state,
        userSetting: {
            ...state.userSetting,
            theme: action.payload.theme
        },
        defaultTheme: action.payload.theme
    })),
    on(reset, () => initialState),
);