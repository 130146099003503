import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// Import socket
import { spotSocket } from 'src/app/app.module';
import { AuthFacade } from 'src/app/store/auth';
import { TradeAssetFacade } from 'src/app/store/trade-asset';

@Component({
  selector: 'app-orderplace',
  templateUrl: './orderplace.component.html',
  styleUrls: ['./orderplace.component.css']
})
export class OrderplaceComponent {
  @Input('isMobileLayout') isMobileLayout: any = false;

  auth: any = {}
  isMobileView: boolean = false;

  isBuyVisible = true;
  isSellVisible = false;
  firstCurrency: any = {}
  secondCurrency: any = {}

  constructor(
    private spotSocket: spotSocket,
    private tradeAssetFacade: TradeAssetFacade,
    private authFacade: AuthFacade,
    private router: Router
  ) { }


  ngOnInit() {

    // window.onresize = () => this.isMobileView = window.innerWidth <= 1199;
    // window.onload = () => this.isMobileView = window.innerWidth <= 1199;

    // if (this.isMobileView == false) {
    //   console.log('mobile view closed')
    //   this.isBuyVisible = true;
    //   this.isSellVisible = true;
    // }

    // if (this.isMobileView == true) {
    //   this.isSellVisible = false;
    // }

    // console.log(this.isMobileView, 'isMobileView')
    this.authFacade.auth$.subscribe({
      next: (userAuth: any) => {
        this.auth = userAuth
      },
      error: (err: any) => { }
    });
    this.tradeAssetFacade.tradeAsset$.subscribe({
      next: ({ firstCurrency, secondCurrency }: any) => {
        this.firstCurrency = firstCurrency
        this.secondCurrency = secondCurrency
      },
      error: (err: any) => { }
    });
    this.spotSocket.on('updateTradeAsset', this.updateAssetWs.bind(this));
  }

  // ngOnDestroy() {
  //   this.spotSocket.off('updateTradeAsset');
  // }

  // showBuy() {
  //   this.isBuyVisible = true;
  //   this.isSellVisible = false;
  // }

  // showSell() {
  //   this.isBuyVisible = false;
  //   this.isSellVisible = true;
  // }

  navigateByUrl(url: string) {
    this.router.navigateByUrl(url)
  }

  updateAssetWs(result: any) {
    if (result.currencyId == this.firstCurrency.currencyId) {
      this.tradeAssetFacade.setFirstCurrency(result)
    } else if (result.currencyId == this.secondCurrency.currencyId) {
      this.tradeAssetFacade.setSecondCurrency(result)
    }
    // return () => {
    //   this.spotSocket.off('updateTradeAsset');
    // }
  }

}
