import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WalletService } from 'src/app/services/wallet.service';

// Import facade
import { CurrencyFacade } from 'src/app/store/currency';
import { UserFacade } from 'src/app/store/user';
import { WalletFacade } from 'src/app/store/wallet';

// Import lib
import { precentConvetPrice } from 'src/app/lib/calculation';
import isEmpty from 'src/app/lib/isEmpty';
import { toFixedDown, truncateDecimals } from 'src/app/lib/roundOf';
import { encryptObject } from 'src/app/lib/cryptoJs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-withdraw-fiat',
  templateUrl: './withdraw-fiat.component.html',
  styleUrls: ['./withdraw-fiat.component.css'],
})
export class WithdrawFiatComponent {
  toFixedDown = toFixedDown;
  truncateDecimals = truncateDecimals

  form: FormGroup;
  serverErrors: any = {};
  submitted: boolean = false;
  loading: boolean = false;

  currencyList: any[] = [];
  user: any = {};
  selectedCurrency: any = "INR";
  selectedCoin: any = {};
  preview: string = '';

  assetData: any = {};
  bankDetails: any = [];
  FiatTDS: any;
tds:number = 0;
  constructor(
    private currencyFacade: CurrencyFacade,
    private userFacade: UserFacade,
    private walletFacade: WalletFacade,
    private route: ActivatedRoute,
    private router: Router,
    private _fb: FormBuilder,
    private walletService: WalletService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    this.form = this._fb.group({
      amount: ['', Validators.required],
      bankId: ['', Validators.required],
      finalAmount: [''],
      twoFACode: ['', Validators.required],
      comments: [''],
      rate:[''],
      tds:['']
    });
  }

  ngOnInit() {
    this.getUserData();
    this.route.paramMap.subscribe((params: any) => {
      if (!isEmpty(params.get('coin')))
        this.selectedCurrency = params.get('coin');
    });
    this.fetchSelectedCurrency(this.selectedCurrency);
    this.getTDSMaster();
  }

  getUserData() {
    this.userFacade.user$.subscribe({
      next: (user: any) => {
        this.user.twoFAStatus = user.twoFAStatus;

        this.user.aadharNumber =
          user.kycStatus == 'approved'
            ? 'verified'
            : 'Not verified';
      },
      error: (err: any) => {
        // console.log(err, err)
      },
    });
  }

  getAssetData() {
    this.walletFacade.wallet$.subscribe({
      next: (assets: any) => {
        if (this.selectedCoin) {
          this.assetData = assets.find(
            (item: any) => item._id == this.selectedCoin._id
          );
          // console.log(this.assetData, 'this.assetData')
        }
      },
      error: (err: any) => {
        // console.log(err, err)
      },
    });
  }

  getbankdetails() {
    this.userService.getbankdetails().subscribe({
      next: (v: any) => {
        // this.bankDetails = v.result.bankDetails;
        this.bankDetails = v.result.bankDetails.filter(
          (element: any) => element.status == 'approved'
        );

      },
      error: (err) => { },
    });
  }

  fetchSelectedCurrency(currency?: string) {
    if (currency) {
      this.selectedCurrency = currency;
    } else {
      this.route.paramMap.subscribe((params: any) => {
        this.selectedCurrency = params.get('coin');
      });
    }
    this.getbankdetails();


    this.currencyFacade.currency$.subscribe({
      next: (data: any) => {
        if (data) {
          if (data && data.length > 0 && isEmpty(this.selectedCurrency)) {
            const findFiat = data.find(
              (element: any) => element.type == 'fiat'
            );
            const uri = 'withdraw-fiat/' + findFiat.coin;
            //this.router.navigateByUrl(uri);
          } else {
            this.currencyList = data.filter(
              (item: any) =>
                item.type === 'fiat' && item.withdrawStatus == 'active'
            );
            this.selectedCoin = this.currencyList.find(
              (el: any) => el.coin == this.selectedCurrency
            );
          }
        }
        this.getAssetData();
      },
      error: (error: any) => {
        // console.log(error, 'error')
      },
    });
  }

  get amount() {
    return this.form.get('amount');
  }
  get bankId() {
    return this.form.get('bankId');
  }
  get twoFACode() {
    return this.form.get('twoFACode');
  }
  get comments() {
    return this.form.get('comments');
  }

  submitForm() {
    if (this.selectedCoin && this.form.value.amount > this.selectedCoin.maximumWithdraw) {
      return;
    }
    this.submitted = true;
    this.loading = true;
    if (!this.form.valid) {
      this.loading = false;
      return;
    }

    const reqData = {
      currencyId: this.selectedCoin._id,
      coin: this.selectedCoin.coin,
      minimumWithdraw: this.selectedCoin.minimumWithdraw,
      // spotBal: this.assetData.spotBal,
      ...this.form.value,
      timeStamp: new Date().getTime(),
    };
    // console.log(reqData, 'reqData')
    let encryptToken = {
      token: encryptObject(reqData),
    };
    // console.log(encryptToken, 'encryptToken')

    this.walletService.apiWithdrawRequestFiat(encryptToken).subscribe({
      next: (v: any) => {
        this.submitted = false;
        this.loading = false;
        if (v.success) {
          this.toastr.success(v.message);
          this.form.reset();
          this.serverErrors = {};
        }
      },
      error: (err: any) => {
        this.submitted = false;
        this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        } else if (err.error.errors) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        }
      },
    });
  }

  changeCurrency(event: any) {
    // this.router.navigateByUrl('/withdraw-fiat/' + event.target.value);
    this.fetchSelectedCurrency(event.target.value);
    this.getAssetData();
    this.serverErrors = {}
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  handleAmountChange(event: any) {
    this.serverErrors = {}
    const amount = event.target.value;
    // let finalAmountBal =
    //   parseFloat(amount) -
    //   precentConvetPrice(amount, this.selectedCoin.withdrawFee);
    this.tds = precentConvetPrice(amount, this.FiatTDS?.rate);
   // let finalAmountBal =
   //   parseFloat(amount) - parseFloat(this.selectedCoin.withdrawFee);
      let finalAmountBal =  parseFloat(amount) - parseFloat(this.selectedCoin.withdrawFee) - this.tds ;
    this.form.patchValue({
      finalAmount:
        finalAmountBal && finalAmountBal > 0
          ? toFixedDown(finalAmountBal, 2)
          : '0.00',
          rate: this.FiatTDS?.rate,
          tds:  this.tds
    });
  }

  inputChange() {
    this.serverErrors = {}
  }
  getTDSMaster() {
    this.walletService.getTDSRateForFiatWithdraw()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.FiatTDS = v.result;
          }
        },
        error: err => { }
      })
  }

}
