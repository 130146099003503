import { Component } from '@angular/core';
import { getDisplayedPages } from 'src/app/lib/pagination';

// Import service
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css']
})
export class LoginHistoryComponent {

  loginHistory: any = []
  getDisplayedPages: number[] = []

  filter: any = {
    page: 1,
    limit: 5,
    totalPages: 0,
    paginator: []
  }

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.fetchLoginHistory(this.filter);
  }

  fetchLoginHistory(filter: any) {
    this.userService.getLoginHistory(filter).subscribe({
      next: (data: any) => {
        this.loginHistory = data.result
        // this.filter.page = data.count
        this.filter.totalPages = Math.ceil(data.count / this.filter.limit)
        // this.filter.paginator = Array(this.filter.totalPages).fill(1).map((x, y) => x + y)
        this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5)
      },
      error: () => { }
    })
  }

  nextPage() {
    this.filter.page += 1

    this.fetchLoginHistory(this.filter);
  }

  goToPage(pageNo: number) {
    this.filter.page = pageNo
    this.fetchLoginHistory(this.filter);
  }

  previousPage() {
    if (this.filter.page > 1)
      this.filter.page -= 1

    this.fetchLoginHistory(this.filter);
  }

  // getDisplayedPages(): number[] {
  //     const currentPage = this.filter.page;
  //     const totalPages = this.filter.totalPages;
  //     const displayRange = 5; // Number of page numbers to display at a time

  //     if (totalPages <= displayRange) {
  //       // If total pages are less than or equal to the display range, show all pages
  //       return Array.from({ length: totalPages }, (_, i) => i + 1);
  //     } else {
  //       const displayedPages = [];
  //       let startPage: number;
  //       let endPage: number;

  //       // Always show the first `displayRange` pages in the beginning
  //       if (currentPage <= Math.ceil(displayRange / 2)) {
  //         startPage = 1;
  //       } else if (currentPage >= totalPages - Math.floor(displayRange / 2)) {
  //         // Always show the last `displayRange` pages at the end
  //         startPage = totalPages - displayRange + 1;
  //       } else {
  //         // Show `displayRange` pages centered around the current page
  //         startPage = currentPage - Math.floor(displayRange / 2);
  //       }

  //       endPage = startPage + displayRange - 1;

  //       for (let i = startPage; i <= endPage; i++) {
  //         displayedPages.push(i);
  //       }

  //       return displayedPages;
  //     }
  //   }


}
