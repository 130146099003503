<div class="dashbox h-100">
  <h2 class="dashbox_title">Last Transaction</h2>

  <div class="table-responsive">
    <table class="table primary_table">
      <thead>
        <tr>
          <th>Date / Time</th>
          <th>Amount</th>
          <th>Type</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let txn of transactions">
          <td>{{ txn.createdAt | date : "medium" }}</td>
          <td>
            <span>{{ txn.actualAmount + " " + txn.coin }}</span>
          </td>
          <td>
            <span class="green_txt">{{
              txn.paymentType.replace("_", " ") | titlecase
            }}</span>
          </td>
          <td>{{txn.status | titlecase}}</td>
        </tr>
        <tr *ngIf="transactions && transactions.length == 0">
          <td colspan="4">
            <div class="text-center mt-3">
              <img
                src="assets/images/no_records_found.png"
                alt="No Records"
                img="img-fluid"
              />
              <p>No Records Found !</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <div class="last_trans_list">
    <div class="last_trans_list_row" *ngFor="let txn of transactions">
      <div>
        <h2>{{ txn.actualAmount + " " + txn.coin }}</h2>
        <h3 class="green_txt">
          {{ txn.paymentType.replace("_", " ") | titlecase }}
        </h3>
      </div>
      <h5>{{ txn.createdAt | date : "medium" }}</h5>
    </div>
    <div *ngIf="transactions && transactions.length == 0">
      <div class="text-center mt-3">
        <img
          src="assets/images/no_records_found.png"
          alt="No Records"
          img="img-fluid"
        />
        <p>No Records Found !</p>
      </div>
    </div>
  </div> -->
</div>
