import { Component, inject } from '@angular/core';
import { toFixed } from 'src/app/lib/roundOf';

// Import service
import { SpotService } from 'src/app/services/spot.service';
import { PairFacade } from '../../../store/pair';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getDisplayedPages } from 'src/app/lib/pagination';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent {
  private readonly pairFacade: PairFacade = inject(PairFacade);
  form: FormGroup;
  pairList: any = []
  toFixed = toFixed
  orderHistory: any = []
  filter: any = {
    page: 1,
    limit: 10,
    totalPages: 0,
    pairName: "all",
    orderType: "all",
    buyorsell: "all",
    status: "all"
  }
  // paginator: number[] = []
  getDisplayedPages: number[] = []

  constructor(private service: SpotService, private _fb: FormBuilder) {
    this.form = this._fb.group({
      pairName: "all",
      buyorsell: "all",
      status: "all",
      orderType: "all"

    });
  }

  ngOnInit() {
    this.fetchOrderHistory(this.filter);
    this.getPairList()
  }

  getPairList() {
    this.service.getPairList().subscribe({
      next: (v: any) => {
        if (v.success) {
          this.pairFacade.setPair(v.result);
          this.pairList = v.result;
        }
      },
      error: (err: any) => { },
    });
  }



  fetchOrderHistory(filter: any) {
    this.service.gethistoryOrderhistory(filter).subscribe({
      next: (data: any) => {
        this.orderHistory = data.result
        // this.filter.page = data.count
        this.filter.totalPages = Math.ceil(data.result.count / this.filter.limit)
        // this.paginator = Array(this.filter.totalPages).fill(1).map((x, y) => x + y)
        this.getDisplayedPages = getDisplayedPages(this.filter.page, this.filter.totalPages, 5)
      },
      error: () => { }
    })
  }

  nextPage() {
    this.filter.page += 1

    this.fetchOrderHistory(this.filter);
  }

  goToPage(pageNo: number) {
    this.filter.page = pageNo
    this.fetchOrderHistory(this.filter);
  }

  previousPage() {
    if (this.filter.page > 1)
      this.filter.page -= 1

    this.fetchOrderHistory(this.filter);
  }

  onOptionChange(event: any) {
    const name = event.target.id
    const filterValue = event.target.value
    this.filter[name] = filterValue

  }

  searchfilter() {
    this.filter.page = 1;
    this.fetchOrderHistory(this.filter);

  }

  clearfilter() {
    const initialfilter = {
      page: 1,
      limit: 10,
      totalPages: 0,
      pairName: "all",
      orderType: "all",
      buyorsell: "all",
      status: "all"
    }
    // this.filter.page = 1
    this.form.reset()
    this.form.patchValue({
      pairName: "all",
      buyorsell: "all",
      orderType: "all",
      status: "all"

    })
    this.fetchOrderHistory(initialfilter);
    this.filter = initialfilter
  }


}

