export function getDisplayedPages(currentPage: number, totalPages: number, displayRange: number) {
    if (totalPages <= displayRange) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
        const displayedPages = [];
        let startPage: number;
        let endPage: number;
        if (currentPage <= Math.ceil(displayRange / 2)) {
            startPage = 1;
        } else if (currentPage >= totalPages - Math.floor(displayRange / 2)) {
            startPage = totalPages - displayRange + 1;
        } else {
            startPage = currentPage - Math.floor(displayRange / 2);
        }
        endPage = startPage + displayRange - 1;
        for (let i = startPage; i <= endPage; i++) {
            displayedPages.push(i);
        }
        return displayedPages;
    }
}