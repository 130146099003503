<div class="dashbox verify_box cursor-pointer" (click)="redirect('bank-info')">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 312">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M360,156.56V40A40,40,0,0,0,320,0H40A40,40,0,0,0,0,40V224a40,40,0,0,0,40,40H240.24A83.92,83.92,0,1,0,360,156.56ZM48,40H314a8,8,0,0,1,0,16H48a8,8,0,0,1,0-16Zm56,168H48a8,8,0,0,1,0-16h56a8,8,0,0,1,0,16Zm0-32H48a8,8,0,0,1,0-16h56a8,8,0,0,1,0,16ZM48,88.47a8,8,0,0,1,0-16H314a8,8,0,0,1,0,16ZM44.94,123c-2.72,0-4.94-3.6-4.94-8s2.22-8,4.94-8h164.3c2.71,0,4.94,3.6,4.94,8s-2.23,8-4.94,8ZM316,296a68,68,0,1,1,68-68A68,68,0,0,1,316,296Z" />
        <path
          d="M346.32,202.32,304,244.72,285.68,226.4a8,8,0,1,0-11.28,11.28l24,24A7.87,7.87,0,0,0,304,264a8.19,8.19,0,0,0,5.68-2.32l48-48a8,8,0,0,0-11.36-11.36Z" />
      </g>
    </g>
  </svg>
  <h2>Bank Verification</h2>
  <h5 [ngClass]="{green_txt: bankStatus() === 'verified', red_txt: bankStatus() !== 'verified'}">
    {{ bankStatus() | titlecase }}
  </h5>
</div>