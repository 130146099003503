import { Component } from '@angular/core';

@Component({
  selector: 'app-market-inner',
  templateUrl: './market-inner.component.html',
  styleUrls: ['./market-inner.component.css']
})
export class MarketInnerComponent {

}
