import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Cookies } from 'src/app/lib/cookies';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        public cookies: Cookies,
        public router: Router,
        private service: AuthService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isLogin = this.cookies.isLogin()
        const type = next.data['type']

        if (type == 'auth' && isLogin == true) {
            this.router.navigateByUrl("/dashboard")
            return false
        } else if (type == 'private' && isLogin != true) {
            this.service.logoutApi()
            return false
        }
        $(".sidebar").removeClass("sidebar--active");
        return true
    }
}