import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setFirstCurrency, setSecondCurrency, reset } from './trade-asset.actions';
import { selectTradeAsset } from './trade-asset.selectors';
import { Observable } from 'rxjs';
import { TradeAssetState } from './trade-asset.state';

@Injectable({ providedIn: 'root' })
export class TradeAssetFacade {
    private readonly store: Store = inject(Store);

    readonly tradeAsset$: Observable<TradeAssetState> = this.store.select(selectTradeAsset);

    setFirstCurrency(payload: any): void {
        this.store.dispatch(setFirstCurrency({ payload }));
    }

    setSecondCurrency(payload: any): void {
        this.store.dispatch(setSecondCurrency({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}