<app-header-dashboard></app-header-dashboard>
<main class="main">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <form [formGroup]="form" (ngSubmit)="submitForm()">
                    <div class="dashbox">
                        <h2 class="dashbox_title">Profile Photo</h2>
                        <div class="security_panel">
                            <div class="security_panel_row">
                                <div class="sec-panel-left">
                                    <div class="security_panel_content">
                                        <h2>Upload your Photo</h2>
                                        <p>
                                            A profile pic is a photo that appears in your account, its
                                            own unique way of displaying profile photographs as an
                                            avatar next to an account name.
                                        </p>
                                    </div>
                                </div>
                                <div class="file_box">
                                    <div class="mb-0">
                                        <label class="form-label" for="formFile">
                                            <p>Upload a Selfie</p>
                                            <span *ngIf="selfieImg;else selfieuploadtxt" class="text-break">{{selfieImg | slice : 0 : 35}}</span>
                                        </label>
                                        <input type="file" class="form-control" name="selfieImg"
                                            (change)="upload($event)" />
                                        <div *ngIf="getSelfieImg?.invalid && (getSelfieImg?.touched || submitted)">
                                            <span class="required" *ngIf="getSelfieImg?.errors?.['required']">Selfie is
                                                required.</span>
                                        </div>
                                        <span class="required"
                                            *ngIf="serverErrors?.selfieImg">{{serverErrors.selfieImg}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashbox">
                        <div class="row">
                            <div class="col-md-6">
                                <h2 class="dashbox_title">Address Verification</h2>

                                <div class="sign_group sign_group_input mt-4">
                                    <label>Choose Document Type</label>
                                    <select class="sign_input" name="address" formControlName="addressType"
                                        (change)="handleSelectIdType($event.target)">
                                        <option value="">Select Type</option>
                                        <option *ngFor="let item of addressOptions" [value]="item._id">
                                            {{ item.docType }}
                                        </option>
                                    </select>
                                    <div *ngIf="getAddressType?.invalid && (getAddressType?.touched || submitted)">
                                        <span class="required" *ngIf="getAddressType?.errors?.['required']">Document
                                            Type is required</span>
                                    </div>
                                    <span class="required"
                                        *ngIf="serverErrors?.addressType">{{serverErrors.addressType}}</span>
                                </div>

                                <div class="mb-4 row">
                                    <div class="d-flex col-lg-6"
                                        *ngIf="selectedAddressType.front">
                                        <div class="file_box">
                                            <div class="mb-3">
                                                <label class="form-label" for="formFile">
                                                    <p>Upload Front Image</p>
                                                    <span class="text-break"
                                                        *ngIf="addressProofFrontImg;else uploadtxt">{{addressProofFrontImg | slice : 0 : 35}}</span>
                                                </label>
                                                <input type="file" class="form-control" name="addressProofFrontImg"
                                                    (change)="upload($event)" />
                                                <div
                                                    *ngIf="getAddressProofFrontImg?.invalid && (getAddressProofFrontImg?.touched || submitted)">
                                                    <span class="required"
                                                        *ngIf="getAddressProofFrontImg?.errors?.['required']">Front
                                                        Image is required.</span>
                                                </div>
                                                <span class="required"
                                                    *ngIf="serverErrors?.addressProofFrontImg">{{serverErrors.addressProofFrontImg}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex col-lg-6"
                                        *ngIf="selectedAddressType.back">
                                        <div class="file_box">
                                            <div class="mb-3">
                                                <label class="form-label" for="formFile">
                                                    <p>Upload Back Page</p>
                                                    <span class="text-break"
                                                        *ngIf="addressProofBackImg;else uploadtxt">{{addressProofBackImg  | slice : 0 : 35}}</span>
                                                </label>
                                                <input type="file" class="form-control" name="addressProofBackImg"
                                                    (change)="upload($event)" />
                                                <div
                                                    *ngIf="getAddressProofBackImg?.invalid && (getAddressProofBackImg?.touched || submitted)">
                                                    <span class="required"
                                                        *ngIf="getAddressProofBackImg?.errors?.['required']">Back Image
                                                        is required.</span>
                                                </div>
                                                <span class="required"
                                                    *ngIf="serverErrors?.addressProofBackImg">{{serverErrors.addressProofBackImg}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="deposit_btn mb-5 px-5">Submit Document</button>
                                <div class="d-none d-md-block">
                                    <p class="verify-sub-title">
                                        Note take a photo of your document and photo should be:
                                    </p>
                                    <ul class="verification-list">
                                        <li>Bright and clear.</li>
                                        <li>All corners of the document should be visible.</li>
                                        <li>Natural expression.</li>
                                        <li>Please do not use hats, masks, etc.</li>
                                        <li>
                                            Make sure your whole face is visible, centered, and your
                                            eyes are open.
                                        </li>
                                        <li>Take picture in a well-lit area.</li>
                                        <li>File size less than 1MB</li>
                                    </ul>
                                    <div class="mt-4 kyc_img">
                                        <div class="mb-4 row">
                                            <div class="d-flex col-md-4">
                                                <div class="p-2 img_box">
                                                    <img alt="" width="158" height="100" class="img-fluid"
                                                        src="../../../assets/images/kyc-img-1.png" />
                                                </div>
                                            </div>
                                            <div class="d-flex col-md-4">
                                                <div class="p-2 img_box">
                                                    <img alt="" width="158" height="100" class="img-fluid"
                                                        src="../../../assets/images/kyc-img-2.png" />
                                                </div>
                                            </div>
                                            <div class="d-flex col-md-4">
                                                <div class="p-2 img_box">
                                                    <img alt="" width="158" height="100" class="img-fluid"
                                                        src="../../../assets/images/kyc-img-3.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h2 class="dashbox_title">Identity Verification</h2>

                                <div class="sign_group sign_group_input mt-4">
                                    <label>Choose Document Type</label>
                                    <select class="sign_input" name="id" formControlName="idType"
                                        (change)="handleSelectIdType($event.target)">
                                        <option value="">Select Type</option>
                                        <option *ngFor="let item of idOptions" [value]="item._id">
                                            {{ item.docType }}
                                        </option>
                                    </select>
                                    <div *ngIf="getIdType?.invalid && (getIdType?.touched || submitted)">
                                        <span class="required" *ngIf="getIdType?.errors?.['required']">Document Type is
                                            required</span>
                                    </div>
                                    <span class="required" *ngIf="serverErrors?.idType">{{serverErrors.idType}}</span>
                                </div>

                                <div class="mb-4 row">
                                    <div class="d-flex col-lg-6"
                                        *ngIf="selectedIdType.front">
                                        <div class="file_box">
                                            <div class="mb-3">
                                                <label class="form-label" for="formFile">
                                                    <p>Upload Front Image</p>
                                                    <span class="text-break"
                                                        *ngIf="idProofFrontImg;else uploadtxt">{{idProofFrontImg | slice : 0 : 35}}</span>
                                                </label>
                                                <input type="file" class="form-control" name="idProofFrontImg"
                                                    (change)="upload($event)" />
                                                <div
                                                    *ngIf="getIdProofFrontImg?.invalid && (getIdProofFrontImg?.touched || submitted)">
                                                    <span class="required"
                                                        *ngIf="getIdProofFrontImg?.errors?.['required']">Front Image
                                                        is required.</span>
                                                </div>
                                                <span class="required"
                                                    *ngIf="serverErrors?.idProofFrontImg">{{serverErrors.idProofFrontImg}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex col-lg-6" *ngIf="selectedIdType.back">
                                        <div class="file_box">
                                            <div class="mb-3">
                                                <label class="form-label" for="formFile">
                                                    <p>Upload Back Image</p>
                                                    <span class="text-break"
                                                        *ngIf="idProofBackImg;else uploadtxt">{{idProofBackImg | slice : 0 : 35}}</span>
                                                </label>
                                                <input type="file" class="form-control" name="idProofBackImg"
                                                    (change)="upload($event)" />
                                                <div
                                                    *ngIf="getIdProofBackImg?.invalid && (getIdProofBackImg?.touched || submitted)">
                                                    <span class="required"
                                                        *ngIf="getIdProofBackImg?.errors?.['required']">Back Image is
                                                        required.</span>
                                                </div>
                                                <span class="required"
                                                    *ngIf="serverErrors?.idProofBackImg">{{serverErrors.idProofBackImg}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>

<ng-template #uploadtxt>
    <span>(Note: .jpg/.png/.pdf/.jpeg max 1MB)</span>
</ng-template>

<ng-template #selfieuploadtxt>
    <span>(Note: .jpg/.jpeg/.png max 1MB)</span>
</ng-template>