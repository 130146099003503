<div class="dashbox_title_flex">
  <h2 class="dashbox_title">Market</h2>
  <div class="primary_inp_grp">
    <input placeholder="Search Pair" type="text" class="primary_inp" (input)="handleSearchPair($event)" />
    <img src="assets/images/search_icon.png" class="img-fluid" alt="Search" />
  </div>
</div>
<ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation" *ngFor="let currency of currencies">
    <button class="nav-link" [ngClass]="{ active: currency === activeCurrency }" id="pills-{{ currency }}-tab"
      data-bs-toggle="pill" [attr.data-bs-target]="'#pills-' + currency + '-pair'" type="button" role="tab"
      [attr.aria-controls]="'#pills-' + currency + '-pair'" aria-selected="false">
      {{ currency }}
    </button>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade" [ngClass]="{ 'active show': currency === activeCurrency }"
    [id]="'pills-' + currency + '-pair'" role="tabpanel" [attr.aria-labelledby]="'pills-' + currency + '-pair-tab'"
    tabindex="0" *ngFor="let currency of currencies">
    <div class="table-responsive">
      <table class="table primary_table">
        <thead>
          <tr>
            <th>Pair</th>
            <th>Price</th>
            <th>24H Change</th>
            <th>24H High</th>
            <th>24H Low</th>
            <th>24H Volume</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let pair of pairs[currency]
                | textFilter : 'tikerRoot' : searchPair
            ">
            <td>
              <div class="d-flex align-items-center cursor-pointer">
                <a class="nav-link" [routerLink]="'/spot/'+pair.firstCurrencySymbol+'_'+pair.secondCurrencySymbol">
                  <img src="{{ getCurrecyImage(pair.firstCurrencySymbol) }}" alt="Crypto"
                    class="img-fluid history_table_icon" />
                  <span>{{ pair.tikerRoot }}</span>
                </a>
              </div>
            </td>
            <td>

              <span [title]="truncateDecimals(pair.markPrice, pair.secondFloatDigit)">
                {{longDecimals(truncateDecimals(pair.markPrice, pair.secondFloatDigit))}}
              </span>

            </td>
            <td>
              <span class="green_txt">
                {{ truncateDecimals(pair.change, 3) }}%
              </span>
            </td>
            <td>
              <span [title]="truncateDecimals(pair.high, pair.secondFloatDigit)">
                {{ longDecimals(truncateDecimals(pair.high, pair.secondFloatDigit)) }}
              </span>
            </td>
            <td>
              <span [title]="truncateDecimals(pair.low, pair.secondFloatDigit)">
                {{ longDecimals(truncateDecimals(pair.low, pair.secondFloatDigit)) }}
              </span>
            </td>
            <td>
              <span [title]="truncateDecimals(pair.firstVolume, pair.secondFloatDigit)">
                {{ longDecimals(truncateDecimals(pair.firstVolume, pair.secondFloatDigit)) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>