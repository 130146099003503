import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    private defaultTitle: string;

    constructor(private title: Title) {
        this.defaultTitle = this.title.getTitle();
    }

    setTitle(newTitle: string) {
        this.title.setTitle(newTitle);
    }

    resetTitle() {
        this.title.setTitle('Welcome to Cred Bull');
    }
}
