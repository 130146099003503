import { Component } from '@angular/core';
import { spotSocket } from 'src/app/app.module';
import { Cookies } from 'src/app/lib/cookies';
import { SpotService } from 'src/app/services/spot.service';
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-userorderlist',
  templateUrl: './userorderlist.component.html',
  styleUrls: ['./userorderlist.component.css']
})
export class UserorderlistComponent {

  tradePairId: string = ''
  openOrderCount: number = 0
  isAuth: boolean = false

  constructor(
    private tradePairFacade: TradePairFacade,
    private spotService: SpotService,
    private spotSocket: spotSocket,
    private cookies: Cookies
  ) {
    this.isAuth = this.cookies.isLogin()
  }

  ngOnInit() {
    this.tradePairFacade.tradePair$
      .subscribe({
        next: (pair: any) => {
          this.tradePairId = pair._id;
          let reqData = {
            page: 1,
            limit: 1
          }
          if (pair && pair._id && this.isAuth) {
            this.fetchOpenOrders(reqData, pair._id);
            this.spotSocket.on('openOrder', this.openOrderWs.bind(this))
          }
        },
        error: (err: any) => { }
      })
  }

  // ngOnDestroy() {
  //   this.spotSocket.off('openOrder');
  // }

  fetchOpenOrders(reqData: any, pairId: string) {
    this.spotService.getOpenOrder(reqData, pairId).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.openOrderCount = v.result.count
        }
      },
      error: (err: any) => { }
    })
  }

  openOrderWs(result: any) {
    if (result.pairId == this.tradePairId) {
      this.openOrderCount = result.count
    }
    // return () => {
    //   this.spotSocket.off('openOrder');
    // }
  }

}
