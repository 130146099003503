<div class="buysellcontrol_head">
    <h4>{{buyorsell == 'sell'? 'Sell': 'Buy'}} {{tradePair.firstCurrencySymbol}}</h4>
    <h4 *ngIf="auth.signedIn">
        <span>Balance:</span>
        <span class="trade_bal" [title]="buyorsell == 'sell' && !isEmpty(firstCurrency) && !isEmpty(tradePair) ?
        truncateDecimals(firstCurrency.spotBal, tradePair.firstFloatDigit)
        : buyorsell == 'buy' && !isEmpty(firstCurrency) && !isEmpty(tradePair) ?
        truncateDecimals(secondCurrency.spotBal, tradePair.secondFloatDigit) : 0">
            {{
            buyorsell == 'sell' && !isEmpty(firstCurrency) && !isEmpty(tradePair) ?
            longDecimals(truncateDecimals(firstCurrency.spotBal, tradePair.firstFloatDigit))
            :
            buyorsell == 'buy' && !isEmpty(firstCurrency) && !isEmpty(tradePair) ?
            longDecimals(truncateDecimals(secondCurrency.spotBal, tradePair.secondFloatDigit))
            :
            0
            }}
        </span>
        {{ buyorsell == 'sell'? tradePair.firstCurrencySymbol : tradePair.secondCurrencySymbol }}
    </h4>
</div>