<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Deposit</h2>
          <div class="row">
            <div class="col-xl-7 col-lg-6">
              <form action="#" class="auth_form">
                <div class="sign_group sign_group_input">
                  <label>Choose Cryptocurrency</label>
                  <div
                    class="deposit_crypto_inp"
                    data-bs-toggle="modal"
                    data-bs-target="#ChooseCrypto"
                  >
                    <div *ngIf="selectedCurrency?.image">
                      <img
                        src="{{ selectedCurrency?.image }}"
                        class="img-fluid"
                        alt="QR"
                      />
                      <h4>
                        {{ selectedCurrency?.coin }}
                        <span>{{ selectedCurrency?.name }}</span>
                      </h4>
                    </div>
                    <img
                      src="assets/images/select_dropdown.png"
                      class="img-fluid choose_crypto_select"
                      alt="QR"
                    />
                  </div>
                </div>
                <div
                  *ngIf="selectedCurrency?.type == 'token'"
                  class="sign_group sign_group_input"
                >
                  <label>Choose Network</label>
                  <select
                    class="sign_input"
                    (change)="tokenNetworkChange($event)"
                  >
                    <!-- <option value="" selected>Select Network</option> -->
                    <option
                      value="{{ item.tokenType }}"
                      *ngFor="let item of networkType"
                    >
                      {{ item.tokenType | uppercase }}
                    </option>
                  </select>
                </div>
                <div class="sign_group sign_group_input wallet_addr">
                  <label>Wallet Address</label>
                  <div class="primary_inp_grp">
                    <!-- {{tokenData?.tokenType}}
                    {{tokenData?.address}} -->
                    <input
                      type="text"
                      class="sign_input pe-2"
                      [value]="
                        selectedCurrency?.type == 'crypto'
                          ? selectedAsset?.address
                          : tokenData?.address
                      "
                      disabled
                    />
                    <div class="bgcolor">
                      <img
                        src="assets/images/qr_icon.png"
                        class="img-fluid qr_icon"
                        alt="QR"
                        data-bs-toggle="modal"
                        data-bs-target="#qr_modal"
                      />
                      <img
                        src="assets/images/copy_icon.png"
                        class="img-fluid copy_icon"
                        alt="Copy"
                        (click)="
                          CopyText(
                            selectedCurrency?.type == 'crypto'
                              ? selectedAsset?.address
                              : tokenData?.address
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div class="deposit_info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 23.63 23.63"
                >
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Capa_1" data-name="Capa 1">
                      <path
                        class="cls-1"
                        d="M11.81,0A11.81,11.81,0,1,0,23.62,11.81,11.81,11.81,0,0,0,11.81,0Zm2.46,18.31c-.61.24-1.09.42-1.45.54a3.81,3.81,0,0,1-1.27.19,2.49,2.49,0,0,1-1.71-.54,1.71,1.71,0,0,1-.61-1.36,4.87,4.87,0,0,1,0-.66,6.75,6.75,0,0,1,.15-.76L10.18,13c.07-.26.12-.5.17-.73a3,3,0,0,0,.07-.63,1,1,0,0,0-.21-.72,1.27,1.27,0,0,0-.82-.2,2.18,2.18,0,0,0-.6.09,4.61,4.61,0,0,0-.53.18l.2-.83c.5-.21,1-.38,1.43-.52a4.16,4.16,0,0,1,1.29-.22,2.46,2.46,0,0,1,1.69.53,1.74,1.74,0,0,1,.6,1.37c0,.12,0,.33,0,.62a3.75,3.75,0,0,1-.16.81l-.75,2.68a5.83,5.83,0,0,0-.17.74,3.61,3.61,0,0,0-.07.62.9.9,0,0,0,.24.73,1.29,1.29,0,0,0,.82.2,2.63,2.63,0,0,0,.63-.1,2.79,2.79,0,0,0,.5-.17ZM14.14,7.43a1.92,1.92,0,0,1-2.56,0,1.61,1.61,0,0,1,0-2.39,1.84,1.84,0,0,1,1.28-.5,1.81,1.81,0,0,1,1.28.5,1.61,1.61,0,0,1,0,2.39Z"
                      />
                    </g>
                  </g>
                </svg>
                <p class="mb-0">
                  Send only <span>{{ selectedAsset?.coin }}</span> to this
                  deposit address. Sending any other coin or token to this
                  address may result in the loss of your deposit.
                </p>
              </div>
            </div>
            <div class="col-xl-5 col-lg-6 order-lg-last order-first">
              <div class="dashbox">
                <div class="deposit_bal_div">
                  <div>
                    <label>Available Balance:</label>
                    <h2>
                      {{ toFixedDown(selectedAsset?.spotBal, selectedCurrency?.decimals) }}
                      {{ selectedAsset?.coin }}
                    </h2>
                  </div>
                  <!-- <div>
                            <label>Trade Balance:</label>
                            <h2>0.000 BTC</h2>
                        </div>
                        <hr class="primary_hr">
                        <div>
                            <label>In Orders:</label>
                            <h4>0.000 BTC</h4>
                        </div> -->
                  <div>
                    <label>Minimum Deposit:</label>
                    <h4>
                      {{ toFixedDown(selectedCurrency?.minimumDeposit, selectedCurrency?.decimals) }}
                      {{ selectedAsset?.coin }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Choose Crypto Modal -->
  <div
    class="modal fade primary_modal"
    id="ChooseCrypto"
    tabindex="-1"
    aria-labelledby="ChooseCryptoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="ChooseCryptoLabel">
            Select a Cryptocurrency
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="curmodalclose"
          ></button>
        </div>
        <div class="modal-body">
          <div class="token_search_list">
            <div class="token_search_list_row" *ngFor="let item of orgCurr;">
              <div (click)="selectDropdown(item.coin)">
                <img src="{{ item?.image }}" alt="{{ item.coin }}" />
                <div>
                  <span class="token_search_list_cur_name">{{
                    item?.coin
                  }}</span>
                  <span class="token_search_list_cur_value">{{
                    item?.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- QR Code Modal -->
  <div
    class="modal fade primary_modal"
    id="qr_modal"
    tabindex="-1"
    aria-labelledby="qr_modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="qr_modalLabel">Scan QR Code</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <div class="qr_code_img">
            <!-- <img src="assets/images/qr_img.png" alt="QR" class="img-fluid" /> -->

            <qrcode
              class="img-fluid"
              [qrdata]="
                selectedCurrency?.type == 'crypto'
                  ? selectedAsset?.address
                  : selectedAsset?.tokenAddressArray &&
                    selectedAsset.tokenAddressArray.length > 0 &&
                    selectedAsset.tokenAddressArray[0].address
              "
              [width]="150"
              [errorCorrectionLevel]="'M'"
            ></qrcode>

            <p>
              Scan QR code to support mobile phone, do not enter the address of
              the extension
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
