import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { AuthService } from 'src/app/services/auth.service'
import { WalletService } from 'src/app/services/wallet.service'

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserFacade } from '../../store/user';
interface Country {
  shortName: string;
  name: string;
}

interface CurrencyOptions {
  _id: string,
  status: string
  coin: string,
  type: string
}


@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.css']
})
export class AddBankComponent {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;
  countries: Country[];
  currencyOption: CurrencyOptions[] = []
  preview: string = '';
  imgreq: string = "";
  NAB: any;


  // currencyOption: any[] = []

  private readonly userFacade: UserFacade = inject(UserFacade);
  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private AuthService: AuthService,
    private WalletService: WalletService,
    private router: Router,
    private _fb: FormBuilder,

  ) {
    this.countries = this.service.getCountries();
    let a = this.WalletService.getCurrency()

    this.form = this._fb.group({
      accountNo: ['', [Validators.required]],
      bankAddress: ['', [Validators.required]],
      bankName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      bankcode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      currencyId: ['', [Validators.required]],
      // holderName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      holderName: ['', Validators.required],
      accountType: ['', Validators.required],
      branchName: ['', Validators.required],
      image: [],
    });
  }

  get accountNo() { return this.form.get('accountNo') }
  get bankAddress() { return this.form.get('bankAddress') }
  get bankName() { return this.form.get('bankName') }
  get bankcode() { return this.form.get('bankcode') }
  get country() { return this.form.get('country') }
  get city() { return this.form.get('city') }
  get currencyId() { return this.form.get('currencyId') }
  get holderName() { return this.form.get('holderName') }
  get accountType() { return this.form.get('accountType') }
  get branchName() { return this.form.get('branchName') }
  get image() {
    return this.form.get('image');
  }
  async ngOnInit() {
    this.getCurrency()
   this.getprofile();
  }

  getCurrency() {
    this.WalletService.getCurrency()
      .subscribe({
        next: (v: any) => {
          let curr = v.result

          this.currencyOption = curr

        },
        error: err => { }
      })

  }

  onInputChange() {
    this.serverErrors = {}
  }

  upload(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.form.patchValue({
      image: files[0],
    });
    const reader = new FileReader();
    reader.onload = () => {
      reader.result as string;
      this.preview = files[0].name;
    };
    reader.readAsDataURL(files[0]);
  }

  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true

    this.form.patchValue({ holderName: this.NAB });
    let formData = new FormData();
    formData.append('currencyId', this.form.value.currencyId);
    formData.append('image', this.form.value.image);
    formData.append('bankName', this.form.value.bankName);
    formData.append('accountType', this.form.value.accountType);
    formData.append('branchName', this.form.value.branchName);
    formData.append('accountNo', this.form.value.accountNo);
    formData.append('holderName', this.form.value.holderName);
    formData.append('bankcode', this.form.value.bankcode);
    formData.append('country', this.form.value.country);
    formData.append('city', this.form.value.city);
    formData.append('bankAddress', this.form.value.bankAddress);
    formData.append('imgreq', this.imgreq);



    this.service.addbank(formData)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false
          if (v.success) {
            this.submitted = false
            this.imgreq = ""
            this.toastr.success(v.message)
            // this.getUser()

            this.router.navigateByUrl("/bank-info")
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false

          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            
            if (err?.error?.misdata == 'mismatch') {
              this.imgreq = "required";

            }
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }
  getprofile() {
    this.userFacade.user$.subscribe({
      next: (data: any) => {
     this.NAB = data.name_at_bank;
      },
      error: (err: any) => { },
      complete: () => { },
    });
  }
}
