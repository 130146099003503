// Import package
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

// Import lib
import { decryptString } from "src/app/lib/cryptoJs";
import { getDisplayedPages } from "src/app/lib/pagination";
import { toFixedDown, toFixed, convert } from "src/app/lib/roundOf";

// Import service
import { LaunchService } from "src/app/services/launchpad.service";

// Import env
import { environment } from "src/environments/environment";

// Import facade
import { CurrencyFacade } from "src/app/store/currency";
import { PriceCnvFacade } from "src/app/store/pricecnv";
import { WalletFacade } from "src/app/store/wallet";

@Component({
  selector: "app-launchpad-details",
  templateUrl: "./launchpad-details.component.html",
  styleUrls: ["./launchpad-details.component.css"],
})
export class LaunchpadDetailsComponent implements AfterViewInit {

  toFixedDown = toFixedDown
  convert = convert
  toFixed = toFixed

  date: any;
  total: any;
  getDisplayedPages: number[] = [];
  amount: string = '';
  coin: any;
  currencyId: any;
  coinBalance: any;
  paramsId: string = "";
  wallet: any;
  priceconversion: any = {};
  now: any;
  status: string = "Upcoming";
  launchSingle: any;
  commitData: any = [];
  launchShow: string = "processing";
  totalCommit: any = [];
  convertprice: any;
  currencyOption: any = [];
  priceConvertTotal: any;
  totalCoin: any;
  serverErrors: any = {};
  targetDate: any = new Date(); // new Date(2023, 6, 11);
  targetTime: any; // this.targetDate.getTime();
  difference!: number;
  launchDate: any;
  url: any = environment.walletApiUrl + "currency/";
  launchUrl: any = environment.launchpadApiUrl + "launchpad/";
  isLoading: boolean = false;
  modalOpen: boolean = false;

  activityFilter: any = {
    page: 1,
    limit: 5,
    totalPages: 0,
  };

  months: Array<string> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(
    private route: ActivatedRoute,
    private LaunchService: LaunchService,
    private toastr: ToastrService,
    private currencyFacade: CurrencyFacade,
    private priceCnvFacade: PriceCnvFacade,
    private walletFacade: WalletFacade,
    private router: Router
  ) { }

  currentTime: any = `${this.months[this.targetDate.getMonth()]
    } ${this.targetDate.getDate()}, ${this.targetDate.getFullYear()}`;

  @ViewChild("days", { static: true })
  days!: ElementRef;
  @ViewChild("hours", { static: true })
  hours!: ElementRef;
  @ViewChild("minutes", { static: true })
  minutes!: ElementRef;
  @ViewChild("seconds", { static: true })
  seconds!: ElementRef;

  ngOnInit() {
    let id = this.route.snapshot.params["id"];
    id = decryptString(id, true);
    this.paramsId = id;
    this.getSingleLaunch(id);
    this.getCommitData(id, this.activityFilter);
    // $('#ChosseBuy').on('shown.bs.modal', function (e) { })
  }

  ngAfterViewInit() {
    setInterval(() => {
      this.tickTock();
      this.difference = this.targetTime - this.now;
      this.difference = this.difference / (1000 * 60 * 60 * 24);

      !isNaN(this.days.nativeElement.innerText)
        ? (this.days.nativeElement.innerText = Math.floor(this.difference))
        : (this.days.nativeElement.innerHTML = `<i class="fas fa-spinner fa-spin"></i>`);
    }, 1000);
  }


  getSingleLaunch(id: any) {
    this.LaunchService.getLaunchById(id).subscribe({
      next: (v: any) => {
        if (v.success) {
          if (v.message === 'NO_DATA') {
            this.router.navigateByUrl("/launchpad")
            return
          }
          this.launchSingle = v.result;
          this.makeAPICalls();
          this.totalCommit = v.commitData;
          if (
            v?.result?.eventStart &&
            new Date(v.result.eventStart) >= new Date()
          ) {
            this.status = "Upcoming";
          } else if (
            v?.result?.eventStart &&
            new Date(v.result.eventStart) <= new Date() &&
            v?.result?.eventEnd &&
            new Date(v.result.eventEnd) >= new Date()
            // new Date(v.result.startTimeStamp) == new Date()
          ) {
            this.status = "Live";
            this.launchShow = "show";
            let eventDate = new Date(v.result.eventEnd);
            this.launchDate = new Date(v.result.eventEnd);
            this.targetDate = eventDate;
            this.targetTime = eventDate.getTime();
          } else if (
            v?.result?.eventEnd &&
            new Date(v.result.eventEnd) <= new Date()
          ) {
            this.status = "Completed";
          }
        }
      },
      error: (err) => { },
    });
  }

  makeAPICalls() {
    this.currencyFacade.currency$.subscribe({
      next: (currencyData: any) => {
        if (this.launchSingle?.availableCoin && currencyData.length > 0) {
          const commonObj = currencyData.filter((item1: any) =>
            this.launchSingle.availableCoin.some(
              (item2: any) => item2.toString() === item1._id.toString()
            )
          );
          this.currencyOption = commonObj;
          this.totalCoin = currencyData;
          this.coin = commonObj[0].coin;
          this.currencyId = commonObj[0]._id;
          this.priceCnvFacade.pricecnv$.subscribe({
            next: (priceconversionData: any) => {
              let priceData = priceconversionData.find(
                (item: any) =>
                  item.convertSymbol === commonObj[0].coin &&
                  item.baseSymbol === this.launchSingle?.coinName
              );
              if (priceData) {
                this.convertprice = priceData.convertPrice
              } else {
                let usdtPrc = priceconversionData.find(
                  (item: any) =>
                    item.convertSymbol === 'USDT' &&
                    item.baseSymbol === this.launchSingle?.coinName
                );
                const coinprice = usdtPrc.convertPrice;
                let selUsdtPrice = priceconversionData.find(
                  (item: any) =>
                    item.convertSymbol === 'USDT' &&
                    item.baseSymbol === commonObj[0].coin
                );
                this.convertprice = coinprice / selUsdtPrice.convertPrice;
              }

              // if (!isEmpty(priceData)) {
              this.priceconversion = priceData;
              this.priceConvertTotal = priceconversionData;
              // this.total = priceData.convertPrice;
              this.total = 0;
              this.walletFacade.wallet$.subscribe({
                next: (walletData: any) => {
                  this.wallet = walletData;
                  let singleWallet = walletData.find(
                    (item: any) =>
                      item._id.toString() === commonObj[0]._id.toString()
                  );
                  this.coinBalance = singleWallet.spotBal;
                },
                error: (err) => {
                  // console.log(err)
                },
              });
              // }
            },
            error: (err) => {
              // console.log(err)
            },
          });
        }
      },
      error: (err) => {
        // console.log(err)
      },
    });
  }

  onSelectChange(event: any) {
    const selectedValue = event.target.value;
    const selectedCoin = this.totalCoin.find(
      (option: any) => option._id === selectedValue
    )?.coin;
    this.currencyId = selectedValue;
    if (selectedCoin) {
      let priceData = this?.priceConvertTotal.find(
        (item: any) =>
          item.convertSymbol === selectedCoin &&
          item.baseSymbol === this.launchSingle?.coinName
      );

      if (priceData) {
        this.convertprice = priceData.convertPrice
      } else {
        let usdtPrc = this?.priceConvertTotal.find(
          (item: any) =>
            item.convertSymbol === 'USDT' && item.baseSymbol === this.launchSingle?.coinName
        );
        const coinprice = usdtPrc.convertPrice;
        let selUsdtPrice = this?.priceConvertTotal.find(
          (item: any) =>
            item.convertSymbol === 'USDT' && item.baseSymbol === selectedCoin
        );
        this.convertprice = coinprice / selUsdtPrice.convertPrice;
      }

      // this.total = priceData.convertPrice;
      this.amount = ''
      this.total = 0
      this.coin = selectedCoin;
      this.priceconversion = priceData;

      let singleWallet = this.wallet.find(
        (item: any) => item._id.toString() === selectedValue.toString()
      );
      this.coinBalance = singleWallet.spotBal;
    }
    this.serverErrors = {};
  }

  onInputChange(event: any) {
    const selectedValue = event.target.value;
    this.total = toFixedDown(selectedValue * this.convertprice, 8);
    this.amount = selectedValue;
    this.serverErrors = {};
  }

  submitData() {
    if (!this.amount || this.amount == '') {
      // this.serverErrors = { amount: 'Kindly Enter Amount' } || {};
      this.toastr.error("Kindly Enter Amount");
      return;
    }
    if (parseFloat(this.total) > parseFloat(this.coinBalance)) {
      // this.serverErrors = { amount: 'Kindly Select Less than Balance' } || {};
      this.amount = '';
      this.total = 0
      this.toastr.error("Insufficient Balance");
      return;
    }
    let data = {
      id: this.launchSingle._id,
      amount: parseFloat(this.amount),
      total: parseFloat(this.total),
      coin: this.coin,
      convertPrice: parseFloat(this.convertprice), // this.priceconversion,
      // coinBalance: this.coinBalance,
      currencyId: this.currencyId,
    };
    this.isLoading = true;
    this.LaunchService.commitAdd(data).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.isLoading = false;
          this.toastr.success(v.message);
          // this.getSingleLaunch(this.paramsId);
          this.getCommitData(this.launchSingle._id, this.activityFilter);
          this.amount = '';
          this.total = 0
        }
      },
      error: (err) => {
        this.isLoading = false;
        const { status, message, error } = err;
        this.toastr.error(error.message);
      },
    });
  }

  getCommitData(id: any, activityData: any) {
    this.LaunchService.getCommit(id, activityData).subscribe({
      next: (v: any) => {
        if (v.success) {
          this.commitData = v.result;
          this.activityFilter.totalPages = Math.ceil(
            v.count / this.activityFilter.limit
          );
          this.getDisplayedPages = getDisplayedPages(
            this.activityFilter.page,
            this.activityFilter.totalPages,
            5
          );
        }
      },
      error: (err) => { },
    });
  }

  msToTime(duration: number) {
    let milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    let hoursConvert = hours < 10 ? "0" + hours : hours;
    let minutesConvert = minutes < 10 ? "0" + minutes : minutes;
    let secondsConvert = seconds < 10 ? "0" + seconds : seconds;
    let data = {
      hours: hoursConvert,
      minutes: minutesConvert,
      seconds: secondsConvert,
      milliseconds: milliseconds,
    };
    return data;
  }

  tickTock() {
    let newDate = new Date();
    newDate.setMinutes(newDate.getMinutes());
    this.date = newDate;
    this.now = this.date.getTime();
    let calDate = new Date(this.launchDate).getTime() - new Date().getTime();
    let msTime: any = this.msToTime(calDate);
    this.days.nativeElement.innerText = Math.floor(this.difference);
    this.hours.nativeElement.innerText = msTime.hours;
    this.minutes.nativeElement.innerText = msTime.minutes;
    this.seconds.nativeElement.innerText = msTime.seconds;
    if (
      parseInt(msTime.hours) <= 0 &&
      parseInt(msTime.minutes) <= 0 &&
      parseInt(msTime.seconds) <= 0
    ) {
      this.status = "Completed";
      this.launchShow = "completed";
    }
  }

  nextPageAct() {
    this.activityFilter.page += 1;
    this.getCommitData(this.paramsId, this.activityFilter);
  }

  previousPageAct() {
    if (this.activityFilter.page > 1) this.activityFilter.page -= 1;
    this.getCommitData(this.paramsId, this.activityFilter);
  }

  goToPageAct(pageNo: number) {
    this.activityFilter.page = pageNo;
    this.getCommitData(this.paramsId, this.activityFilter);
  }

  scrollToDiv(target: HTMLElement) {
    target.scrollIntoView();
  }

  moreZero(value: number) {
    return value > 0 ? value : 0;
  }


}
