import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setPair, reset } from './pair.actions';
import { seletPair } from './pair.selectors';
import { Observable } from 'rxjs';
import { PairState } from './pair.state';

@Injectable({ providedIn: 'root' })
export class PairFacade {
    private readonly store: Store = inject(Store);

    readonly pair$: Observable<PairState> = this.store.select(seletPair);

    setPair(payload: any): void {
        this.store.dispatch(setPair({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}