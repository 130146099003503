import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, PairState } from './pair.state';
import { setPair, reset } from './pair.actions';

export const pairFeatureKey = 'pair';

export const pairReducers: ActionReducer<PairState> = createReducer(
    initialState,
    on(setPair, (_, action) => action.payload),
    on(reset, () => initialState)
);