<div class="dashbox verify_box cursor-pointer" (click)="redirect('security')">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
        <defs>
            <style>
                .cls-1 {
                    fill-rule: evenodd;
                }
            </style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path class="cls-1"
                    d="M240,300.13a58.5,58.5,0,0,0,47.33-57.21V201.69L240,181.41l-47.34,20.28v41.23A58.52,58.52,0,0,0,240,300.13Zm-31.69-67.72a8.92,8.92,0,0,1,12.61,0l13.44,13.43,29.23-29.52a8.9,8.9,0,0,1,12.63,12.54c-11.85,12-23.63,24-35.54,35.92a8.92,8.92,0,0,1-12.61,0L208.31,245a8.92,8.92,0,0,1,0-12.61ZM240,330.75c43.68-7.62,76-42.42,76-87.51V181.81l-76-32.56-76,32.56v61.43C164,288.33,196.32,323.13,240,330.75ZM176.67,191.14,240,164l63.33,27.14v51.78a74.52,74.52,0,0,1-62,73.23l-1.37.24-1.37-.24a74.52,74.52,0,0,1-62-73.23V191.14ZM58.22,237.35V60.46H300l-6.22-4.86A8,8,0,1,1,303.63,43L328,62.09a8,8,0,0,1,.12,12.65L303.63,93.88a8,8,0,1,1-9.81-12.56L300,76.46H74.22V237.35a8,8,0,0,1-16,0Zm375.27,22V425.64H203.38l6.22,4.86a8,8,0,1,1-9.81,12.56l-24.51-19.14a8,8,0,0,1,.12-12.66l24.39-19a8,8,0,1,1,9.81,12.56l-6.22,4.86H417.49V259.39a8,8,0,0,1,16,0ZM123.84,371.21H8.6a8.61,8.61,0,0,0-8.6,8.6V471.4A8.61,8.61,0,0,0,8.6,480H123.84a8.61,8.61,0,0,0,8.6-8.6V379.81A8.61,8.61,0,0,0,123.84,371.21ZM76.21,429.49V447a10,10,0,1,1-20,0v-17.5a19.05,19.05,0,1,1,20,0ZM40.7,355.21H19.16V344.42a47.06,47.06,0,1,1,94.12,0v10.79H91.74V344.42a25.52,25.52,0,1,0-51,0ZM401.61,11.89,397.45,0h56.09l-4.17,11.89ZM471.37,0h-.88l-9.78,27.89H390.27L380.49,0h-.88A8.66,8.66,0,0,0,371,8.63V190.75a8.67,8.67,0,0,0,8.63,8.64h91.76a8.67,8.67,0,0,0,8.63-8.64V8.63A8.66,8.66,0,0,0,471.37,0Zm-38.3,185.16H417.91a5.67,5.67,0,0,1,0-11.34h15.16a5.67,5.67,0,1,1,0,11.34Z" />
            </g>
        </g>
    </svg>
    <h2>2F Authentication</h2>
    <!-- <h5>{{user.twoFAStatus | titlecase}}</h5> -->
    <h5 [ngClass]="{green_txt: user.twoFAStatus !== 'enabled', red_txt: user.twoFAStatus === 'disabled'}">
        {{ user.twoFAStatus | titlecase }}
    </h5>
</div>