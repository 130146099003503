<div class="modal buycrypto_modal" [style]="{ display: showModal ? 'block' : 'none' }" [ngClass]="{dark: themeData == 'dark'}">
    <div class=" modal-dialog modal-dialog-centered p-0">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h1 class="modal-title fs-5" id="onramperModalLabel">
                    Email OTP Verification
                </h1> -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()"></button>
            </div>
            {{onramperURL}}
            <div class="modal-body">
                <iframe [src]="urlSafe" height="540px" width="100%" title="Onramper widget"
                    allow="accelerometer; autoplay; camera; gyroscope; payment">
                </iframe>
            </div>
        </div>
    </div>
</div>