import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

// Import facade
import { MarketPriceFacade } from '../store/marketprice';
import { TradePairFacade, TradePairState } from '../store/tradepair';

@Injectable({
  providedIn: 'root'
})
export class SpotService {

  constructor(
    private http: HttpClient,
    private marketPriceFacade: MarketPriceFacade,
    private tradePairFacade: TradePairFacade
  ) { }

  getPairList() {
    return this.http.get(environment.spotApiUrl + 'api/spot/tradePair')
  }

  getMarketPrice(pairId: string) {
    this.http.get(environment.spotApiUrl + 'api/spot/marketPrice/' + pairId).subscribe({
      next: (v: any) => {
        if (v.success)
          this.marketPriceFacade.setSpotMarketPrice(v.result)
      },
      error: (err) => { }
    })
  }

  setTradePair({
    firstCurrencySymbol,
    firstCurrencyId,
    firstFloatDigit,
    secondCurrencySymbol,
    secondCurrencyId,
    secondFloatDigit,
    botstatus,
    _id
  }: TradePairState) {
    this.tradePairFacade.setTradePair(
      {
        firstCurrencySymbol,
        firstCurrencyId,
        firstFloatDigit,
        secondCurrencySymbol,
        secondCurrencyId,
        secondFloatDigit,
        botstatus,
        _id
      })
  }

  orderPlace(data: any) {
    return this.http.post(environment.spotApiUrl + 'api/spot/orderPlace', data)
  }

  getOpenOrder(params: any, pairId: string) {
    return this.http.get(environment.spotApiUrl + `api/spot/openOrder/${pairId}`, { params })
  }

  getOrderHistory(params: any, pairId: string) {
    return this.http.get(environment.spotApiUrl + `api/spot/orderHistory/${pairId}`, { params })
  }

  getTradeHistory(params: any, pairId: string) {
    return this.http.get(environment.spotApiUrl + `api/spot/tradeHistory/${pairId}`, { params })
  }

  getRecentTrade(pairId: string) {
    return this.http.get(environment.spotApiUrl + `api/spot/recentTrade/${pairId}`)
  }

  getOrderBook(pairId: string) {
    return this.http.get(environment.spotApiUrl + `api/spot/ordeBook/${pairId}`)
  }

  gethistoryOrderhistory(params: any) {
    return this.http.get(environment.spotApiUrl + `api/spot/getMySpotHistory`, { params })
  }

  gethistorytradeHistory(params: any) {
    return this.http.get(environment.spotApiUrl + `api/spot/getFilledOrderHistory`, { params })
  }

  cancelOrder(hash: string) {
    return this.http.post(environment.spotApiUrl + 'api/spot/cancelOrder', { id: hash })
  }

  // getChartData () {
  //   return this.http.get(environment.spotApiUrl + `api/chart`)
  // }

}
