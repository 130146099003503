<div class="form-body">
    <div class="row">
        <div class="form-holder">
            <div class="form-content">
                <div class="form-items" style="max-width: 10%">
                    <h4 class="mb-4 d-flex justify-content-center">Deactivate Your Account</h4>

                    <form class="auth_form" [formGroup]="form" (submit)="submitForm()" *ngIf="formType == 1">
                        <div class="col-md-12 sign_group sign_group_input">

                            <label>Email address</label>

                            <input class="sign_input" type="text" placeholder="Enter your email"
                                formControlName="email">

                            <div *ngIf="email?.invalid && submitted">
                                <span class="required" *ngIf="email?.errors?.['required']">Email field is
                                    required</span>
                                <span class="required" *ngIf="email?.errors?.['pattern']">Invalid email</span>
                            </div>

                            <span class="required" *ngIf="serverErrors?.email">
                                {{ serverErrors.email }}
                            </span>

                        </div>
                        <div class="form-button d-flex justify-content-center">
                            <button id="submit" type="submit" class="sign_btn" [disabled]="isReqBtnLoading">
                                Delete Account <span *ngIf="isReqBtnLoading"
                                    class="spinner-border spinner-border-sm"></span>
                            </button>
                        </div>
                    </form>
                    <div *ngIf="formType == 2">
                        <div class="mb-3 d-flex justify-content-center">
                            <span class="userEmail != ''">
                                Enter 6 digit OTP
                            </span>
                        </div>
                        <ng-otp-input (onInputChange)="onOtpChange($event)"
                            [config]="{ length:6, containerClass: 'd-flex justify-content-center' }"></ng-otp-input>

                        <!-- <div class="form-button d-flex justify-content-center mt-4">
                            <button id="button" type="button" class="deposit_btn" [disabled]="isOtpSubmitted"
                                (click)="redirectToBack()">
                                <span *ngIf="isOtpSubmitted" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                Back
                            </button>
                        </div> -->
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>