import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFilter'
})
export class TextFilterPipe implements PipeTransform {
  transform(data: any[], filterType: string, value: string): any {
    if (!data || !filterType || !value) {
      return data;
    }
    return data.filter(item => item[filterType].indexOf(value) !== -1);
  }
}
