<app-header-dashboard></app-header-dashboard>
<div class="d-flex justify-content-center">
  <main class="main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="dashbox">
            <h2 class="dashbox_title">Profile</h2>
            <!-- <p class="mb-5 text-primary text-center">We'll need a bit more information before you go to dashboard</p> -->
            <div class="edit_profile_head">
              <div>
                <h3>General</h3>
                <p>View your personal profile information</p>
              </div>
              <button (click)="signOut()" class="sign_btn">Logout</button>
            </div>
            <div class="edit_profile_user_info">
              <div class="sidebar__user-img">
                <span *ngIf="profileDetails.firstName !== '' && profileDetails.lastName !== ''; else anonymoususer">
                  {{ (profileDetails.firstName | slice:0:1 | uppercase) + (profileDetails.lastName | slice : 0 : 1 |
                  uppercase)}}
                </span>
                <ng-template #anonymoususer>
                  <span>AU</span>
                </ng-template>
              </div>

              <div class="sidebar__user-title">
                <p>
                  {{ profileDetails.firstName }} {{ profileDetails.lastName }}
                </p>
                <span>{{ profileDetails.email }}</span>
              </div>
            </div>
            <app-profile-form></app-profile-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>