<footer>
  <div class="container">
    <div class="footer_top">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <a routerLink="/">
            <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="Logo"
              class="img-fluid footer_logo" />
          </a>
          <p>Your best crypto partner</p>
          <ul class="social_links">
            <li>
              <a [href]="siteSetting.telegramLink" target="_blank"><i class="fab fa-telegram-plane"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.twitterUrl" target="_blank"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.linkedinLink" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.redditLink" target="_blank"><i class="fab fa-reddit-alien"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.mediumLink" target="_blank"><i class="fab fa-medium-m"></i></a>
            </li>
          </ul>
          <ul class="social_links">

            <li>
              <a [href]="siteSetting.facebookLink" target="_blank"><i class="fab fa-facebook"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.instagramLink" target="_blank"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.pinterestLink" target="_blank"><i class="fab fa-pinterest-p"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.youtubeLink" target="_blank"><i class="fab fa-youtube"></i></a>
            </li>
            <li>
              <a [href]="siteSetting.tumblerLink" target="_blank"><i class="fab fa-tumblr"></i></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6">
          <h3>Quick Links</h3>
          <ul class="quick_link">
            <li><a routerLink="/market-home">Market</a></li>
            <li>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScfoaKqS1Qt6oCuobYbIyxSpHiVlDaEzlFRfzbMS9h8VXUv6A/viewform?usp=sf_link"
                target="_blank">List Your Token/Coin</a>
            </li>
            <li><a routerLink="/launchpad">Launchpad</a></li>
            <li><a routerLink="/fee-home">Fees</a></li>
            <li><a routerLink="/about-us">About</a></li>
            <li><a routerLink="/spot">Trade</a></li>
            <li><a routerLink="/contact">Contact</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6">
          <h3>New for Updates</h3>
          <p>Subscribe to get update and notify our exchange and products</p>
          <div class="subscribe_form">
            <input class="form-control" placeholder="Enter your email address" type="email"
              (input)="onInputChange($event)" value="{{ email }}" />
            <button type="button" (click)="submitForm()">Send</button>
            <span class="required" *ngIf="serverErrors?.email">{{serverErrors.email}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <p>Copy rights @ {{date.getFullYear()}}, Credbull Exchange Private Limited. All right reserved.</p>
      <ul>
        <li><a routerLink="/terms">Terms of service</a></li>
        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
      </ul>
    </div>
  </div>
</footer>