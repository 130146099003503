import { createAction, props } from '@ngrx/store';
import { TradePairState } from './tradepair.state';

export const tradePairKey = '[TradePair]';

export const setTradePair = createAction(
    `${tradePairKey} SetTradePair`,
    props<{ payload: any }>()
);

export const reset = createAction(
    `${tradePairKey} Reset`
);