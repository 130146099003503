<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <app-bank-details></app-bank-details>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <app-two-fa></app-two-fa>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <app-kyc-verification></app-kyc-verification>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <app-bank-verification></app-bank-verification>
      </div>
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
        <div class="airdrop_panel"
        [style.background]="'url(' + cmsurl + 'cms-airdrop_bg.png' + ') no-repeat center'">
          <ng-component [innerHTML]="dashboardBanner.content" />
        </div>
      </div>
    </div>

    <div class="row flex_height">
      <div class="col-xxl-12 col-xl-12">
        <app-login-history></app-login-history>
      </div>
      <div class="col-xxl-12 col-xl-12">
        <app-last-transaction></app-last-transaction>
      </div>
    </div>
  </div>
</main>