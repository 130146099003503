import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, AuthState } from './auth.state';
import { signIn, signOut } from './auth.actions';

export const authFeatureKey = 'auth';

export const authReducers: ActionReducer<AuthState> = createReducer(
    initialState,
    on(signIn, (_, action) => {
        return { signedIn: true, ...action.payload }
    }),
    on(signOut, () => initialState)
    // on(signOut, (state) => {
    //     return { signedIn: false, token: '', uniqueId: '' }
    // }),
);