<div class="dashbox h-100">
  <h2 class="dashbox_title">Login History</h2>
  <div class="table-responsive">
    <table class="table primary_table">
      <thead>
        <tr>
          <th>Date / Time</th>
          <th>Device</th>
          <th>IP Address</th>
          <th>Location</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let history of loginHistory">
          <td>{{history.createdDate | date: 'MMM d, y, h:mm:ss'}}</td>
          <td>{{(history.broswername | titlecase) + ', ' + (history.os | titlecase)}}</td>
          <td>{{history.ipaddress}}</td>
          <td>{{history.regionName + ', ' + history.countryName}}</td>
          <td>{{history.status}}</td>
        </tr>
      </tbody>
    </table>
    <nav class="primary_pagination" *ngIf="filter.totalPages > 1">
      <ul class="pagination">
        <li class="page-item" [ngClass]="{disabled: filter.page == 1}">
          <span class="page-link cursor-pointer" aria-label="Previous" (click)="previousPage()">
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>
        <li class="page-item" *ngFor="let item of getDisplayedPages">
          <a class="page-link" [ngClass]="{ active: item == filter.page}" href="javascript:void(0)"
            (click)="goToPage(item)">{{item}}</a>
        </li>
        <li class="page-item" [ngClass]="{disabled: filter.totalPages == filter.page}">
          <span class="page-link cursor-pointer" (click)="nextPage()" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  </div>
</div>