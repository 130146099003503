import { Component } from '@angular/core';
import { spotSocket } from 'src/app/app.module';

// Import lib
import { fraction, toFixed, toFixedDown } from 'src/app/lib/roundOf';

// Import models
import { RecentTradeData } from 'src/app/core/models';

// Import service
import { SpotService } from 'src/app/services/spot.service';

// Import facade
import { TradePairFacade } from 'src/app/store/tradepair';

@Component({
  selector: 'app-recent-trades',
  templateUrl: './recent-trades.component.html',
  styleUrls: ['./recent-trades.component.css']
})
export class RecentTradesComponent {
  toFixed = toFixed
  toFixedDown = toFixedDown
  fraction = fraction

  tradePair: any = {}
  tradeData: RecentTradeData[] = []

  constructor(
    private spotService: SpotService,
    private tradePairFacade: TradePairFacade,
    private spotSocket: spotSocket
  ) { }

  ngOnInit() {
    this.tradePairFacade.tradePair$.subscribe({
      next: (pair: any) => {
        this.tradePair = pair
        this.fetchRecentTrade(pair._id)
      },
      error: (err: any) => { }
    })
    this.fetchRecentTradeWs = this.fetchRecentTradeWs.bind(this);
    this.spotSocket.on('recentTrade', this.fetchRecentTradeWs)
  }

  // ngOnDestroy() {
  //   this.spotSocket.off('recentTrade');
  // }

  fetchRecentTrade(pairId: string) {
    this.spotService.getRecentTrade(pairId).subscribe({
      next: (v: any) => {
        this.tradeData = v.result
      },
      error: (err: any) => { }
    })
  }

  fetchRecentTradeWs(result: any) {
    if (result.pairId == this.tradePair._id) {
      let data = this.tradeData.slice(0, -1);
      this.tradeData = [...result.data, ...data]
    }
    // return () => {
    //   this.spotSocket.off('recentTrade');
    // }
  }
}
