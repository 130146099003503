<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <div class="dashbox_title_flex">
            <h2 class="dashbox_title">Fiat Withdraw</h2>
            <h2 class="dashbox_title">
              Balance:
              <span>{{
                assetData?.spotBal && truncateDecimals(assetData?.spotBal, 2)
              }}</span>
              {{ selectedCoin?.symbol }}
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-6 col-lg-6 mb-5">
              <form class="auth_form" [formGroup]="form">
                <div class="sign_group sign_group_input">
                  <label>Choose Cryptocurrency</label>
                  <select class="sign_input" (change)="changeCurrency($event)">
                    <option
                      *ngFor="let option of currencyList"
                      [selected]="selectedCurrency == option.coin"
                    >
                      {{ option.coin }}
                    </option>
                  </select>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Enter Amount<span>*</span></label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="0.00"
                      formControlName="amount"
                      [appDecimalLimit]="2"
                      (input)="handleAmountChange($event)"
                    />
                    <span class="input_info"
                      >Fee: {{ selectedCoin?.withdrawFee }}
                    </span>
                  </div>
                  <div
                    *ngIf="amount?.invalid && (amount?.touched || submitted)"
                  >
                    <span class="required" *ngIf="amount?.errors?.['required']"
                      >Amount field is required</span
                    >
                  </div>
                  <div *ngIf="amount?.value > selectedCoin?.maximumWithdraw">
                    <span class="required"
                      >Maximum withdraw amount
                      {{ selectedCoin?.maximumWithdraw }}
                    </span>
                  </div>
                  <span class="required" *ngIf="serverErrors?.amount">
                    {{ serverErrors.amount }}
                  </span>
                </div>
                <div class="form_note">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <label
                      >Min:
                      <span
                        >{{ selectedCoin?.minimumWithdraw }}
                        {{ selectedCoin?.symbol }}</span
                      ></label
                    >
                    <label
                      >Max:
                      <span
                        >{{ selectedCoin?.maximumWithdraw }}
                        {{ selectedCoin?.symbol }}</span
                      ></label
                    >
                  </div>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Receiving Amount</label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="0.00"
                      formControlName="finalAmount"
                      [appDecimalLimit]="2"
                      (input)="inputChange()"
                      readonly="true"
                    />
                    <span class="required" *ngIf="serverErrors?.finalAmount">
                      {{ serverErrors.finalAmount }}
                    </span>
                  </div>
                </div>
                <div class="form_note">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <label
                      >TDS Rate:
                      <span>{{FiatTDS?.rate}} %</span
                      ></label>
              
                    <label
                      >INR:
                      <span
                        >{{tds}}
                       </span
                      ></label
                    >
                  </div>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Choose the Bank Account*</label>
                  <select
                    class="sign_input"
                    formControlName="bankId"
                    (change)="inputChange()"
                  >
                    <option value="">Choose a Bank</option>
                    <option
                      *ngFor="let option of bankDetails"
                      [value]="option._id"
                    >
                      {{ option.bankName }}
                    </option>
                  </select>
                  <div
                    *ngIf="bankId?.invalid && (bankId?.touched || submitted)"
                  >
                    <span class="required" *ngIf="bankId?.errors?.['required']"
                      >Bank Account is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.bankId">
                    {{ serverErrors.bankId }}
                  </span>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Comments</label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="Enter Comments"
                      formControlName="comments"
                      (input)="inputChange()"
                    />
                  </div>
                  <!-- <div *ngIf="comments?.invalid && (comments?.touched || submitted)">
                    <span class="required" *ngIf="comments?.errors?.['required']">Comment is required</span>
                  </div> -->
                  <span class="required" *ngIf="serverErrors?.comments">
                    {{ serverErrors.comments }}
                  </span>
                </div>
                <div class="sign_group sign_group_input">
                  <label>Enter 2FA Code*</label>
                  <div class="primary_inp_grp">
                    <input
                      type="text"
                      class="sign_input"
                      placeholder="Enter 2FA"
                      formControlName="twoFACode"
                      (input)="inputChange()"
                    />
                  </div>
                  <div
                    *ngIf="
                      twoFACode?.invalid && (twoFACode?.touched || submitted)
                    "
                  >
                    <span
                      class="required"
                      *ngIf="twoFACode?.errors?.['required']"
                      >2FA field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.twoFACode">
                    {{ serverErrors.twoFACode }}
                  </span>
                </div>

                <button
                  class="trade_sell_btn"
                  type="button"
                  *ngIf="
                    user &&
                      user.aadharNumber &&
                      user.aadharNumber != 'verified';
                    else kycverified
                  "
                  (click)="navigate('identification')"
                >
                  KYC Not Verified
                </button>

                <ng-template #kycverified>
                  <button
                    class="trade_sell_btn"
                    type="button"
                    *ngIf="
                      user &&
                        user.twoFAStatus &&
                        user.twoFAStatus === 'disabled' &&
                        user.aadharNumber == 'verified';
                      else depositSubmit
                    "
                    (click)="navigate('security')"
                  >
                    Enable 2FA
                  </button>
                </ng-template>
                <ng-template #depositSubmit>
                  <button
                    class="sign_btn"
                    [disabled]="loading"
                    type="button"
                    (click)="submitForm()"
                  >
                    <div *ngIf="loading; else submitButton">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                      Submitting...
                    </div>
                    <ng-template #submitButton> Submit </ng-template>
                  </button>
                </ng-template>
              </form>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="dashbox">
                <div class="fiat_wallet_notes">
                  <h3>Notes:</h3>
                  <ul>
                    <li>
                      Minimum {{ selectedCoin?.minimumWithdraw }}
                      {{ selectedCoin?.symbol }} per transaction
                    </li>
                    <li>
                      Max {{ selectedCoin?.maximumWithdraw }}
                      {{ selectedCoin?.symbol }} Per transaction
                    </li>
                    <!-- <li>Total ₹20 Lakh per day</li> -->
                    <li>
                      Processing time - upto 2 hours (during banking hours and
                      weekdays)
                    </li>
                    <li>
                      Fee(inclusive of all taxes).
                      {{ selectedCoin?.withdrawFee }}
                      {{ selectedCoin?.symbol }} per transaction.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
