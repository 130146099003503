import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setTotalBalance, reset } from './dashboard.actions';
import { selectDashboard } from './dashboard.selectors';
import { Observable } from 'rxjs';
import { DashboardState } from './dashboard.state';

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
    private readonly store: Store = inject(Store);

    readonly dashboard$: Observable<DashboardState> = this.store.select(selectDashboard);

    setTotalBalance({ usdt, btc }: any): void {
        this.store.dispatch(setTotalBalance({ payload: { usdt, btc } }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}