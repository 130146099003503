import { createAction, props } from '@ngrx/store';

export const tradeAssetKey = '[TradeAsset]';

export const setFirstCurrency = createAction(
    `${tradeAssetKey} setFirstCurrency`,
    props<{ payload: any }>()
);

export const setSecondCurrency = createAction(
    `${tradeAssetKey} setSecondCurrency`,
    props<{ payload: any }>()
);

export const reset = createAction(`${tradeAssetKey} Reset`);