<div class="login_wrapper">
  <div class="authbanner_wrapper">
    <a routerLink="/">      
      <img [src]="theme == 'dark' ? 'assets/images/new-logo.svg': 'assets/images/new-logo.svg'" alt="Login"
        class="img-fluid auth_logo" />
    </a>
    <div class="auth_content">
      <h2>The Ultimate Utility Crypto Exchange!</h2>
      <p>
        Experience the ultimate utility crypto exchange. Our mission is to empower traders, from novices to experts, with the tools they need to succeed in the dynamic world of cryptocurrencies.
      </p>
    </div>
    <img
      src="assets/images/signin_img.png"
      alt="Login"
      class="img-fluid auth_right_img"
    />
  </div>
  <div class="auth_form_wrapper">
    <div class="row">
      <div class="col-lg-12">
        <div class="sign_content">
          <!-- authorization form -->
          <!-- <div>
            <h2>Forgot Your Password</h2>
          </div> -->
          <div>
            <h2>Forgot Your Password</h2>
            <div class="signin_btns">
              <button
                (click)="showDiv1()"
                [ngClass]="{ active: isDiv1Visible }"
              >
                <i class="fas fa-envelope"></i>
              </button>
              <button
                (click)="showDiv2()"
                [ngClass]="{ active: isDiv2Visible }"
              >
                <i class="fas fa-phone-alt"></i>
              </button>
            </div>
          </div>
          <p *ngIf="isDiv1Visible">
            Write your e-mail address so we can send you a link to reset your
            password
          </p>
          <p *ngIf="isDiv2Visible">
            Write your phone number so we can send you a OTP to reset your
            password
          </p>

          <form class="auth_form" [formGroup]="form" (submit)="submitForm()">
            <div class="sign_group sign_group_input" *ngIf="isDiv1Visible">
              <label>Email address</label>
              <input
                type="text"
                class="sign_input"
                placeholder="Enter Email"
                formControlName="email"
                (input)="handleInputChange()"
              />
              <div *ngIf="email?.invalid && (email?.touched || submitted)">
                <span class="required" *ngIf="email?.errors?.['required']"
                  >Email field is required</span
                >
                <span class="required" *ngIf="email?.errors?.['email']"
                  >Invalid Email</span
                >
              </div>
              <span class="required" *ngIf="serverErrors?.email">{{
                serverErrors.email
              }}</span>
            </div>

            <div class="sign_group sign_group_input" *ngIf="isDiv2Visible">
              <label>Phone Number</label>
              <ngx-mat-intl-tel-input
                formControlName="newPhoneNo"
                [enablePlaceholder]="true"
                [enableSearch]="true"
                name="newPhoneNo"
                (countryChanged)="countryChangedEvent($event)"
                (ngModelChange)="handleInputChange()"
              >
              </ngx-mat-intl-tel-input>
              <div
                *ngIf="
                  newPhoneNo?.invalid &&
                  (newPhoneNo?.touched || submitted) &&
                  isDiv2Visible
                "
              >
                <span class="required" *ngIf="newPhoneNo?.errors?.['required']"
                  >Phone Number field is required</span
                >
              </div>
              <span
                class="required"
                *ngIf="serverErrors?.newPhoneNo && isDiv2Visible"
                >{{ serverErrors.newPhoneNo }}</span
              >
            </div>

            <div class="mb-3" *ngIf="otpTextBox">
              <div class="sign_group sign_group_input" *ngIf="isDiv2Visible">
                <label>OTP</label>
                <div class="primary_inp_grp">
                  <input
                    type="text"
                    class="sign_input"
                    placeholder="Enter OTP"
                    formControlName="otp"
                    (input)="handleInputChange()"
                    name="otp"
                  />
                  <span
                    *ngIf="seconds === 0; else timer"
                    class="input_info cursor-pointer"
                    (click)="resendOtp()"
                    >Resend OTP</span
                  >
                  <ng-template #timer>
                    <span class="input_info">{{ seconds }}</span>
                  </ng-template>
                </div>
              </div>
              <!-- <div *ngIf="otp?.invalid && (otp?.touched || submitted)">
                <span class="required" *ngIf="otp?.errors?.['required']">OTP field is required</span>
              </div> -->
              <span class="required" *ngIf="serverErrors?.otp">{{
                serverErrors.otp
              }}</span>
            </div>

            <button
              class="sign_btn"
              *ngIf="!otpTextBox; else otp_confirm"
              type="submit"
              [disabled]="isLoading"
            >
              Submit
              <span
                *ngIf="isLoading"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <ng-template #otp_confirm>
              <button class="sign_btn" type="button" (click)="verifyOtp()">
                <!-- <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> -->
                Confirm OTP
              </button>
            </ng-template>
            <span class="sign_text"><a routerLink="/signin">Sign In</a></span>
            <!-- <button class="sign_btn" type="submit">Submit</button> -->
          </form>
          <!-- end authorization form -->
        </div>
      </div>
    </div>
  </div>
</div>
