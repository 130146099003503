import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setWallet, reset } from './wallet.actions';
import { selectWallet } from './wallet.selectors';
import { Observable } from 'rxjs';
import { WalletState } from './wallet.state';

@Injectable({ providedIn: 'root' })
export class WalletFacade {
    private readonly store: Store = inject(Store);

    readonly wallet$: Observable<WalletState> = this.store.select(selectWallet);

    setWallet(payload: any): void {
        this.store.dispatch(setWallet({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}