import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { AuthService } from 'src/app/services/auth.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserFacade } from '../../store/user';


interface Country {
  shortName: string;
  name: string;
}

interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  name_at_bank: string;
  bankVerifyName: string;
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})

export class EditProfileComponent implements OnInit {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;

  countries: Country[];
  states: string[] = [];
  cities: string[] = [];
  profileDetails: Profile = {
    firstName: '',
    lastName: '',
    email: '',
    name_at_bank: '',
    bankVerifyName: ''
  };

  private readonly userFacade: UserFacade = inject(UserFacade);

  country = new FormControl(null, [Validators.required]);
  state = new FormControl({ value: null, disabled: false }, [
    Validators.required,
  ]);
  city = new FormControl({ value: null, disabled: false }, [
    Validators.required,
  ]);

  constructor(
    private service: UserService,
    private AuthService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder,
  ) {

    this.countries = this.service.getCountries();
    this.form = this._fb.group({
      country: this.country,
      state: this.state,
      city: this.city,
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      email: ['', Validators.required],
      phoneNo: ['', Validators.required],
      name_at_bank: ['', [Validators.required]],


    });


  }

  get firstName() { return this.form.get('firstName') }
  get lastName() { return this.form.get('lastName') }
  get postalCode() { return this.form.get('postalCode') }
  get countryIn() { return this.form.get('country') }
  get stateIn() { return this.form.get('state') }
  get cityIn() { return this.form.get('city') }
  get name_at_bank() { return this.form.get('name_at_bank') }



  ngOnInit() {
    this.country.valueChanges.subscribe((country) => {
      this.state.reset();
      // this.state.disable();
      if (country) {
        this.states = this.service.getStatesByCountry(country);
        this.state.enable();
      }
    });

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      //this.city.disable();
      if (state) {
        this.cities = this.service.getCitiesByState(this.country.value, state);
        this.city.enable();
      }
    });
    this.getprofile()
  }

  getprofile() {
    this.userFacade.user$
      .subscribe({
        next: (data: any) => {
          this.profileDetails.email = data.email
          this.profileDetails.firstName = data.firstName
          this.profileDetails.lastName = data.lastName
          this.profileDetails.name_at_bank = data.name_at_bank
          this.profileDetails.bankVerifyName = data.bankVerifyName



          this.form.patchValue({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNo: data.phoneCode + data.phoneNo,
            country: data.country,
            state: data.state,
            city: data.city,
            postalCode: data.postalCode,
            name_at_bank: data.name_at_bank

          })

        },
        error: (err: any) => {
          this.submitted = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }
  onInputChange() {
    this.serverErrors = {}
  }
  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true

    this.service.updateProfile(this.form.value)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false

          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.getUser()

            //this.router.navigateByUrl("/edit-profile")
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false

          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }


  getUser() {
    this.service.getUserApi()
      .subscribe({
        next: (v: any) => {
          if (v.success) {
            this.userFacade.setUser(v.result);
          }
        },
        error: err => { }
      })
  }


  signOut(): void {
    this.AuthService.logoutApi()
  }

} 