export interface UserSettingState {
    theme: string,
    loading: boolean,
    _id: string,
    twoFA: string,
    passwordChange: string,
    siteNotification: string,
    userId: string,
    languageId: string,
    createdAt: string,
    updatedAt: string,
    currencySymbol: string,
    LatestEvent: boolean,
    announcement: boolean,
    tradingviewAlert: boolean,
    tradeOrderPlaceAlertMobile: boolean,
    tradeOrderPlaceAlertWeb: boolean,
    defaultWallet: string,
    loginNotification: boolean,
}

const initialUserSetting: UserSettingState = {
    theme: "",
    loading: true,
    _id: "",
    twoFA: "",
    passwordChange: "",
    siteNotification: "",
    userId: "",
    languageId: "",
    createdAt: "",
    updatedAt: "",
    currencySymbol: "",
    LatestEvent: false,
    announcement: false,
    tradingviewAlert: false,
    tradeOrderPlaceAlertMobile: false,
    tradeOrderPlaceAlertWeb: false,
    defaultWallet: "spotBal",
    loginNotification: false
};

export const initialState: any = {
    defaultTheme: "dark",
    userSetting: {},
    siteSetting: {}
}