import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ReCaptchaV3Service } from 'ng-recaptcha';

// Import service
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { decryptObject } from 'src/app/lib/cryptoJs';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css'],
})
export class RegisterFormComponent {
  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{6,18}$/;

  showPassword: boolean = false
  confirmShowPassword: boolean = false
  isLoading: boolean = false

  private subscription?: Subscription;
  reCaptcha: any;
  ReferralUser: any;
  validReferral = false;
  loaders: any = false

  otpVerified: boolean = false;

  seconds: number = 180;
  timerInSeconds: number = 180;

  mobileVerify:boolean = false;
  SMSOTP: any;
  allowEmail: boolean =false;
  potp :boolean = false;
  eotp : boolean = false;
  EmailOTP: boolean = false;
  potpbtn = false;
  eotpbtn = true;
  resPhone: any;
  decryptedData: any;
  decryptedMailData: any;
  resMail: any;
  constructor(
    private _fb: FormBuilder,
    private service: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {

    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      firstName: [''],
      lastName: [''],
      newPhoneNo: ['', [Validators.required]],
      newPhoneCode: [''],
      roleType: 1,
      aggree: [false],
      password: ['', [Validators.required, Validators.pattern(this.passwordRegex)]],
      confirmPassword: [''],
      reCaptcha: [''],
      referralcode: [''],
      remember: [false],
      otp: [''],
      phoneOTP :[''],
      emailOTP:[''],
      emailStatus:[''],
      phoneStatus:[''],
      status:['']
    });
  }

  ngOnInit() {
    //this.getToken()
    // this.reCaptcha = setInterval(() => {
    //   this.getToken()
    // }, 2000);
  }

  ngOnDestroy() {
    if (this.reCaptcha) {
      clearInterval(this.reCaptcha);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  // getToken() {
  //   this.subscription = this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token: string) => {
  //       this.form.patchValue({
  //         reCaptcha: token
  //       })
  //     });
  // }

  get email() { return this.form.get('email') }
  
  get firstName() { return this.form.get('firstName') }
  
  get lastName() { return this.form.get('lastName') }

  get newPhoneNo() { return this.form.get('newPhoneNo') }

  get password() { return this.form.get('password') }
  get phoneOTP() { return this.form.get('phoneOTP') }
  get emailOTP() { return this.form.get('emailOTP') }
  get emailStatus() { return this.form.get('emailStatus') }
  get phoneStatus() { return this.form.get('phoneStatus') }
  get status() { return this.form.get('status') }
  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true;
    this.subscription = this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {
        const { newPhoneNo, newPhoneCode } = this.form.value
        const reqData = {
          ...this.form.value,
          newPhoneNo: newPhoneNo.replace(newPhoneCode, ''),
          reCaptcha: token
        }
        this.service.registerApi(reqData)
          .subscribe({
            next: (v: any) => {
              this.isLoading = false
              if (v.status) {
                this.submitted = false
                this.router.navigateByUrl("/signin")
                this.toastr.success(v.message)
              }
            },
            error: (err: any) => {
              this.isLoading = false
              this.submitted = false
              if ([400, 404].includes(err.status)) {
                const validationErrors = err.error.errors;
                this.serverErrors = validationErrors || {};
              } else if (err.status === 500) {
                this.toastr.error(err.error.message)
              }
            },
            complete: () => { }
          })
      })
  }

  onInputChange() {
    this.serverErrors = {}
  }

  countryChangedEvent(event: any) {
    this.form.patchValue({
      newPhoneCode: '+' + event.dialCode
    })
  }
  

  hanldeShowPassword() {
    this.showPassword = !this.showPassword
  }

  hanldeConfirmShowPassword() {
    this.confirmShowPassword = !this.confirmShowPassword
  }
  checkReferral(event: any) {
    this.ReferralUser = '';
    if (this.form.value.referralcode != '' && (this.form.value.referralcode.length == 5 || this.form.value.referralcode.length == 6 || this.form.value.referralcode.length == 7 || this.form.value.referralcode.length == 8)) {
      this.isLoading = true;
      const reqData = {
        referralcode: this.form.value.referralcode
      }
      this.service.validateRefrralCode(reqData)
        .subscribe({
          next: (v: any) => {
            this.isLoading = false
            if (v.status) {
              this.validReferral = true;
              this.ReferralUser = v.message;
            }
            else {
              this.serverErrors = { referralcode: 'Invalid Referral Code' } || {};
            }
          },
          error: (err: any) => {
            this.isLoading = false
            if ([400, 404].includes(err.status)) {
              const validationErrors = err.error.errors;
              this.serverErrors = validationErrors || {};
            } else if (err.status === 500) {
              this.toastr.error(err.error.message)
            }
          },
          complete: () => { }
        })
    }

  }
  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        this.loaders = false
        clearInterval(timer);
      }
    }, 1000);
  }
  // resendOtp() {
  //   const reqData = {
  //     roleType: this.isDiv1Visible ? 1 : 2,
  //     otpAuth: this.otpToken,
  //   }
  //   this.service.resendOtpApi(reqData)
  //     .subscribe({
  //       next: (v: any) => {
  //         this.loaders = true
  //         this.timer(this.timerInSeconds);
  //         // this.count = 'resend'
  //         this.toastr.success(v.message)
  //         this.serverErrors = {}
  //       },
  //       error: (err: any) => {
  //         this.toastr.error(err.error.message)
  //       }
  //     })
  // }

  sendMobileOTP()
  {
    const { newPhoneNo, newPhoneCode } = this.form.value;
    if (!newPhoneNo) {
      this.toastr.error('Phone number is required');
      return;
    }
    
    const reqBody = {
      ...this.form.value,
      newPhoneNo: newPhoneNo.replace(newPhoneCode, ''),
    }
    this.service.sendPhoneOtpApi(reqBody).subscribe({
      next: async (v: any) => {
        if (v.status) {
        //  this.SMSOTP = decryptObject(v.data.content)
          //console.log("Encrypted Content Received:",decryptObject(v.data)); 
         // console.log(",decryptObject(v.data.content[0])",decryptObject(v.data.content[0])) // Log the received encrypted content
         this.loaders = true;
         this.decryptedData = decryptObject(v.data);
         this.SMSOTP = this.decryptedData.otp;
          this.resPhone = this.decryptedData.newPhoneCode+this.decryptedData.newPhoneNo;
          this.timer(this.timerInSeconds);
          this.potpbtn = true;
          this.eotpbtn = true;
          this.potp = true;
         
          this.toastr.success(v.message)
          this.serverErrors = {}

        }else
        {
          this.potpbtn = false;

        }
      },  error: (err: any) => {
        this.toastr.error(err.error.message)
      }
    });
  }
  verifyPhoneOTP()
  {
   // console.log(this.SMSOTP,"OOOO",this.form.value.phoneOTP);
    if(this.SMSOTP == this.form.value.phoneOTP && this.resPhone == this.form.value.newPhoneNo)
    {
      this.allowEmail = true;
     // this.form.get('newPhoneNo')?.disable();
      this.potp = false;
      this.eotpbtn = false;
      
      this.form.patchValue({
        phoneStatus: 'verified'
        });
        this.toastr.success("MobileNumber Verified");
    }
    else{
      this.allowEmail = false;
     // this.form.get('newPhoneNo')?.enable();
      this.potp = true;
      this.potpbtn = true;
      this.toastr.error("MobileNumber OTP Invalid");
    }
  }
  sendMailOTP()
  {
   // this.isLoading = true;
   const { email } = this.form.value;
   if (!email) {
    this.toastr.error('Email is required');
    return;
  }
    const reqBody = {
      email: this.form.value.email
    }
    this.service.sendEmailOtpApi(reqBody).subscribe({
      next: async (v: any) => {
        if (v.status) {
          this.loaders = true;
          this.decryptedMailData = decryptObject(v.data);
        this.EmailOTP = this.decryptedMailData.otp;
        this.resMail = this.decryptedMailData.email;
        this.timer(this.timerInSeconds);
        this.eotpbtn = true;
        this.eotp = true;
        this.toastr.success(v.message)
        }else
        {
          this.eotpbtn = false;
          this.eotp = false;
          this.toastr.error(v.message)
        }
      }, error: (err: any) => {
        this.toastr.error(err.error.message)
      }
    });
  }
  verifyEmailOTP()
  {
  //  console.log(this.EmailOTP,"OOOO",this.form.value.emailOTP);
    if(this.EmailOTP == this.form.value.emailOTP && this.resMail == this.form.value.email)
    {
      this.eotp = false;
      this.mobileVerify = true;
      //this.form.get('email')?.disable();
      this.form.patchValue({
        emailStatus: 'verified',
        status:'verified'
        });
        this.toastr.success("Email Verified");
        //this.isLoading = true;
    }
    else{  
      this.eotp = true;
      //this.form.get('email')?.enable();
      this.toastr.error("Email OTP Invalid");
    }   
  }
  checkPhone(event: any) {
      this.isLoading = true;
      const { newPhoneNo, newPhoneCode } = this.form.value;
      const reqBody = {
        ...this.form.value,
        newPhoneNo: newPhoneNo.replace(newPhoneCode, ''),
      }
      console.log(this.form.value.newPhoneNo.length,this.form.value.newPhoneCode.length,"this.form.value.newPhoneNo.length");
      if (this.form.value.newPhoneNo != '' && this.form.value.newPhoneNo.length >= 9){
      this.service.validatePhone(reqBody)
        .subscribe({
          next: (v: any) => {
            this.isLoading = false
            if (v.status) {
             this.serverErrors = { }
            }
            else {
              this.toastr.error(v.message)
            }
          },
          error: (err: any) => {
            this.isLoading = false
            if ([400, 404].includes(err.status)) {
              const validationErrors = err.error.errors;
              this.serverErrors = validationErrors || {};
            } else if (err.status === 500) {
              this.toastr.error(err.error.message)
            }
          },
          complete: () => { }
        })
    
  }
  }
  
  checkMail(event: any) {
   
    this.isLoading = true;
    const { email } = this.form.value;
    const reqBody = {
      ...this.form.value,
    }
    this.service.validateEmail(reqBody)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false
          if (v.status) {
           //this.serverErrors = { }
          }
          else {
          //  this.serverErrors = { email: v.message } || {};
            this.toastr.error(v.message)
          }
        },
        error: (err: any) => {
          this.isLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
}

}
