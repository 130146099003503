import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent {
  currentpath = {}
  constructor(
    private router: Router,
    private route: ActivatedRoute

  ) {


  }

  ngOnInit() {
    //const type = this.route.snapshot.params['type']
    this.route.params.subscribe(val => {
      this.currentpath = val['type'];
    });
  }

  gotohistory(page: string) {
    this.router.navigateByUrl('/history/' + page)

  }

}
