import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { UserSettingFacade } from 'src/app/store/usersetting';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  theme = ''

  form: FormGroup
  submitted: boolean = false
  serverErrors: any = {}
  isLoading: boolean = false

  private subscription?: Subscription;

  reCaptcha: any;
  isDiv1Visible = true;
  isDiv2Visible = false;
  otpTextBox: boolean = false;
  seconds: number = 0;
  timerInSeconds: number = 60;
  otpToken: string = '';

  constructor(
    private authService: AuthService,
    private _fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private userSettingFacade: UserSettingFacade
  ) {
    this.form = this._fb.group({
      email: ['', []],
      newPhoneNo: ['', []],
      newPhoneCode: [''],
      otp: [''],
    })
  }

  get email() { return this.form.get('email') }
  get newPhoneNo() { return this.form.get('newPhoneNo') }

  ngOnInit() {
    this.getUserSetting()

  }

  getUserSetting() {
    this.userSettingFacade.usersetting$.subscribe({
      next: (data: any) => {
        this.theme = data.defaultTheme
      },
      error: (err: any) => { }
    })
  }

  ngOnDestroy() {
    if (this.reCaptcha) {
      clearInterval(this.reCaptcha);
    }
  }

  showDiv1() {
    this.isDiv1Visible = true;
    this.isDiv2Visible = false;
  }

  showDiv2() {
    this.isDiv1Visible = false;
    this.isDiv2Visible = true;
  }


  countryChangedEvent(event: any) {
    this.form.patchValue({
      newPhoneCode: '+' + event.dialCode
    })
  }

  submitForm() {
    this.submitted = true
    if (!this.form.valid) {
      return;
    }
    this.subscription = this.recaptchaV3Service.execute('importantAction')
      .subscribe((token: string) => {

        const { newPhoneCode, newPhoneNo } = this.form.value
        const reqData = {
          ...this.form.value,
          newPhoneNo: newPhoneNo.replace(newPhoneCode, ''),
          roleType: this.isDiv1Visible ? 1 : 2,
          reCaptcha: token,
        }

        this.authService.forgotPasswordApi(reqData)
          .subscribe({
            next: (v: any) => {
              if (v.success) {
                this.submitted = false
                this.toastr.success(v.message)
                this.router.navigateByUrl('signin')
              } else if (v.status === 'MOBILE_USER') {
                this.toastr.success(v.message)
                this.timer(this.timerInSeconds);
                this.otpTextBox = true
                this.otpToken = v.userToken
              }
            },
            error: (err) => {
              this.submitted = false
              const { status, message, error } = err;
              if ([400, 404].includes(status)) {
                const validationErrors = error.errors || {};
                this.serverErrors = validationErrors;
              } else {
                this.toastr.error(message);
              }
            }
          })
      })
  }


  resendOtp() {
    this.timer(this.timerInSeconds);

    const reqData = {
      otpAuth: this.otpToken,
    }
    this.authService.resendOtpApi(reqData)
      .subscribe({
        next: (v: any) => {
          this.toastr.success(v.message)
        },
        error: (err: any) => {
          this.toastr.error(err.error.message)
        }
      })
  }

  timer(seconds: number) {
    this.seconds = seconds;
    const timer = setInterval(() => {
      this.seconds--;
      if (this.seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);
  }


  verifyOtp() {
    const { otp } = this.form.value
    const reqData = {
      otpAuth: this.otpToken,
      otp,
      optType: 'forget',
    }
    this.authService.verifyOtpApi(reqData)
      .subscribe({
        next: (v: any) => {
          this.toastr.success(v.message)
          this.form.reset()
          this.serverErrors = {}
          this.router.navigateByUrl(`/reset-password/${v.userToken}`)
        },
        error: (err: any) => {
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        }
      })
  }


  handleInputChange() {
    this.serverErrors = {}
  }
}
