<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Withdraw</h2>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <form action="#" class="auth_form" [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="sign_group sign_group_input">
                  <label>Select Coin</label>
                  <div class="deposit_crypto_inp" data-bs-toggle="modal" data-bs-target="#ChooseCrypto">
                    <div *ngIf="selectedCurrency?.image">
                      <img src="{{ selectedCurrency?.image }}" class="img-fluid" alt="QR" />
                      <h4>
                        {{ selectedCurrency?.coin }}
                        <span>{{ selectedCurrency?.name }}</span>
                      </h4>
                    </div>
                    <img src="assets/images/select_dropdown.png" class="img-fluid choose_crypto_select" alt="QR" />
                  </div>

                  <div *ngIf="
                      currencyId?.invalid && (currencyId?.touched || submitted)
                    ">
                    <span class="required" *ngIf="currencyId?.errors?.['required']">CurrencyId field is required</span>

                    <span class="required" *ngIf="serverErrors?.receiverAddress">{{ serverErrors.currencyId }}</span>
                  </div>

                  <!-- <div
                    *ngIf="
                      currencyId?.invalid && (currencyId?.touched || submitted)
                    "
                  >
                    <span
                      class="required"
                      *ngIf="currencyId?.errors?.['required']"
                      >Currency field is required</span
                    >
                  </div>
                  <span class="required" *ngIf="serverErrors?.currencyId">{{
                    serverErrors.currencyId
                  }}</span> -->
                </div>
                <div class="sign_group sign_group_input">
                  <label>Address*</label>

                  <div class="primary_inp_grp">
                    <input type="text" class="sign_input" value="" placeholder="Enter Receiver Address"
                      formControlName="receiverAddress" />
                    <div *ngIf="
                        receiverAddress?.invalid &&
                        (receiverAddress?.touched || submitted)
                      ">
                      <span class="required" *ngIf="receiverAddress?.errors?.['required']">Address field is
                        required</span>
                    </div>
                    <span class="required" *ngIf="serverErrors?.receiverAddress">{{ serverErrors.receiverAddress
                      }}</span>
                  </div>
                </div>
                <!-- <div class="sign_group sign_group_input">
                  <label>Choose Network</label>
                  <select class="sign_input">
                    <option>Choose Withdrawal Network</option>
                    <option>BEP20 (BSC)</option>
                    <option>ERC20</option>
                    <option>TRC20</option>
                  </select>
                </div> -->
                <div class="sign_group sign_group_input">
                  <label>Enter Amount*</label>
                  <div class="primary_inp_grp">
                    <input type="text" class="sign_input" formControlName="amount" placeholder="Enter Amount"
                      (input)="handleAmountChange($event)" />

                    <div *ngIf="amount?.invalid && (amount?.touched || submitted)">
                      <span class="required" *ngIf="amount?.errors?.['required']">Amount field is required</span>
                    </div>
                    <span class="required" *ngIf="serverErrors?.amount">{{
                      serverErrors.amount
                      }}</span>
                  </div>
                </div>

                <div class="sign_group sign_group_input">
                  <label>Receiving Amount</label>
                  <div class="primary_inp_grp">
                    <input type="text" class="sign_input" formControlName="finalAmount" placeholder="Enter Amount"
                      [appDecimalLimit]="8" placeholder="0" readonly="true" />
                  </div>
                </div>

                <div class="sign_group sign_group_input">
                  <label>Enter 6 Digit 2FA Code*</label>
                  <div class="primary_inp_grp">
                    <input type="text" class="sign_input" formControlName="twoFACode" placeholder="Enter 2FA Code" />

                    <div *ngIf="
                        twoFACode?.invalid && (twoFACode?.touched || submitted)
                      ">
                      <span class="required" *ngIf="twoFACode?.errors?.['required']">2FA Code is required</span>
                    </div>
                    <span class="required" *ngIf="serverErrors?.twoFACode">{{
                      serverErrors.twoFACode
                      }}</span>
                  </div>
                </div>

                <button class="trade_sell_btn" type="button" *ngIf="
                    user &&
                      user.aadharNumber &&
                      user.aadharNumber != 'verified';
                    else kycverified
                  " (click)="navigate('identification')">
                  KYC Not Verified
                </button>
                <ng-template #kycverified>
                  <button class="trade_sell_btn" type="button" *ngIf="
                      user &&
                        user.twoFAStatus &&
                        user.twoFAStatus === 'disabled' &&
                        user.aadharNumber == 'verified';
                      else depositSubmit
                    " (click)="navigate('security')">
                    Enable 2FA
                  </button>
                </ng-template>

                <ng-template #depositSubmit>
                  <button class="sign_btn" [disabled]="isLoading" type="submit">
                    <div *ngIf="isLoading; else submitButton">
                      <div class="spinner-border spinner-border-sm" role="status"></div>
                      Submitting...
                    </div>
                    <ng-template #submitButton> Submit </ng-template>
                  </button>
                </ng-template>

                <!-- <button class="sign_btn" [disabled]="isLoading">Submit</button> -->
              </form>
            </div>
            <div class="col-xl-6 col-lg-6 order-lg-last order-first">
              <div class="withdraw_bal_div">
                <div>
                  <label>Available Balance:</label>
                  <h4>
                    {{ truncateDecimals(selectedAsset?.spotBal, selectedCurrency?.decimals) }} {{ selectedAsset?.coin }}
                  </h4>
                </div>
                <div>
                  <label>Minimum Withdrawal:</label>
                  <h4>
                    {{ truncateDecimals(selectedCurrency?.minimumWithdraw, selectedCurrency?.decimals) }}
                    {{ selectedAsset?.coin }}
                  </h4>
                </div>

                <div>
                  <label>Maximum Withdrawal:</label>
                  <h4>
                    {{ truncateDecimals(selectedCurrency?.maximumWithdraw, selectedCurrency?.decimals) }}
                    {{ selectedAsset?.coin }}
                  </h4>
                </div>
                <div>
                  <label>Transaction Fee (%):</label>
                  <h4>
                    {{ truncateDecimals(selectedCurrency?.withdrawFee, selectedCurrency?.decimals) }}
                    {{ selectedAsset?.coin }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Choose Crypto Modal -->
  <div class="modal fade primary_modal" id="ChooseCrypto" tabindex="-1" aria-labelledby="ChooseCryptoLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="ChooseCryptoLabel">
            Select a Cryptocurrency
          </h1>
          <button type="button" class="btn-close" id="curmodalclose" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="token_search_list">
            <div class="token_search_list_row" *ngFor="let item of orgCurr">
              <div (click)="selectDropdown(item)">
                <img src="{{ item?.image }}" alt="{{ item.coin }}" />
                <div>
                  <span class="token_search_list_cur_name">{{
                    item?.coin
                    }}</span>
                  <span class="token_search_list_cur_value">{{
                    item?.name
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>