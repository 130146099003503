import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service'
import { AuthService } from 'src/app/services/auth.service'
import { WalletService } from 'src/app/services/wallet.service'

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { decryptString } from 'src/app/lib/cryptoJs';

interface Country {
  shortName: string;
  name: string;
}

interface CurrencyOptions {
  _id: string,
  status: string
  coin: string,
  type: string,
}

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent {

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;
  countries: Country[];
  currencyOption: CurrencyOptions[] = []
  id: string = ""
  // currencyOption: any[] = []


  constructor(
    private service: UserService,
    private toastr: ToastrService,
    private AuthService: AuthService,
    private WalletService: WalletService,
    private router: Router,
    private _fb: FormBuilder,
    private route: ActivatedRoute


  ) {
    this.countries = this.service.getCountries();
    let a = this.WalletService.getCurrency()

    this.form = this._fb.group({
      accountNo: ['', [Validators.required]],
      bankAddress: ['', [Validators.required]],
      bankName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      bankcode: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      currencyId: ['', [Validators.required]],
      holderName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]],
      accountType: ['', Validators.required],
      branchName: ['', Validators.required]
    });
  }

  get accountNo() { return this.form.get('accountNo') }
  get bankAddress() { return this.form.get('bankAddress') }
  get bankName() { return this.form.get('bankName') }
  get bankcode() { return this.form.get('bankcode') }
  get country() { return this.form.get('country') }
  get city() { return this.form.get('city') }
  get currencyId() { return this.form.get('currencyId') }
  get holderName() { return this.form.get('holderName') }
  get branchName() { return this.form.get('branchName') }
  get accountType() { return this.form.get('accountType') }

  ngOnInit() {
    this.getCurrency()
    const id = this.route.snapshot.params['id']
    this.id = decryptString(id, true)
    this.getData(this.id)
  }

  getCurrency() {
    this.WalletService.getCurrency()
      .subscribe({
        next: (v: any) => {
          this.currencyOption = v.result
        },
        error: err => { }
      })

  }

  getData(id: string) {
    this.service.getbankdetails()
      .subscribe({
        next: (v: any) => {
          let editResult: any;
          for (let item of v.result.bankDetails) {
            if (item._id == id) {
              editResult = item
            }
          }
          const { _id,
            accountNo,
            bankAddress,
            bankId,
            bankName,
            bankcode,
            city,
            country,
            currencyId,
            branchName,
            accountType,
            holderName } = editResult
          this.form.patchValue({
            _id,
            accountNo,
            bankAddress,
            bankId: _id,
            bankName,
            bankcode,
            accountType,
            branchName,
            city,
            country,
            currencyId,
            holderName,
          });
        },
        error: err => {
          // console.log('err on server: ', err)
        }
      })
  }


  onInputChange() {
    this.serverErrors = {}
  }
  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true
    let reqData = {
      ...this.form.value,
      bankId: this.id
    }

    this.service.addbank(reqData)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false

          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.router.navigateByUrl("/bank-info")
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

}


