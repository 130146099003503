<div class="create_ticket_div">
  <h5>Create Support Ticket</h5>
  <form
    action="#"
    class="auth_form row"
    [formGroup]="form"
    (ngSubmit)="submitForm()"
  >
    <div class="col-md-6">
      <div class="sign_group sign_group_input">
        <label>Ticket for</label>
        <select class="sign_input" formControlName="categoryId">
          <option value="">Choose Ticket Type</option>
          <option *ngFor="let item of categories" [value]="item._id">
            {{ item?.categoryName }}
          </option>
        </select>
        <div *ngIf="categoryId?.invalid && (categoryId?.touched || submitted)">
          <span class="required" *ngIf="categoryId?.errors?.['required']"
            >Category Name is required</span
          >
        </div>
        <span class="required" *ngIf="serverErrors?.categoryId">{{
          serverErrors.categoryId
        }}</span>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="sign_group sign_group_input">
        <label>Email Address</label>
        <input type="text" class="sign_input" placeholder="Enter Email" />
      </div>
    </div> -->
    <div class="col-md-12">
      <div class="sign_group sign_group_input">
        <label>Message to Support Team</label>
        <textarea
          type="number"
          class="sign_input"
          placeholder="Enter for the ticket description"
          rows="5"
          formControlName="message"
        ></textarea>

        <div *ngIf="messages?.invalid && (messages?.touched || submitted)">
          <span class="required" *ngIf="messages?.errors?.['required']"
            >Message is required</span
          >
        </div>
        <span class="required" *ngIf="serverErrors?.messages">{{
          serverErrors.message
        }}</span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="sign_group sign_group_input">
        <label>Upload Document</label>
        <div class="primary_inp_grp custom_file_upload">
          <input
            type="file"
            class="sign_input"
            (change)="upload($event)"
            placeholder="(.png/ .jpg/ .jpeg formats only)"
          />
          <label>(Max 1MB Allowed Only types jpg, jpeg, png, pdf)</label>
          <img
            src="assets/images/folder_icon.png"
            class="img-fluid copy_icon"
            alt="Copy"
          />
        </div>
        <span class="required" *ngIf="serverErrors?.image">{{
          serverErrors.image
        }}</span>
      </div>
      {{ preview }}
    </div>
    <div class="col-md-6">
      <button class="sign_btn" type="submit" [disabled]="isLoading">
        Submit Ticket
      </button>
    </div>
  </form>
</div>
