<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Edit Bank Details</h2>
          <p>Enter the following Bank Details</p>
          <form
            action="#"
            class="auth_form row"
            [formGroup]="form"
            (ngSubmit)="submitForm()"
          >
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Currency</label>
                <select class="sign_input" formControlName="currencyId">
                  <option value="">Choose Currency</option>
                  <ng-container *ngFor="let option of currencyOption">
                    <option [value]="option._id" *ngIf="option.type == 'fiat'">
                      {{ option.coin }}
                    </option>
                  </ng-container>
                </select>

                <div
                  *ngIf="
                    currencyId?.invalid && (currencyId?.touched || submitted)
                  "
                >
                  <span
                    class="required"
                    *ngIf="currencyId?.errors?.['required']"
                    >Currency field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.currencyId">{{
                  serverErrors.currencyId
                }}</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Bank Name</label>
                <input
                  type="text"
                  class="sign_input"
                  alphaOnly
                  placeholder="Enter Bank Name"
                  (input)="onInputChange()"
                  formControlName="bankName"
                />
                <div
                  *ngIf="bankName?.invalid && (bankName?.touched || submitted)"
                >
                  <span class="required" *ngIf="bankName?.errors?.['required']"
                    >Bank Name field is required</span
                  >
                  <span class="required" *ngIf="bankName?.errors?.['pattern']"
                    >Allow only alphabets</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.bankName">{{
                  serverErrors.bankName
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Account Type</label>
                <select class="sign_input" formControlName="accountType">
                  <option value="">Choose Account Type</option>
                  <option value="current">Current</option>
                  <option value="savings">Savings</option>
                </select>
                <div
                  *ngIf="
                    accountType?.invalid && (accountType?.touched || submitted)
                  "
                >
                  <span
                    class="required"
                    *ngIf="accountType?.errors?.['required']"
                    >Account Type field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.bankName">{{
                  serverErrors.accountType
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Branch Name</label>
                <input
                  type="text"
                  class="sign_input"
                  placeholder="Enter Branch Name"
                  (input)="onInputChange()"
                  formControlName="branchName"
                />
                <div
                  *ngIf="
                    branchName?.invalid && (branchName?.touched || submitted)
                  "
                >
                  <span
                    class="required"
                    *ngIf="branchName?.errors?.['required']"
                    >Branch Name field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.branchName">{{
                  serverErrors.branchName
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Account Number</label>
                <input
                  type="number"
                  class="sign_input"
                  alphaNumeric
                  placeholder="Enter Account Number"
                  (input)="onInputChange()"
                  formControlName="accountNo"
                  numbersOnly
                />

                <div
                  *ngIf="
                    accountNo?.invalid && (accountNo?.touched || submitted)
                  "
                >
                  <span class="required" *ngIf="accountNo?.errors?.['required']"
                    >Account Number field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.accountNo">{{
                  serverErrors.accountNo
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Account Holder Name</label>
                <input
                  type="text"
                  class="sign_input"
                  alphaOnly
                  placeholder="Enter Account Holder Name"
                  (input)="onInputChange()"
                  formControlName="holderName"
                />
                <div
                  *ngIf="
                    holderName?.invalid && (holderName?.touched || submitted)
                  "
                >
                  <span
                    class="required"
                    *ngIf="holderName?.errors?.['required']"
                    >Holder Name field is required</span
                  >
                  <span class="required" *ngIf="holderName?.errors?.['pattern']"
                    >Allow only alphabets</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.holderName">{{
                  serverErrors.holderName
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>IFSC/IBAN/Swift Code</label>
                <input
                  type="text"
                  class="sign_input"
                  alphaNumeric
                  placeholder="Enter IFSC/IBAN/Swift Code"
                  formControlName="bankcode"
                />
                <div
                  *ngIf="bankcode?.invalid && (bankcode?.touched || submitted)"
                >
                  <span class="required" *ngIf="bankcode?.errors?.['required']"
                    >IFSC field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.bankcode">{{
                  serverErrors.bankcode
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Bank Country</label>
                <select
                  class="sign_input"
                  formControlName="country"
                  (input)="onInputChange()"
                  formControlName="country"
                >
                  <option value="">Choose Country</option>
                  <option
                    *ngFor="let country of countries"
                    [value]="country.shortName"
                  >
                    {{ country.name }}
                  </option>
                </select>

                <div
                  *ngIf="country?.invalid && (country?.touched || submitted)"
                >
                  <span class="required" *ngIf="country?.errors?.['required']"
                    >Country is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.country">{{
                  serverErrors.country
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Bank City</label>
                <input
                  type="text"
                  class="sign_input"
                  alphaOnly
                  placeholder="Enter City"
                  (input)="onInputChange()"
                  formControlName="city"
                />
                <div *ngIf="city?.invalid && (city?.touched || submitted)">
                  <span class="required" *ngIf="city?.errors?.['required']"
                    >City field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.city">{{
                  serverErrors.city
                }}</span>
              </div>
            </div>

            <div class="col-md-6">
              <div class="sign_group sign_group_input">
                <label>Bank Address</label>
                <input
                  type="text"
                  class="sign_input"
                  placeholder="Enter Bank Address"
                  (input)="onInputChange()"
                  formControlName="bankAddress"
                />
                <div
                  *ngIf="
                    bankAddress?.invalid && (bankAddress?.touched || submitted)
                  "
                >
                  <span
                    class="required"
                    *ngIf="bankAddress?.errors?.['required']"
                    >Bank Address field is required</span
                  >
                </div>
                <span class="required" *ngIf="serverErrors?.bankAddress">{{
                  serverErrors.bankAddress
                }}</span>
              </div>
            </div>

            <div class="col-md-6">
              <button class="sign_btn" type="submit" [disabled]="isLoading">
                <span
                  *ngIf="isLoading"
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
