import { ActionReducer, createReducer, on } from '@ngrx/store';
import { initialState, DashboardState } from './dashboard.state';
import { setTotalBalance, reset } from './dashboard.actions';

export const dashboardFeatureKey = 'dashboard';

export const dashboardReducers: ActionReducer<DashboardState> = createReducer(
    initialState,
    on(setTotalBalance, (state, action) => ({ ...state, totalBalance: action.payload })),
    on(reset, () => initialState),
);