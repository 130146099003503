<div class="baseblock_inner baseblock_inner_trade_chart">
    <div class="baseblock_content">
        <div class="tradingchart_wrap">
            <div class="tradingview_chart" id="tv_chart_container">                
                <div class="chart_img">
                    <div class="app-tv-chart-container" id="tv_chart_container" style="height:100%;"></div>
                </div>
            </div>
        </div>
    </div>
</div>