<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">History</h2>
          <ul class="nav nav-pills primary_tab" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="currentpath == 'txn' ? 'active' : ''"
                type="button"
                role="tab"
                (click)="gotohistory('txn')"
              >
                Transaction History
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="currentpath == 'order' ? 'active' : ''"
                type="button"
                role="tab"
                (click)="gotohistory('order')"
              >
                Order History
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                [ngClass]="currentpath == 'trade' ? 'active' : ''"
                type="button"
                role="tab"
                (click)="gotohistory('trade')"
              >
                Trade History
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <app-transactionhistory
              *ngIf="currentpath == 'txn'"
            ></app-transactionhistory>
            <app-order-history
              *ngIf="currentpath == 'order'"
            ></app-order-history>
            <app-trade-history
              *ngIf="currentpath == 'trade'"
            ></app-trade-history>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
