<app-header-dashboard></app-header-dashboard>
<main class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="dashbox">
          <h2 class="dashbox_title">Security</h2>
          <div class="security_panel">
            <div class="security_panel_row">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 480">
                  <defs>
                    <style>
                      .cls-1 {
                        fill-rule: evenodd;
                      }
                    </style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path class="cls-1"
                        d="M240,300.13a58.5,58.5,0,0,0,47.33-57.21V201.69L240,181.41l-47.34,20.28v41.23A58.52,58.52,0,0,0,240,300.13Zm-31.69-67.72a8.92,8.92,0,0,1,12.61,0l13.44,13.43,29.23-29.52a8.9,8.9,0,0,1,12.63,12.54c-11.85,12-23.63,24-35.54,35.92a8.92,8.92,0,0,1-12.61,0L208.31,245a8.92,8.92,0,0,1,0-12.61ZM240,330.75c43.68-7.62,76-42.42,76-87.51V181.81l-76-32.56-76,32.56v61.43C164,288.33,196.32,323.13,240,330.75ZM176.67,191.14,240,164l63.33,27.14v51.78a74.52,74.52,0,0,1-62,73.23l-1.37.24-1.37-.24a74.52,74.52,0,0,1-62-73.23V191.14ZM58.22,237.35V60.46H300l-6.22-4.86A8,8,0,1,1,303.63,43L328,62.09a8,8,0,0,1,.12,12.65L303.63,93.88a8,8,0,1,1-9.81-12.56L300,76.46H74.22V237.35a8,8,0,0,1-16,0Zm375.27,22V425.64H203.38l6.22,4.86a8,8,0,1,1-9.81,12.56l-24.51-19.14a8,8,0,0,1,.12-12.66l24.39-19a8,8,0,1,1,9.81,12.56l-6.22,4.86H417.49V259.39a8,8,0,0,1,16,0ZM123.84,371.21H8.6a8.61,8.61,0,0,0-8.6,8.6V471.4A8.61,8.61,0,0,0,8.6,480H123.84a8.61,8.61,0,0,0,8.6-8.6V379.81A8.61,8.61,0,0,0,123.84,371.21ZM76.21,429.49V447a10,10,0,1,1-20,0v-17.5a19.05,19.05,0,1,1,20,0ZM40.7,355.21H19.16V344.42a47.06,47.06,0,1,1,94.12,0v10.79H91.74V344.42a25.52,25.52,0,1,0-51,0ZM401.61,11.89,397.45,0h56.09l-4.17,11.89ZM471.37,0h-.88l-9.78,27.89H390.27L380.49,0h-.88A8.66,8.66,0,0,0,371,8.63V190.75a8.67,8.67,0,0,0,8.63,8.64h91.76a8.67,8.67,0,0,0,8.63-8.64V8.63A8.66,8.66,0,0,0,471.37,0Zm-38.3,185.16H417.91a5.67,5.67,0,0,1,0-11.34h15.16a5.67,5.67,0,1,1,0,11.34Z" />
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>2 Factor Authentication</h2>
                  <span class="">How to Activate 2FA Authentication:<a (click)="videoModal()"  class="cursor-pointer"> Click Here </a></span>
                  <p>
                    With 2 Factor Authentication, only you can access your
                    account even if someone else has your password.
                  </p>
                </div>
              </div>
              <button class="deposit_btn" data-bs-toggle="modal" data-bs-target="#tfa_enable_modal"
                *ngIf="profileDetails.twoFAStatus != 'enabled'">
                Enable
              </button>

              <button class="deposit_btn" data-bs-toggle="modal" data-bs-target="#tfa_disable_modal"
                *ngIf="profileDetails.twoFAStatus == 'enabled'">
                Disable
              </button>
            </div>

            <div class="security_panel_row">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <g id="Page-1">
                        <g id="_010---Secure-Password" data-name="010---Secure-Password">
                          <path id="Shape"
                            d="M438.86,228.57a27.43,27.43,0,0,0-27.43-27.43H100.57a27.43,27.43,0,0,0-27.43,27.43v54.86H438.86ZM128,237.71H109.71V256a9.14,9.14,0,0,1-18.28,0V228.57a9.14,9.14,0,0,1,9.14-9.14H128a9.14,9.14,0,0,1,0,18.28Z" />
                          <path id="Shape-2" data-name="Shape"
                            d="M73.14,484.57A27.43,27.43,0,0,0,100.57,512H411.43a27.43,27.43,0,0,0,27.43-27.43V429.71H73.14Z" />
                          <path id="Shape-3" data-name="Shape"
                            d="M502.86,301.71H9.14A9.15,9.15,0,0,0,0,310.86v91.43a9.14,9.14,0,0,0,9.14,9.14H502.86a9.14,9.14,0,0,0,9.14-9.14V310.86A9.15,9.15,0,0,0,502.86,301.71ZM87.36,367.25a9.14,9.14,0,0,1-10.15,15.21L64,373.65V384a9.15,9.15,0,0,1-18.29,0V373.65L32.5,382.46a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68A9.14,9.14,0,1,1,32.5,330.68l13.21,8.81V329.14a9.15,9.15,0,0,1,18.29,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,0,1-10.15,15.21l-13.21-8.81V384a9.14,9.14,0,1,1-18.28,0V373.65l-13.22,8.81a9.14,9.14,0,1,1-10.14-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.15-15.21l13.19,8.81V329.14a9.14,9.14,0,1,1,18.28,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,1,1-10.15,15.21l-13.21-8.81V384a9.14,9.14,0,0,1-18.28,0V373.65l-13.21,8.81a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.15-15.21l13.19,8.81V329.14a9.14,9.14,0,0,1,18.28,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,1,1-10.14,15.21l-13.22-8.81V384a9.14,9.14,0,0,1-18.28,0V373.65l-13.21,8.81a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.14-15.21l13.19,8.81V329.14a9.14,9.14,0,0,1,18.28,0v10.35l13.22-8.81a9.14,9.14,0,0,1,10.14,15.21l-16,10.68Zm95.5,25.89H429.71a9.14,9.14,0,0,1,0-18.28h54.86a9.14,9.14,0,0,1,0,18.28Z" />
                          <path id="Shape-4" data-name="Shape"
                            d="M155.43,137.14a100.57,100.57,0,0,1,201.14,0v45.72h36.57V137.14a137.14,137.14,0,0,0-274.28,0v45.72h36.57Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>Change Password</h2>
                  <p>
                    Strong password required. Enter at least 6 characters long.
                    Combine uppercase, lowercase letters, numbers and symbols.
                  </p>
                </div>
              </div>
              <button routerLink="/change-password" class="withdraw_btn">Change</button>
            </div>

            <div class="security_panel_row">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 496.01">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #43adf7;
                        fill-rule: evenodd;
                      }
                    </style>
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path class="cls-1"
                        d="M83.83,0H391.14a43.93,43.93,0,0,1,43.8,43.8V452.21a43.93,43.93,0,0,1-43.8,43.8H83.83A43.93,43.93,0,0,1,40,452.21v-2.59H94.79V374.23H40V285.7H94.79V210.31H40V121.78H94.79V46.39H40V43.8A43.93,43.93,0,0,1,83.83,0ZM0,226.31V269.7H78.79V226.31ZM0,390.22v43.39H78.79V390.22ZM0,62.39v43.39H78.79V62.39ZM450.94,242.21H496v101H450.94Zm0-146.44H496v101H450.94ZM209.47,251.52a194.83,194.83,0,0,1,3.43-57.86l43.3-3.33L282,139.78c2.69-5.26,3.21-11.33-1.31-15.12L236.06,87.13c-43.54,31.87-70.13,96.19-63.92,167.64S215.62,385,264,408.87l37.52-44.66c3.8-4.52,2.24-10.41-1.31-15.12l-34.16-45.34-43.23,4.17a194.57,194.57,0,0,1-13.35-56.4Z" />
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>Change Phone Number</h2>
                  <p>
                    <!-- You will need to log in to your account after changing your phone number -->
                    {{profileDetails.phoneCode + ' ' + profileDetails.phoneNo}}
                    <span [ngClass]="{
                        'text-success': profileDetails.phoneStatus === 'verified', 
                        'text-danger': profileDetails.phoneStatus !== 'verified'
                      }">
                      {{profileDetails.phoneStatus | titlecase}}
                    </span>
                  </p>
                </div>
              </div>
              <button routerLink="/change-phone-number" class="withdraw_btn">Change</button>
            </div>


            <!-- <div class="security_panel_row">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <g id="Page-1">
                        <g id="_010---Secure-Password" data-name="010---Secure-Password">
                          <path id="Shape"
                            d="M438.86,228.57a27.43,27.43,0,0,0-27.43-27.43H100.57a27.43,27.43,0,0,0-27.43,27.43v54.86H438.86ZM128,237.71H109.71V256a9.14,9.14,0,0,1-18.28,0V228.57a9.14,9.14,0,0,1,9.14-9.14H128a9.14,9.14,0,0,1,0,18.28Z" />
                          <path id="Shape-2" data-name="Shape"
                            d="M73.14,484.57A27.43,27.43,0,0,0,100.57,512H411.43a27.43,27.43,0,0,0,27.43-27.43V429.71H73.14Z" />
                          <path id="Shape-3" data-name="Shape"
                            d="M502.86,301.71H9.14A9.15,9.15,0,0,0,0,310.86v91.43a9.14,9.14,0,0,0,9.14,9.14H502.86a9.14,9.14,0,0,0,9.14-9.14V310.86A9.15,9.15,0,0,0,502.86,301.71ZM87.36,367.25a9.14,9.14,0,0,1-10.15,15.21L64,373.65V384a9.15,9.15,0,0,1-18.29,0V373.65L32.5,382.46a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68A9.14,9.14,0,1,1,32.5,330.68l13.21,8.81V329.14a9.15,9.15,0,0,1,18.29,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,0,1-10.15,15.21l-13.21-8.81V384a9.14,9.14,0,1,1-18.28,0V373.65l-13.22,8.81a9.14,9.14,0,1,1-10.14-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.15-15.21l13.19,8.81V329.14a9.14,9.14,0,1,1,18.28,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,1,1-10.15,15.21l-13.21-8.81V384a9.14,9.14,0,0,1-18.28,0V373.65l-13.21,8.81a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.15-15.21l13.19,8.81V329.14a9.14,9.14,0,0,1,18.28,0v10.35l13.21-8.81a9.14,9.14,0,1,1,10.15,15.21l-16,10.68Zm100.57,0a9.14,9.14,0,1,1-10.14,15.21l-13.22-8.81V384a9.14,9.14,0,0,1-18.28,0V373.65l-13.21,8.81a9.14,9.14,0,1,1-10.15-15.21l16-10.68-16-10.68a9.14,9.14,0,1,1,10.14-15.21l13.19,8.81V329.14a9.14,9.14,0,0,1,18.28,0v10.35l13.22-8.81a9.14,9.14,0,0,1,10.14,15.21l-16,10.68Zm95.5,25.89H429.71a9.14,9.14,0,0,1,0-18.28h54.86a9.14,9.14,0,0,1,0,18.28Z" />
                          <path id="Shape-4" data-name="Shape"
                            d="M155.43,137.14a100.57,100.57,0,0,1,201.14,0v45.72h36.57V137.14a137.14,137.14,0,0,0-274.28,0v45.72h36.57Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <div class="security_panel_content">
                  <h2>Deactivate Your Account</h2>
                  <p>
                    This is permanent. This account will no longer be availabe and all data will be deleted. This can't be be undone.
                  </p>
                </div>
              </div>
              <button routerLink="/deactivation" class="withdraw_btn">Delete Account</button>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TFA Enable Modal -->
  <div class="modal fade primary_modal" [style]="{ display: !showotpModal ? 'none' : '' }" id="tfa_enable_modal"
    tabindex="-1" aria-labelledby="tfa_enable_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="tfa_enable_modalLabel">
            Email OTP Verification
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="otpform">
            <div class="sign_group sign_group_input mb-2">
              <label>Enter the 6 digit code</label>
              <div class="primary_inp_grp">
                <input type="text" class="sign_input" placeholder="Enter OTP" formControlName="emailOTP"
                  (input)="hanldleChange()" />
              </div>
              <span class="required" *ngIf="serverErrors?.emailOTP">{{
                serverErrors?.emailOTP
                }}</span>
            </div>
            <button *ngIf="!otpsent" type="button" class="sign_btn" (click)="sendOTP()">
              Send Code
            </button>

            <button type="button" class="sign_btn" (click)="verifyOTP()">
              Submit
            </button>
            <p class="text-center mt-2" *ngIf="otpsent">
              Didn't receive code ?
              <a href="javascript:void(0)" (click)="sendOTP()">Resend</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- TFA Disable Modal -->
  <div class="modal fade primary_modal" [style]="{ display: !showotpModal ? 'none' : '' }" id="tfa_disable_modal"
    tabindex="-1" aria-labelledby="tfa_disable_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="tfa_disable_modalLabel">
            Disable 2FA
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="disableform">
            <div class="sign_group sign_group_input mb-2">
              <label>Enter the 6 digit code</label>
              <div class="primary_inp_grp">
                <input type="text" class="sign_input" placeholder="Enter code" formControlName="code" />
              </div>
              <span class="required" *ngIf="serverErrors.code">{{
                serverErrors.code
                }}</span>
            </div>

            <button type="button" class="sign_btn" (click)="disable2fasubmit()">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Google Authenticator Enable Modal -->
  <div class="modal primary_modal" [style]="{ display: showModal ? 'block' : 'none' }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="googleauth_enable_modalLabel">
            Enable Google Authenticator
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="showmodal()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="tfaform">
            <div class="google_auth_qr text-center mb-3">
              <p>
                Enable Two Factor Authentication as an additional security measure,
                to protect your funds and be able to withdraw.
                Please use Google Authenticator which you can <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_BLANK">Download</a> Here
              </p>
              <p>
                If you want to turn on 2FA, use Google Authenticator app to scan
                code, then enter six-digit code provided by the app to the form
                below.
              </p>

              <p>Scan QR Code</p>
              <img src="{{ profileDetails.imageUrl }}" class="img-fluid" alt="QR" />
              <p>Can't scan the QR Code? Configure your app with this key:</p>
            </div>

            <div class="sign_group sign_group_input mb-4">
              <div class="primary_inp_grp">
                <input type="text" class="sign_input" value="{{ profileDetails.secret }}" />
                <img src="assets/images/copy_icon.png" class="img-fluid copy_icon" alt="Copy"
                  (click)="CopyText(profileDetails.secret)" />
              </div>
            </div>

            <div class="sign_group sign_group_input mb-4">
              <label>Enter Authentication Code</label>
              <div class="primary_inp_grp">
                <input type="text" class="sign_input" formControlName="code" placeholder="Enter code" />
                <span class="required" *ngIf="serverErrors.code">{{
                  serverErrors.code
                  }}</span>
              </div>
            </div>

            <div>
              <div class="sign_group sign_group_checkbox">
                <div>
                  <input id="aggree" name="aggree" type="checkbox" autoComplete="off" (input)="onInputChange()"
                    formControlName="aggree" />

                  <label for="aggree">I have backup the code</label>
                </div>
                <span class="required" *ngIf="serverErrors?.aggree">{{serverErrors.aggree}}</span>
              </div>
            </div>
            <button class="sign_btn" type="button" (click)="tfaSubmit()">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- youtube video viewer  -->
  <div class="modal primary_modal" [style]="{ display: showVideoModal ? 'block' : 'none' }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="googleauth_enable_modalLabel">
            Know About 2fa Authentication
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="videoModal()"></button>
        </div>
        <div class="modal-body">
          <iframe width="470" height="270" src="https://www.youtube.com/embed/WuTCOu1pU74?si=Rdrf3z2e62xqwUJg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
  
</main>