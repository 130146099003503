import { Component, Input } from '@angular/core';
import { SupporttiketDetailsComponent } from '../supporttiket-details/supporttiket-details.component';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subject } from "rxjs";

interface Category {
  _id: string;
  categoryName: string;
}

@Component({
  selector: 'app-supportticket-form',
  templateUrl: './supportticket-form.component.html',
  styleUrls: ['./supportticket-form.component.css'],
})
export class SupportticketFormComponent {

  @Input() supportprops: Subject<boolean> = new Subject<boolean>();

  form: FormGroup;
  serverErrors: any;
  submitted: boolean = false;
  isLoading: boolean = false;
  preview: string = "";
  categories: Category[] = [];

  constructor(
    private service: UserService,
    private AuthService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private _fb: FormBuilder
  ) {
    this.form = this._fb.group({
      categoryId: ['', [Validators.required]],
      message: ['', [Validators.required]],
      image: ['']
    });
  }

  get categoryId() { return this.form.get('categoryId') }
  get messages() { return this.form.get('message') }

  ngOnInit() {
    this.getCategory();
  }

  getCategory() {
    this.service.getsupportcategory().subscribe({
      next: (v: any) => {
        this.categories = v.result;
        // this.form.patchValue({
        //   firstName: data.firstName,
        //   lastName: data.lastName,
        //   email: data.email,
        //   phoneNo: data.phoneCode + data.phoneNo,
        //   country: data.country,
        //   state: data.state,
        //   city: data.city,
        //   postalCode: data.postalCode

        // })
      },
      error: (err: any) => {
        this.submitted = false;
        if ([400, 404].includes(err.status)) {
          const validationErrors = err.error.errors;
          this.serverErrors = validationErrors || {};
        } else if (err.status === 500) {
          this.toastr.error(err.error.message);
        }
      },
      complete: () => { },
    });
  }

  submitForm() {
    this.submitted = true;
    if (!this.form.valid)
      return
    this.isLoading = true

    const formValue = this.form.value
    var formData = new FormData();
    // console.log(formValue, 'formValue.endTimeStamp')
    formData.append('categoryId', formValue.categoryId);
    formData.append('message', formValue.message);
    formData.append('image', formValue.image);

    this.service.createnewticket(formData)
      .subscribe({
        next: (v: any) => {
          this.isLoading = false

          if (v.success) {
            this.submitted = false
            this.toastr.success(v.message)
            this.form.reset()
            this.serverErrors = {}
            this.supportprops.next(true);
            this.preview = ""


            // this.childC.update();



            //this.router.navigateByUrl("/support-ticket")
          }
        },
        error: (err: any) => {
          this.submitted = false
          this.isLoading = false
          if ([400, 404].includes(err.status)) {
            const validationErrors = err.error.errors;
            this.serverErrors = validationErrors || {};
          } else if (err.status === 500) {
            this.toastr.error(err.error.message)
          }
        },
        complete: () => { }
      })
  }

  upload(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.form.patchValue({
      image: files[0]
    })
    const reader = new FileReader();
    reader.onload = () => {
      reader.result as string;
      // this.preview = reader.result as string;
      this.preview = files[0].name;

    }
    reader.readAsDataURL(files[0])
  }


}




