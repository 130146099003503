import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { setUser, reset } from './user.actions';
import { selectUser } from './user.selectors';
import { Observable } from 'rxjs';
import { UserState } from './user.state';

@Injectable({ providedIn: 'root' })
export class UserFacade {
    private readonly store: Store = inject(Store);

    readonly user$: Observable<UserState> = this.store.select(selectUser);

    
    setUser(payload: any): void {
        this.store.dispatch(setUser({ payload }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }

    userProfile$ = this.store.pipe(select(selectUser));
    fetchProfileUpdateStatus() {
        return this.userProfile$;
    }

}